<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">{{modalOptions?.type == 'ADD' ? 'Agregar' : (modalOptions?.type == 'EDIT' ? 'Editar' : 'Detalle de')}} Cliente</h6>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<mk-loader *ngIf="isLoading" class="loader-spinner" ></mk-loader>

<div class="modal-body py-0">
    <form [formGroup]="FormCustomer" novalidate>
        <div class="row">
            <div class="col-sm-12 col-lg-5 py-3 border-right">
                <div class="row mb-2"> 
                    <div class="col-{{modalOptions?.type != 'ADD' ? 10:12}}">
                        <h6>Nombre <span class="text-danger">*</span></h6>
                        <div class="input-group">
                            <input type="text" class="form-control" maxlength="60" placeholder="Nombre del cliente" formControlName="name" >
                        </div>
                        <small *ngIf="validateForm.campoEsValido('name', FormCustomer)" class="text-danger">Campo requerido<br>Máximo 60 caracteres</small> 
                    </div>
                    <div *ngIf="modalOptions?.type != 'ADD'" class="col-2 text-right">
                        <h6 class="mb-3">Estado</h6>
                        <label class="custom-switch my-auto cursor-pointer">
                            <input type="checkbox" name="custom-switch-checkbox" name="as" formControlName="active" class="custom-switch-input" >
                            <span class="custom-switch-indicator"></span>
                        </label>
                    </div>
                </div> 
                <div class="row">
                    <div class="col-sm-7">
                        <h6>Razón Social</h6>
                        <div class="input-group">
                            <input type="text" class="form-control" maxlength="60" placeholder="Razón Social" formControlName="businessName" >
                        </div>
                        <small *ngIf="validateForm.campoEsValido('businessName', FormCustomer)" class="text-danger">Máximo 60 caracteres</small> 
                    </div> 
                    <div class="col-sm-5">
                        <h6>RFC</h6>
                        <div class="input-group">
                            <input type="text" class="form-control" maxlength="13" minlength="12" placeholder="RFC" formControlName="rfc" >
                        </div>
                        <small *ngIf="validateForm.campoEsValido('rfc', FormCustomer)" class="text-danger">Máx. 13 caracteres, Min. 12 caracteres</small> 
                    </div> 
                </div>

                <!--* Contacto Operativo -->
                <div class="row mt-3 border-top pt-2 ">
                    <div class="col-12 mt-1"><span class="text-center"><h5>Contacto Operativo</h5></span></div>
                    <div class="col-12 mt-1">
                        <h6>Nombre</h6>
                        <input type="text" class="form-control" placeholder="Nombre de contacto" maxlength="30" formControlName="operationalContact"> 
                    </div>   

                    <div class="col-12 d-flex flex-wrap pt-2">
                        <h6>Teléfono(s)</h6>
                        <div class="input-group">
                            <ng-select class="form-control px-1 text-left" [disabled]="blockEdition" [clearable]="false" [(ngModel)]="typePhone" (change)="changePhoneType($event)"
                            [ngModelOptions]="{standalone: true}" placeholder="Tipo de telefono">
                                <ng-option value="CEL">Celular</ng-option>
                                <ng-option value="HOME">Casa</ng-option>
                                <ng-option value="OFFICE">Oficina</ng-option>
                            </ng-select>

                            <input type="text" class="form-control" 
                                (keypress)="validator.isInteger($event);"  
                                [placeholder]="typePhone!='OFFICE'?'(___)___-____':'(___)___-____;__'" 
                                [(ngModel)]="inpPhone"
                                [ngModelOptions]="{standalone: true}"
                                [disabled]="blockEdition"
                                [textMask]="{mask: phoneMaskCO, guide: false}" 
                                >
                                
                            <div class="input-group-append">
                                <button type="button" class="btn btn-dark" (click)="addPhones()" [disabled]="inpPhone?.length < 9"><i class="fa fa-plus mr-2"></i>Añadir</button>
                            </div>
                        </div>
                    </div>   
                    <div class="col-12 d-flex flex-wrap pt-2" *ngIf="phones?.length">
                        <ng-container *ngFor="let item of phones; index as i">
                            <span class="_pill badge-{{item.primary ? 'primary-light' : 'medium-light'}} d-flex m-0 mb-1 mr-1" >
                                <div class="d-flex cursor-pointer" (click)="primaryPhone(true, i)">
                                    <span *ngIf="item.primary" style="margin-left: -5px;"><i class="fa fa-star mr-1 text-warning"></i></span>
                                    <b>{{item.phone}} <i class="fa fa-{{item.type == 'HOME' ? 'home':(item.type == 'OFFICE'?'building':'mobile')}} ml-1"></i></b>
                                </div>
                                <i class="fa fa-close ml-2 my-auto cursor-pointer text-danger" (click)="phones.splice(i,1)"></i>
                            </span>
                        </ng-container>
                    </div>

                    <div class="col-12 mt-2">
                        <h6>Correo(s)</h6>
                        <div class="input-group">
                            <input #inpEmail type="text" class="form-control" placeholder="Correo" maxlength="40" [disabled]="blockEdition"> 
                            <ng-select class="form-control" placeholder="Dominio" 
                                [clearable]="false"  
                                [items]="domainsData"
                                bindLabel="name" bindValue="name"
                                [(ngModel)]="domain"
                                [ngModelOptions]="{standalone: true}"
                                [disabled]="blockEdition"
                            >
                            </ng-select>
                            <div class="input-group-append"> 
                                <button type="button" class="btn btn-dark"  (click)="addEmails(inpEmail.value, domain)" [disabled]="!inpEmail.value || !domain"><i class="fa fa-plus mr-2"></i>Añadir</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex flex-wrap pt-2" *ngIf="emails?.length">
                        <ng-container *ngFor="let item of emails; index as i">
                            <span class="_pill badge-{{item.primary ? 'primary-light' : 'medium-light'}} d-flex m-0 mb-1 mr-1" >
                                <div class="d-flex cursor-pointer" (click)="primaryMail(true, i)">
                                    <span *ngIf="item.primary" style="margin-left: -5px;"><i class="fa fa-star mr-1 text-warning"></i></span>
                                    <b>{{item.email}}</b>
                                </div>
                                <i class="fa fa-close ml-2 my-auto cursor-pointer text-danger" (click)="emails.splice(i,1)"></i>
                            </span>
                        </ng-container>
                    </div>

                </div>

                <!-- ^Contacto Administrativo -->
                <div class="row mt-3 border-top pt-2 ">
                    <div class="col-12 mt-1"><span class="text-center"><h5>Contacto Administrativo</h5></span></div>
                    <div class="col-12 mt-1">
                        <h6>Nombre</h6>
                        <input type="text" class="form-control" placeholder="Nombre de contacto" maxlength="30" formControlName="administrativeContact"> 
                    </div>   

                    <div class="col-12 d-flex flex-wrap pt-2">
                        <h6>Teléfono(s)</h6>
                        <div class="input-group">
                            <ng-select class="form-control px-1 text-left" [disabled]="blockEdition" [clearable]="false" [(ngModel)]="typePhoneCA" (change)="changePhoneType($event, 'CA')"
                            [ngModelOptions]="{standalone: true}" placeholder="Tipo de telefono">
                                <ng-option value="CEL">Celular</ng-option>
                                <ng-option value="HOME">Casa</ng-option>
                                <ng-option value="OFFICE">Oficina</ng-option>
                            </ng-select>

                            <input type="text" class="form-control" 
                                (keypress)="validator.isInteger($event);"  
                                [placeholder]="typePhoneCA!='OFFICE'?'(___)___-____':'(___)___-____;__'" 
                                [(ngModel)]="inpPhoneCA"
                                [ngModelOptions]="{standalone: true}"
                                [textMask]="{mask: phoneMaskCA, guide: false}" 
                                [disabled]="blockEdition"
                                >
                                
                            <div class="input-group-append">
                                <button type="button" class="btn btn-dark" (click)="addPhones('CA')" [disabled]="inpPhoneCA?.length < 9"><i class="fa fa-plus mr-2"></i>Añadir</button>
                            </div>
                        </div>
                    </div>   
                    <div class="col-12 d-flex flex-wrap pt-2" *ngIf="phonesCA?.length">
                        <ng-container *ngFor="let item of phonesCA; index as i">
                            <span class="_pill badge-{{item.primary ? 'primary-light' : 'medium-light'}} d-flex m-0 mb-1 mr-1" >
                                <div class="d-flex cursor-pointer" (click)="primaryPhone(true, i, 'CA')">
                                    <span *ngIf="item.primary" style="margin-left: -5px;"><i class="fa fa-star mr-1 text-warning"></i></span>
                                    <b>{{item.phone}} <i class="fa fa-{{item.type == 'HOME' ? 'home':(item.type == 'OFFICE'?'building':'mobile')}} ml-1"></i></b>
                                </div>
                                <i class="fa fa-close ml-2 my-auto cursor-pointer text-danger" (click)="phonesCA.splice(i,1)"></i>
                            </span>
                        </ng-container>
                    </div>

                    <div class="col-12 mt-2">
                        <h6>Correo(s)</h6>
                        <div class="input-group">
                            <input #inpEmailBill type="text" class="form-control" [disabled]="blockEdition" placeholder="Correo Administrativo" maxlength="30"> 
                            <ng-select class="form-control" placeholder="Dominio" 
                                [clearable]="false"  
                                [items]="domainsData"
                                bindLabel="name" bindValue="name"
                                [(ngModel)]="domainBill"
                                [ngModelOptions]="{standalone: true}"
                                [disabled]="blockEdition"
                            >
                            </ng-select>
                            <div class="input-group-append"> 
                                <button type="button" class="btn btn-dark"  (click)="addEmailsBill(inpEmailBill.value, domainBill)" [disabled]="!inpEmailBill.value || !domainBill"><i class="fa fa-plus mr-2"></i>Añadir</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex flex-wrap pt-2" *ngIf="billMails?.length">
                        <ng-container *ngFor="let item of billMails; index as i">
                            <span class="_pill badge-{{item.primary ? 'primary-light' : 'medium-light'}} d-flex m-0 mb-1 mr-1" >
                                <div class="d-flex cursor-pointer" (click)="primaryMailBill(true, i)">
                                    <span *ngIf="item.primary" style="margin-left: -5px;"><i class="fa fa-star mr-1 text-warning"></i></span>
                                    <b>{{item.email}}</b>
                                </div>
                                <i class="fa fa-close ml-2 my-auto cursor-pointer text-danger" (click)="billMails.splice(i,1)"></i>
                            </span>
                        </ng-container>
                    </div>

                </div>
                
            </div> 

            <div class="col-sm-12 col-lg-7 py-3">
                <div class="row">
                    <div class="col-12">
                        <h6>Dirección</h6>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Dirección" maxlength="120" formControlName="address">
                        </div>
                        <small *ngIf="validateForm.campoEsValido('address', FormCustomer)" class="text-danger">Máx. 120 caracteres</small> 
                    </div>
                </div>
                <div class="row mt-2">
                    <!-- <div class="col-6">
                        <h6>Vendedor</h6>
                        <div class="input-group">
                            <select class="form-control" placeholder="Vendedor" formControlName="seller">
                                <option value="" selected hidden>Seleccione Vendedor</option>
                            </select>
                        </div>
                    </div>  -->
                    <div class="col-lg-4">
                        <h6>Codigo Postal</h6>
                        <div class="input-group">
                            <ng-select class="form-control" placeholder="Selecciona" formControlName="zipCode" 
                                placeholder="Seleccione codigo postal"
                                [items]="zip$ | async"
                                bindLabel="code" 
                                bindValue="code"
                                [minTermLength]="minLengthTerm-1" 
                                [loading]="zipLoading"
                                typeToSearchText="Porfavor escriba {{minLengthTerm}} o mas caracteres" [typeahead]="zipInput$"
                                [clearable]="false" 
                            ></ng-select>
                        </div>
                    </div>
                    <div class="col-8">
                        <h6>Referencia <span class="text-danger">*</span></h6>
                        <div class="input-group">
                            <ng-select class="form-control" placeholder="Referencias" 
                                formControlName="reference"
                                [clearable]="false"  
                                [items]="referencesData"
                                bindLabel="name" 
                                bindValue="_id"
                                [multiple]="true"
                            ></ng-select>
                        </div>
                        <small *ngIf="validateForm.campoEsValido('reference', FormCustomer)" class="text-danger">Campo requerido</small> 
                    </div> 
                </div> 
                <div class="row mt-2" formGroupName="bankAccount">
                    <div class="col-sm-6">
                        <h6>Nombre de Banco</h6>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Nombre de banco de Cliente" maxlength="60" formControlName="name">
                        </div>
                        <small *ngIf="validateForm.campoEsValido('name', FormCustomer, true, 'bankAccount')" class="text-danger">Máx. 60 caracteres</small> 
                    </div> 
                    <div class="col-sm-6">
                        <h6>Número de Cuenta </h6>
                        <div class="input-group">
                            <input type="text" (keypress)="validator.isInteger($event);" class="form-control" placeholder="Número de cuenta de cliente" maxlength="20" formControlName="number">
                        </div>
                        <small *ngIf="validateForm.campoEsValido('number', FormCustomer, true, 'bankAccount')" class="text-danger">Máx. 20 caracteres</small> 
                    </div> 
                </div>

                <div class="row mt-2">
                    <div class="col-sm-12 col-md-6 col-lg-8">
                        <h6>Forma de pago</h6>
                        <div class="input-group"> 
                            <ng-select class="form-control" [items]="paymentForm" [multiple]="false" formControlName="paymentType"
                                placeholder="Forma de pago" bindLabel="text" appearance="true" bindValue="value" [hideSelected] >
                            </ng-select>
                        </div> 
                    </div> 
                    <div class="col-sm-12 col-md-6 col-lg-4 d-flex justify-content-between pt-4">
                        <h6 class="my-auto">Req. Pago Inmediato</h6>
                        <label class="custom-switch my-auto cursor-pointer">
                            <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input"  formControlName="immediatePayment">
                            <span class="custom-switch-indicator"></span>
                        </label>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-6">
                        <h6>Método de pago</h6>
                        <div class="input-group">
                            <ng-select class="form-control" [items]="paymentMethod" [multiple]="false" 
                                placeholder="Método de pago" bindLabel="text" appearance="true" bindValue="value" formControlName="paymentMethod">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <h6>Uso de CFDI</h6>
                        <div class="input-group">
                            <div class="input-group">
                                <ng-select class="form-control" [items]="useOfCfdi" [multiple]="false" 
                                    placeholder="Uso de CFDI" bindLabel="text" appearance="true" bindValue="value" formControlName="paymentCFDI" [hideSelected]	
                                    >
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <ng-container formGroupName="credit">
                        <div class="col-sm-4">
                            <h6>Días de crédito</h6>
                            <div class="input-group">
                                <input type="text" class="form-control" (keypress)="validator.isInteger($event);" placeholder="Días de crédito del Cliente" maxlength="3" formControlName="days">
                            </div>
                        </div> 
                        <div class="col-sm-4">
                            <h6>Límite de Crédito</h6>
                            <div class="input-group">
                                <input type="text" class="form-control" (keypress)="validator.isNumeric($event);" placeholder="Límite de Crédito" maxlength="7" formControlName="limit">
                            </div>
                        </div> 
                    </ng-container>
                    <div class="col-sm-4">
                        <h6>Co-Pago, % Cliente</h6>
                        <div class="input-group">
                            <input type="text" class="form-control" (keypress)="validator.isNumeric($event);" placeholder="% de pago de cliente" maxlength="3" formControlName="paymentPercent">
                        </div>
                    </div> 
                </div>
                
                <div class="row mt-2">
                    <div class="col-sm-6" >
                        <h6>Lista de Precios</h6>
                        <div class="input-group">
                            <ng-select class="form-control" 
                                [items]="priceListid" 
                                placeholder="Lista de precios"
                                bindLabel="name" 
                                bindValue="_id" 
                                formControlName="priceListid" 
                                [hideSelected]
                            ></ng-select>
                            <div class="input-group-append"> 
                                <button [disabled]="!FormCustomer?.value?.priceListid" type="button" class="btn btn-dark" (click)="printList()"><i class="fa fa-print mr-1"></i></button>
                            </div>
                        </div>
                        <small *ngIf="false" class="text-danger">Campo requerido</small> 
                    </div> 
                    <div class="col-sm-6">
                        <h6>Porcentaje de IVA</h6>
                        <div class="input-group">
                            <ng-select class="form-control" [items]="taxPercentage" [multiple]="false" 
                                placeholder="Procentaje de IVA" bindLabel="text" appearance="true" bindValue="value" formControlName="ivaPercent" [hideSelected]>
                            </ng-select>
                        </div>
                        <small *ngIf="false" class="text-danger">Campo requerido</small> 
                    </div> 
                </div>
                <!-- <div class="row border-top mt-3 pt-2">
                    <div class="col-md-6 d-flex justify-content-between align-content-end">
                        <h6 class="my-auto">Imprimir resultados con fecha</h6>
                        <label class="custom-switch my-auto">
                            <input type="checkbox" name="custom-switch-checkbox" name="" class="custom-switch-input" [checked]="printWithDate" (change)="printWithDate = !printWithDate" disabled>
                            <span class="custom-switch-indicator"></span>
                        </label>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group">
                            <div class="input-group-prepend"><div class="input-group-text">Indicaciones de entrada</div></div>
                                <input *ngIf="!urlPdf" type="file" class="form-control" placeholder="Nombre de banco de Cliente" >
                            <div class="input-group-append" *ngIf="urlPdf">
                                <button type="button" class="btn btn-dark" title="Ver indicaciones de entrada" (click)="openModal(modalIndicationPlant, 'xl')"><i class="mdi mdi-eye mr-1"></i> {{urlPdf}}</button>
                                <button type="button" class="btn btn-danger" title="Eliminar indicaciones de entrada" [swal]="deleteSwal" (confirm)="urlPdf = null"><i class="fa fa-trash"></i></button>
                            </div>
                        </div>
                    </div> 
                </div> -->
                <div class="row border-top mt-3 pt-2">
                    <div class="col-6">
                        <h6 class="my-auto">Fecha de Impresión en Resultados</h6>
                        <div class="d-flex justify-content-between pt-3 w-75">
                            <h6 class="ml-4">Sin Fecha</h6>
                            <label class="custom-switch my-auto cursor-pointer">
                                    <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input"  formControlName="dated">
                                    <span class="custom-switch-indicator"></span>
                                </label>
                            <h6 class="mr-4">Con Fecha</h6>
                        </div>
                      
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <h6 class="ml-3">Indicaciones de entrada</h6>

                            <div class="col-12 d-flex justify-content-between">
                                <div class="card input-group cursor-pointer" [class.bg-success]="true" >

                                    <input [accept]="'.pdf'" #plantEntryIndicationInp (change)="changeFile($event)" [disabled]="blockEdition"
                                    type="file" id="plantEntryIndication" style="display: none;">
                            
                                        <ng-container *ngIf="!FormCustomer?.value?.plantEntryIndication">
                                            <label class="text-overflow cursor-pointer" for="plantEntryIndication">
                                                <i class="fa fa-upload mr-1"></i> Subir archivo
                                            </label>
                                        </ng-container>
                                        <ng-container *ngIf="FormCustomer?.value?.plantEntryIndication">
                                            <label class="text-overflow cursor-pointer" (click)="openModal(modalIndicationPlant, 'xl')">
                                                <i class="fa fa-file mr-1"></i> Ver archivo
                                            </label>
                                        </ng-container>
                                        
                            
                                        <!-- <label class="_btn btn-info" *ngIf="FormCustomer?.value?.indicationsDoc" for="indicationsDoc" ><i class="fa fa-refresh"></i></label> -->
                                        <label class="_btn btn-danger" *ngIf="FormCustomer?.value?.plantEntryIndication" [swal]="deleteFileSwal" (confirm)="deleteFile()"><i class="fa fa-trash"></i></label>
                            
                                    
                                    <!-- <div class="input-group-append">
                            
                                        <button *ngIf="Download" class="btn btn-primary px-2" placement="top" (click)="download(this.contractFile[0]?.name)">
                                            <i class="fa fa-download"></i>
                                        </button>
                                    </div> -->
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="row border-top mt-3 pt-2">
                    <div class="col-6">
                        <div class="row">
                            <div class="col-12 d-flex justify-content-between">
                                <h6 class="my-auto">Enviar Publicidad</h6>
                                <div class="btn-group my-auto">
                                    <button [disabled]="blockEdition" (click)="updateConfig('sendPublicity',!FormCustomer?.value?.config?.sendPublicity)" class="btn btn-sm btn-icon btn-{{FormCustomer?.value?.config?.sendPublicity ? 'success' : 'light'}}"><i class="fa fa-bullhorn"></i></button>
                                </div>
                            </div>

                            <div class="col-12 d-flex justify-content-between mt-1">
                                <h6 class="my-auto">Envío Impreso</h6>
                                <div class="btn-group my-auto">
                                    <button [disabled]="blockEdition" type="button" (click)="updateSend('print',!FormCustomer?.value?.sendMethod?.print)" class="btn btn-sm btn-icon btn-{{(FormCustomer?.value?.sendMethod?.print) ? 'success' : 'light'}}"><i class="fa fa-print"></i></button>
                                </div>
                            </div>

                            <div class="col-12 d-flex justify-content-between mt-1">
                                <h6 class="my-auto">Envío Email</h6>
                                <div class="btn-group my-auto">
                                    <button [disabled]="blockEdition" type="button" (click)="updateSend('email',!FormCustomer?.value?.sendMethod?.email)" class="btn btn-sm btn-icon btn-{{FormCustomer?.value?.sendMethod?.email ? 'success' : 'light'}}"><i class="fa fa-envelope"></i></button>
                                </div>
                            </div>

                            <div class="col-12 d-flex justify-content-between mt-1">
                                <h6 class="my-auto">Imprimir Comprobante</h6>
                                <div class="btn-group my-auto">
                                    <button [disabled]="blockEdition" type="button" (click)="updateConfig('printVoucher',!FormCustomer?.value?.config?.printVoucher)" class="btn btn-sm btn-icon btn-{{FormCustomer?.value?.config?.printVoucher ? 'success' : 'light'}}"><i class="fa fa-file"></i></button>
                                </div>
                            </div>

                            <div class="col-12 d-flex justify-content-between mt-1">
                                <h6 class="my-auto">Impresión Sin Pago</h6>
                                <div class="btn-group my-auto">
                                    <button type="button" [disabled]="blockEdition" (click)="updateConfig('printWithoutPay',!FormCustomer?.value?.config?.printWithoutPay)" class="btn btn-sm btn-icon btn-{{FormCustomer?.value?.config?.printWithoutPay ? 'success' : 'light'}}"><i class="fa fa-print"></i></button>
                                </div>
                            </div>

                            <div class="col-12 d-flex justify-content-between mt-1">
                                <h6 class="my-auto">Bloqueo por factura vencida</h6>
                                <div class="btn-group my-auto">
                                    <button type="button" [disabled]="blockEdition" (click)="updateConfig('blockexpiredBill',!FormCustomer?.value?.config?.blockexpiredBill)" class="btn btn-sm btn-icon btn-{{FormCustomer?.value?.config?.blockexpiredBill ? 'success' : 'light'}}"><i class="fa fa-ban"></i></button>
                                </div>
                            </div>

                            <div class="col-12 d-flex justify-content-between mt-1">
                                <h6 class="my-auto">Desgloce de estudios</h6>
                                <div class="btn-group my-auto">
                                    <button [disabled]="blockEdition" type="button" (click)="updateConfig('breakDown',!FormCustomer?.value?.config?.breakDown)" class="btn btn-sm btn-icon btn-{{FormCustomer?.value?.config?.breakDown ? 'success' : 'light'}}"><i class="fa fa-list"></i></button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-12 d-flex justify-content-between">
                                <h6 class="my-auto">Imprimir Precios</h6>
                                <div class="btn-group my-auto">
                                    <button type="button" [disabled]="blockEdition" (click)="updateConfig('printPrices',!FormCustomer?.value?.config?.printPrices)" class="btn btn-sm btn-icon btn-{{FormCustomer?.value?.config?.printPrices ? 'success' : 'light'}}"><i class="fa fa-print"></i></button>
                                </div>
                            </div>

                            <div class="col-12 d-flex justify-content-between mt-1">
                                <h6 class="my-auto">Imprimir Fecha</h6>
                                <div class="btn-group my-auto">
                                    <button type="button" [disabled]="blockEdition" (click)="updateConfig('printDate',!FormCustomer?.value?.config?.printDate)" class="btn btn-sm btn-icon btn-{{FormCustomer?.value?.config?.printDate ? 'success' : 'light'}}"><i class="fa fa-calendar"></i></button>
                                </div>
                            </div>

                            <div class="col-12 d-flex justify-content-between mt-1">
                                <h6 class="my-auto">Reporte sin logo</h6>
                                <div class="btn-group my-auto">
                                    <button type="button" [disabled]="blockEdition" (click)="updateConfig('printOutLogo',!FormCustomer?.value?.config?.printOutLogo)" class="btn btn-sm btn-icon btn-{{FormCustomer?.value?.config?.printOutLogo ? 'success' : 'light'}}"><i class="fa fa-file"></i></button>
                                </div>
                            </div>

                            <div class="col-12 d-flex justify-content-between mt-1">
                                <h6 class="my-auto">Incluir Leyenda</h6>
                                <div class="btn-group my-auto">
                                    <button type="button" [disabled]="blockEdition" (click)="updateConfig('includeLegend',!FormCustomer?.value?.config?.includeLegend)" class="btn btn-sm btn-icon btn-{{FormCustomer?.value?.config?.includeLegend ? 'success' : 'light'}}"><i class="fa fa-file-text"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </form> 
</div>

<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-dark" (click)="close()">Cerrar</button>
    <button type="button" class="btn btn-success" *ngIf="!blockEdition" (click)="submit()">Guardar</button>
</div>


<ng-template #readonlyFile>
    <ng-container *ngIf="!FormCustomer?.value?.plantEntryIndication">
        <div class="card input-group justify-content-between m-0 bg-muted">
            <label class="text-overflow cursor-pointer">
                <i class="fa fa-file mr-1"></i> Sin archivo
            </label>
        </div>
    </ng-container>
    <ng-container *ngIf="FormCustomer?.value?.plantEntryIndication">
        <div class="card input-group justify-content-between m-0 bg-success">
            <label class="text-overflow cursor-pointer" (click)="openModal(modalIndicationPlant, 'xl')">
                <i class="fa fa-file mr-1"></i> Ver archivo
            </label>
        </div>
    </ng-container>
</ng-template>

<ng-template #modalFile let-modal>
    <modal-download-file (onClose)="modal.dismiss()" [file]="FormCustomer?.value?.plantEntryIndication"></modal-download-file>
</ng-template>

<swal #deleteFileSwal class="d-none" title="¿Estas seguro de eliminar el archivo?" icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>

<swal #deleteSwal class="d-none" title="¿Estas seguro de eliminar las indicaciones de entrada?" icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}"></swal>

<ng-template #modalIndicationPlant let-modal>
    <modal-view (onClose)="modal.dismiss()" [url]="FormCustomer.value?.plantEntryIndication" [isAPI]="false"></modal-view>
</ng-template>

<ng-template #modalPriceList let-modal>
    <modal-report (onClose)="modal.dismiss()" [modalOptions]="modalOptions"></modal-report>
</ng-template>
