<mk-loader *ngIf="isLoading" class="loader-spinner"></mk-loader>
<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Movimientos</h6>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body py-0">
    <div class="row border-top">
        <div class="col-sm-12 p-0">
            <mk-loader *ngIf="isLoading" class="loader-spinner" ></mk-loader>
            <div class="table-responsive">
                <table mat-table [dataSource]="DT_movement" matSort #Sort="matSort" matSortActive="date"
                    matSortDirection="desc"
                    class="table table-bordered table-striped mb-0 material-table-container">
    
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                        <td mat-cell *matCellDef="let item">
                            {{item.date.split("*")[1]}} 
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto </th>
                        <td mat-cell *matCellDef="let item">
                            <div class="w-100 d-flex justify-content-between">
                                <span><b>{{item.amount | currency}}</b></span>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
                        <td mat-cell *matCellDef="let item">
                            <span> {{item.type.split("*")[0]}} </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="user">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Usuario </th>
                        <td mat-cell *matCellDef="let item">
                            <span> {{item.user}} </span>
                        </td>
                    </ng-container>
    
                    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
    
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell text-center" colspan="10">
                            {{isLoading?"Cargando ...":"Sin datos encontrados"}}</td>
                    </tr>
                </table>
            </div>
            <mat-paginator #Paginator="matPaginator" [pageSize]="17" aria-label="" showFirstLastButtons>
            </mat-paginator>
        </div>
    </div>
</div>