import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class StudyService {

  constructor(
    private http: HttpService
  ) { }

  // Peticiones para Estudios
  public postStudy = (form): Promise<any> => this.http.post(`api/study`, form).toPromise();
  public getStudy = (id:string): Promise<any> => this.http.get(`api/study/${id}`).toPromise();
  public putStudy = (study:any): Promise<any> => this.http.put(`api/study`, study).toPromise();
  public editStudyStatus = (obj:any): Promise<any> => this.http.put(`api/study/changeStatus`, obj).toPromise();
  public nextNumberAvailable = (): Promise<any> => this.http.get(`api/study/nextNumberAvailable`).toPromise();

  // Validaciones para disponibilidad (edicion) de Estudio
  public availableStudy = (obj:any): Promise<any> => this.http.put(`api/study/changeAvailability`, obj).toPromise();
  public unlockStudy = (id:string): Promise<any> => this.http.get(`api/study/${id}`).toPromise();

  // Peticiones para Catalogos
  public getDepartmentsSelect = (): Promise<any> => this.http.get(`api/departments/select`).toPromise();
  public getMethodsSelect = (): Promise<any> => this.http.get(`api/methods/select`).toPromise();
  public getUnitConversionSelect = (): Promise<any> => this.http.get(`api/unitConversions/select`).toPromise();
  public getSampleTypeSelect = (): Promise<any> => this.http.get(`api/sampleType/select`).toPromise();
  public getQuestionarySelect = (): Promise<any> => this.http.get(`api/questionary/select`).toPromise();
  public getStudySelect = (): Promise<any> => this.http.get(`api/study/select`).toPromise();
  public getIndicationsAll = (): Promise<any> => this.http.get(`api/indications/select`).toPromise();
  public getIndicationsClients = (): Promise<any> => this.http.get(`api/indicationsClient/select`).toPromise();
  public getContainerType = (): Promise<any> => this.http.get(`api/containerType/select`).toPromise();
  public cloneStudy = (body: any, id: string): Promise<any> => this.http.post(`api/study/clone`, {longName: body?.longName, shortName: body?.shortName, id: id}).toPromise();
  public filterList = (body: any): Promise<any> => this.http.post(`api/study/filter`, {type: body}).toPromise();
  public changeDiscountAmount = (newPrice: string, _id: string): Promise<any> => this.http.put(`api/study/changeDiscountAmount`, {discountAmount: newPrice, id: _id}).toPromise();
  public getDeterminationsSelect = (id: string): Promise<any> => this.http.get(`api/study/determinations/${id}`).toPromise();
}
