import { Subject, Observable } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { ModalBranchComponent } from './../../components/modal-branch/modal-branch.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/shared/services/http.service';
import { CryptoSharkService } from 'src/app/shared/services/crypto-shark.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BranchService {


  public branch: any = { _id: '5d6d7936c2d2451186dc125a' };
  public modal: any;
  public changeBranch = new Subject();

  constructor(private crypto: CryptoSharkService, private http: HttpService, private modalService: NgbModal) {
  }

  public async load(){
    try{
      this.branch = JSON.parse(this.crypto.decrypt(localStorage.getItem('branch')));
      if (!this.branch) {
        this.openModal();
      }
    }catch (err){
      this.openModal();
    }
  }

  public async openModal(){
    var list = await this.list();
    this.modal = this.modalService.open(ModalBranchComponent, {size: 'sm', backdrop: 'static'});
    this.modal.componentInstance.branchs = list;
    this.modal.result.then((result) => {
      this.load();
      this.changeBranch.next(this.branch);
    }, (reason) => {
      this.load();
    });
  }

  public list = (): Promise<any> => this.http.get(`api/branch`).toPromise();

  public cashRegisterDefault = (branchId: string): Promise<any> => this.http.get(`api/cashRegister/defaults/${branchId}`).toPromise();

}
