import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { concat, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { DecryptPipe } from 'src/app/shared/pipes/decrypt.pipe';
import { AlertService } from 'src/app/shared/services/alert.service';
import { BuiltFormDataService } from 'src/app/shared/services/built-form-data.service';
import { CryptoSharkService } from 'src/app/shared/services/crypto-shark.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ValidatorsService } from 'src/app/shared/services/validators.service';

@Component({
  selector: 'cat-modal-client',
  templateUrl: './modal-client.component.html',
  styleUrls: ['./modal-client.component.scss']
})
export class ModalClientComponent implements OnInit {
  @Input() modalOptions: any = null;
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>(); 
  @Output() onSave: EventEmitter<any> = new EventEmitter<any>();  

  @ViewChild('inpEmail') private inpEmail: any;
  @ViewChild('inpEmailBill') private inpEmailBill: any;
  @ViewChild('plantEntryIndicationInp') private plantEntryIndicationInp: ElementRef;
  @ViewChild('modalPriceList') modalPriceList: NgbModal
  

  public FormCustomer: FormGroup; 
  public userOfCfdiSelected:any = null;
  public paymentMethodSelected:any = null; 
  public paymentSelected:any = null; 

  public isLoading: boolean = false;
  public domainsData: any[] = [];
  public domain: any;
  public domainBill: any;
  
  public referencesData: any[] = [];
  public priceListid: any[] = [];

  // public needsImmediatePayment: boolean = false;
  // public printWithDate: boolean = false;

  public zip$: Observable<any>;
  public zipLoading = false;
  public zipInput$ = new Subject<string>();
  public minLengthTerm = 1;
  public urlPdf: string;
  
  //*Variables de contacto operativo
  public typePhone: string|null = null;
  public inpPhone: any = null;
  public phones: any[] = [];
  public phoneMaskCO = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public emails: any[] = [];

  //^Variables de contacto Administrativo
  public typePhoneCA;
  public inpPhoneCA;
  public phonesCA: any[] = [];
  public phoneMaskCA = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public billMails: any[] = [];
  public blockEdition: boolean = false;

  public paymentForm: Object[] = [
    {"value": "01", "text": "01 Efectivo"},
    {"value": "02", "text": "02 Cheque nominativo"},
    {"value": "03", "text": "03 Transferencia electronica de fondos"},
    {"value": "04", "text": "04 Tarjeta de credito"},
    {"value": "05", "text": "05 Monedero electrónico"},
    {"value": "06", "text": "06 Dinero electrónico"},
    {"value": "08", "text": "08 Vales de despensa"},
    {"value": "12", "text": "12 Dación en pago"},
    {"value": "13", "text": "13 Pago por subrogación"},
    {"value": "14", "text": "14 Pago por consignación"},
    {"value": "15", "text": "15 Condonación"},
    {"value": "17", "text": "17 Compensación"},
    {"value": "23", "text": "23 Novación"},
    {"value": "24", "text": "24 Confusión"},
    {"value": "25", "text": "25 Remisión de deuda"},
    {"value": "26", "text": "26 Prescripción o caducidad"},
    {"value": "27", "text": "27 A satisfacción del acreedor"},
    {"value": "28", "text": "28 Tarjeta de débito"},
    {"value": "29", "text": "29 Tarjeta de servicio"},
    {"value": "99", "text": "99 Por definir"}
  ];

  public paymentMethod: Object[] = [
    {"value": "PUE", "text": "PUE Pago en una sola exhibición"},
    {"value": "PIP", "text": "PIP Pago inicial y parcialidades"},
    {"value": "PPD", "text": "PPD Pago en parcialidades o diferido"}
  ];
   
  public useOfCfdi: Object[] = [
    {"value": "G01", "text": "G01 Adquisición de mercancias"},
    {"value": "G02", "text": "G02 Devoluciones, descuentos o bonificaciones"},
    {"value": "G03", "text": "G03 Gastos en general"},
    {"value": "I01", "text": "I01 Construcciones"},
    {"value": "I02", "text": "I02 Mobilario y equipo de oficina por inversiones"},
    {"value": "I03", "text": "I03 Equipo de transporte"},
    {"value": "I04", "text": "I04 Equipo de computo y accesorios"},
    {"value": "I05", "text": "I05 Dados, troqueles, moldes, matrices y herramental"},
    {"value": "I06", "text": "I06 Comunicaciones telefónicas"},
    {"value": "I07", "text": "I07 Comunicaciones satelitales"},
    {"value": "I08", "text": "I08 Otra maquinaria y equipo"},
    {"value": "D01", "text": "D01 Honorarios médicos, dentales y gastos hospitalarios"},
    {"value": "D02", "text": "D02 Gastos médicos por incapacidad o discapacidad"},
    {"value": "D03", "text": "D03 Gastos funerales"},
    {"value": "D04", "text": "D04 Donativos"},
    {"value": "D05", "text": "D05 Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)"},
    {"value": "D06", "text": "D06 Aportaciones voluntarias al SAR"},
    {"value": "D07", "text": "D07 Primas por seguros de gastos médicos"},
    {"value": "D08", "text": "D08 Gastos de transportación escolar obligatoria"},
    {"value": "D09", "text": "D09 Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones"},
    {"value": "D10", "text": "D10 Pagos por servicios educativos (colegiaturas)"},
    {"value": "P01", "text": "P01 Por definir"}
  ];

  public taxPercentage: Object[] = [
    {"value": 0, "text": "0%"},
    {"value": 8, "text": "8%"},
    // {"value": 11, "text": "11%"},
    {"value": 16, "text": "16%"}
  ];

  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private alertService: AlertService,
    private decrypt: DecryptPipe,
    public validator: ValidatorsService,
    private modalService: NgbModal,
    private cryptoService: CryptoSharkService,
    private userService: UserService,
    public validateForm: ValidateFormsDirective,
    private _builtForm: BuiltFormDataService,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void { 
    this.loadDomains();
    this.loadReferences();
    this.loadSelectZip();
    this.loadPriceList();

    this.FormCustomer = this.fb.group({
      name:[null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(60)])],
      active: [true],
      businessName: [null, Validators.compose([Validators.minLength(1), Validators.maxLength(60)])],
      rfc: [null, Validators.compose([Validators.minLength(12), Validators.maxLength(13)])], 
      //*Contactos operativos
      operationalContact: [null, Validators.compose([])],
      phone:[[]],
      email: [[]],
      //^ Contacto Administrativo
      administrativeContact: [null, Validators.compose([])],
      phonesCA: [[]],
      billMails:[[]],
      address: [null, Validators.compose([Validators.minLength(1), Validators.maxLength(120)])],
      zipCode: [null],
      reference:[null, Validators.compose([Validators.required])],
      bankAccount: this.fb.group({
        name: [null, Validators.compose([Validators.minLength(1), Validators.maxLength(60)])],
        number: [null, Validators.compose([Validators.minLength(1), Validators.maxLength(20)])]
      }),
      immediatePayment: [false],
      paymentType: [null],
      paymentMethod: [null],
      paymentCFDI: [null],
      credit: this.fb.group({
        days: [0, Validators.compose([Validators.minLength(1), Validators.maxLength(3)])],
        limit: [0, Validators.compose([Validators.minLength(1), Validators.maxLength(7)])]
      }),
      priceListid: [null],
      ivaPercent: [null],
      dated: [false],
      plantEntryIndication:[null],
      config: this.fb.group({
        breakDown: [false],
        printDate: [false],
        printOutLogo: [false],
        printPrices: [false],
        printVoucher: [false],
        sendPublicity: [false],
        blockexpiredBill: [false],
        includeLegend: [false],
        printWithoutPay: [false]
      }),
      sendMethod: this.fb.group({
        email: [false],
        print: [false],
      }),
      paymentPercent: [0, Validators.compose([Validators.maxLength(3)])]
    })

    if (this.modalOptions.type == 'EDIT' || this.modalOptions.type == 'VIEW') {
      this.detailClient();
    }
  }

  public async detailClient(){
    try {
      this.isLoading = true;
      let data = await this.httpService.get(`api/customer/${this.modalOptions.id}`).toPromise();

      let references:any = [];
      for (const item of data?.references || []) {
        references.push(item?._id);
      }
      this.FormCustomer.patchValue({
        name: this.decrypt.transform(data.name),
        businessName: this.decrypt.transform(data.businessName),
        rfc: this.decrypt.transform(data.rfc),
        operationalContact: data?.operationalContact,
        administrativeContact: data?.administrativeContact,
        address: data.address,
        zipCode: data.zipCode || null,
        bankAccount:{
          name: (data?.bankAccount?.name)?this.decrypt.transform(data?.bankAccount?.name):null,
          number: (data?.bankAccount?.number)?this.decrypt.transform(data?.bankAccount?.number):null,
        },
        active: data?.active,
        paymentMethod: data?.paymentMethod,
        paymentCFDI: data?.paymentCFDI,
        paymentType: data?.paymentType,
        credit:{
          days: data?.credit?.days,
          limit: data?.credit?.limit 
        },
        immediatePayment: data?.immediatePayment,
        ivaPercent: data?.ivaPercent,
        priceListid: data?.priceListid,
        reference: data?.reference,
        dated: data?.dated,
        config: {
          breakDown: data?.config?.breakDown || false,
          printDate: data?.config?.printDate || false,
          printOutLogo: data?.config?.printOutLogo || false,
          printPrices: data?.config?.printPrices || false,
          printVoucher: data?.config?.printVoucher || false,
          blockexpiredBill: data?.config?.blockexpiredBill || false,
          includeLegend: data?.config?.includeLegend || false,
          sendPublicity: data?.config?.sendPublicity || false,
          printWithoutPay: data?.config?.printWithoutPay || false
        },
        sendMethod:{
          email: data?.sendMethod?.email || false,
          print: data?.sendMethod?.print || false,
        },
        listPriceData: data?.listPriceData,
        paymentPercent: data?.paymentPercent || 0,
        plantEntryIndication: data?.plantEntryIndication
      });
     
      if (this.modalOptions.type == 'VIEW' || (!this.modalOptions?.permissions["EDIT"] && this.modalOptions.type == 'EDIT')) {
        // this.FormCustomer.get('paymentMethod').disable();
        // this.FormCustomer.get('paymentCFDI').disable();
        // this.FormCustomer.get('paymentType').disable();
        // this.FormCustomer.get('ivaPercent').disable();

        this.FormCustomer.disable();
        this.blockEdition = true;
      }

      for ( const p of data?.phone || []){p.phone = this.decrypt.transform(p.phone)};
      for ( const m of data?.email || []){m.email = this.decrypt.transform(m.email)};
      for ( const m of data?.billMails || []){m.email = this.decrypt.transform(m.email)}; 
      for ( const p of data?.phonesCA || []){p.phone = this.decrypt.transform(p.phone)};

      this.phones = data?.phone || [];
      this.emails = data?.email || [];
      this.billMails = data?.billMails || [];
      this.phonesCA = data?.phonesCA || [];
      this.urlPdf = data?.plantEntryIndication;

        
    } catch (error) {
      console.log(error);
    }finally{
      this.isLoading = false;
    }
  }

  public updateConfig(type: string, value: boolean):void{
    this.FormCustomer?.get("config")?.get(type)?.setValue(value);
  }

  public updateSend(type: string, value: boolean):void{
    this.FormCustomer.get("sendMethod")?.get(type)?.setValue(value);
  }

  public addPhones(type: string = "CO"){
    if (type == "CO") {
      if (this.phones?.filter(item => {return item.phone == this.inpPhone}).length == 0) {
        this.phones.push({"phone": this.inpPhone, type: this.typePhone, "primary": false}); 
        this.inpPhone = null;
        this.typePhone = null;
      }else{
        this.alertService.infoToast("El teléfono ya se encuentra agregado.")
      } 
    }else if(type == "CA"){
      if (this.phonesCA?.filter(item => {return item.phone == this.inpPhoneCA}).length == 0) {
        this.phonesCA.push({"phone": this.inpPhoneCA, type: this.typePhoneCA, "primary": false}); 
        this.inpPhoneCA = null;
        this.typePhoneCA = null;
      }else{
        this.alertService.infoToast("El teléfono ya se encuentra agregado.")
      }
    }  
    
  }

  public changePhoneType(event: any, type: string = "CO"):void{
    if(type == "CO"){
      this.inpPhone = null;
      if (event != "OFFICE") {
        this.phoneMaskCO = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
      }else{
        this.phoneMaskCO = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, ';', /\d/, /\d?/, /\d?/, /\d?/];
      }
    }else if(type == "CA"){
      this.inpPhoneCA = null;
      if (event != "OFFICE") {
        this.phoneMaskCA = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
      }else{
        this.phoneMaskCA = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, ';', /\d/, /\d?/, /\d?/, /\d?/];
      }
    }
    
  }

  public primaryPhone(event: any, index: number, type: string = "CO"):void{
    if (type == "CO") {
      this.phones.find(phone => {return phone.primary})?(this.phones.find(phone => {return phone.primary}).primary = false):null;
      this.phones[index].primary = event;
    }else if(type == "CA"){
      this.phonesCA.find(phone => {return phone.primary})?(this.phonesCA.find(phone => {return phone.primary}).primary = false):null;
      this.phonesCA[index].primary = event;
    }
    
  }

  public async loadDomains(){
    try {
      this.domainsData = await this.httpService.get(`api/domain/select`).toPromise();
    } catch (error) {
      console.log(error);
    }
  }

  public async loadPriceList(){
    try {
      this.priceListid = await this.httpService.get(`api/listprice/select`).toPromise();
    } catch (error) {
      console.log(error);
    }
  }

  public async loadReferences(){
    try {
      this.referencesData = await this.httpService.get(`api/references/select`).toPromise();
      for (const item of this.referencesData || []) {
        item.name = this.decrypt.transform(item?.name) || ""; 
      }
    } catch (error) {
      console.log(error);
    }
  }

  public addEmails(mail: string, domain: any): void{
    mail = mail.replace(/\s/g,"");
    if (this.emails?.filter(item => {return item.email == mail+domain}).length == 0) {
      this.emails.push({"email": mail+domain, "primary": false});
      this.inpEmail.nativeElement.value = null;
      this.domain = null
    }else{
      this.alertService.infoToast("El correo ya se encuentra agregado.")
    }
  }

  public primaryMail(event: any, index: number):void{
    this.emails.find(mail => {return mail.primary})?(this.emails.find(mail => {return mail.primary}).primary = false):null;
    this.emails[index].primary = event;
  }

  public addEmailsBill(mail: string, domain: any): void{
    mail = mail.replace(/\s/g,"");
    if (this.billMails?.filter(item => {return item.email == mail+domain}).length == 0) {
      this.billMails.push({"email": mail+domain, "primary": false});
      this.inpEmailBill.nativeElement.value = null;
      this.domainBill = null;
    }else{
      this.alertService.infoToast("El correo ya se encuentra agregado.")
    }
  }

  public primaryMailBill(event: any, index: number):void{
    this.billMails.find(mail => {return mail.primary})?(this.billMails.find(mail => {return mail.primary}).primary = false):null;
    this.billMails[index].primary = event;
  }

  public loadSelectZip() {
    this.zip$ = concat(
      of([]), // default items
      this.zipInput$.pipe(
        filter(res => {
          return res !== null 
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => this.zipLoading = true),
        switchMap(term => {
          if (term.length < this.minLengthTerm) {
            this.zipLoading = false
            return of([]);
          }
          return this.getZipData(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.zipLoading = false)
          )
        })
      )
    );
  }

  private getZipData(term: string | null = null): Observable<any> {
    return this.httpService.get(`api/bill/zipCode/${term}`).pipe(map((resp: any) => {      
        if (resp?.Error) {
          throwError(resp.Error);
        } else {
          return resp;
        }
      })
    );
  }

  public openModal(modal, size: string = 'lg', ) {
    this.modalService.open(modal, { size: size, backdrop: "static"});
  }

  public async submit(){
    try {
      this.isLoading = true;

      //* Contacto Operativo
      let phonesEncrypt: any = [];
      let onePhonePrimary = false;
      for ( const p of this.phones ){  
        if(p.primary) onePhonePrimary = true;
        phonesEncrypt.push({"phone": this.cryptoService.encryptAes(p.phone, this.userService.login.company.dk), "primary": p.primary, type: p.type}); 
      };  

      if (this.phones.length > 0 && !onePhonePrimary) {
        phonesEncrypt[0].primary = true;
      }
      this.FormCustomer.value.phone = phonesEncrypt;
    
      let emailsEncrypt: any = [];
      let oneMailPrimary = false;
      for(const e of this.emails){
        if(e.primary) oneMailPrimary = true;
        emailsEncrypt.push({"email": this.cryptoService.encryptAes(e.email, this.userService.login.company.dk), "primary": e.primary});
      }
      if (this.emails.length > 0 && !oneMailPrimary) {
        emailsEncrypt[0].primary = true;
      }
      this.FormCustomer.value.email = emailsEncrypt;

      //^ Contacto Administrativo 
      let phonesCAEncrypt: any = [];
      let onePhoneCAPrimary = false;
      for ( const p of this.phonesCA ){  
        if(p.primary) onePhoneCAPrimary = true;
        phonesCAEncrypt.push({"phone": this.cryptoService.encryptAes(p.phone, this.userService.login.company.dk), "primary": p.primary, type: p.type}); 
      };  

      if (this.phonesCA.length > 0 && !onePhoneCAPrimary) {
        phonesCAEncrypt[0].primary = true;
      }
      this.FormCustomer.value.phonesCA = phonesCAEncrypt;

      let emailsBillsEncrypt: any = [];
      let oneMailBillPrimary = false;
      for(const e of this.billMails){
        if(e.primary) oneMailBillPrimary = true;
        emailsBillsEncrypt.push({"email": this.cryptoService.encryptAes(e.email, this.userService.login.company.dk), "primary": e.primary});
      }
      if (this.billMails.length > 0 && !oneMailBillPrimary) {
        emailsBillsEncrypt[0].primary = true;
      }
      this.FormCustomer.value.billMails = emailsBillsEncrypt;
      
      if(!this.FormCustomer.valid){ 
        this.FormCustomer.markAllAsTouched();
        this.alertService.infoToast('Campos incompletos o invalidos!');
        this.isLoading = false;
        return ;
      } 
      let obj: any = {}

      obj = await this.validateForm.clearNullObjects(this.FormCustomer.value);
      
      obj.name = this.cryptoService.encryptAes(this.FormCustomer.value.name, this.userService.login.company.dk);
      if(this.FormCustomer.value.businessName) obj.businessName = this.cryptoService.encryptAes(this.FormCustomer.value.businessName, this.userService.login.company.dk);
      obj.rfc = (this.FormCustomer.value.rfc)? this.cryptoService.encryptAes(this.FormCustomer.value.rfc, this.userService.login.company.dk) : null;
      if (obj.bankAccount) {
        if(this.FormCustomer.value.bankAccount?.name) obj.bankAccount.name = this.cryptoService.encryptAes(this.FormCustomer.value.bankAccount?.name, this.userService.login.company.dk);
        if(this.FormCustomer.value.bankAccount?.number) obj.bankAccount.number = this.cryptoService.encryptAes(this.FormCustomer.value.bankAccount?.number, this.userService.login.company.dk);
      }

      let formData = new FormData();
      this._builtForm.buildFormData(formData, obj);

      if (this.modalOptions.type == 'ADD') {
        let resp = await this.httpService.post(`api/customer`, formData).toPromise();
        this.alertService.successToast(resp.message);
        this.onSave.emit({id: resp.insertedId});
        this.close();
        this.isLoading = false;
        
      }else if (this.modalOptions.type == 'EDIT') {
        
        let resp = await this.httpService.put(`api/customer/${this.modalOptions.id}`, formData).toPromise();
        this.alertService.successToast(resp.message);
        this.onSave.emit({id: this.modalOptions.id});
        this.close();
        this.isLoading = false;
        
      }

    } catch (error) {
      console.log("=========",error);
      if (error?.status != 403) {
        this.alertService.errorToast(error.message);
      }

      this.FormCustomer.value.name = this.cryptoService.decrypt(this.FormCustomer.value.name, this.userService.login.company.dk);
      if(this.FormCustomer.value.businessName) this.FormCustomer.value.businessName = this.cryptoService.decrypt(this.FormCustomer.value.businessName, this.userService.login.company.dk);
      this.FormCustomer.value.rfc = (this.FormCustomer.value.rfc)? this.cryptoService.decrypt(this.FormCustomer.value.rfc, this.userService.login.company.dk) : null;
      if (this.FormCustomer.value.bankAccount) {
        if(this.FormCustomer.value.bankAccount?.name) this.FormCustomer.value.bankAccount.name = this.cryptoService.decrypt(this.FormCustomer.value.bankAccount?.name, this.userService.login.company.dk);
        if(this.FormCustomer.value.bankAccount?.number) this.FormCustomer.value.bankAccount.number = this.cryptoService.decrypt(this.FormCustomer.value.bankAccount?.number, this.userService.login.company.dk);
      }
    } finally{
      // this.isLoading = false;
      
    }
  }

  close(): void { this.onClose.emit() }

  public deleteFile(){
    this.FormCustomer.patchValue({ plantEntryIndication: null });
    this.plantEntryIndicationInp.nativeElement.value = null;
  }

  public changeFile(event: any){
    this.FormCustomer.patchValue({ plantEntryIndication: event.target.files[0]});
  }

  public printList = () => {
    if (this.FormCustomer.value.priceListid) {
      this.modalOptions.tipoModal = 'byAPI'
      this.modalOptions.API = `api/customer/listPrice/print/${this.FormCustomer.value.priceListid}`
      this.modalService.open(this.modalPriceList, { size: 'xl' });
    }else{
      this.alertService.info('Para visualizar el PDF de la lista de precios se requiere asignar una lista de precios al cliente.','');
    }
  }

}
