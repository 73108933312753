<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Administrar Formula</h6>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body pb-0">
    
    <div class="row">
        <div class="col-md-12">
            <h6>Estudio (Nombre largo - Nombre corto)</h6>
            <div class="input-group">
                <ng-select class="form-control" (change)="changeStudy()" [(ngModel)]="study" [items]="studyList" bindValue="_id" bindLabel="name" placeholder="Seleccionar estudio"></ng-select>
            </div>
        </div>
    </div>
        
    <div class="row mt-3">
        <div class="col-sm-12">
            <div class="form-control border-1 ___i" 
                type="text" placeholder="Formula..."
                #input
                [contentEditable]="true"
                [mention]="determinationsList"
                [mentionConfig]="{labelKey:'determination', mentionSelect: format, triggerChar: '!'}"
                [mentionListTemplate]="determinationListTemplate"
                (itemSelected)="addDetermination($event)"
                (keypress)="validator.isFormula($event);"
            ></div>
        </div>
    </div>
</div>
<div class="modal-footer justify-content-between mt-2">
    <button type="button" class="btn btn-dark" (click)="close()">Cerrar</button>
    <button type="button" class="btn btn-success" (click)="submit();">Guardar</button>
</div>

<!-- SWALS -->
<swal #deleteSwal class="d-none" title="¿Estas seguro de eliminar la abreviacion?" icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>

<ng-template #determinationListTemplate let-item="item">
    <div class="itemMention">
        <span>{{item?.determination}}</span>
    </div>
</ng-template>