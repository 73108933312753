<div class="__header">
    <div class="logo">
        <img
            src="../../../assets/img/logo.svg">
    </div>
    <div class="info">
        <b>LABORATORIO CLINICO EXPRESS S.C.</b>
        <span>
            <ng-container *ngIf="!data?.address">
                BLVD. SANCHEZ TABOADA 9250-16 <br>
                ZONA URBANA RIO, TIJUANA B.C. C.P. 22010 <br>
            </ng-container>
            <ng-container *ngIf="data?.address">{{data?.address | uppercase}}<br></ng-container>
            R.F.C. {{(data?.rfc || 'LCE-080129-6N3') | uppercase}} <br>
        </span>
        <b class="text-primary">{{data?.phones || 'TELEFONO 664-215-7733'}}</b>
    </div>
    <div class="order">
        <span>Fecha: {{ (data?.create?.date) | moment:"l hh:mm A" }} </span>
        <b class="line"><span>Orden: </span> {{ data?.ticket?.folio }}</b>
        <div class="qr"><img src="{{data?.qr}}"></div>
    </div>
</div>
<ng-container *ngIf="data?.ticket?.status == 'PENDING'">
    <div class="_watermark"><span>ADEUDO</span></div>
</ng-container>
<ng-container *ngIf="data?.courtesy">
    <div class="_watermark"><span>CORTESIA</span></div>
</ng-container>
<div class="block patient">
    <div class="_label"><span>Datos del Paciente</span></div>
    <h2>{{ (data?.patient?.name | decrypt) | titlecase }}</h2>
    <div class="line"><span>Fecha Nacimiento:</span> <b> {{ data?.patient?.birthDate?(data?.patient?.birthDate | moment: 'l'):'Sin fecha registrada' }} </b>
    </div>
    <div class="line"><span>Correo: </span>
        <b>{{ (data?.patient?.email[0])?(data?.patient?.email[0]?.email | decrypt):'Sin Correo' }}</b></div>
    <div class="line"><span>Sexo: </span> <b>{{ data?.patient?.gender=='MALE'?'Masculino':'Femenino' }}</b></div>
    <div class="line"><span>Dr(a): </span> <b>{{ (data?.medic?.name | decrypt ) |titlecase }}</b></div>
    <h3 class="text-primary"> {{ (data?.customer?.name | decrypt)|titlecase }}</h3>
    <div class="line"><span>Fecha de Entrega: </span> <b>
            {{ (data?.ticket?.orderData?.results?.normal?.active)?(data?.ticket?.orderData?.results?.normal?.dueDate | moment:'l hh:mm A'):'N/A' }}
        </b></div>
    <div class="line"><span>Fecha de Entrega Parcial:</span> <b>
            {{ (data?.ticket?.orderData?.results?.partial?.active)?(data?.ticket?.orderData?.results?.partial?.dueDate | moment:'l hh:mm A'):'N/A' }}
        </b></div>
</div>
<!-- ORDEN -->
<div class="block summary">
    <div class="_label"><span>Orden</span></div>
    <ng-container *ngFor="let item of data?.ticket?.detail">
        <div class="line">
            <span>{{ item.name }} </span>
            <b> {{((data?.courtesy)?0:item.price) | currency }} MXN</b>
        </div>
    </ng-container>


    <div class="bot">
        <div class="line">
            <span class="text-primary">IMPORTE A PAGAR M.N</span>
            <b> {{( (data?.courtesy)?0:(data?.ticket?.total) )| currency }} MXN</b>
        </div>
        <!-- <div class="line">
            <span class="text-primary">IMPORTE A PAGAR DLLS</span>
            <b>$651.60</b>
        </div> -->
    </div>
</div>
<!-- PAGOS -->
<div class="block summary">
    <div class="_label"><span>Pagos</span></div>
    <ng-container *ngFor="let item of data?.ticket?.payments">
        <div class="line">
            <span>{{ (item.create?.date) | moment: 'l' }}</span>
            <b><span> {{ item.type }}</span> {{(item.amount) | currency }} MXN</b>
        </div>
    </ng-container>

    <div class="line" *ngIf="data?.totalPoints">
        <span>{{ (data?.create?.date) | moment: 'l' }}</span>
        <b><span>PUNTOS</span> {{data?.totalPoints | currency }} MXN</b>
    </div>

    <div class="bot">
        <div class="line">
            <span class="text-primary">TOTAL PAGADO</span>
            <b>{{((data?.ticket?.paymentsData?.amount || 0) + data?.totalPoints )| currency }} MXN</b>
        </div>
    </div>
</div>
<div class="block saldos">
    <div class="_label"><span>Saldo</span></div>
    <h3><span>MXN</span> {{( (data?.courtesy)?0: (data?.ticket?.total-(data?.ticket?.paymentsData?.amount || 0) - data?.totalPoints) )| currency }} </h3>
    <h3><span>USD</span> {{( (data?.courtesy)?0:((data?.ticket?.total-(data?.ticket?.paymentsData?.amount || 0) - data?.totalPoints)/data?.tc) )| currency }}</h3>
</div>
<div class="__footer">
    <span contenteditable="true" #text1>
        SI REQUIERE FACTURAR FAVOR DE SOLICITARLA INMEDIATAMENTE AL MOMENTO DE RECIBIR SU SERVICIO GRACIAS POR SU
        PREFERENCIA
        <br>
        LABEXPRESS
    </span>
    <a href="https://www.labexpress.com.mx" target="_blank">visite www.labexpress.com.mx</a>

    <p class="_note" contenteditable="true" #text2>Sus resultados serán impresos una vez que se haya cubierto la totalidad del costo de sus estudios.</p>
    <span contenteditable="true" #text3>REQUIERE PRESENTAR SU RECIBO PARA LA ENTREGA DE RESULTADOS</span>

    <b contenteditable="true" #text4>TIJUANA, BC, MEXICO
        El presente Recibo/Comprobante
        es un Contrato de Servicios, al momento
        de efectuar su pago o recibir el servicio,
        acepta los procedimientos previamente
        indicados por el personal para el desarrollo
        y procesos de sus estudios
    </b>
    <b contenteditable="true" #text5>
        Laboratorio ante la Entidad Mexicana
        de Acreditacion A.C. No de Acreditacion CL-053
        Fecha de Acreditacion 2014-07-18
        Para alcance dirigirse a www.ema.orn.mx</b>
</div>