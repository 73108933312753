<!-- <div class="row">
  <div class="col-12 _detail pb-3">
          <div class="card custom-card bg-primary _head mt-3">
              <div class="_bg"><img src="../../../assets/img/trianglePattern.png" alt=""></div>
              <div class="row date">
                <div class="col-12 d-flex justify-content-between flex-wrap ">
                    <span><i class="fa fa-calendar mr-1 op-5"></i>{{detail?.date | moment:'ll'}}</span>
                    <span>{{detail?.date | moment:'hh:mm a'}}</span>
                </div>
              </div>
              <div class="row">
                  <div class="col-12 d-flex justify-content-between flex-wrap ">
                      <div class="card border-0 _cert bg-white-9">
                          
                          <div class="labels d-flex justify-content-between">
                            <div class="user">
                              <div class="_user">
                                  <div class="img"><img [src]="imgUser | imagesLoad" alt=""></div>
                              </div>
                              <div class="info">
                                  <b>Paciente</b>
                                  <span class="m-0">{{detail?.patient?.name | decrypt}}</span>
                              </div>
                            </div>
                            
                            
                          </div>
                          <div class="info d-flex justify-content-between">
                            <span class="m-0">Genero: {{detail?.patient?.gender | status }}</span><br>
                            <span class="m-0" *ngIf="detail?.patient?.birthDate">Edad: {{detail?.patient?.birthDate | ageByBirthday }}</span>
                          </div>
                        
                      </div>
                  </div>
              </div>
          </div>
          <ng-container *ngIf="detail?.note">
            <h3 class="text-center text-primary my-3">Nota</h3>
            {{detail?.note}}
          </ng-container>
          
          <ng-container>
              <h3 class="text-center text-primary my-3">Contacto de Paciente</h3>
              <div class="d-flex justify-content-between">
                <span *ngIf="detail?.patient?.email && detail?.patient?.email[0]?.email">Correo: {{detail?.patient?.email[0]?.email | decrypt}}</span>
                <span *ngIf="detail?.patient?.phone && detail?.patient?.phone[0]?.phone">Telefono: {{detail?.patient?.phone[0]?.phone | decrypt}}</span>
              </div>
          </ng-container>
          <ng-container>
              <h3 class="text-center text-primary my-3">Sucursal</h3>
              <span>{{detail?.branch?.name}}</span><br>
              <span>{{detail?.branch?.address}}</span>
          </ng-container>

  </div>
</div> -->

<div class="row">
  <div class="col-12">
    <div class="card mt-4 py-4">
      <div class="d-flex flex-wrap justify-content-between text-primary px-4">
          <div>
              <span class="text-muted">Fecha</span>
              <h3>{{detail?.date | moment:'ll'}}</h3>
          </div>
          <div class="text-right">
              <span class="text-muted">Hora</span>
              <h3>{{detail?.date | moment:'h:mm A': 'H:mm A'}}</h3>
          </div>
          <div class="w-100">
              <b class="text">Sucursal {{detail?.branch?.name || '...'}}</b>
              <span class="text-muted ml-2">({{detail?.branch?.address || 'Sin direccion'}})</span>
          </div>
      </div>
      <div class="w-100 border-top mt-4 pt-4 px-4">
          <div class="w-100"><h4>Paciente: <b>{{detail?.patient?.name | decrypt}}</b></h4></div>
          <div class="d-flex justify-content-between text-primary">
            <span *ngIf="detail?.patient?.email && detail?.patient?.email[0]?.email">Correo: <b>{{detail?.patient?.email[0]?.email | decrypt}}</b></span>
            <span *ngIf="detail?.patient?.phone && detail?.patient?.phone[0]?.phone">Teléfono: <b>{{detail?.patient?.phone[0]?.phone | decrypt}}</b></span>
          </div>
      </div>
  </div>
  </div>
</div>
<div class="row py-3">
  <div class="col-12 d-flex justify-content-between">
    <div class="my-auto">
      <b>Status <mk-status [status]="detail?.status || ''"></mk-status></b>
    </div>
    <div *ngIf="detail?.status != 'CANCELLED' && detail?.status != 'DONE'">
      <button class="btn btn-danger" [swal]="cancelAppointmentSwal" (confirm)="cancelAppointment()">Cancelar Cita</button>
    </div>
  </div>
</div>

<swal #cancelAppointmentSwal class="d-none" title="¿Estás seguro de que deseas cancelar esta cita?" text="No podrás revertir la cancelación" icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar'}">
</swal>
