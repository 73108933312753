<mk-loader *ngIf="isLoading" class="loader-spinner" style="z-index: 120"></mk-loader>
<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title"><i class="fa fa-{{modalOptions?.icon}} mr-2"></i>{{modalOptions?.title}}</h6>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
 
<div class="modal-body">
    <form [formGroup] ="cancelForm">
        <div class="row">
        </div>
        <div class="row">
          <div class="col-sm-12">
              <h6>{{modalOptions?.field?.label}}</h6>
              <div class="input-group">
                  <textarea class="form-control" [placeholder]="modalOptions?.field?.placeholder" formControlName="motive"></textarea>
              </div>
          </div>
      </div>

    </form>
</div>

<div class="modal-footer justify-content-between">
    <button class="btn btn-dark" (click)="close()">Cancelar</button>
    <button class="btn btn-danger" *ngIf="modalOptions?.noAuthorizationButton" (click)="sendData(null, true)">Rechazar</button>
    <button class="btn btn-success" (click)="modalOptions?.noAuthorization ? noAuthorization() : openModal(modal)">{{modalOptions?.noAuthorization ? 'Continuar' : 'Autorizar'}}</button>

</div>

<ng-template #modal let-modal>
    <app-modal-auth  [reason]="cancelForm.value?.motive" (onSuccess)="sendData($event); modal.dismiss()" (onClose)="modal.dismiss()"></app-modal-auth>
</ng-template>