import { AlertService } from 'src/app/shared/services/alert.service';
import { MovementsService } from './../../shared/services/movements.service';
import { Component, Input, OnInit } from '@angular/core';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'


@Component({
  selector: 'app-modal-movement',
  templateUrl: './modal-movement.component.html',
  styleUrls: ['./modal-movement.component.scss']
})
export class ModalMovementComponent implements OnInit {


  public currencyMask = createNumberMask({
    prefix: '$',
    includeThousandsSeparator: true,
    allowDecimal: true,
  })

  @Input() cashRegister: any;

  public movement: any = {
    type: 'INCOME',
    amount: null,
    description: null
  }

  constructor( public movementService: MovementsService, public alertService: AlertService) { }

  ngOnInit(): void {
  }

  close(): void { this.movementService.modal.close() }

  public save(){
    let parsedAmount = this.movement.amount.replace(/[$,]/g,'');
    this.alertService.confirmToast(`Se crea un ${this.movement.type=='INCOME'?'INGRESO':'EGRESO'} por $${parsedAmount}${Number.isInteger(Number.parseFloat(parsedAmount))? ".00":''}`, async ()=>{
      this.movement.amount = parsedAmount
      await this.movementService.addMovement(this.movement);
      this.alertService.successToast(`${this.movement.type=='INCOME'?'INGRESO':'EGRESO'} agregado con exito.`);
      this.close();
    });
  }

  disableBtn():boolean{
    return !this.movement.amount || Number.parseFloat(this.movement.amount)<=0  || !this.movement.description
  }

}
