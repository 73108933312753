<!-- Main Footer-->
<div class="main-footer text-center">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <span>Copyright © {{ today | date: 'y'}}
                    <a href="https://labexpress.com.mx/" target="_blank">Laboratorio Express, S.C. 2024</a>.
                    All rights reserved.</span>
            </div>
        </div>
    </div>
</div>
<!--End Footer-->