import { BranchService } from './branch.service';
import { ExchangeRateService } from './exchangeRate.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ILogin } from '../../interfaces/ilogin'
import { CryptoSharkService } from './crypto-shark.service';
import { HttpService } from './http.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/**
 * Servicio administrador de sesión de usuario
 */
export class UserService {
  private _login: ILogin = {}
  private _user: any = {}
  public mensaje: string = '';

  public logOutVariable$ = new Subject<any>();

  constructor(
    private httpService: HttpService, 
    private router: Router,
    private cryptoService: CryptoSharkService,
    private exchangeRateService: ExchangeRateService,
    private branchService: BranchService,
  ) { 
    window['routerGlobal'] = this.router;
    window['logOutVariable'] = this.logOutVariable$;
  }

  /**
   * Guardar los datos del usuario
   * @param login Credenciales del usuario que inició sesión
   */
  signInCurrentUser(login: ILogin) {
    // AuthConstants.login = login;
    localStorage.setItem('ldt', JSON.stringify(login));
  }

  /**
   * Obtener datos de la sesión del usuario
   */
  get login() {
    return this._login
  }

  /**
   * Obtener datos del usuario
   */
  get user() {
    return this._user
  } 

  setCashRegister(id){
    this._user.cashRegister = id;
  }
  setCashRegisterTitle(title){
    this._user.cashRegisterTitle = title
  }
  setCashRegisterDefault(item){
    this._user.cashRegisterDefault = item
  }
  /**
   * Obtener datos de sesión del usuario validUser()
   * @param returnUrl Url a la que regresará una vez que haya iniciado sesión
   */

  
  getCurrentUser(returnUrl: string): Promise<boolean> {
    return new Promise( (resolve) => {
      this._login = JSON.parse(localStorage.getItem(`ldt`));
      
      // this._login = AuthConstants.login;
      if (!this._login?.isRegister) {
        this.router.navigate(['/login'], {queryParams: { returnUrl } });
        resolve(false);
      }
      
      this.httpService.get(`api/login`).subscribe(async (response) => {
        this._login.company['key'] = response.data[0]; //<-- ENCRYPT REQUESTS  
        this._login.company['dk'] = this.cryptoService.decrypt(response.data[1],this._login.company.key);//<-- DECRYPT RESPONSES 
        
        
        // let emailDecrypt =  this.cryptoService.decrypt(response?.email || null, this._login.company.dk);
        // emailDecrypt == '' ? this._user.email = response?.email : emailDecrypt;

        
        // try {
        //   let userDecrypt =  this.cryptoService.decrypt(response?.name, this._login.company.dk);
        //   userDecrypt == '' ? this._user.name = response?.name : userDecrypt;
        // } catch (error) {
        //   if(response?.name) this._user.name = response?.name;
        // }
        this._user.name = response?.name;
        
        
        // this._user.userName = this.cryptoService.decrypt(response.userName, this._login.company.key
        // this._user.branch = response?.branchData?._id;
        this._user.cashRegister = response.cashRegister;
        this._user.exchangeRate = response.exchangeRate;
        this._user.cashRegisterTitle = response.cashRegisterTitle;
        this._user.roleName = response.roles?.roleType;
        this._user.adminPOS = response.adminPOS;
        this._user.permissions = response?.roles?.permissions;
        this._user.socketRoom = response?.socketRoom;   
        this._user.signOutOff = response.signOutOff;
        
        this.exchangeRateService.exchangeRate().then(data=>{
          this.exchangeRateService.amount = data?.amount?data.amount:0;
          if (response.exchangeRate) this.exchangeRateService.loadAmount();
        });
        
        if (response?.branchData) {
          let branch = this.cryptoService.encryptAes(JSON.stringify(response?.branchData));
          localStorage.setItem('branch', branch);
        }

        await this.branchService.load();
        this._user.branch = this.branchService.branch?._id;
        if(this._user.branch)this._user.cashRegisterDefault = await this.branchService.cashRegisterDefault(this._user.branch);
        if (!this._user.signOutOff) {
          this.inactivityTime();
        }
        
        resolve(true)
      });
    });
  }
  

  /**
   * Cerrar sesión
   * Borrar los datos de sesión del usuario
   */
  public signOutCurrentUser() {
    localStorage.removeItem('ldt')
    this.router.navigate(['/login'])
  }

  private inactivityTime() {
    let time;
    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeyup = resetTimer;
    document.onclick = resetTimer;
    document.addEventListener('scroll', resetTimer, true);
    
    function logout() {
      if (window['routerGlobal'].url != '/login') {
        window['logOutVariable'].next();
        localStorage.removeItem('ldt');
        location.href = '/login';
        // window['routerGlobal'].navigate(['/login']);
      }
    }

    function resetTimer() {
      console.log("se reinicio tiempo");
      
      clearTimeout(time);
      time = setTimeout(logout, 300000)// 5 min de inactividad 
      // time = setTimeout(logout, 10000) // 10 seg de inactividad
    }
  };
}
