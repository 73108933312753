import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TableService } from 'src/app/shared/services/table.service';
import { FiltersDatatableService } from 'src/app/shared/services/filters-datatable.service';
import { IFilter } from './../../interfaces/iFilter';
import { CallService } from 'src/app/shared/services/http/call-service';
import { AlertService } from 'src/app/shared/services/alert.service';
import Swal from 'sweetalert2';
import { CallsService } from 'src/app/shared/services/calls.service';
import * as moment from 'moment-timezone';
import { SocketService } from 'src/app/shared/services/socket.service';
import { BadgetService } from 'src/app/shared/services/badget.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'panel-call',
  templateUrl: './panel-call.component.html',
  styleUrls: ['./panel-call.component.scss']
})

export class PanelCallComponent implements OnInit, OnDestroy {

  public isLoading: boolean = false;
  public activeCalls: any; 
  public DT_Calls: MatTableDataSource<any>;
  public displayColumns: string[] = ['folio', 'date', 'dateCreate', 'createdByUser', 'description', 'processType', 'attendedByUser', 'attendedByDate', 'attend', 'action'];
  public filters: IFilter[] = [
    { name: "Sin Atender", type: "CREATED", active: true },
    { name: "Atendidos", type: "ATTENDED", active: true },
  ];
  
  public modalOptions: { id: any, module: "CALLS", item: null } = { id: null, module: "CALLS", item: null }
  public modalOptionsCall = {id: null, type: "ADD"};
  public callForm;
  public defaultFilterPredicate;
  public searchField = null;
  public datasource: any[] = [];
  public subscribePanel: Subscription;
  public subscribeSocket: Subscription;
  public permisions: any = {};
  
  public close = () => { }

  public statusColors = {
    CREATED: "#ce0e5e",
    ATTENDED: "#7447c7",
    PROCESSED: "#25a775",
  }

  @ViewChild('Paginator') paginator: MatPaginator;
  @ViewChild('Sort') sort: MatSort;

  constructor(
    public tableService: TableService,
    private modal: NgbModal,
    private filterService: FiltersDatatableService,
    private callService: CallService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private callsServices: CallsService,
    private socketService : SocketService,
    public badgetService: BadgetService,
    private userService: UserService
  ) {
    this.DT_Calls = new MatTableDataSource;

    this.subscribePanel = this.callsServices?.openPanel.subscribe(() => {
      this.searchField = null;
      this.getDataFiltered();
    });
    
    this.subscribeSocket = this.socketService.listenData(`calls`).subscribe(async (data) => {
      if (data?.sound) {
        let audio = new Audio('assets/audio/sound6.mp3');
        audio.play();
      }
      this.getDataFiltered();
    });
    
    let permisionsModule = this.userService?.user?.permissions?.find(item => {return item?.path == 'calls'});
    for (const item of permisionsModule?.actions || []) { this.permisions[item?.action] =  (this.userService?.user?.roleName == "ADMINISTRADOR") ? true : item?.value; }

  }

  ngOnDestroy(){
    this.subscribeSocket.unsubscribe();
    this.subscribePanel.unsubscribe();
  }

  async ngOnInit() {
    this.defaultFilterPredicate = this.DT_Calls.filterPredicate;
    this.getDataFiltered()
  }

  public getDataFiltered = async () => {
    try {
      this.isLoading = true;
      this.searchField = null;
      this.filterDataByStatus()
    } catch (error) {
      this.isLoading = false;
      console.log(error);
    }
  }

  private getCallData = async () => {
    
    let data = await this.callService.getPanelCalls();
    
    for (let item of data || []) {
      item.delay = moment().endOf('day') > moment(item.date).endOf('day');
      
      const deteComplete = moment(item.date).format("YYYY-MM-DD")+"T"+item.hour;;//revisar porque lo estan manejando asi ?
      item.dateCreate = item.createdBy.date + "*" + moment.tz(item.createdBy.date, "America/Tijuana").format("DD/MMM/YYYY - h:mm a");
      item.createdByUser = item.createdBy?.userName || (item?.mobile ? 'APP MÓVIL' : '');
      item.date = deteComplete + "*" + moment(deteComplete).format("DD/MMM/YYYY")+" - "+moment.tz(item.hour, "HH:mm","America/Tijuana").format("h:mm a");
      item.attendedByUser = item.attendedBy?.nameLogin || 'Sin Atender';
      item.attendedByExtraUser = item.attendedBy?.nameLoginExtra || 'Sin Atender';
      item.attendedByDate = (item.attendedBy?.date) ? (item.attendedBy?.date + "*" + moment(item.attendedBy.date).format("L - h:mm a")) : 'Sin Atender';
      
    }

    data = await data.filter(element => {return element.status != "CANCELLED"  && element.status != "PROCESSED"});

    // this.datasource = data;
      
    this.DT_Calls.data = data;
    this.DT_Calls.paginator = this.paginator;
    this.DT_Calls.sort = this.sort;
    this.isLoading = false;
  }

  public filterDataByStatus = () => {
    const filters: string[] = this.filterService.filtersSelected(this.filters)
    // generamos el filtro dentro de la dt_table en base a los filtros activos
  
    this.DT_Calls.filterPredicate = (data: any) => this.filterService.dataFiltered(data, filters)
    // inicializamos
    this.DT_Calls.filter = 'inicializado'
    
     this.getCallData();
  }

  formCall = () => {
    this.callForm = this.formBuilder.group({
      id: [''],
      description: ['', Validators.compose([Validators.required, Validators.maxLength(60)])],
      processTypeId: ['', Validators.compose([Validators.required])],
      urgent: [false, Validators.compose([Validators.required])],
      date: ['', Validators.compose([Validators.required])],
      hour: ['', Validators.compose([Validators.required])],
      program: [false, Validators.compose([Validators.required])],
    });
  }

  public attendCall = async () => {
    this.callService.attendCall(this.callForm)
  }

  public processCallSwal = (item) => {
    Swal.fire({
      title: '¿Desea procesar esta llamada?',
      text: `lugar: ${item.description}`,
      icon: 'info',
      showDenyButton: true,
      confirmButtonText: 'Si, Procesar!',
      denyButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) await this.processCall(item._id)
    });
  }


  public undoCallSwal = (item) => {
    Swal.fire({
      title: '¿Regresar el estatus a sin atender?',
      text: 'Se eliminara la fecha y usuario de quien atendio la llamada.',
      icon: 'info',
      showDenyButton: true,
      confirmButtonText: 'Cambiar',
      denyButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) await this.unatendedCall(item._id)
    });
  }

  private processCall = async (id) => {
    const data = { id }
    await this.callService.processCall(data).then((response) => {
      this.alertService.successToast(response.message);
      this.getDataFiltered()
    }).catch((error) => {
      this.alertService.infoToast(error.error.message)
    })
  }

  private unatendedCall = async (id) => {
    const data = { id }
    await this.callService.unatendedCall(data).then((response) => {
      this.alertService.successToast(response.message);
      this.getDataFiltered()
    }).catch((error) => {
      this.alertService.infoToast(error.error.message);
    })
  }

  openModal(template, size, data = null) {
    !data || (this.modalOptions.item = data)
    this.modal.open(template, { size: size })
  }

  search(event) {
    const filtersDefault = [
      { name: "Sin Atender", type: "CREATED", active: true },
      { name: "Atendidos", type: "ATTENDED", active: true },
      // { name: "Finalizadas", type: "PROCESSED", active: true },
    ];
    //const filters: string[] = this.filterService.filtersSelected(this.filters);
    this.DT_Calls.filterPredicate = this.defaultFilterPredicate;
    this.filters = filtersDefault;
    //this.DT_Services.filter = 'inicializado'

    const filterValue = (event.target as HTMLInputElement).value;
    this.DT_Calls.filter = filterValue.trim().toLowerCase();
    
  }

}
