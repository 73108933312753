<mk-loader *ngIf="isLoading" class="loader-spinner"></mk-loader>
<div class="modal-body py-0">
    <div class="row border-bottom">
        <div class="col-12 p-3 d-flex">
            <i class="fa fa-money fa-2x my-auto text-primary"></i>
            <div class="d-flex flex-column ml-3 lh-1">
                <h3 class="m-0">Tipo de cambio</h3>
                <span class="text-muted">Actualiza el valor del día.</span>
            </div>
        </div>
    </div>
    <div class="row py-4 px-1" (click)="inputRate.select()">
        <div class="col-12 d-flex">
            <div class="wrap">
                <input #inputRate type="text" [textMask]="{mask: currencyMask}" [(ngModel)]="amount" placeholder="$00.00">
            </div>
        </div>
        <div class="col-12 mt-3 text-center">
            <!-- <span class="text-info"><b>Valor anterior: </b> {{17.45 | currency}}</span> -->
            <span class="text-muted">Haz click para cambiar valor.</span>
        </div>
    </div>

</div>
<div class="modal-footer justify-content-end">
    <button class="btn btn-success" (click)="save()">Guardar</button>
</div>