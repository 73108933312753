import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { SocketService } from './socket.service';

@Injectable({
  providedIn: 'root',
})
/**
 * Guard que verifica si el usuario tiene una sesión iniciada. 
 * * Depende de UserService
 * 
 * INSTRUCCIONES DE USO: Utilizar en el canActivate de las routes
 */
export class AuthGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private socket: SocketService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (route.data.path == 'login') {
      this.socket.clearRooms();
      this.socket.disconnect();
      return true;

    }else{
      return this.userService.getCurrentUser(state.url);
    }
    
  }
 
}
