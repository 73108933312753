import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ExchangeRateService } from '../../services/exchangeRate.service';
import { ZoomService } from '../../services/zoom.service';
import { SidemenuComponent } from '../../components/sidemenu/sidemenu.component';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss']
})
export class FullLayoutComponent implements OnInit {

  @ViewChild('sideMenu', {static: true}) sideMenu: SidemenuComponent;

  constructor(
    private router: Router,
    private zoom: ZoomService,
    private navService : NavService,
  ) {}

  ngOnInit(): void {
  }

  mainSidebarOpen: any;
  hoverEffect($event, keepOpen = true) {
    if( $event?.type == 'mouseover' && keepOpen){
      this.mainSidebarOpen = 'main-sidebar-open';
      document.querySelector('body').classList.add('overflow-hidden');
    }else if($event?.x > 239 || !keepOpen){
      this.mainSidebarOpen = '';
      document.querySelector('body').classList.remove('overflow-hidden');
    }
  }
  
  clickonBody(){
    this.mainSidebarOpen = '';
    document.querySelector('body').classList.remove('overflow-hidden')
  }
}
