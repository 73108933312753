import * as moment from "moment";

export const datePicker = {
  locale: {
    spanish: {
      displayFormat: 'DD/MM/YYYY',
      "customRangeLabel": "Personalizadas",
      "applyLabel": "Aplicar",
      "cancelLabel": "Cancelar",
      "daysOfWeek": [
        "Do",
        "Lu",
        "Ma",
        "Mi",
        "Ju",
        "Vi",
        "Sa"
      ],
      "monthNames": [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      ],
      "firstDay": 1
    },
    spanish_short:{
      displayFormat: 'DD/MM/YYYY',
      "customRangeLabel": "Personalizadas",
      "applyLabel": "Aplicar",
      "cancelLabel": "Cancelar",
      "daysOfWeek": [
        "Do",
        "Lu",
        "Ma",
        "Mi",
        "Ju",
        "Vi",
        "Sa"
      ],
      "monthNames": [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic"
      ],
      "firstDay": 1
    }
  },
  customRange: {
    spanish: {
      'Semana actual': [moment().startOf("isoWeek"), moment().endOf("isoWeek")],
      'Mes Actual': [moment().startOf("month"), moment().endOf("month")],
      'Mes pasado': [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
      
    }
  },
  localeDateTimePicker: {
    spanish: {
      displayFormat: 'DD/MM/YYYY HH:mm',
      "customRangeLabel": "Personalizadas",
      "applyLabel": "Aplicar",
      "cancelLabel": "Cancelar",
      "daysOfWeek": [
        "Do",
        "Lu",
        "Ma",
        "Mi",
        "Ju",
        "Vi",
        "Sa"
      ],
      "monthNames": [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      ],
      "firstDay": 1
    }
  }
}

//'Todos': [moment('2021-01-01'), moment().endOf("week")],