import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagesLoadPipe } from './images-load.pipe';
import { DecryptPipe } from './decrypt.pipe';
import { statusPipe } from './status.pipe';
import { FiltroPipe } from './filtro.pipe';
import { momentPipe } from './moment.pipe';
import { InitialsPipe } from './initials.pipe';
import { selectsPipe } from './selects.pipe';
import { ageByBirthdayPipe } from './ageByBirthday.pipe'; 

@NgModule({
  providers: [statusPipe],
  declarations: [ImagesLoadPipe, DecryptPipe, statusPipe, FiltroPipe, momentPipe, InitialsPipe, selectsPipe, ageByBirthdayPipe],
  imports: [],
  exports: [ImagesLoadPipe, DecryptPipe, statusPipe, FiltroPipe, momentPipe, InitialsPipe, selectsPipe, ageByBirthdayPipe]
})
export class PipesModule { }
