import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cat-modal-products',
  templateUrl: './modal-products.component.html',
  styleUrls: ['./modal-products.component.scss']
})
export class ModalProductsComponent implements OnInit {


  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  close = () => {this.onClose.emit()}
  
  constructor() { }

  ngOnInit(): void {
  }

}
