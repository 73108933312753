<div class="modal-body p-0">
    <div class="row m-0 border-bottom">
        <div class="col-12 p-3 d-flex">
            <i class="fa fa-flask fa-2x my-auto text-primary"></i>
            <div class="d-flex flex-column ml-3 lh-1">
                    <h3 class="m-0"> Muestras</h3>
                    <span class="text-muted">Listado de muestras de la orden.</span>
            </div>
        </div>
    </div>
    <div class="row m-0 py-2 border-bottom">
        <div class="col-4">
            <small class="op-7">Folio</small>
            <h4 class="mb-0 text-danger">{{samples?.folio}}</h4>
        </div>
        <div class="col-8">
            <small class="op-7">Paciente</small>
            <h4 class="mb-0 text-overflow">{{ samples?.patient?.name | decrypt}}</h4>
        </div>
    </div>
    <div class="_scroll">
        <ng-container *ngFor="let item of samples?.container || []; index as i">
            <label class="row cursor-pointer p-0 mb-0" [class.border-top]="i" style="--container: #{{item.colorPrint != 'ffffffff' ? item.colorPrint : '0001'}}">
                <div class="col-2 d-flex">
                    <label class="ckbox m-auto"><input type="checkbox" [checked]="item?.sample" (change)="item.sample = !item.sample"><span></span></label>
                </div>
                <div class="col-10 p-2">
                    <div class="card custom-card m-0">
                        <label-card [sample]="item" [orderData]="samples"></label-card>
                    </div>
                </div>
            </label>
        </ng-container>
    </div>

    
</div>
<div class="modal-footer justify-content-between">
    <button class="btn btn-danger" type="" (click)="close()"> Cancelar</button>
    <button class="btn btn-success" (click)="submit()"> Guardar </button>
</div>