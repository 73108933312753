import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ValidateFormsDirective } from '../../../../../../directives/validate-forms.directive';
import { StudyService } from 'src/app/shared/services/http/study.service';
import { ValidatorsService } from 'src/app/shared/services/validators.service';

@Component({
  selector: 'modal-formula',
  templateUrl: './modal-formula.component.html',
  styleUrls: ['./modal-formula.component.scss']
})
export class ModalFormulaComponent implements OnInit {
  @Input() modalOptions: any;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSave: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('input') input: ElementRef;
  
  close = () => {this.onClose.emit()}

  public study: any = null;
  public determination: any = null;
  public studyList: any = [];
  public determinationsList: any = []; 
  public isLoading: boolean = false;
  
  constructor(
    public validateForm: ValidateFormsDirective,
    private studyService: StudyService,
    public validator: ValidatorsService,
  ) { 
      
  }

  ngOnInit() {
    this.getStudies();
    if (this.modalOptions?.formula?.length) {
      this.setFormula(this.modalOptions?.formula);
    }
  }

  private getStudies = async () => {
    try {
      this.studyList = await this.studyService.getStudySelect();
      for (let item of this.studyList || []) {
        item.name = item.longName + " - " + item.shortName;
      }
      this.study = this.modalOptions?._id || null;
      if (this.study) this.getDeterminations();
    } catch (error) {
      console.log(error);
    }
  }

  public getDeterminations = async () => {
    try {
      if (this.study) this.determinationsList = await this.studyService.getDeterminationsSelect(this.study);
      else this.determinationsList = [];
      
    } catch (error) {
      console.log(error);
    }
  }

  public addDetermination = (deter: any) => {
    try {
      setTimeout(() => {
        let html = this.input.nativeElement.innerHTML;
        this.input.nativeElement.innerHTML = html.replace(/{/g, `<span class="__mention" contenteditable="false" data-id="${deter._id}" data-idStudy="${this.study}" data-name="${deter.determination}">`).replace(/\}/g, '</span> ');
  
        const selection = window.getSelection();
        const range = document.createRange();
        selection.removeAllRanges();
        range.selectNodeContents(this.input.nativeElement);
        range.collapse(false);
        selection.addRange(range);
        this.input.nativeElement.focus();
      }, 2);
      
    } catch (error) {
      console.log(error);
    }
  }

  public changeStudy = () => {
    try {
      this.getDeterminations();
    } catch (error) {
      
    }
  }

  format(item: any) {
    return `{${item.determination}}`;
  }

  public submit = () => {
    try {
      let children = this.input.nativeElement.childNodes;

      let arreglo = [];
      children?.forEach( (value, index) => {
        if (value.localName == 'span') {
          arreglo.push({ study: value.dataset?.idstudy, determination: value.dataset?.id, name: value.dataset.name });
        }else if(value.nodeName == '#text' && value.textContent?.trim()?.length){
          let text: string = value.textContent;
          let arrayText = this.tokenizeExpression(text.trim());
          
          arreglo.push(...arrayText);
        }
      });

      this.onSave.emit({formula: arreglo, index: this.modalOptions?.index});
      
    } catch (error) {
      console.log(error);
      
    }
  }

  private setFormula = (formula: any) => {
    try {
      setTimeout(() => {
        let innerHTML = '';
        for (const item of formula || []) {
          if (typeof item == 'string') {
            innerHTML = innerHTML + item;
          }else if (typeof item == 'object') {
            innerHTML = innerHTML + `<span class="__mention" contenteditable="false" data-id="${item.determination}" data-idStudy="${item.study}" data-name="${item.name}">${item.name}</span>`
          }
        }
        this.input.nativeElement.innerHTML = innerHTML;
  
        const selection = window.getSelection();
        const range = document.createRange();
        selection.removeAllRanges();
        range.selectNodeContents(this.input.nativeElement);
        range.collapse(false);
        selection.addRange(range);
        this.input.nativeElement.focus();
      }, 2);
    } catch (error) {
      console.log(error);
    }
  }

  private calculateCKD_EPI_2021(creatinine: number, age: number, isMale: boolean): number {
    // Definir valores de kappa y alfa según el sexo
    const kappa = isMale ? 0.9 : 0.7;
    const alpha = isMale ? -0.302 : -0.241;

    // Calcular los términos de la ecuación
    const term1 = Math.pow(Math.min(creatinine / kappa, 1), alpha);
    const term2 = Math.pow(Math.max(creatinine / kappa, 1), -1.200);
    const term3 = Math.pow(0.9938, age);
    const term4 = isMale ? 1 : 1.012;

    // Aplicar la fórmula CKD-EPI 2021(update)
    const eGFR = 142 * term1 * term2 * term3 * term4;

    return eGFR;
  }

  //Convierte los strings de la formula a array con cada elemento de la formula
  private tokenizeExpression = (expression: string): string[]  => {
    try {
      // Eliminar espacios en blanco
      expression = expression.replace(/\s+/g, "");

      // Extraer números, operadores y paréntesis con una expresión regular
      const tokens = expression.match(/(\d+(\.\d+)?)|[+\-*/()]|(\.\d+)/g);
      if (!tokens) throw new Error("Expresión inválida");

      return tokens;
    } catch (error) {
      console.log(error);
    }
  }

  //Se obtiene el resultado de las determinaciones tipo formula, se requiere mandar un array con cada elemento de la formula en orden
  private evaluateTokens = (tokens: string[]): number => {
    const outputQueue: (number | string)[] = [];
    const operatorStack: string[] = [];

    const precedence: { [key: string]: number } = { "+": 1, "-": 1, "*": 2, "/": 2 };
    const isOperator = (op: string) => ["+", "-", "*", "/"].includes(op);

    // Algoritmo Shunting-Yard: convierte a notación postfija
    tokens.forEach(token => {
      if (!isNaN(parseFloat(token))) {
          outputQueue.push(parseFloat(token));
      } else if (isOperator(token)) {
        while (
          operatorStack.length &&
          precedence[operatorStack[operatorStack.length - 1]] >= precedence[token]
        ) {
          outputQueue.push(operatorStack.pop()!);
        }
        operatorStack.push(token);
      } else if (token === "(") {
        operatorStack.push(token);
      } else if (token === ")") {
        while (operatorStack.length && operatorStack[operatorStack.length - 1] !== "(") {
          outputQueue.push(operatorStack.pop()!);
        }
        operatorStack.pop(); // Eliminar '(' de la pila
      }
    });

    while (operatorStack.length) {
      outputQueue.push(operatorStack.pop()!);
    }

    // Evaluar notación postfija (Reverse Polish Notation)
    const evalStack: number[] = [];
    outputQueue.forEach(token => {
      if (typeof token === "number") {
        evalStack.push(token);
      } else if (isOperator(token)) {
        const b = evalStack.pop()!;
        const a = evalStack.pop()!;
        switch (token) {
          case "+": evalStack.push(a + b); break;
          case "-": evalStack.push(a - b); break;
          case "*": evalStack.push(a * b); break;
          case "/": evalStack.push(a / b); break;
        }
      }
    });

    return evalStack[0];
  }
  
  
  // public onKeyDown(e): void {
  //   var evt = e || window.event;
  //   var keyCode = evt.charCode || evt.keyCode;
    
  //   switch (keyCode) {
  //     case 13: { if (this.isTagging) this.isTagging = false; else ((this.input.nativeElement.textContent)?.trim() != '' ? this.sendMessage() : ""); } break;
  //     case 50: { this.isTagging = true;} break;
  //     detault: break;
  //   }
    
  //   this.updateMentions();
  // }

  // public updateMentions(): void {
  //   var _mentions = this.input.nativeElement.children;
    
  //   for (const item of _mentions) {
  //     var reg = arreglo.filter(mention => { return mention == item.dataset?.id });
  //     if (!reg.length && item.localName == 'span') {
  //       arreglo.push(item.dataset?.id);
  //     }
  //   }

  //   if(arreglo.length > 0) {
  //     var filtered = this.filterArray(this.arrayMembers, arreglo);
  //     this.members = filtered;
  //   } else{
  //     this.members = this.arrayMembers;
  //   }
  // }
}
