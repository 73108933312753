import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { ProcessTypeService } from 'src/app/shared/services/http/process-type.service';
import { IProcessType } from '../../../../src/app/interfaces/iprocessType';
import { CallService } from 'src/app/shared/services/http/call-service';
import { AlertService } from 'src/app/shared/services/alert.service';
import * as moment from 'moment';
import { datePicker } from 'src/app/shared/mk-configs/datePicker/datePickerLocale';
import { BranchService } from 'src/app/shared/services/branch.service';

@Component({
  selector: 'modal-call',
  templateUrl: './modal-call.component.html',
  styleUrls: ['./modal-call.component.scss']
})
export class ModalCallComponent implements OnInit {


  public isLoading: boolean = false;
  public callForm: FormGroup;
  public daysOfWeek: string[] = ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom']
  public processTypeList: IProcessType[];
  // public locale = moment.locale('ES');
  public locale: any = datePicker.locale.spanish;
  public days: any = [0];
  public selectedDate:{ startDate:moment.Moment,endDate: moment.Moment } = {startDate: moment(), endDate: moment()};
  public selectedDateEnd:{ startDate:moment.Moment,endDate: moment.Moment } = {startDate: moment(), endDate: moment()};
  public today: moment.Moment = moment();

  @Output() onSave: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() modalOptions: any = null;

  public close = () => { this.onClose.emit() }

  constructor(
    private fb: FormBuilder,
    public fieldIsValid: ValidateFormsDirective,
    public processType: ProcessTypeService,
    public callService: CallService,
    public alertService: AlertService,
    private branchService: BranchService
  ) {
  }

  async ngOnInit() {
    this.formCall()
    
    if (this.modalOptions.type == "EDIT"){

      await this.changeFieldsValue(true);
      await this.getCall(this.modalOptions?.item?._id, this.modalOptions.type);

    } else if (this.modalOptions.type == "ADD") {

      await this.changeFieldsValue(true); 

    }else if (this.modalOptions.type == "VIEW"){

      await this.changeFieldsValue(false); 
      this.getCall(this.modalOptions?.item?._id, this.modalOptions.type) ;

    }

    this.getProcessType();
  }

  private getCall = async (id, type = null) => {
    try {
      this.isLoading = true;
      const data = await this.callService.getCall(id);
      this.callForm.patchValue({
        id: data._id,
        description: data.description,
        program: data.program,
        processTypeId: data.processTypeId,
        hour: data.hour,
        generated: data.generated,
        urgent: data.urgent,
        block: data.block
      });
      if ((type == "EDIT" || type == "VIEW") && moment(data.date) < moment()) {
        this.today = moment(data.date);
      }else{
        this.today = moment();
      }
      this.selectedDate = {
        startDate: moment(data.date),
        endDate: moment(data.date)
      }
      this.selectedDateEnd = {
        startDate: moment(data.dateEnd),
        endDate: moment(data.dateEnd)
      }
      
      //se pushean los datos de la lista de datos al form array 
      this.days = (data.days.length > 0) ? data.days.map(Number) : []
      const daysSelected: FormArray = this.callForm.get('days') as FormArray;
      if (this.days.length > 0) {
        this.days.map((day) => daysSelected.push(new FormControl(day)))
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.log(error);
      
    }
   
  }

  private getProcessType = async () => {
    this.processTypeList = await this.processType.getAllProcessType()
  }

  formCall = () => {
    this.callForm = this.fb.group({
      id: [null],
      description: ['', Validators.compose([Validators.required, Validators.maxLength(500)])],
      processTypeId: [null, Validators.compose([Validators.required])],
      urgent: [false, Validators.compose([Validators.required])],
      date: [null, Validators.compose([Validators.required])],
      hour: [moment.tz("America/Tijuana").format("HH:mm"), Validators.compose([Validators.required])],
      program: [false, Validators.compose([Validators.required])],
      days: this.fb.array([], Validators.compose([])),
      block: [false, Validators.compose([Validators.required])],
      dateEnd: [null]
    });
  }

  public existsDay = (day) => this.days?.includes(day) // se prende el checkbox si el dato esta en la lista de dias

  onCheckboxChange = (e: any) => {
    const daysSelected: FormArray = this.callForm.get('days') as FormArray;
    
    if (e.target.checked) {
      const daySelected = Number(e.target.value)
      daysSelected.push(new FormControl(daySelected));
    } else {
      daysSelected.controls.forEach((item: FormControl, index) => {
        if (item.value == e.target.value) {
          daysSelected.removeAt(index);
          return;
        }
      });
    }
  }

  private createCall = async () => {
    this.isLoading = true;
    this.callForm.patchValue({
      date: this.selectedDate.startDate.format("YYYY-MM-DD"),
      dateEnd: this.selectedDateEnd.startDate.format("YYYY-MM-DD")
    });

    if (!this.callForm.valid) {
      this.callForm.markAllAsTouched();
      this.alertService.infoToast("Campos incompletos, verifique porfavor");
      this.isLoading = false;
      return;
    }

    let obj = {
      date: this.callForm.value.date,
      days: JSON.stringify(this.callForm.value.days),
      description: this.callForm.value.description,
      hour: this.callForm.value.hour,
      processTypeId: this.callForm.value.processTypeId,
      program: this.callForm.value.program,
      urgent:  this.callForm.value.urgent,
      branchCode: this.branchService?.branch?.code,
      block: this.callForm.value.block,
      dateEnd: this.callForm.value.dateEnd,
    };
    
    this.callService.createCall(obj).then(resp => {
      this.alertService.successToast(resp.message);
      this.onSave.emit();
      this.close();
    }).catch(err => {
      this.isLoading = false;
      if (err.status != 403) {
        this.alertService.infoToast(err.error.message);
      }
      console.log(err.error.message);
    });
  }

  private updateCall = async () => {
    try {
      this.isLoading = true;
      this.callForm.patchValue({
        date: this.selectedDate.startDate.format("YYYY-MM-DD"),
        dateEnd: this.selectedDateEnd.startDate.format("YYYY-MM-DD")
      });

      if (!this.callForm.valid) {
        this.callForm.markAllAsTouched();
        this.alertService.infoToast("Campos incompletos, verifique porfavor");
        this.isLoading = false;
        return;
      }

      let obj = {
        id: this.callForm.value.id,
        date: this.callForm.value.date,
        days: JSON.stringify(this.callForm.value.days),
        description: this.callForm.value.description,
        hour: this.callForm.value.hour,
        processTypeId: this.callForm.value.processTypeId,
        program: this.callForm.value.program,
        urgent:  this.callForm.value.urgent,
        block: this.callForm.value.block,
        dateEnd: this.callForm.value.dateEnd,
      };

      this.callService.updateCall(obj).then(resp => {
        this.isLoading = false;
        this.alertService.successToast(resp.message)
        this.onSave.emit();
        this.close();
      })
    }
    catch (error) {
      this.isLoading = false;
      console.log(error)
    }
  }

  submit = () => {
    this.modalOptions.type === 'EDIT' ? this.updateCall() : this.createCall();
  }

  public changeFieldsValue = async (_switch:boolean) => {
    return new Promise( (resolve) => {
      if (_switch) {
        for (const key in this.callForm.controls) {
          this.callForm.controls[key].enable();
        }
      }else{
        for (const key in this.callForm.controls) {
          this.callForm.controls[key].disable();
        }
      }
      resolve(true)
    });
  }

  public loadOriginCall = async () => {
    try {
      await this.changeFieldsValue(true);
      await this.getCall(this.modalOptions?.item?.callId, this.modalOptions.type);
      this.modalOptions.type = 'EDIT';
      this.modalOptions.origin = true;
    } catch (error) {
      console.log(error);
    }
  }

  public goBack = async () => {
  try {
      await this.changeFieldsValue(false);
      await this.getCall(this.modalOptions?.item?._id, this.modalOptions.type);
      this.modalOptions.type = 'VIEW';
      this.modalOptions.origin = false;

    } catch (error) {
      console.log(error);
    }
  }

  public changeDateProcess = () => {
    if(this.selectedDateEnd.startDate < this.selectedDate.startDate){
      this.selectedDateEnd = {
        startDate: this.selectedDate.startDate,
        endDate: this.selectedDate.endDate
      }
    }
  }

}
