import { Component, EventEmitter, OnInit, Input,Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DynamicFormService } from 'src/app/shared/services/dynamic-form.service';
import { ValidatorsService } from 'src/app/shared/services/validators.service';

@Component({
  selector: 'cat-modal-determination',
  templateUrl: './modal-determination.component.html',
  styleUrls: ['./modal-determination.component.scss']
})
export class ModalDeterminationComponent implements OnInit {
  public addValueForm: FormGroup;
  public determinationForm: FormGroup;
  public modalFormulaOptions: any = {};

  @ViewChild(MatTable) table: MatTable<any>;
  @Input() modalOptionsDeter : any = {_id: null, type: "ADD"};
  @Input() subStudies;
  @Input() modalOptions;

  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();

  close = () => {this.onClose.emit()}

  public determinationsData = {
    name: "ACIDO VANILMADELICO EN ORINA DE 24 HORAS",
    unit: " mg/24hrs",
    order: 2,
    legend: "Leyenda",
    substudy: "",
    determinations: [
      {
        position: 0,
        r_init: 1,
        r_end: 7,
        measure: "YEARS",
        gender: "ANY",
        smoker: false,
        pregnant: false,
        type: "NUMERIC",
        n_init: 1.5,
        n_end: 7.4,
        crit_init: 7.5,
        crit_end: 10,
        note: "",
        description: ""
      },
      {
        position: 1,
        r_init: 1,
        r_end: 7,
        measure: "MOTHS",
        gender: "ANY",
        smoker: false,
        pregnant: true,
        type: "DESCRIPTIVE",
        n_init: 1.5,
        n_end: 7.4,
        crit_init: 7.5,
        crit_end: 10,
        note: "",
        description: ""
      },
      {

        position: 2,
        r_init: 1,
        r_end: 7,
        measure: "YEARS",
        gender: "ANY",
        smoker: true,
        pregnant: true,
        type: "INTERPRETATIVE",
        n_init: 1.5,
        n_end: 7.4,
        crit_init: 7.5,
        crit_end: 10,
        note: "",
        description: ""
      }
    ],
    expanded: false,
  }; 
  
  public DT_values: MatTableDataSource<any>;
  public displayColumns: string[] = ['position','init_range', 'end_range', 'measure', 'gender','smoker', 'pregnant', 'type', 'n_start', 'n_end', 'formula', 'crit_start', 'crit_end','description', 'note','action'];
  public typeList =  [{label: "Descriptivo", value: "DESCRIPTIVE"}, {label: "Interpretativo", value: "INTERPRETATIVE"}, {label: "Numérico", value: "NUMERIC"}, {label: "Formula", value: "FORMULA"}]
  public isLoading: boolean = false;
  public valueType = "NUMERIC";
  public init_flag: boolean = false; 
  public item_flag: boolean = false;
  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    public validateForm: ValidateFormsDirective,
    private dynamicFormService: DynamicFormService,
    public validatorService: ValidatorsService,
    private modalService: NgbModal,
  ) { 
    this.DT_values = new MatTableDataSource;
  }

  async ngOnInit(){  

    this.determinationForm = this.fb.group({
      _id: [null],
      determination: [null, Validators.compose([Validators.required])],
      printOrder: [null, Validators.compose([Validators.required, Validators.max(this.modalOptionsDeter.length)])],
      unit: [null, Validators.compose([Validators.required])],
      legendF: [null],
      SubEstudy: [null],
      values: [[]],
    });


    this.addValueForm = this.fb.group({
      r_init: [null, Validators.compose([Validators.required])],
      r_end: [null, Validators.required],
      measure: [null, Validators.required],
      gender: [null, Validators.required],
      smoker: [false],
      pregnant: [false],
      type: ['NUMERIC', Validators.required],
      n_init: [null, Validators.required],
      n_end: [null, Validators.required],
      crit_init: [null],
      crit_end: [null],
      description: [null],
      note: [null],
      formula: [[]],
    },{
      validators: [this.rangeValue]
    });

    await this.assingValidators(this.determinationsData.determinations)
    this.determinationForm.reset();
    this.determinationForm.patchValue({printOrder: this.modalOptionsDeter.length})

    if (this.modalOptionsDeter?.type == 'EDIT') {
      
      this.determinationForm.patchValue({
        _id: this.modalOptionsDeter?._id,
        determination: this.modalOptionsDeter?.item?.determination,
        printOrder: this.modalOptionsDeter?.item?.printOrder,
        unit: this.modalOptionsDeter?.item?.unit,
        legendF: this.modalOptionsDeter?.item?.legendF,
        SubEstudy: this.modalOptionsDeter?.item?.SubEstudy
      });

      this.DT_values.data = this.modalOptionsDeter?.item?.values.sort((a, b) => a.position - b.position);
      let haveFormula: any = []; 
      
      for (const item of this.DT_values.data || []) {
        if(item.formula?.length){
          haveFormula = item.formula;
          break;
        }
      }

      if (haveFormula.length) {
        this.addValueForm.patchValue({
          formula: haveFormula,
          type: "FORMULA"
        });
        this.changeValueType({value: "FORMULA"});
      }
    }
  }

  public movePosition = (item, position) => {
    if (position == 1) {// BAJANDO
      //Reasignacion de posicion al dato debajo del target
      this.DT_values.data[item?.position + 1].position = item.position;
    }else{// SUBE
      //Reasignacion de posicion al dato de arriba del target
      this.DT_values.data[item?.position - 1].position = item.position;
    }
      // W Asigna la posicion nueva
      this.DT_values.data[item?.position].position = item.position + (position);   

    // O Sort de array y actualizacion de tabla
    this.DT_values.data = this.DT_values.data.sort((a, b) => a.position - b.position);
    this.table.renderRows();      
  }

  public deleteVal = (event) => {
    this.DT_values.data = this.DT_values.data.filter(item => {return item.position != event?.position});
    this.alertService.successToast("Valor eliminado exitosamente.");

    for (let index = 0; index < this.DT_values.data.length; index++) {
        this.DT_values.data[index].position = index;  
    }
  }

  public addVal = async () => {
    //Valida formulario

    if (this.addValueForm?.errors) {
      if(Object.entries(this.addValueForm?.errors)?.length){
        if(this.addValueForm?.errors?.['rangeValue']){
          this.init_flag = true;
          this.alertService.infoToast("Rango inicio no puede ser mayor a rango fin")
          return;
        }else{
          this.init_flag = false;
        }
      }
    }
   
    if (!this.addValueForm.valid) {
      this.alertService.infoToast("Campos incompletos");     
      this.addValueForm.markAllAsTouched();        
      return;
    }

    //Valida inicio
    
    this.DT_values.data.unshift({
      r_init: this.addValueForm?.value?.r_init,
      r_end: this.addValueForm?.value?.r_end,
      measure: this.addValueForm?.value?.measure,
      gender: this.addValueForm?.value?.gender,
      smoker: this.addValueForm?.value?.smoker || false,
      pregnant: this.addValueForm?.value?.pregnant || false,
      type: this.addValueForm?.value?.type,
      n_init: this.addValueForm?.value?.n_init,
      n_end: this.addValueForm?.value?.n_end,
      crit_init:  this.addValueForm?.value?.crit_init,
      crit_end: this.addValueForm?.value?.crit_end,
      description: this.addValueForm?.value?.description,
      note: this.addValueForm?.value?.note,
      formula: this.addValueForm?.value?.formula
    });
    //? Asigna Validadores dependiendo del tipo de valor
    await this.assingValidators(this.DT_values.data);
    let haveFormula: any = [];
    for (let index = 0; index < this.DT_values.data.length; index++) {
      this.DT_values.data[index].position = index;
      if (this.DT_values.data[index].formula?.length && haveFormula?.length == 0) { haveFormula = this.DT_values.data[index].formula; }
    }

    this.table.renderRows();     
    this.addValueForm.reset();
    this.addValueForm.patchValue({
      type: "NUMERIC"
    });
    if (haveFormula?.length) {
      this.addValueForm.patchValue({
        formula: haveFormula
      });
    }
    this.changeValueType({value: 'NUMERIC'});
  }

  public  changeValueType = async (event) => {
    return new Promise( (resolve) => {
      let validators = [];
      this.valueType = event?.value;
      if (event?.value == "DESCRIPTIVE") {
        validators = [
          {formControlName: "r_end",target:{ validators:{required: true}}},
          {formControlName: "r_init",target:{ validators:{required: true}}},
          {formControlName: "measure",target:{ validators:{required: true}}},
          {formControlName: "type",target:{ validators:{required: true}}},
          {formControlName: "n_init",target:{ validators:{required: false}}},
          {formControlName: "n_end",target:{ validators:{required: false}}},
          {formControlName: "crit_init",target:{ validators:{required:false}}},
          {formControlName: "crit_end",target:{ validators:{required: false}}}
        ];
      }else if(event?.value == "INTERPRETATIVE"){
        validators = [
          {formControlName: "r_end",target:{ validators:{required: true}}},
          {formControlName: "r_init",target:{ validators:{required: true}}},
          {formControlName: "measure",target:{ validators:{required: true}}},
          {formControlName: "type",target:{ validators:{required: true}}},
          {formControlName: "n_init",target:{ validators:{required: false}}},
          {formControlName: "n_end",target:{ validators:{required: false}}},
          {formControlName: "crit_init",target:{ validators:{required:false}}},
          {formControlName: "crit_end",target:{ validators:{required: false}}}
        ];
      }else if(event?.value == "NUMERIC"){
        validators = [
          {formControlName: "r_end",target:{ validators:{required: true}}},
          {formControlName: "r_init",target:{ validators:{required: true}}},
          {formControlName: "measure",target:{ validators:{required: true}}},
          {formControlName: "type",target:{ validators:{required: true}}},
          {formControlName: "n_init",target:{ validators:{required: true}}},
          {formControlName: "n_end",target:{ validators:{required: true}}},
          {formControlName: "crit_init",target:{ validators:{required:false}}},
          {formControlName: "crit_end",target:{ validators:{required: false}}}
        ];
      }else if(event?.value == "FORMULA"){
        validators = [
          {formControlName: "r_end",target:{ validators:{required: true}}},
          {formControlName: "r_init",target:{ validators:{required: true}}},
          {formControlName: "measure",target:{ validators:{required: true}}},
          {formControlName: "type",target:{ validators:{required: true}}},
          {formControlName: "n_init",target:{ validators:{required: true}}},
          {formControlName: "n_end",target:{ validators:{required: true}}},
          {formControlName: "crit_init",target:{ validators:{required:false}}},
          {formControlName: "crit_end",target:{ validators:{required: false}}}
        ];
      }

      validators.forEach(async element => {
        this.addValueForm = await this.dynamicFormService.asignControls(element , this.addValueForm)
      });

      resolve(true)
    })
        
  }

   public assingValidators = (determinations) => {
    // Por el momento solo se validaran campos requeridos y rangos
    try {
     determinations.forEach(element => {
        if (element?.type == 'NUMERIC') {
          element.validators = [
            {field: "r_init", type: 'required'},
            {field: "r_init", field2:'r_end', type: 'rangeValue'},
            {field: "r_end", type: 'required'},
            {field: "n_init", type: 'required'},
            {field: "n_end", type: 'required'},
            {field: "crit_init", type: 'required'},
            {field: "crit_end", type: 'required'},
          ];
        }else{
          element.validators = [
            {field: "r_init", type: 'required'},
            {field: "r_init", field2:'r_end', type: 'rangeValue'},
            {field: "r_end", type: 'required'},
          ];
        }
     });
     
     this.determinationsData.determinations = determinations;
    } catch (error) {
      console.log(error);
    }
   }

   public validElementTable = (field, item) => {
      let required = item?.validators?.find(element => {return element?.field == field && element.type == 'required'});   
      return required && !item[field] ? true : false;
   }

   public valRangeInit = (field, item) => {
    let rangeValue = item?.validators?.find(element => {return element?.field == field && element.type == 'rangeValue'});   
    return (rangeValue && item[field]) && ((item[field] || 0) > (item['r_end'] || 0)) ? true : false;
   }

   public Submit = async () => {
    let Valid = true;

    if (!this.determinationForm.valid) {
      this.alertService.infoToast("Campos incompletos");     
      this.determinationForm.markAllAsTouched();        
      return;
    } 
    // Validacion de campos en tabla
    await this.DT_values.data.forEach(async element => {
      await element?.validators.forEach(item => {
        if (item.type == 'required') {
          if (!element[item?.field]) {
            this.alertService.infoToast("Campos en tabla incompletos");
            Valid = false;
          }
        }else if(item.type == 'rangeValue'){
          if (element[item?.field] > element[item?.field2]) {
            this.alertService.infoToast("Campos en tabla incorrectos, verifique porfavor");
            Valid = false;
          }
        }
      });
      element.smoker = element.smoker || false;
      element.pregnant = element.pregnant || false;
    });
    // ---------- Termina validacion ---------
    if (Valid) { 
      if (this.modalOptionsDeter.type == 'ADD') {
        this.determinationForm.patchValue({
          _id: Date.now(),
          values: this.DT_values.data
        });

        this.onSave.emit(this.determinationForm.value);
      }else if(this.modalOptionsDeter.type == 'EDIT'){
        this.determinationForm.patchValue({
          values: this.DT_values.data
        });
        this.onEdit.emit(this.determinationForm.value);
      }
    }
   }

  rangeValue: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const init = parseInt(control.get('r_init').value);
    const end = parseInt(control.get('r_end').value);
  return (init > end) ? { rangeValue: true } : null;
  };

  public ValRinit(){
    if (this.addValueForm.value?.r_init >= 0 && this.addValueForm.value?.r_end >= 0) {
      if((this.addValueForm.value?.r_init || 0) > (this.addValueForm.value?.r_end || 0)){ 
        this.init_flag = true
      }else{
        this.init_flag = false
      }
    }else{
      this.init_flag = false
    }
  }

  public ValRinitTable(item, field){        
    if (item?.r_init && item?.r_end) {
      if((parseInt(item?.r_init) || 0)  > (parseInt(item?.r_end) || 0)){         
        this.item_flag = true
      }else{
        this.item_flag = false
      }
    }else{
      this.item_flag = false
    }
    return  this.item_flag;
  }

  public clearFields = (event, item) => {
    if (event != "NUMERIC" && event != "FORMULA") {
      item.n_init = null;
      item.n_end = null;
    }
    
    item.crit_init = null;
    item.crit_end = null;
    item.note = null; 
    item.description = null;
  }

  public isInteger(evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[0-9]+/;
    if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  openModal(modal, size: string = 'sm') {
    this.modalService.open(modal, { size: size });
  }

  public saveFormula = (data: any) => {
    try {
      if (data?.index == undefined || data?.index == null) {
        this.addValueForm.patchValue({
          formula: data?.formula
        });
      } else if(data?.index >= 0) {
        this.DT_values.data.find(item => {return item.position == data?.index}).formula = data?.formula;
        this.table.renderRows();
      }
    } catch (error) {
      console.log(error);
    }
  }
}


