import {Pipe, PipeTransform} from '@angular/core'
import { DataBankService } from 'src/app/components/mk-table/services/data-bank.service';

@Pipe({
    name: 'selects'
})

/**
 * Pipe para transformar status.
 * 
 * INSTRUCCIONES DE USO: 
 * 1) Importar PipesModule en el módulo donde quieras tranformar status
 * 2) Utilizar el pipe 'status' en el html de tu componente
 */

export class selectsPipe implements PipeTransform {

    private options: any = [];
    constructor(
        private bankData: DataBankService
    ) { }

    transform(value: string, collection: string): string {
        this.options = this.bankData.data(collection);
        return value? (this.options?.find(option=>{return option._id == value})?.name || "No asignado") :"" ;
    }

}

