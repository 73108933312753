import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'label-card',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnInit {
  @Input('sample') sample: any;
  @Input('orderData') orderData: any;

  constructor() { }

  ngOnInit() {
  }

}
