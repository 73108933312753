import { Injectable } from '@angular/core';
import { PanelCallComponent } from 'src/app/components/panel-call/panel-call.component';
import { LayoutService } from './layout.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CallsService {
  public openPanel = new Subject<void>();

  constructor(private layoutCtrl: LayoutService) {
  }

  public async load(){
  }

  public async openModal($event){
    this.openPanel.next();
    this.layoutCtrl.emitSidebarNotifyChange($event, PanelCallComponent);
  }
}
