import { ModalExchangeRateComponent } from './modal-exchangeRate/modal-exchangeRate.component';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, registerLocaleData, TitleCasePipe } from '@angular/common';
import { MkImgGroupComponent } from './mk-img-group/mk-img-group.component';
// import { PipesModule } from '../shared/pipes/pipes.module';
import { MkUserComponent } from './mk-user/mk-user.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MkStatusComponent } from './mk-status/mk-status.component';
import { MKLoaderComponent } from './mk-loader/mk-loader.component';
import { PipesModule } from '../shared/pipes/pipes.module';
import { AgmCoreModule } from '@agm/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import LocaleEs from '@angular/common/locales/es'; 
import { appointmentCardComponent } from './appointment-card/appointment-card.component';
import { DetailAppointmentComponent } from './detail-appointment/detail-appointment.component';
import { ModalAuthComponent } from './modal-auth/modal-auth.component';
import { TextMaskModule } from 'angular2-text-mask';
import { ModalSelectCashRegisterComponent } from './modal-select-cash-register/modal-select-cash-register.component';
import { ModalCloseCashRegisterComponent } from './modal-close-cash-register/modal-close-cash-register.component';
import { ModalMovementComponent } from './modal-movement/modal-movement.component';
import { ModalBranchComponent } from './modal-branch/modal-branch.component';
import { ModalViewComponent } from './modal-view/modal-view.component';
import { ModalSamplesComponent } from './modal-samples/modal-samples.component';
import { LabelComponent } from './label/label.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TicketComponent } from './ticket/ticket.component';
import { ArchwizardModule } from 'angular-archwizard';
import { ModalMovementListComponent } from './modal-movement-list/modal-movement-list.component';
import { statusPipe } from '../shared/pipes/status.pipe';
import { ModalReportComponent } from './modal-report/modal-report.component';
import { PanelCallComponent } from './panel-call/panel-call.component';
import { PanelServiceComponent } from './panel-service/panel-service.component';
import { AtendCallComponent } from './panel-call/components/atend-call/atend-call.component';
import { ModalCallCancelComponent } from './modal-call-cancel/modal-call-cancel.component';
import { ValidateFormsDirective } from '../directives/validate-forms.directive';
import { ModalCancelComponent } from './modal-cancel/modal-cancel.component';
import { AttendServiceComponent } from './panel-service/components/attend-service/attend-service.component';
import { PanelUrgenciesComponent } from './panel-urgencies/panel-urgencies.component';
import { TagInputComponent } from './tag-input/tag-input.component';
import { ModalGlobalCancelComponent } from './modal-global-cancel/modal-global-cancel.component';
import { ModalCallComponent } from './modal-calls/modal-call.component';
import { LocaleService, NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DecryptPipe } from '../shared/pipes/decrypt.pipe';
import { FileItemComponent } from './file-item/file-item.component';
import { CredentialsModalComponent } from './credentials-modal/credentials-modal.component';
; registerLocaleData(LocaleEs, 'es')

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    SweetAlert2Module,
    NgbModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    NgxDaterangepickerMd.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB7AZ8E0BsiK2X10ituHHsFjSBLPUNz1Qk', 
      libraries: ['places']
    }),
    TextMaskModule,
    ArchwizardModule,
  ],
  declarations: [
    MkImgGroupComponent, 
    MkUserComponent, 
    MkStatusComponent, 
    MKLoaderComponent, 
    appointmentCardComponent,
    DetailAppointmentComponent,
    ModalExchangeRateComponent,
    ModalAuthComponent,
    ModalSelectCashRegisterComponent,
    ModalCloseCashRegisterComponent,
    ModalMovementComponent,
    ModalBranchComponent,
    ModalViewComponent,
    ModalSamplesComponent,
    LabelComponent,
    TicketComponent,
    ModalMovementListComponent,
    ModalReportComponent,
    PanelCallComponent,
    PanelServiceComponent,
    AtendCallComponent,
    ModalCallCancelComponent,
    ModalCancelComponent,
    AttendServiceComponent,
    PanelUrgenciesComponent,
    TagInputComponent,
    ModalGlobalCancelComponent,
    ModalCallComponent,
    FileItemComponent,
    ModalCallComponent,
    CredentialsModalComponent,
  ],
  providers:[
    CurrencyPipe,
    statusPipe,
    ValidateFormsDirective,
    DecryptPipe,
    TitleCasePipe,
    LocaleService
  ],
  exports: [
    MkImgGroupComponent, 
    MkUserComponent, 
    MkStatusComponent, 
    MKLoaderComponent, 
    appointmentCardComponent,
    DetailAppointmentComponent,
    ModalExchangeRateComponent,
    ModalAuthComponent,
    ModalSelectCashRegisterComponent,
    ModalCloseCashRegisterComponent, 
    ModalViewComponent,
    ModalSamplesComponent,
    LabelComponent,
    TicketComponent,
    ModalMovementListComponent,
    ModalReportComponent,
    ModalCallCancelComponent,
    AtendCallComponent,
    PanelCallComponent,
    ModalCancelComponent,
    AttendServiceComponent,
    TagInputComponent,
    ModalGlobalCancelComponent,
    ModalCallComponent,
    FileItemComponent,
    ModalCallComponent,
    CredentialsModalComponent,
  ]
})
export class ComponentsModule {}
