<div class="modal-body">
    <div class="row border-bottom">
        <div class="col-12 p-3 d-flex">
            <i class="fa fa-store fa-2x my-auto text-primary"></i>
            <div class="d-flex flex-column ml-3 lh-1">
                <ng-container>
                    <h3 class="m-0">Sucursal</h3>
                    <span class="text-muted">Selecciona sucursal.</span>
                </ng-container>
       
            </div>
        </div>
    </div>
    <ng-container >
        <ng-container *ngFor="let c of branchs; index as i" >
            <div class="py-2 px-3 row _item justify-content-between" [class.border-top]="i" (click)="branch = c;select()">
                <div class="d-flex flex-column">
                    <h5 class="m-0 text-primary">{{c.name}}</h5>
                    <span class="text-muted">{{c.code}}</span>
                </div>
                <i class="fa fa-chevron-right my-auto op-5"></i>
            </div>
        </ng-container>
    </ng-container>

</div>
