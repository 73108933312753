import { DomSanitizer } from '@angular/platform-browser';
import { Injectable, SecurityContext } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor(protected sanitizer: DomSanitizer) { }

  public print(resp): Promise<boolean>{
    return new Promise<boolean>((resolve, rejected)=>{
      try{
        const pdf = new Blob([resp], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(pdf);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl));
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
        resolve(true);
      }catch(err){
        rejected(false);
      }
    });
  }

  public download(resp, folio): Promise<boolean>{
    return new Promise<boolean>((resolve, rejected) =>{

      try{
        
        const pdf = new Blob([resp], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdf);

        var a         = document.createElement('a');
        a.href        = pdfUrl; 
        a.download    = `${folio}.pdf`;
        document.body.appendChild(a);
        a.click();

        resolve(true);
      }
      catch(err){
        rejected(false);
      }

    });
  }
}
