import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mk-user',
  templateUrl: './mk-user.component.html',
  styleUrls: ['./mk-user.component.scss'],
})
export class MkUserComponent implements OnInit {

  @Input() user: IUserMK;
  @Input() tooltipDirection: string = 'top';
  @Input() color: string = 'primary';

  constructor() { }

  ngOnInit(): void {
  }
}

interface IUserMK{name: string, img: string}