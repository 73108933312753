import { ModalMovementComponent } from './../../components/modal-movement/modal-movement.component';
import { HttpService } from 'src/app/shared/services/http.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MovementsService {

  public modal: any;

  constructor(
    private http: HttpService,
    private modalService: NgbModal,
  ) { }

  public async openModal(cashR){
    this.modal = this.modalService.open(ModalMovementComponent, {size: 'sm'});
    this.modal.componentInstance.cashRegister = cashR;
  }

  public addMovement = (data: any): Promise<any> => this.http.post(`api/movement`, data).toPromise();

}
