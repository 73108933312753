import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ValidateFormsDirective } from '../../../../../../directives/validate-forms.directive';
import { AlertService } from '../../../../../../shared/services/alert.service';

@Component({
  selector: 'cat-modal-abbreviations',
  templateUrl: './modal-abbreviations.component.html',
  styleUrls: ['./modal-abbreviations.component.scss']
})
export class ModalAbbreviationsComponent implements OnInit {
  @Input() modalOptions;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onAddabbreb: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('Sort') sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  close = () => {this.onClose.emit()}
  public DT_abreviations: MatTableDataSource<any>;
  public displayColumns: string[] = ['code','description','action'];
  public abbrevForm: FormGroup;

  public abreviationList:any= []; 
  public isLoading: boolean = false;
  public editGuestpencil: boolean = false;
  public IndexEdit;
  constructor( 
       private fb: FormBuilder,
       public validateForm: ValidateFormsDirective,
       private alertService: AlertService

    ) { 
    this.DT_abreviations = new MatTableDataSource;

  }

  ngOnInit(){
    this.abbrevForm = this.fb.group({
      code: [null,  Validators.required],
      description:[null, Validators.required]
    });
    this.modalOptions.item ?  this.abreviationList = this.modalOptions.item : [];
    this.DT_abreviations.data = this.abreviationList;
    this.DT_abreviations.sort = this.sort;
    
  }

  public addAbbreb = () => {
    if (!this.abbrevForm.valid) {
      this.alertService.infoToast("Campos incompletos");
      this.abbrevForm.markAllAsTouched();        
      return;
    }

    let codeFound = this.DT_abreviations.data.filter(element => {return element?.code == this.abbrevForm?.value?.code}).length;    
    if (codeFound > 0) {
      this.alertService.infoToast("Codigo Repetido")
    }else{
      this.DT_abreviations.data.push(this.abbrevForm.value);
      this.table.renderRows();  
      this.onAddabbreb.emit(this.DT_abreviations.data);    
      this.abbrevForm.patchValue({
        code: null,
        description: null
      }); 
      this.abbrevForm.get('code')?.markAsUntouched();
      this.abbrevForm.get('description')?.markAsUntouched();
      this.alertService.successToast("Abreviacion Agregada con exito.")      
    }
  }
  public deleteAbbreb = (element) =>{
    this.DT_abreviations.data =  this.DT_abreviations.data.filter(item => {return item?.code != element?.code});
    this.onAddabbreb.emit(this.DT_abreviations.data);    
    this.table.renderRows();      
    this.alertService.successToast("Abreviacion eliminada con exito.");   
  }

  public editAbbreb = (element) => {
    this.IndexEdit = this.DT_abreviations.data.findIndex(item => {return item?.code == element?.code});
    this.editGuestpencil = true;
    this.abbrevForm.patchValue({
      code: element?.code,
      description: element?.description
    });
  }

  public cancelEdit = () =>{
    this.editGuestpencil = false;
    this.abbrevForm.patchValue({
      code: null,
      description: null
    });
    this.IndexEdit = undefined;
  };

  public saveEdit = () =>{
    if (!this.abbrevForm.valid) {
      this.alertService.infoToast("Campos incompletos");
      this.abbrevForm.markAllAsTouched();        
      return;
    }

    if (this.IndexEdit >= 0) {
            
      this.DT_abreviations.data[this.IndexEdit] = {code: this.abbrevForm?.value?.code, description: this.abbrevForm?.value?.description};      
      this.table.renderRows();      
      this.alertService.confirmToast("Abreviacion editada con exito.", null, '');   
      this.editGuestpencil = false;
      this.abbrevForm.patchValue({
        code: null,
        description: null
      });  
      this.IndexEdit = undefined;    
      // Solucion de letras rojas apareciendo despues de editar una abreviacion.  
      this.abbrevForm.get('code')?.markAsUntouched();
      this.abbrevForm.get('description')?.markAsUntouched();
      
    }else{
      this.alertService.infoToast("Registro no encontrado");
    }


  }


}
