import { ProgramedService } from 'src/app/shared/services/http/programed.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TableService } from 'src/app/shared/services/table.service';
import { FiltersDatatableService } from 'src/app/shared/services/filters-datatable.service';
import * as moment from 'moment';
import { SocketService } from 'src/app/shared/services/socket.service';
import { CallsService } from 'src/app/shared/services/calls.service';
import { ProgrammedServiceService } from 'src/app/shared/services/programmed-service.service';
import { CryptoSharkService } from 'src/app/shared/services/crypto-shark.service';
import { UserService } from 'src/app/shared/services/user.service';
import Swal from 'sweetalert2';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpService } from 'src/app/shared/services/http.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { ResolveEnd } from '@angular/router';
import { BadgetService } from 'src/app/shared/services/badget.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-panel-service',
  templateUrl: './panel-service.component.html',
  styleUrls: ['./panel-service.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PanelServiceComponent implements OnInit {

  public isLoading: boolean = false;
  @ViewChild('modalView') modalView: NgbModal

  public DT_Services: MatTableDataSource<any>;
  public displayColumns: string[] = ['folio', 'dateProcess', 'created', 'name', 'phone', 'address', 'attended', 'dateAttended', 'typePay', 'attend', 'action'];
  // columnsToDisplayWithExpand = [...this.displayColumns, 'expand'];
  // expandedElement;

  public filters = [
    { name: "Sin Atender", type: "CREATED", active: true },
    { name: "Atendidos", type: "ATTENDED", active: true },
    // { name: "Finalizadas", type: "PROCESSED", active: true },
  ];

  public statusColors = {
    CREATED: "#ce0e5e",
    ATTENDED: "#7447c7",
    PROCESSED: "#25a775",
  }
  public modalOptions = {_id: null, type: "ADD", item: null, module: "SERVICE"}
  public group: string = 'dateFrm';
  public reducedGroups: any[] = [];
  public datasource: any[] = [];
  public date: any = { startDate: moment().startOf("isoWeek"), endDate: moment().endOf("isoWeek") };
  public pdf = null;
  public defaultFilterPredicate;
  public searchField = null;
  public subscribePanel: Subscription;
  public subscribeSocket: Subscription;
  public permisions: any = {};

  @ViewChild('Paginator') paginator: MatPaginator;
  @ViewChild('Sort') sort: MatSort;

  constructor(
    public tableService: TableService,
    private modal: NgbModal,
    private filterService: FiltersDatatableService,
    private programedService: ProgramedService,
    private socketService : SocketService,
    private programedServiceServices: ProgrammedServiceService,
    private cryptoService: CryptoSharkService,
    private userService: UserService,
    private alertService: AlertService,
    private sanitazer: DomSanitizer,
    private httpService: HttpService,
    public badgetService: BadgetService
  ) {
    this.DT_Services = new MatTableDataSource;

    this.subscribePanel = this.programedServiceServices?.openPanel.subscribe(async () => {
      this.searchField = null;
      await this.getDataFiltered()
    });

    this.subscribeSocket = this.socketService.listenData(`scheduledService`).subscribe(async (data) => {
      await this.getProgramedData()
    });
    
    let permisionsModule = this.userService?.user?.permissions?.find(item => {return item?.path == "services"});
    for (const item of permisionsModule?.actions || []) { this.permisions[item?.action] =  (this.userService?.user?.roleName == "ADMINISTRADOR") ? true : item?.value; }

  }

  public getDataFiltered = async () => {
    await this.getProgramedData()
    this.filterDataByStatus()
  }

  ngOnDestroy(){
    this.subscribeSocket.unsubscribe();
    this.subscribePanel.unsubscribe();
  }

  async ngOnInit() {
    this.searchField = null;
    this.defaultFilterPredicate = this.DT_Services.filterPredicate;
    await this.getProgramedData()
    this.buildDataSource();
  }

  private getProgramedData = async () => {
    try {
      let Array = [];
      let data = await this.programedService.getPanelService();
      
      //? Funcion temporal
      data.forEach(element =>{
        Array =  Array.concat(element?.regs);
      });

      Array.forEach(element => {
        element.name = this.cryptoService.decrypt(element?.name, this.userService?.login?.company?.dk),
        element.phone = JSON.parse(this.cryptoService.decrypt(element?.phone , this.userService?.login?.company?.dk))?.phone,
        element.dateProcess = element?.date + "*" + moment(element?.date).format("DD/MMM/YYYY - h:mm a"),
        element.created = element?.createdBy?.userName,
        element.address = this.cryptoService.decrypt(element?.location?.address, this.userService?.login?.company?.dk);
        element.attended = element?.attendedBy?.nameLogin ? element?.attendedBy?.nameLogin : "Sin Atender";
        element.attendedByExtraUser = element.attendedBy?.userNameExtra || 'Sin Atender';
        element.dateAttended = element?.attendedBy?.date ? element?.attendedBy?.date + "*" + moment(element?.attendedBy?.date).format("DD/MMM/YYYY - h:mm a") : "Sin Atender"
      });

      this.datasource = Array;
      this.buildDataSource();
      
    } catch (error) {
      console.log(error);
    }
  }

  public filterDataByStatus = () => {
    const filters: string[] = this.filterService.filtersSelected(this.filters)
    // generamos el filtro dentro de la dt_table en base a los filtros activos
    this.DT_Services.filterPredicate = (data: any) => this.filterService.dataFiltered(data, filters)
    // inicializamos
    this.DT_Services.filter = 'inicializado'
    this.buildDataSource()
  }

  public  buildDataSource = async () => {
   this.DT_Services.data = await this.groupBy(this.group, this.datasource, this.reducedGroups);
   this.DT_Services.data.forEach(element => {
    if (element.isGroup) {
      element.groupName = element?.groupName +'*'+ moment(element?.groupName, 'DD-MM-YYYY').format('dddd')
    }
   });
   this.DT_Services.paginator = this.paginator;
  }

  groupBy(column: string, data: any[], reducedGroups?: any[]) {

    if (!column) return data;
    let collapsedGroups = reducedGroups;
    if (!reducedGroups) collapsedGroups = [];
    const customReducer = (accumulator, currentValue) => {
      let currentGroup = currentValue[column];
      if (!accumulator[currentGroup])
        accumulator[currentGroup] = [{
          groupName: `${currentValue[column]}`,
          value: currentValue[column],
          isGroup: true,
          reduced: collapsedGroups.some((group) => group.value == currentValue[column])
        }];

      accumulator[currentGroup].push(currentValue);

      return accumulator;
    }
    const groups = data.reduce(customReducer, {});
    const groupArray = Object.keys(groups).map(key => groups[key]);
    let flatList = groupArray.reduce((a, c) => { return a.concat(c); }, []);
    
    return flatList.filter((rawLine) => {
      return rawLine.isGroup ||
        collapsedGroups.every((group) => rawLine[column] != group.value);
    });
  }

  uniqueBy(a, key) {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }


  isGroup(index, item): boolean {
    return item.isGroup;
  }

  openModal(template, size, data) {
    !data || (this.modalOptions._id = data?._id);
    !data || (this.modalOptions.item = data);
    this.modal.open(template, { size: size });
  }
  
  public processServiceSwal = (item) => {
    Swal.fire({
      title: '¿Desea procesar este servicio?',
      text: `${item?.description ? "lugar:" + item?.description : ""}`,
      icon: 'info',
      showDenyButton: true,
      confirmButtonText: 'Si, Procesar!',
      denyButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed){
        item.id = item._id;

        this.programedService.processprogramedService(item).then((resp) => {
          this.alertService.successToast("Servicio Finalizado");
        }).catch(error => { 
          console.log(error) 
        })
      }
    });
  }

  public close(){
    this.modal.dismissAll();
  }

  public undoServiceSwal = (item) => {
    Swal.fire({
      title: '¿Regresar el estatus a sin atender?',
      text: 'Se eliminara la fecha y usuario de quien atendio la llamada.',
      icon: 'info',
      showDenyButton: true,
      confirmButtonText: 'Aceptar',
      denyButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) await this.unatendedService(item._id)
    });
  }

  private unatendedService = async (id) => {
    const data = { id }
    await this.programedService.unattendedprogramedService(data).then(async (response) => {
      this.alertService.successToast(response.message);
      await this.getProgramedData()
    }).catch((error) => {
      this.alertService.infoToast(error.error.message);
    })
  }

  public async printQuotation(id){
    try {
      this.openModal(this.modalView, 'xxl', null);

      this.isLoading = true;
      if (id) {
        let response = await this.httpService.getBlob(`api/quotations/print/${id}`,"application/json").toPromise();
        const url = URL.createObjectURL(response);
        this.pdf = this.sanitazer.bypassSecurityTrustResourceUrl(url);
        this.isLoading = false;

      }else{
        this.alertService.infoToast("El servicio no cuenta con una cotizacion");
        this.isLoading = false;
      }


    } catch (error) {
      console.log(error);
    }
  }

  public async printService(id: string){
    try {
      this.openModal(this.modalView, 'xxl', null);

      this.isLoading = true;
      if (id) {
        let response = await this.httpService.getBlob(`api/scheduledService/print/${id}`,"application/json").toPromise();
        const url = URL.createObjectURL(response);
        this.pdf = this.sanitazer.bypassSecurityTrustResourceUrl(url);
        this.isLoading = false;

      }else{
        this.alertService.infoToast("El servicio no cuenta con una cotizacion");
        this.isLoading = false;
      }


    } catch (error) {
      console.log(error);
    }
  }

  search(event) {
    const filtersDefault = [
      { name: "Sin Atender", type: "CREATED", active: true },
      { name: "Atendidos", type: "ATTENDED", active: true },
      // { name: "Finalizadas", type: "PROCESSED", active: true },
    ];
    //const filters: string[] = this.filterService.filtersSelected(this.filters);
    this.DT_Services.filterPredicate = this.defaultFilterPredicate;
    this.filters = filtersDefault;
    //this.DT_Services.filter = 'inicializado'

    const filterValue = (event.target as HTMLInputElement).value;
    this.DT_Services.filter = filterValue.trim().toLowerCase();
    
  }

}
