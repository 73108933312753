import { LowerCasePipe, TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'cat-modal-substudy',
  templateUrl: './modal-substudy.component.html',
  styleUrls: ['./modal-substudy.component.scss']
})
export class ModalSubstudyComponent implements OnInit {
  public subStudyForm: FormGroup;
  @Input() subStudies;
  @Input() modalOptionsSubE = {id: null, type: 'ADD', item: null};
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onAddsubStudy: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onEditsubStudy: EventEmitter<boolean> = new EventEmitter<boolean>();

  
  close = () => {this.onClose.emit()}

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    public validateForm: ValidateFormsDirective,
    private lowerCase: LowerCasePipe
  ) { }

  ngOnInit(): void {
    this.subStudyForm = this.fb.group({
      _id: [null],
      name: [null, Validators.compose([Validators.required])],
    },{
      validators: [this.ifExist]
    });

    if (this.modalOptionsSubE.type == 'EDIT') {
      this.subStudyForm.patchValue({
        _id: this.modalOptionsSubE.item?._id,
        name: this.modalOptionsSubE.item?.name
      });
    }    
  }

  public addSubStudy = () => {
    if (!this.subStudyForm.valid) {
      this.subStudyForm?.errors?.exist ?  this.alertService.infoToast("El sub estudio ya existe") : this.alertService.infoToast("Campos incompletos");   
      this.subStudyForm.markAllAsTouched();        
      return;
    }
    
    this.subStudyForm.patchValue({
      _id: Date.now()
    });
    this.onAddsubStudy.emit(this.subStudyForm.value);
    this.onClose.emit();
    this.alertService.successToast("Sub-estudio agregado.");

  }

  public editSubStudy = () =>{
    if (!this.subStudyForm.valid) {
      this.alertService.infoToast("Campos incompletos");      
      this.subStudyForm.markAllAsTouched();        
      return;
    }

    this.onEditsubStudy.emit(this.subStudyForm.value);
    this.onClose.emit();
  }

  ifExist: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const nameCase = this.lowerCase.transform(control.get('name')?.value)?.trim();

  return this.subStudies.find(item => {return this.lowerCase.transform(item?.name).trim() == nameCase})? { exist: true } : null;
  };
}
