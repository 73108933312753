import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { HttpService } from 'src/app/shared/services/http.service';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class TicketComponent implements OnInit {

  @Input() ticket: any;
  @Input() onBranch: boolean = false;
  @Input() dataBranch: any = null;
  @Input() getTextValues: boolean = false;
  @Output() onChangeTicket: EventEmitter<any> = new EventEmitter<any>(); 

  @ViewChild('text1') public text1: ElementRef;
  @ViewChild('text2') public text2: ElementRef;
  @ViewChild('text3') public text3: ElementRef;
  @ViewChild('text4') public text4: ElementRef;
  @ViewChild('text5') public text5: ElementRef;
  
  public data: any;

  constructor(
    private httpServices: HttpService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.dataBranch?.currentValue) {
      let value = changes?.dataBranch?.currentValue;
      this.data = {
        phones: value?.phones,
        emails: value?.emails,
        rfc: value?.rfc,
        ticket: {
          folio: value?.code+"0000-0000"
        },
        create: {
          date: moment().format()
        },
        address: value?.address,
        qr: '../../../assets/img/qr_fake.png'
      }
      if(value?.text1) this.text1.nativeElement.innerHTML = value?.text1;
      if(value?.text2) this.text2.nativeElement.innerHTML = value?.text2;
      if(value?.text3) this.text3.nativeElement.innerHTML = value?.text3;
      if(value?.text4) this.text4.nativeElement.innerHTML = value?.text4;
      if(value?.text5) this.text5.nativeElement.innerHTML = value?.text5;
    }

    if (changes?.getTextValues?.currentValue) {
      this.onChangeTicket.emit({
        text1: this.text1.nativeElement.innerHTML,
        text2: this.text2.nativeElement.innerHTML,
        text3: this.text3.nativeElement.innerHTML,
        text4: this.text4.nativeElement.innerHTML,
        text5: this.text5.nativeElement.innerHTML
      })
    }
  }

  ngOnInit(): void {
    if (this.ticket) {
      this.loadTicketData();
    }
  }

  public async loadTicketData(){
    try {
      this.data = await this.httpServices.get(`api/order/pdf/${this.ticket}/DATA`).toPromise();
    } catch (error) {
      console.log(error);
    }
  }

}
