
<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">{{modalOptionsContainers.type == 'ADD' ? "Agregar" : "Editar"}} Contenedor</h6>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="containerForm">
        <div class="row">
                <div class="col-md-12 mb-3 d-flex">
                    <h6 class="my-auto mr-3">Color  <span class="text-danger">*</span></h6>
                    <div class="input-group" style="flex: 1;">
                        <div class="d-flex">
                            <ng-container *ngFor="let color of colorList">
                                <div>
                                    <label class="colorinput d-flex mb-0" >
                                        <input formControlName="colorActive"  (change)="selectColor($event)" type="radio"  [value]="color?.code" class="colorinput-input" checked=""><span class="colorinput-color" [style.background-color]="color?.code"></span>
                                    </label>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                </div>
                <div class="col-12 mb-2">
                    <small *ngIf="validateForm.campoEsValido('color', containerForm)" class="text-danger ml-1">Seleccione un color.</small>
                </div>

                <div class="col-md-6">
                    <h6>Tipo de contenedor  <span class="text-danger">*</span></h6>
                    <div class="input-group">
                        <ng-select class="form-control"  formControlName="type" [items]="containerList" bindLabel="name" (change)="($event)" [clearable]="false" placeholder="Tipo de contenedor"></ng-select>
                    </div>
                    <small *ngIf="validateForm.campoEsValido('type', containerForm)" class="text-danger ml-1">Seleccione tipo de contenedor.</small>

                </div>
                <div class="col-md-6">
                    <h6>Tipo de Muestra  <span class="text-danger">*</span></h6>
                    <div class="input-group">
                        <ng-select class="form-control"  [clearable]="false" [items]="sampleTypeList" bindLabel="name" formControlName="sample" placeholder="Tipo de Muestra"></ng-select>
                    </div>
                    <small *ngIf="validateForm.campoEsValido('sample', containerForm)" class="text-danger ml-1">Seleccione tipo de muestra.</small>

                </div>
                <div class="col-sm-12 mt-2">
                    <!-- <h6>Etiquetas</h6> -->
                    <!-- <div class="input-group"> -->
                        <!--! cambiar por el input multiple -->
                        <!-- <textarea class="form-control" formControlName="labels" rows="3" placeholder="Agregar Etiquetas"></textarea> -->
                    <!-- </div> -->

                    <h6>Tags <small class="text-info">(Presiona <b>Enter</b> para agregar una etiqueta)</small></h6>
                    <app-tag-input formControlName="labels" ></app-tag-input>
                    <!-- <small *ngIf="campoEsValido('email')" class="text-danger">Correo electrónico No valido.</small> -->
                </div>
        </div>
    </form>

</div>
<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-dark" (click)="close()">Cerrar</button>
    <button type="button" class="btn btn-success" (click)="modalOptionsContainers.type == 'ADD' ? addContainer() : editContainer()">{{modalOptionsContainers.type == 'ADD' ? 'Guardar' : 'Actualizar'}}</button>
</div>