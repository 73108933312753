import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { until } from 'protractor';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { UserService } from './user.service';
import { HttpService } from './http.service';


//Menu Bar
export interface Menu {
  headTitle?: string;
  title?: string;
  path?: string;
  icon?: string;
  img?: string;
  type?: string;
  badgeClass?: string;
  badgeValue?: string | number;
  active?: boolean;
  module?: string,
  children?: Menu[];
  section?:number,
  noHide?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class NavService implements OnDestroy {

  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  public megaMenu: boolean = false;
  public megaMenuCollapse: boolean = window.innerWidth < 1199 ? true : false;
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;
  private permissions: any;
  private dataBadget:any ;
  public changeBadget = new Subject();

  constructor(
    private router: Router,
    private users: UserService,
    private httpService: HttpService,
  ) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, 'resize').pipe(
      debounceTime(1000),
      takeUntil(this.unsubscriber)
    ).subscribe((evt: any) => {
      this.setScreenWidth(evt.target.innerWidth);
      if (evt.target.innerwidth < 991) {
        this.collapseSidebar = false;
        this.megaMenu = false;
      }

      if (evt.target.innerWidth < 1199) {
        this.megaMenuCollapse = true;
      }
    });
    if (window.innerWidth < 991) {
      this.router.events.subscribe(event => {
        this.collapseSidebar = false;
        this.megaMenu = false;
      })
    }

    
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  getModules(){    
    let MENUITEMS : Menu[] = [
      { headTitle: 'Labsystem', section:0},
      { title: 'Recepción', type: 'sub', icon: 'fa fa-th-large', active: false, module: "reception", section: 0, children: [
        {path: '/reception', title: "Recepción", type: 'link', module: "reception"},
        {path: '/new-order', title: "Nueva Orden", type: 'link', module: "reception"}
      ] },
      // { path: '/reception', title: 'Recepción', type: 'link', icon: 'fa fa-th-large', active: false, module: "reception", section: 0 },
      // { path: '/new-order', title: 'Nueva Orden', type: 'link', icon: 'fa fa-plus', active: false, module: "new-order", section: 0 },
      { title: 'Cotizaciónes', type: 'sub', icon: 'fa fa-file-text', active: false, module: "quote", section: 0, children: [
        {path: '/quotes', title: "Cotizaciones", type: 'link', module: "quotes"},
        {path: '/new-quote', title: "Nueva Cotización", type: 'link', module: "quotes"}
      ] },
      { path: '/cash-register', title: 'Cajas Registradoras', type: 'link', img: 'cash-register-white.svg', active: false, module: "cash-register", section:0 },
      // { path: '/dates', title: 'Citas', type: 'link', icon: 'fa fa-calendar', active: false, module: "dates", section:0 },
      { path: '/billing', title: 'Facturación', type: 'link', icon: 'fa fa-money', active: false, module: "billing", section:0 },
      { path: '/membership', title: 'Membresia', type: 'link', icon: 'fa fa-address-card', active: false, module: "membership", section:0 },
      { title: 'Llamadas', type: 'sub', icon: 'fa fa-phone', active: false, section:0, module: "calls", children: [
        {path: "/calls", title: "Llamadas Registradas", type: 'link', module: "calls"}, 
        {path: "/services", title: "Servicio a Domicilio", type: 'link', module: "calls"}
      ], badgeValue: 0 },
      { path: '/sellers', title: 'Ventas', type: 'link', icon: 'zmdi zmdi-money xx-large', active: false, module: "sellers", section:0 },
      { headTitle: 'Reportes', section:1},
      { path: '/reports', title: 'Reportes', type: 'link', icon: 'zmdi zmdi-assignment', active: false, module: "reports", section:1 },
      { headTitle: 'Catalogos', section:2},
      { path: '/catalogs', title: 'Catalogos', type: 'link', icon: 'fa fa-book', active: false, module: "catalogs", section:2, noHide: true },
      { headTitle: 'Cuenta', section:3},
      { path: '/user', title: 'Usuarios', type: 'link', icon: 'fa fa-user', active: false, module: "user", section:3 },
      { path: '/roles', title: 'Roles', type: 'link', icon: 'fa fa-users', active: false, module: "roles", section:3 },
      // { path: '/supports', title: 'Soporte', type: 'link', icon: 'fa fa-question-circle', active: false, module: "supports", section:3 },
    ];
    

    this.permissions = this.users.user.permissions;
    if (this.users.user.roleName != "ADMINISTRADOR") {
      //Elimina los modulos que se tiene permisos
      MENUITEMS = MENUITEMS.filter(menu => !menu.module || menu?.noHide || this.permissions.find(permission => {
        return (menu.module == permission?.path || menu?.children?.find(child => {return child?.path == `/${permission?.path}`}))
        && permission?.actions?.find(action => {return action?.action == 'ACCESS' && action?.value})}));

      for (let menu of MENUITEMS) {
        menu.children = menu?.children?.filter(item => {
          return this.permissions?.find(permission => {
            return `/${permission?.path}` == item?.path 
          })?.actions?.find(action => {return action?.action == 'ACCESS' && action?.value}) || 
          this.permissions?.find(permission => { 
            return `${permission?.path}` == item?.module 
          })?.actions?.find(action => {
            return action?.path == item?.path 
          })?.value 
        });
      }
      
      const index = MENUITEMS.findIndex(menu => menu.module);
      MENUITEMS[index].active = true;

      //Elimina los titulos que no cuentan con modulo
      let hist = {};
      MENUITEMS.map( function (a) { if (a.section in hist) hist[a.section] ++; else hist[a.section] = 1; } );
      MENUITEMS = MENUITEMS.filter(menu => hist[menu.section] > 1);
    }
    return new BehaviorSubject<Menu[]>(MENUITEMS);
  }

  public async request(){
    this.dataBadget = await this.httpService.get(`api/medic/request/count`).toPromise();
    this.changeBadget.next(this.dataBadget);
  }
}