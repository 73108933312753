<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">{{modalOptionsDeter?.type == 'EDIT' ? 'Editar' : 'Agregar'}} Determinación</h6>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body pb-0">
    <form [formGroup]="determinationForm">
        <div class="row">
                <div class="col-md-3">
                    <h6>Nombre  <span class="text-danger">*</span></h6>
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Nombre" maxlength="60" formControlName="determination"/>
                    </div>
                    <small *ngIf="validateForm.campoEsValido('determination', determinationForm)" class="text-danger ml-1">Ingrese un nombre.</small>

                </div>
                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-6">
                            <h6>Orden de impresión  <span class="text-danger">*</span></h6>
                            <div class="input-group">
                                <input type="text" class="form-control" (keypress)="isInteger($event)" formControlName="printOrder" maxlength="5" placeholder="Orden de impresión"/>
                            </div>
                            <small *ngIf="validateForm.campoEsValido('printOrder', determinationForm)" class="text-danger ml-1">Ingrese orden de impresión valido (0 - {{modalOptionsDeter.length}}).</small>

                        </div>
                        <div class="col-md-6">
                            <h6>Unidad  <span class="text-danger">*</span></h6>
                            <div class="input-group">
                                <input type="text" class="form-control"  maxlength="60" formControlName="unit" placeholder="Unidad"/>
                            </div>
                            <small *ngIf="validateForm.campoEsValido('unit', determinationForm)" class="text-danger ml-1">Ingrese una unidad.</small>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <h6>Leyenda Fija  <span class="text-danger">*</span></h6>
                    <div class="input-group">
                        <input type="text" class="form-control" formControlName="legendF" maxlength="60" placeholder="Leyenda Fija"/>
                    </div>
                    <small *ngIf="validateForm.campoEsValido('legendF', determinationForm)" class="text-danger ml-1">Ingrese una leyenda fija.</small>

                </div>
                <div class="col-md-3">
                    <h6>Sub-Estudio</h6>
                    <div class="input-group">
                        <ng-select class="form-control" [items]="subStudies"  bindLabel="name"  [bindValue]="modalOptions.type == 'EDIT' ? '_id' : 'name'" formControlName="SubEstudy" placeholder="Sub-Estudio"></ng-select>
                    </div>
                </div>
        </div>
    </form>

    <div class="row mt-3">
        <div class="col-sm-12 d-flex justify-content-between">
                <h4 class="text-primary my-auto">Valores de determinación</h4>
                <span class="text-muted my-auto">Agrega un nuevo valor llenando los datos requeridos y presionando el botón del lado derecho. <i class="fa fa-arrow-down"></i></span>
        </div>
    </div>

    <!-- Y Formulario BASE -->
    <form [formGroup]="addValueForm">
        <div class="row border-top mt-3 pt-2">
            <div class="col-sm-1 d-flex">
                <!-- <ng-container *ngIf="(addValueForm.controls.r_init.value || addValueForm.controls.r_end.value)"> -->
                    <button class="btn btn-muted btn-block mt-auto" (click)="addValueForm.reset()"><i class="fa fa-redo mr-2"></i> Limpiar</button>
                <!-- </ng-container> -->
                <!-- <ng-container *ngIf="!(addValueForm.controls.r_init.value || addValueForm.controls.r_end.value)">
                    <button class="btn btn-info btn-block mt-auto" (click)="addValueForm.reset()"><i class="fa fa-arrow-up mr-2"></i> Anterior</button>
                </ng-container> -->
            </div>
            <div class="col-sm-10 px-0">
                <div class="row row-ssm">
                    <div class="col-sm-4 col-md-4">
                        <div class="row row--sm">
                            <div class="col-sm-3">
                                <h6> Rango Inicio <span class="text-danger">*</span></h6>
                                <div class="input-group"> <input type="text" maxlength="3" class="form-control" [class.border-danger]="init_flag || validateForm.campoEsValido('r_init', addValueForm)" (keypress)="validatorService.isInteger($event)" (input)="ValRinit()" formControlName="r_init" placeholder="Rango Inicio" ></div>
                                <!-- <small *ngIf="validateForm.campoEsValido('r_init', addValueForm)" class="text-danger ml-1">Ingrese un rango de inicio.</small> -->
                                <!-- <small *ngIf="init_flag" class="text-danger ml-1">Valor inicio no puede ser mayor a fin.</small> -->
                            </div>
                            <div class="col-sm-3">
                                <h6> Rango Fin <span class="text-danger">*</span></h6>
                                <div class="input-group"><input type="text" maxlength="3" class="form-control" [class.border-danger]="validateForm.campoEsValido('r_end', addValueForm)" (keypress)="validatorService.isInteger($event)" (input)="ValRinit()" formControlName="r_end" placeholder="Rango Fin" ></div>
                                <!-- <small *ngIf="validateForm.campoEsValido('r_end', addValueForm)" class="text-danger ml-1">Ingrese un rango fin.</small> -->
                            </div>
                            <div class="col-sm-3">
                                <h6>  Medida <span class="text-danger">*</span></h6>
                                <div class="input-group">
                                    <ng-select class="form-control" [class.border-danger]="validateForm.campoEsValido('measure', addValueForm)" placeholder="Medida" formControlName="measure" [clearable]="false">
                                        <ng-option value="DAYS">Días</ng-option>
                                        <ng-option value="MOTHS">Meses</ng-option>
                                        <ng-option value="YEARS">Años</ng-option>
                                    </ng-select>
                                </div>
                                <!-- <small *ngIf="validateForm.campoEsValido('measure', addValueForm)" class="text-danger ml-1">Seleccione una medida.</small> -->
                
                            </div>
                            <div class="col-sm-3">
                                <h6> Sexo <span class="text-danger">*</span></h6>
                                <div class="input-group">
                                    <ng-select class="form-control" [class.border-danger]="validateForm.campoEsValido('gender', addValueForm)" placeholder="Sexo" formControlName="gender" [clearable]="false">
                                        <ng-option value="MALE"><i class="fa fa-male mr-2"></i> M</ng-option>
                                        <ng-option value="FEMALE"><i class="fa fa-female mr-2"></i> F</ng-option>
                                        <ng-option value="ANY"><i class="fa fa-female"></i><i class="fa fa-male mr-2"></i> FM</ng-option>
                                    </ng-select>
                                </div>  
                                <!-- <small *ngIf="validateForm.campoEsValido('gender', addValueForm)" class="text-danger ml-1">Seleccione un genero.</small> -->
                
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-1 p-0">
                        <div class="row row--sm">
                            <div class="col-sm-6">
                                <h6> <div class="text-center"><small>Fumador</small></div> </h6>
                                <div class="text-center"><label class="ckbox m-0 cursor-pointer check-det"><input type="checkbox" formControlName="smoker"><span></span></label></div>  
                            </div>
                            <div class="col-sm-6">
                                <h6> <div class="text-center"><small>Embarazo</small></div></h6>
                                <div class="text-center"><label class="ckbox m-0 cursor-pointer check-det"><input type="checkbox" formControlName="pregnant"><span></span></label></div>                     
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-7">
                        <div class="row row--sm">
                            <div class="col-md-3">
                                <h6> Tipo de valor <span class="text-danger">*</span></h6>
                                <div class="input-group">
                                    <ng-select class="form-control" [class.border-danger]="validateForm.campoEsValido('type', addValueForm)" (change)="changeValueType($event)" [items]="typeList" bindValue="value" bindLabel="label" placeholder="Tipo de valor" formControlName="type" [clearable]="false"></ng-select>
                                </div>   
                                <!-- <small *ngIf="validateForm.campoEsValido('type', addValueForm)" class="text-danger ml-1">Seleccione un tipo de valor.</small> -->
                            </div>
        
                            <ng-container *ngIf="valueType == 'NUMERIC'">
                                <div class="col-sm-6">
                                    <div class="row row--sm">
                                        <div class="col-sm-3">
                                            <h6> N. Inicio <span class="text-danger">*</span></h6>
                                            <div class="input-group">
                                                <div class="input-group"><input type="text" maxlength="6" (keypress)="validatorService.isNumeric($event)"  placeholder="N. Inicio" formControlName="n_init" class="form-control" [class.border-danger]="validateForm.campoEsValido('n_init', addValueForm)"></div>
                                            </div>    
                                            <!-- <small *ngIf="validateForm.campoEsValido('n_init', addValueForm)" class="text-danger ml-1">Ingrese un número de inicio</small> -->
                                        </div>
                                        <div class="col-sm-3">
                                            <h6> N. Fin	 <span class="text-danger">*</span></h6>
                                                <div class="input-group"><input maxlength="6" type="text" placeholder="N. Fin" (keypress)="validatorService.isNumeric($event)" formControlName="n_end" class="form-control" [class.border-danger]="validateForm.campoEsValido('n_end', addValueForm)"></div>
                                                <!-- <small *ngIf="validateForm.campoEsValido('n_end', addValueForm)" class="text-danger ml-1">Ingrese un número fin</small> -->
                                        </div>
                                        <div class="col-sm-3">
                                            <h6> Crit. Inicio <span class="text-danger">*</span></h6>
                                                <div class="input-group"><input type="text" maxlength="6" (keypress)="validatorService.isNumeric($event)" class="form-control" formControlName="crit_init" placeholder="Crit. Inicio" [class.border-danger]="validateForm.campoEsValido('crit_init', addValueForm)"></div>
                                                <!-- <small *ngIf="validateForm.campoEsValido('crit_init', addValueForm)" class="text-danger ml-1">Ingrese un número critico de inicio</small> -->
                                        </div>
                                        <div class="col-sm-3">
                                            <h6> Crit. Fin <span class="text-danger" >*</span></h6>
                                            <div class="input-group"><input type="text" maxlength="6" class="form-control" (keypress)="validatorService.isNumeric($event)" formControlName="crit_end"  placeholder="Crit. Fin" [class.border-danger]="validateForm.campoEsValido('crit_end', addValueForm)"></div>
                                            <!-- <small *ngIf="validateForm.campoEsValido('crit_end', addValueForm)" class="text-danger ml-1">Ingrese un número critico fin</small> -->
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="valueType != 'NUMERIC'">
                                <div class="col-sm-{{valueType != 'INTERPRETATIVE' ? 6 : 9}}">
                                    <h6> Valor Referencia  </h6>
                                    <div class="input-group"><input type="text" maxlength="60" class="form-control" formControlName="description" placeholder="Valor Referencia"></div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="valueType != 'INTERPRETATIVE'">
                                <div class="col-sm-3">
                                    <h6> Nota</h6>
                                    <div class="input-group"><input type="text" maxlength="60" class="form-control" formControlName="note" placeholder="Nota"></div>
                                </div>
                            </ng-container>
        
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-1 d-flex">
                <button class="btn btn-dark btn-block mt-auto" (click)="addVal()"><i class="fa fa-plus mr-2"></i> Agregar</button>
            </div>
        </div>
    </form>

    <div class="row border-top mt-2">
        <div class="col-sm-12 p-0">
            <div class="table-responsive">
                <table mat-table [dataSource]="DT_values" matSort  #Sort="matSort" matSortActive="code" matSortDirection="asc" class="table table-bordered table-striped mb-0 material-table-container">
                    <ng-container matColumnDef="position">
                        <th mat-header-cell *matHeaderCellDef width="75px"></th> 
                        <td mat-cell *matCellDef="let item">
                            <div class="text-center d-flex">
                                <button class="btn btn-dark" (click)="movePosition(item, -1)" [disabled]="item?.position <= 0"><i class="fa fa-caret-up"></i></button>
                                <button class="btn btn-dark"  (click)="movePosition(item, 1)" [disabled]="(item?.position + 1) >=  DT_values.data?.length"><i class="fa fa-caret-down"></i></button>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="init_range">
                        <th mat-header-cell *matHeaderCellDef> Rango Inicio </th> 
                        <td mat-cell *matCellDef="let item" width="100px">
                            <div class="input-group"><input type="text" class="form-control" placeholder="Rango Inicio" (input)="ValRinitTable(item, 'r_init')" (keypress)="validatorService.isInteger($event)" maxlength="3" [(ngModel)]="item.r_init"></div>
                            <!-- Cambiar por estilo a campo -->
                            <small *ngIf="validElementTable('r_init', item)" class="text-danger ml-1">Ingrese un rango de inicio.</small>
                            <small *ngIf="valRangeInit('r_init', item)" class="text-danger ml-1">Valor inicio no puede ser mayor a fin.</small>

                        </td>
                    </ng-container>
                    <ng-container matColumnDef="end_range">
                        <th mat-header-cell *matHeaderCellDef> Rango Fin </th> 
                        <td mat-cell *matCellDef="let item" width="100px">
                            <div class="input-group"><input type="text" class="form-control" placeholder="Rango Fin" (input)="ValRinitTable(item, 'r_end')" (keypress)="validatorService.isInteger($event)" maxlength="3"  [(ngModel)]="item.r_end"></div>
                            <!-- Cambiar por estilo a campo -->
                            <small *ngIf="validElementTable('r_end', item)" class="text-danger ml-1">Ingrese un rango fin.</small>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="measure">
                        <th mat-header-cell *matHeaderCellDef> Medida </th> 
                        <td mat-cell *matCellDef="let item" width="100px">
                            <div class="input-group">
                                <ng-select class="form-control" placeholder="Medida" [clearable]="false" [(ngModel)]="item.measure">
                                    <ng-option value="DAYS">Días</ng-option>
                                    <ng-option value="MOTHS">Meses</ng-option>
                                    <ng-option value="YEARS">Años</ng-option>
                                </ng-select>
                            </div>  
                            <!-- Cambiar por estilo a campo -->
                            <small *ngIf="validElementTable('measure', item)" class="text-danger ml-1">Seleccione una medida.</small>
                
                            </td>
                    </ng-container>
                    <ng-container matColumnDef="gender">
                        <th mat-header-cell *matHeaderCellDef> Genero </th> 
                        <td mat-cell *matCellDef="let item"  width="100px">
                            <div class="input-group">
                                <ng-select class="form-control" placeholder="Genero" [clearable]="false" [(ngModel)]="item.gender">
                                    <ng-option value="MALE"><i class="fa fa-male mr-2"></i> M</ng-option>
                                    <ng-option value="FEMALE"><i class="fa fa-female mr-2"></i> F</ng-option>
                                    <ng-option value="ANY"><i class="fa fa-female"></i><i class="fa fa-male mr-2"></i> FM</ng-option>
                                </ng-select>
                            </div> 
                            <!-- Cambiar por estilo a campo -->
                            <small *ngIf="validElementTable('gender', item)" class="text-danger ml-1">Seleccione un genero.</small>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="smoker">
                        <th mat-header-cell *matHeaderCellDef> Fumador </th> 
                        <td mat-cell *matCellDef="let item"  width="80px">
                            <div class="text-center"><label class="ckbox m-0 cursor-pointer check-det"><input type="checkbox"  [(checked)]="item.smoker"><span></span></label></div>       
                            <!-- Cambiar por estilo a campo -->
                            <!-- <small *ngIf="validElementTable('measure', item)" class="text-danger ml-1">Seleccione una medida.</small>               -->
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="pregnant">
                        <th mat-header-cell *matHeaderCellDef> Embarazo </th> 
                        <td mat-cell *matCellDef="let item"  width="80px">
                            <div class="text-center"><label class="ckbox m-0 cursor-pointer check-det"><input type="checkbox"  [(checked)]="item.pregnant"><span></span></label></div>    
                            <!-- Cambiar por estilo a campo -->
                            <!-- <small *ngIf="validElementTable('pregnant', item)" class="text-danger ml-1">Seleccione una medida.</small>                                -->
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef> Tipo de valor </th> 
                        <td mat-cell *matCellDef="let item" width="140px">
                            <div class="input-group">
                                <ng-select class="form-control" (change)="clearFields($event, item)" placeholder="Tipo de valor" [clearable]="false" [(ngModel)]="item.type">
                                    <ng-option value="NUMERIC">Numérico</ng-option>
                                    <ng-option value="DESCRIPTIVE">Descriptivo</ng-option>
                                    <ng-option value="INTERPRETATIVE">Interpretativo</ng-option>
                                </ng-select>
                            </div>   
                            <!-- Cambiar por estilo a campo -->
                            <small *ngIf="validElementTable('type', item)" class="text-danger ml-1">Seleccione un tipo de valor.</small>                                              
                         </td>
                    </ng-container>
                    <!-- NUMERICO  -->
                        <ng-container matColumnDef="n_start">
                            <th mat-header-cell *matHeaderCellDef colspan="4"> Valores </th> 
                            <ng-container  *matCellDef="let item">
                                <td  mat-cell *ngIf="item.type == 'NUMERIC'">
                                    <div class="input-group"><input type="text" class="form-control" (keypress)="validatorService.isNumeric($event)" maxlength="6"  [(ngModel)]="item.n_init" placeholder="N. Inicio"></div>
                            <!-- Cambiar por estilo a campo -->
                            <small *ngIf="validElementTable('n_init', item)" class="text-danger ml-1">Ingrese un número de inicio.</small>                               
                                </td>
                            </ng-container>
                        </ng-container>
                        <ng-container matColumnDef="n_end">
                            <th mat-header-cell *matHeaderCellDef hidden> N. Fin </th> 
                            <ng-container *matCellDef="let item">
                                <td mat-cell *ngIf="item.type == 'NUMERIC'">
                                    <div class="input-group"><input type="text" class="form-control" (keypress)="validatorService.isNumeric($event)" maxlength="6"  [(ngModel)]="item.n_end" placeholder="N. Fin"></div>
                            <!-- Cambiar por estilo a campo -->
                            <small *ngIf="validElementTable('n_end', item)" class="text-danger ml-1">Ingrese un número de fin.</small>                               
                                </td>
                            </ng-container>
                        </ng-container>
                        <ng-container matColumnDef="crit_start">
                            <th mat-header-cell *matHeaderCellDef hidden> Crit. Inicio </th> 
                            <ng-container  *matCellDef="let item">
                                <td mat-cell *ngIf="item.type == 'NUMERIC'">
                                    <div class="input-group"><input type="text" class="form-control" (keypress)="validatorService.isNumeric($event)" maxlength="6"  [(ngModel)]="item.crit_init" placeholder="Crit. Inicio"></div>
                            <!-- Cambiar por estilo a campo -->
                            <small *ngIf="validElementTable('crit_init', item)" class="text-danger ml-1">Ingrese un número critico de inicio.</small>                               
                                </td>
                            </ng-container>
                        </ng-container>
                        <ng-container matColumnDef="crit_end">
                            <th mat-header-cell *matHeaderCellDef hidden> Crit. Fin </th> 
                            <ng-container  *matCellDef="let item">
                                <td mat-cell  *ngIf="item.type == 'NUMERIC'">
                                    <div class="input-group"><input type="text" class="form-control" (keypress)="validatorService.isNumeric($event)" maxlength="6"   [(ngModel)]="item.crit_end" placeholder="Crit. Fin"></div>
                            <!-- Cambiar por estilo a campo -->
                            <small *ngIf="validElementTable('crit_end', item)" class="text-danger ml-1">Ingrese un número critico fin.</small>      
                                </td>
                            </ng-container>
                            
                        </ng-container>
                    <!-- --------- -->

                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef hidden> Valor Referencia </th> 
                        <ng-container *matCellDef="let item" >
                            <ng-container *ngIf="item.type != 'NUMERIC'">
                                <td mat-cell [attr.colspan]="item.type != 'INTERPRETATIVE' ? 4 : 5">
                                    <div class="input-group"><input type="text" class="form-control"  maxlength="60" [(ngModel)]="item.description" placeholder="Valor Referencia"></div>
                                <!-- Cambiar por estilo a campo -->
                                <!-- <small *ngIf="validElementTable('description', item)" class="text-danger ml-1">Ingrese una descripción.</small>       -->
                                </td>
                            </ng-container>
                        </ng-container>
                  
                    </ng-container>

                    <ng-container matColumnDef="note">
                        <th mat-header-cell *matHeaderCellDef width="20%"> Nota </th> 
                        <ng-container *matCellDef="let item" >
                            <ng-container *ngIf="item.type != 'INTERPRETATIVE'">
                                <td mat-cell>
                                    <div class="input-group"><input type="text" class="form-control" maxlength="60" [(ngModel)]="item.note" placeholder="Nota"></div>
                                    <!-- Cambiar por estilo a campo -->
                                    <!-- <small *ngIf="validElementTable('note', item)" class="text-danger ml-1">Ingrese una nota.</small>       -->
                                </td>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef> <div class="text-center">Acción</div> </th> 
                        <td mat-cell *matCellDef="let item" width="50px"> 
                           <div class="text-center">
                                <button class="btn btn-danger" [swal]="deleteValue" (confirm)="deleteVal(item)" ><i class="fa fa-trash"></i></button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
        
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell text-center" colspan="16">{{isLoading?"Cargando ...":"Sin datos encontrados"}}</td>
                    </tr>
                </table>
          
            </div>



        </div>
    </div>
</div>
<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-dark" (click)="close()">Cerrar</button>
    <button type="button" class="btn btn-success" (click)="Submit()">{{modalOptionsDeter?.type == 'EDIT' ? 'Actualizar' : 'Guardar'}}</button>
</div>

<swal #deleteValue class="d-none" title="¿Estas seguro de eliminar este valor?" icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>