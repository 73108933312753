import { BranchService } from './branch.service';
import { ModalCloseCashRegisterComponent } from './../../components/modal-close-cash-register/modal-close-cash-register.component';
import { ModalSelectCashRegisterComponent } from './../../components/modal-select-cash-register/modal-select-cash-register.component';
import { UserService } from 'src/app/shared/services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CashRegisterService {

  modal: any;

  constructor(
    private http: HttpService,
    private modalService: NgbModal,
    private userService: UserService,
    private branchService: BranchService
  ) { }

  public async openModal(onlySelect = false){
    if((this.userService.user.cashRegister || this.userService.user.adminPOS) && !onlySelect)return;
    var list = await this.listCashRegister(this.branchService.branch._id);
    this.modal = this.modalService.open(ModalSelectCashRegisterComponent, {size: 'smm', backdrop: 'static'});
    this.modal.componentInstance.chashRegisters = list;
    this.modal.componentInstance.onlySelect = onlySelect;
  }

  public async closeCashRegisterModal(id: string){
    var cashR = await this.detail(id);
    this.modal = this.modalService.open(ModalCloseCashRegisterComponent,{size: 'smm', backdrop: 'static'});
    this.modal.componentInstance.item = cashR;
  }


  public detail = (id: string): Promise<any> => this.http.get(`api/cashRegister/${id}`).toPromise();

  public history = (id: string): Promise<any> => this.http.get(`api/cashRegister/history/${id}`).toPromise();

  public assignCashRegister = (id: string, amount: string, denomination: any, force: string = null): Promise<any> => {
    let obj = {denomination: denomination}; 
    force ? obj['force'] = force : null;
    return this.http.put(`api/cashRegister/assign/${id}/${amount}`, obj).toPromise();
  }

  public unAssignCashRegister = (id: string, amount: any, denomination: any): Promise<any> => this.http.put(`api/cashRegister/unAssign/${id}`, {denomination: denomination, amounts: amount}).toPromise();

  public listCashRegister = (branch: string): Promise<any> => this.http.get(`api/cashRegister/list/${branch}`).toPromise();

  public allCashRegister = (): Promise<any> => this.http.get(`api/cashRegister/list`).toPromise();

}
