<div class="title border-bottom d-flex justify-content-between">
    <div class="date">
        <i class="fa fa-calendar mr-3 op-5"></i>{{item.date | moment:'ll'}}
    </div>
    <div class="time text-primary">
        <span>{{item.date | moment:'hh:mm A'}}</span>
    </div>
</div>
<div class="bg-white">
    <div class="d-flex justify-content-between">
        <span class="text-primary">Paciente: <b>{{item?.patient?.name | decrypt}}</b></span>
        <mk-status [status]="item.status || ''"></mk-status>
    </div>
    <div class="d-flex justify-content-between">
        <span><i class="fa fa-building mr-2 op-7"></i>{{item?.branch?.name}} <span class="text-muted">({{item?.branch?.address}})</span></span>
    </div>
    <span class="text-muted" *ngIf="item?.note"><b>Nota: </b>{{item?.note}}</span>
</div>