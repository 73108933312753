<div class="header">
    <div class="line">
        <span>{{orderData?.folio}}</span>
        <span>{{sample?.containerType}}</span>
        <span>FN: {{(orderData?.patient?.birthDate | date:'dd/MM/yyyy') || 'Sin Fecha'}}</span>
    </div>
</div>
<div class="info">
    <div class="line">
        <span><b>{{(orderData?.patient?.name | decrypt) | titlecase}}, {{(orderData?.patient?.birthDate | ageByBirthday)!='-'?(orderData?.patient?.birthDate | ageByBirthday):(orderData?.patient?.age + " " + (orderData?.patient?.ageMeasure | status) )}}</b></span>
        <span class="labels"><b>{{sample?.labels}}</b></span>
    </div>
</div>
<div class="body">
    <div class="img">
        <img src="{{orderData?.canvas}}">
    </div>
    <div class="customer">
        {{(orderData?.customer?.name | decrypt) | titlecase}}<br>
        {{orderData?.create?.date | moment: "DD/MM/YYYY"}} <br>
        {{orderData?.create?.date | moment: "HH:mm:ss"}}
    </div>
</div>