import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IFilter } from './../../interfaces/iFilter';

@Injectable({
  providedIn: 'root'
})

export class FiltersDatatableService {
  constructor() { }


  public filtersSelected = (filtersSelected: IFilter[]) => {
    let filters: string[] = []
    filtersSelected.forEach((filter) => {
      if (filter.active === true) filters.push(filter.type)
    })
    return filters;
  }

  public dataFilteredWithGroup = (data: any, filters) => {
    let filter = filters.length > 0 ? filters.some(filter => filter == data.status) : false
    if (data.isGroup) {
      filter = true
    }
    return filter
  }

  public dataFiltered = (data: any, filters) => {
    let filter = filters.length > 0 ? filters.some(filter => filter == data.status) : false
    if (data.isGroup) {
      filter = true
    }
    return filter
  }


}
