import { Injectable } from '@angular/core';
import { LayoutService } from './layout.service';
import { Subject } from 'rxjs';
import { PanelUrgenciesComponent } from 'src/app/components/panel-urgencies/panel-urgencies.component';

@Injectable({
  providedIn: 'root'
})
export class UrgenciesService {
  public openPanel = new Subject<void>();

  constructor(private layoutCtrl: LayoutService) {
  }

  public async load(){
  }

  public async openModal($event){
    this.openPanel.next();
    this.layoutCtrl.emitSidebarNotifyChange($event, PanelUrgenciesComponent);
  }
}
