import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpService } from 'src/app/shared/services/http.service';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class TicketComponent implements OnInit {

  @Input() ticket: any;
  
  public data: any;

  constructor(
    private httpServices: HttpService
  ) {
  }

  ngOnInit(): void {
    if (this.ticket) {
      this.loadTicketData();
    }
  }

  public async loadTicketData(){
    try {
      this.data = await this.httpServices.get(`api/order/pdf/${this.ticket}/DATA`).toPromise();
    } catch (error) {
      console.log(error);
    }
  }

}
