import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { BadgetService } from "./badget.service";
import { SocketService } from "./socket.service";
import { UserService } from "./user.service";

@Injectable({
    providedIn: 'root'
})

/**
 * Guard para verificar que el usuario tenga permiso de acceso y/o rol. El nombre del rol se encuentra en UserService
 * 
 * INSTRUCCIONES DE USO: 
 * 1) Utilizar en canActivate de cada modulo
 * 2) Proporcionar un objeto data: {role: ROL_ESPERADO}
 */

export class RoleGuard implements CanActivate {
    private expectedRole: string
    private path = "";

    constructor(
        private userService: UserService, 
        private router: Router,
        private socket: SocketService,
        private modalService: NgbModal,
        private badGetService: BadgetService

    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        this.socket.clearRooms();
        this.socket.addRoom(this.userService.user?.socketRoom);
        this.socket?.disconnect();
        this.socket.connect();
        this.badGetService.reloadBadgets();
        
        this.modalService.dismissAll();
        
        if (this.userService.user.roleName === "ADMINISTRADOR") {
            return true
        }

        this.expectedRole = route.data.role
        
        let permissionPath = this.userService.user.permissions?.find(item => { return item?.path == this.expectedRole})?.actions?.find(item => { return item?.action == "ACCESS" })?.value || //valida si tiene acceso al modulo raiz
        this.userService.user.permissions?.find(permission => { return permission?.actions?.find(action => { return action?.path == `/${this.expectedRole}` })?.value }); // valida si alguno de los hijos tiene acceso
        
        if (permissionPath) {
            return true
        } else {
            let firstPermission = this.userService.user.permissions.find(permission => {return permission?.actions?.find(action => {return action?.action == 'ACCESS' && action?.value})});
            this.router.navigate([`/${firstPermission?.path}`]);
        }
    }

}