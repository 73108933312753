import { BranchService } from './shared/services/branch.service';
import { ExchangeRateService } from './shared/services/exchangeRate.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'LABS_ADMIN';
  // public isSpinner = true;

  constructor(){
    
  }

  ngOnInit(): void {
    moment.locale('ES'); 
  }


}
