import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BuiltFormDataService {

  constructor() { }

  public buildFormData(formData: FormData, data: any, parentKey?: string) {
    if (data && (typeof data === 'object' && !parentKey) && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}` : key);
      });
    } else {
      const value = data == null ? '' : ((typeof data === 'object' && !(data instanceof File))?JSON.stringify(data):data);
      formData.append(parentKey, value);
    }
  }
}
