<!-- Page -->
<div class="page main-signin-wrapper bg-primary h-75">

  <div class="container d-flex h-100">
    <div class="card __texture custom-card m-auto pt-3 pb-5 px-5 text-center">
        <h1 class="tx-140 m-0 text-primary">404</h1>
        
        <h2>Lo sentimos, página no encontrada</h2>
        <span class="tx-15 op-7">Parece que la página que ha buscado fue movida o nunca existió</span>
        <a class="btn btn-primary btn-ripple mb-0 mt-4 w-50 mx-auto" href="/">Ir a inicio</a>
    </div>
  </div>

</div>
<!-- End Page -->