import { Injectable } from '@angular/core';
import { CallService } from '../services/http/call-service';
import { HttpService } from './http.service';
import { ProgramedService } from './http/programed.service';
import { UrgencyService } from './http/urgency.service';

@Injectable({
  providedIn: 'root'
})
export class BadgetService {

  public callsBadget = 0;
  public servicesBadget = 0;
  public urgencysBadget = 0;
  public medicRequestBadget = 0;
  public patientRequestBadget = 0;

  constructor(
    public httpService: HttpService,
    public callsSService: CallService,
    public programedService: ProgramedService,
    public urgencyService: UrgencyService,
    private http: HttpService

  ) { 
  }

  public async newBadgetCalls(){
    return new Promise( async (resolve, reject) => {
      try {
        let data;
        await this.callsSService.getPanelCalls().then(resp => {  
          data = resp 
          this.callsBadget = data?.filter(element => {return element.status != "CANCELLED" && element.status != "PROCESSED"}).length;
        }).catch(err => { console.log(err); })
        resolve(true);
      } catch (error) {
        console.log(error);
        resolve(false);
      }
    });
  }

  public async newBadgetServices(){
    return new Promise( async (resolve, reject) => {
      try {
        let data = [];
        await this.programedService.getPanelService().then(resp => {  
          resp.forEach(element =>{
            data =  data.concat(element?.regs);
          });

          this.servicesBadget = data?.length;
          
        }).catch(err => { console.log(err); })
        resolve(true);
      } catch (error) {
        console.log(error);
        resolve(false);
      }
    });
  }

  public async newBadgetUrgency(){
    return new Promise( async (resolve, reject) => {
      try {
       await this.urgencyService.getUrgents().then(resp => {
        resp.calls = resp?.calls.filter(element => {return element.status != "PROCESSED"});
        resp.services = resp?.services.filter(element => {return element.status != "PROCESSED"});

        let callsCount = resp?.calls?.length || 0;
        let servicesCount = resp?.services?.length || 0;
        this.urgencysBadget = callsCount + servicesCount;
        
       })
        resolve(true);
      } catch (error) {
        console.log(error);
        resolve(false);
      }
    });
  }

  public async newBadgetMedicRequest(){
    return new Promise( async (resolve, reject) => {
      try {
        await this.http.get('api/medic/request/count').toPromise().then(resp => {  
          this.medicRequestBadget = resp;
        }).catch(err => { console.log(err); })
        resolve(true);
      } catch (error) {
        console.log(error);
        resolve(false);
      }
    });
  }

  public async newBadgetPatientRequest(){
    return new Promise( async (resolve, reject) => {
      try {
        await this.http.get('api/patient/request/count').toPromise().then(resp => {  
          this.patientRequestBadget = resp;
        }).catch(err => { console.log(err); })
        resolve(true);
      } catch (error) {
        console.log(error);
        resolve(false);
      }
    });
  }

  public reloadBadgets = async () => {
    
    // Api de badgets en general
    await this.http.get(`api/calls/header`).toPromise().then(resp => {
      this.callsBadget = resp?.calls;
      this.servicesBadget = resp?.scheduledPanel;
      this.urgencysBadget = (resp?.urgentCalls + resp?.urgentServices) || 0;
     });
  }
}
