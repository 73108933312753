// import { ModalExchangeRateComponent } from './../../components/modal-exchangeRate/modal-exchangeRate.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ModalExchangeRateComponent } from 'src/app/components/modal-exchangeRate/modal-exchangeRate.component';

@Injectable({
  providedIn: 'root',
})
/**
 * Servicio administrador de sesión de usuario
 */
export class ExchangeRateService {

    public amount: number = 0;
    
    public modal: any;

    constructor(
        private http: HttpService,
        private modalService: NgbModal
    ) {
     }

    public async loadAmount(){
      await this.exchangeRate().then(data=>{
        this.amount = data?.amount?data.amount:0;
      });
      this.modal = this.modalService.open(ModalExchangeRateComponent, {size: 'sm',backdrop: 'static'});
    }
    
    public exchangeRate = (): Promise<any> => this.http.get(`api/exchangeRate`).toPromise();
    
    public updateAmount = (data: any): Promise<any> => this.http.post(`api/exchangeRate`, data).toPromise();

  }