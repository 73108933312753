import { Routes} from '@angular/router';
import { RoleGuard } from '../services/role-guard.service';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...
export const Pages_Routes: Routes = [
    {
        path: '', redirectTo: '/reception', pathMatch: 'full'
    },
    {
        path: 'home',
        canActivate: [RoleGuard],
        data: { role: 'home'},
        loadChildren: () => import('../../pages/home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'reception',
        canActivate: [RoleGuard],
        data: { role: 'reception'},
        loadChildren: () => import('../../pages/reception/reception.module').then(m => m.ReceptionModule)
    },
    {
        path: 'new-order',
        canActivate: [RoleGuard],
        data: { role: 'new-order'},
        loadChildren: () => import('../../pages/new-order/new-order.module').then(m => m.NewOrderModule)
    },
    {
        path: 'quotes',
        canActivate: [RoleGuard],
        data: { role: 'quotes' },
        loadChildren: () => import('../../pages/quotes/quote.module').then(m => m.QuoteModule)
    },
    {
        path: 'new-quote',
        canActivate: [RoleGuard],
        data: { role: 'new-quote' },
        loadChildren: () => import('../../pages/new-quote/new-quote.module').then(m => m.NewOrderModule)
    },
    {
        path: 'new-quote/programed',
        canActivate: [RoleGuard],
        data: { role: 'new-quote' },
        loadChildren: () => import('../../pages/new-quote/new-quote.module').then(m => m.NewOrderModule)
    },
    {
        path: 'cash-register',
        canActivate: [RoleGuard],
        data: { role: 'cash-register'},
        loadChildren: () => import('../../pages/cash-registers/cash-registers.module').then(m => m.CashRegistersModule)
    },
    {
        path: 'promotions',
        canActivate: [RoleGuard],
        data: { role: 'promotions'},
        loadChildren: () => import('../../pages/promotions/promotion.module').then(m => m.PromotionModule)
    },
    {
        path: 'news',
        canActivate: [RoleGuard],
        data: { role: 'news'},
        loadChildren: () => import('../../pages/news/news.module').then(m => m.NewsModule)
    },
    // {
    //     path: 'dates',
    //     canActivate: [RoleGuard],
    //     data: { role: 'dates'},
    //     loadChildren: () => import('../../pages/calendar/calendar.module').then(m => m.CalendarModule)
    // }, 
    {
        path: 'kardex',
        canActivate: [RoleGuard],
        data: { role: 'kardex'},
        loadChildren: () => import('../../pages/kardex/kardex.module').then(m => m.KardexModule)
    }, 
    {
        path: 'reports',
        canActivate: [RoleGuard],
        data: { role: 'reports'},
        loadChildren: () => import('../../pages/reports/reports.module').then(m => m.ReportsModule)
    }, 
    {
        path: 'catalogs',
        loadChildren: () => import('../../pages/catalogs/catalogs.module').then(m => m.CatalogsModule)
    },  
    {
        path: 'billing',
        canActivate: [RoleGuard],
        data: { role: 'billing'},
        loadChildren: () => import('../../pages/finances/billing/billing.module').then(m => m.BillingModule)
    },  
    {
        path: 'membership',
        canActivate: [RoleGuard],
        data: { role: 'membership'},
        loadChildren: () => import('../../pages/membership/membership.module').then(m => m.MembershipModule)
    },
    {
        path: 'calls',
        canActivate: [RoleGuard],
        data: { role: 'calls'},
        loadChildren: () => import('../../pages/calls/calls.module').then(m => m.CallsModule)
    },
    {
        path: 'services',
        canActivate: [RoleGuard],
        data: { role: 'services'},
        loadChildren: () => import('../../pages/programmed-services/programmed-services.module').then(m => m.ProgrammedServicesModule)
    },
    {
        path: 'user',
        canActivate: [RoleGuard],
        data: { role: 'user'},
        loadChildren: () => import('../../pages/users/users.module').then(m => m.UsersModule)
    },
    {
        path: 'roles',
        canActivate: [RoleGuard],
        data: { role: 'roles'},
        loadChildren: () => import('../../pages/roles/roles.module').then(m => m.RolesModule)
    },
    {
        path: 'results',
        canActivate: [RoleGuard],
        data: { role: 'results'},
        loadChildren: () => import('../../pages/results/results.module').then(m => m.ResultsModule)
    },
    {
        path: 'results/capture',
        canActivate: [RoleGuard],
        data: { role: 'results-capture'},
        loadChildren: () => import('../../pages/results-capture/results-capture.module').then(m => m.ResultsCaptureModule)
    },
    {
        path: 'sellers',
        loadChildren: () => import('../../pages/sellers/sellers.module').then(m => m.SellersModule)
    }
    
]