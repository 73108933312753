import { Injectable, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import {MktDropwdownComponent} from 'src/app/components/mk-table/components/mkt-dropwdown/mkt-dropwdown.component'


@Injectable({providedIn: 'root'})
export class DropdownService {

  private data = new Subject<any>();
  public dataEvent = this.data.asObservable();

  public setData(data){
    this.data.next(data);
  }
  
  constructor() { }

  openDialog(template: any, event: any, offset: number = 0, center = false){
    this.data.next({show: true,center: center, template: template, event: event, offset: offset})
  }

  closeDialog(event: any = null){

    if(!event?.target.classList.contains('mktd-block')) {
      this.data.next({show: false,center: false, template: null, event: {pageX: 0, pageY: 0}, offset: 0})
    }
    
  }
}
