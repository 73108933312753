import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class DataBankService {
  private _users: ISelects[];
  private _provider: ISelects[];
  private _contratista: ISelects[];
  private allCollection = ['users', 'provider', 'contratista']

constructor(
  private httpService: HttpService,
) { }

  public loadData():void{
    for (const collection of this.allCollection) {
      this.dataLoad(collection);
    }
  }

  public data(collection:string){
    return this['_'+collection]
  }

  async dataLoad(collection:string){
    return new Promise( async (resolve, reject) => {
      try {
        this['_'+collection] = await this.httpService.get(`api/catalogue/${collection}`).toPromise();
        resolve(this['_'+collection]);
      } catch (error) {
        resolve(false);
        console.log(error);
        
      }
    });
  }
  
}

export interface ISelects{
  _id: string
  name: string
}
