<!-- Sidebar -->
<app-overlay [class.show]="isOpen" (click)="onClose()"></app-overlay>
<div #sidebar class="sidebar sidebar-right sidebar-animate" >
    <div class="sidebar-icon">
        <a href="javascript:void(0)" class="text-left float-left text-dark fs-20" (click)="onClose()">
            <i class="fa fa-times fa-2x"></i>
        </a>
    </div>
    <div class="sidebar-body">
        <!-- <h5>Todo</h5> -->
        
        <ng-container *ngIf="isTemplate else componentOutlet "><ng-container [ngTemplateOutlet]="template"></ng-container></ng-container>
        <ng-template #componentOutlet ><ng-container [ngComponentOutlet]="template"></ng-container></ng-template>
    </div>
</div>
<!-- End Sidebar -->