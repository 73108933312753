import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
@Injectable({
  providedIn: 'root'
})

export class CallService {

  constructor(
    private http: HttpService
  ) { }

  // las rutas seran definidas ya que este el back
  public getAllCalls = (): Promise<any> => this.http.get('api/calls').toPromise();
  public getPanelCalls = (): Promise<any> => this.http.get('api/calls/panel').toPromise();
  public getAllCallsByDate = (data): Promise<any> => this.http.get(`api/calls/${data.status}/${data.start}/${data.end}`).toPromise();
  public getCall = (_id: string): Promise<any> => this.http.get(`api/calls/${_id}`).toPromise();
  public deleteCall = (_id: string): Promise<any> => this.http.delete(`api/calls/${_id}`).toPromise();
  public updateCall = (call: any): Promise<any> => this.http.put('api/calls', call).toPromise();
  public createCall = (call: any): Promise<any> => this.http.post('api/calls', call).toPromise();
  public attendCall = (call: any): Promise<any> => this.http.put('api/calls/attend', call).toPromise();
  public unatendedCall = (call: any): Promise<any> => this.http.put('api/calls/unattended', call).toPromise();
  public processCall = (call: any): Promise<any> => this.http.put('api/calls/process', call).toPromise();
  public cancelCall = (call: any): Promise<any> => this.http.put('api/calls/cancel', call).toPromise();

}
