import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { PdfService } from './pdf.service';

@Injectable({
  providedIn: 'root'
})
export class LabelsService {

  constructor(
    private http: HttpService,
    private pdfService: PdfService
  ) { }

  public getLabels(id: string){
    return new Promise( async (resolve, reject) => {
      try{
        let data:any = await this.generateContainer(id);

        let container2: any = [];
        for (const container of data.container) { // valida la cantidad de caracteres (2 lineas de 36 caracteres cada una con un total de 72 caracteres)
          if (container?.labels.join(", ")?.length > 72) {
            let temporalLabel = "";
            let temporalContainer = [];
            for (const label of container?.labels) {
              if((temporalLabel + (temporalLabel.length?', ':'') + label ).length < 72){
                temporalLabel = temporalLabel + (temporalLabel.length?', ':'') + label; 
              }else{
                temporalContainer.push(temporalLabel);
                temporalLabel = label;
              }
            }
            for (const item of temporalContainer) {
              container2.push({
                colorContainer: container?.colorContainer,
                colorPrint: container?.colorPrint,
                container: container?.container,
                containerType: container?.containerType,
                group: container?.group,
                labels: item
              })
            }
          }else{
            container2.push({
              colorContainer: container?.colorContainer,
              colorPrint: container?.colorPrint,
              container: container?.container,
              containerType: container?.containerType,
              group: container?.group,
              labels: container?.labels.join(", ")
            })
          }
        }

        data.container = container2;
        
        resolve(data);
      }catch(err){
        console.log(err);
      }
    });
  }

  public createContainer(id: string){
    return new Promise( async (resolve, reject) => {
      try{
        let data: any = await this.generateContainer(id);
        let obj = {
          order: id,
          container: data?.container || []
        }
        await this.http.post(`api/order/containers`, obj).toPromise();

        resolve(true);
      }catch(err){
        resolve(false);
        console.log(err);
      }
    });
  }

  public getContainers(id: string){
    return new Promise( async (resolve, reject) => {
      try {
        let data = await this.http.get(`api/order/containers/${id}`).toPromise();
        resolve(data || []);
      } catch (error) {
        resolve([]);
        console.log(error);
      }
    });
  }

  public generateContainer(id: string){
    return new Promise( async (resolve, reject) => {
      try{
        let data = await this.http.get(`api/order/labels/${id}`).toPromise();
        data.container = [];
        for (const study of data?.studies || []) { //genera agrupaciones
          let group: number = 1;
          for (const container of study?.containers || []) {
            container.sample = study?.sample;
            container.group = (study?.containers?.filter(search => { return search.colorContainer == container.colorContainer && !search.group })?.length > 1)?(container.colorContainer+(group++).toString()):container.colorContainer+group.toString();
          }
          data.container.push(...study.containers);
        }

        delete data.studies;
        
        data.container = data?.container?.reduce((groupBy, item) => { // agrupar por agrupaciones XD
          const { group } = item;
          groupBy[group] = groupBy[group] ?? [];
          groupBy[group].push(item);
          return groupBy;
        }, {})

        let container1:any = [];
        
        for (const key in data.container || {}) { // Une etiquetas con misma agrupacion a una sola
          let element = data.container[key];
          let labels: any = [];
          let colorContainer: any = [];
          let colorPrint: any = [];
          let container: any = [];
          let containerType: any = [];
          let group: any = [];
          let sample: boolean = true;
          for (const item of element) {
            labels.push(...item.labels);
            if(!item.sample) sample = false;

            (item.colorContainer)?colorContainer.push(item.colorContainer):null;
            (item.colorPrint)?colorPrint.push(item.colorPrint):null;
            (item.container)?container.push(item.container):null;
            (item.containerType)?containerType.push(item?.containerType):null;
            (item.group)?group.push(item.group):null;
          }
          
          container1.push({
            colorContainer: colorContainer[0],
            colorPrint: colorPrint[0],
            container: container[0],
            containerType: containerType[0],
            group: group[0],
            labels: labels,
            sample: sample
          })
        }
        
        data.container = container1;
        
        resolve(data);
      }catch(err){
        console.log(err);
      }
    });
  }

  public getItemsByOrder(id: string){
    return new Promise( async (resolve, reject) => {
      try{
        let data = await this.http.get(`api/order/items/${id}`).toPromise();
        resolve(data);
      }catch(err){
        console.log(err);
      }
    });
  }

  public printLabels(id: string){
    return new Promise( async (resolve, reject) => {
      try{
        this.http.getBlob(`api/order/labels/print/${id}`,`application/pdf`).subscribe( async data=>{
          var printed = await this.pdfService.print(data); // return false or true
        });
        resolve(null);
      }catch(err){
        console.log(err);
      }
    });
  }

  public printLabelsSelected(id: string, labels: any){
    return new Promise( async (resolve, reject) => {
      try{
        this.http.postBlob(`api/order/labels/printSelected/${id}`, {labels: labels},`application/json`).subscribe( async data=>{
          var printed = await this.pdfService.print(data); // return false or true
        });
        resolve(null);
      }catch(err){
        console.log(err);
      }
    });
  }

  public updateLabels(id: string, samples: any[]){
    return new Promise( async (resolve, reject) => {
      try {
        let response = await this.http.put(`api/order/containers/${id}`, {containers: samples}).toPromise();
        resolve(response);
      } catch (error) {
       console.log(error);
       resolve(false);
      }
    });
  }

}

