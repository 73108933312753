import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { IProcessType } from 'src/app/interfaces/iprocessType';
@Injectable({
  providedIn: 'root'
})

export class ProcessTypeService {

  constructor(
    private http: HttpService
  ) { }

  // las rutas seran definidas ya que este el back
  public getAllProcessType = (): Promise<IProcessType[]> => this.http.get('api/processType').toPromise();
  public getProcessType = (_id: string): Promise<IProcessType> => this.http.get(`api/processType/${_id}`).toPromise();
  public deleteProcessType = (_id: string): Promise<IProcessType> => this.http.delete(`api/processType/${_id}`).toPromise();
  public updateProcessType = (proccessType: any): Promise<any> => this.http.put('api/processType', proccessType).toPromise();
  public createProcessType = (proccesType: any): Promise<any> => this.http.post('api/processType', proccesType).toPromise();

}
