import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
@Injectable({
  providedIn: 'root'
})

export class ProgramedService {

  constructor(
    private http: HttpService
  ) { }

  private routepath = 'api/scheduledService'
  // las rutas seran definidas ya que este el back
  public getAllprogramedServices = (startDate, endDate): Promise<any> => this.http.get(`${this.routepath}/ALL/${startDate}/${endDate}`).toPromise();
  public getAllprogramedServicesByDate = (data): Promise<any> => this.http.get(`${this.routepath}/${data.status}/${data.start}/${data.end}`).toPromise();
  public getprogramedService = (_id: string): Promise<any> => this.http.get(`${this.routepath}/${_id}`).toPromise();
  public deleteprogramedService = (_id: string): Promise<any> => this.http.delete(`${this.routepath}/${_id}`).toPromise();
  public updateprogramedService = (programedService: any): Promise<any> => this.http.put(`${this.routepath}`, programedService).toPromise();
  public createprogramedService = (programedService: any): Promise<any> => this.http.post(`${this.routepath}`, programedService).toPromise();
  public attendprogramedService = (programedService: any): Promise<any> => this.http.put(`${this.routepath}/attend`, programedService).toPromise();
  public processprogramedService = (programedService: any): Promise<any> => this.http.put(`${this.routepath}/process`, programedService).toPromise();
  public cancelprogramedService = (programedService: any): Promise<any> => this.http.put(`${this.routepath}/cancel`, programedService).toPromise();
  public unattendedprogramedService = (programedService: any): Promise<any> => this.http.put(`${this.routepath}/unattended`, programedService).toPromise();
  public getPanelService = (): Promise<any> => this.http.get(`${this.routepath}/panel`).toPromise();

  public getQuotationSelect = (status: string): Promise<any> => this.http.get(`api/quotations/select/${status}`).toPromise();
  public getMedicsSelect = (): Promise<any> => this.http.get(`api/medic/selects`).toPromise();

}
