import { Injectable } from '@angular/core';
import { ZoomService } from './zoom.service';

@Injectable({
  providedIn: 'root'
})
export class TableService {

  constructor(private zoom: ZoomService) { }

  public get dom(): string {
    return "<'table-responsive border-bottom't><'d-flex w-100 flex-wrap justify-content-sm-center justify-content-xl-between'<'col-auto d-sm-none d-xl-flex align-items-center'i><'col-auto pr-xs _pagination'p>>";
  }

  public getRowCount(rowHeight: number, cutoff:number = 0): number{
    var windowHeight = window.innerHeight / this.zoom.getZoomValue //screen height
    var header =  166;
    var footer = 120;

    return Math.floor((windowHeight - header - footer - cutoff) / rowHeight) - 1; // -3  as margin
  }

}
