import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { AlertService } from 'src/app/shared/services/alert.service';
import { StudyService } from 'src/app/shared/services/http/study.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'cat-modal-containers',
  templateUrl: './modal-containers.component.html',
  styleUrls: ['./modal-containers.component.scss']
})
export class ModalContainersComponent implements OnInit {

  @Input() modalOptionsContainers = {id: null, type: 'ADD', item: null}

  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onAddcontainer: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onEditcontainer: EventEmitter<boolean> = new EventEmitter<boolean>();

  close = () => {this.onClose.emit()}
  public containerForm: FormGroup;
  public sampleTypeList = [];
  public colorList = [{code:'#df4a54', name: "Rojo"}, {code:'#ffb748', name:"Naranja Bajito"}, {code:'#2dc8f0', name: "Azul Bajito"}, {code:'#9665a6', name: "Morado"}, {code:'#008348', name: "Verde"}, {code:'#7f8c8d', name:"Gris"}, {code:'#f1c40f', name:"Amarillo"}, {code:'#e67e22', name: "Naranja Fuerte"}, {code:'#cfb786', name: "Beige"}, {code:'#1e3799', name: "Azul Fuerte"}, {code:'#ffffff', name: "Blanco"}];
  public containerList = [
    // {label:"Tubo APTIMA" ,value: "a56s56da675as56"}
  ]
  constructor(       
    private fb: FormBuilder,
    private alertService: AlertService,
    public validateForm: ValidateFormsDirective,
    public studyServices: StudyService
    ) {

   }

  ngOnInit(): void {
    this.containerForm = this.fb.group({
      colorActive: ['#df4a54'],
      color: [{code:'#df4a54', name: "Rojo"},  Validators.required],
      type:[null],
      sample:[null],
      labels:[[]],
      _id: [null]
    });

    this.getCatalogs();

    if (this.modalOptionsContainers.type == 'EDIT') {
      // let labelsString = JSON.stringify(this.modalOptionsContainers?.item?.labels);

      let tempLabel: any = [];
      for (const item of this.modalOptionsContainers?.item?.labels) {
        tempLabel.push({label: item});
      }

      let labelsString = JSON.stringify(tempLabel);
      
      this.containerForm.patchValue({
        _id: this.modalOptionsContainers?.item?._id,
        color: this.modalOptionsContainers?.item?.color || this.colorList.find(element => {return element.code == ('#'+this.modalOptionsContainers?.item?.colorPrint)}),
        type: this.modalOptionsContainers?.item?.type || this.modalOptionsContainers?.item?.containerType,
        sample: this.modalOptionsContainers?.item?.sample ||this.modalOptionsContainers?.item?.container,
        labels: labelsString, 
        colorActive: this.modalOptionsContainers?.item?.color?.code || ('#'+this.modalOptionsContainers?.item?.colorPrint)
      });
    }
  }

  ngOnChanges(change: SimpleChanges){
    
  }

  public addContainer = async () => {
    if (!this.containerForm.valid) {
      this.alertService.infoToast("Campos incompletos");      
      this.containerForm.markAllAsTouched();        
      return;
    }
    // let tmptags = [];
    
    // if (this.containerForm.value.labels) {
    //   this.containerForm.value.labels = JSON.parse(this.containerForm.value.labels)
      
    //   for (const iterator of  this.containerForm.value.labels) {
    //     if (iterator?.id) {
    //       tmptags.push(iterator?.id)
    //     }else{
    //       tmptags.push(iterator?.label) 
    //     }
    //   }
      
    //   this.containerForm.value.labels = tmptags;
    // }else{
    //   delete this.containerForm.value.labels
    // }

    // this.containerForm.value._id = Date.now();
    // // this.containerForm.patchValue({
    // //   _id: Date.now(),
    // // });

    let object: any = await this.transformObject(this.containerForm.value);
    
    this.onAddcontainer.emit(object);
    this.onClose.emit();
  }

  public selectColor = (event) => {
    let colorFinded = this.colorList.find(element => {return element.code == this.containerForm.value.colorActive})
    this.containerForm.patchValue({
      color: colorFinded
    });    
  }

  public editContainer = async () => {  
    if (!this.containerForm.valid) {
      this.alertService.infoToast("Campos incompletos");      
      this.containerForm.markAllAsTouched();        
      return;
    }   

    let object: any = await this.transformObject(this.containerForm.value);
    
    this.onEditcontainer.emit(object);
    this.onClose.emit();
  }

  public getCatalogs = async () => {
    try {
      //Tipo de muestra
      await this.studyServices.getSampleTypeSelect().then(resp => {
          this.sampleTypeList = resp;
      });

     //Tipo de contenedor
      await this.studyServices.getContainerType().then(resp => {
           this.containerList = resp;           
      });
    } catch (error) {
      console.log(error);
    }
  }

  // Función que transforma la estructura de un objeto y devuelve una promesa
  private transformObject(original: OriginalObject): Promise<TransformedObject> {
    return new Promise((resolve, reject) => {
      try {
        // Crea un nuevo objeto con la estructura deseada
        const transformed: TransformedObject = {
          colorPrint: original.colorActive.replace('#', ''), // Elimina el '#' del color activo
          colorContainer: original.color.name, // Extrae el nombre del color
          containerType: original.type, // Asigna el tipo de contenedor
          container: original.sample, // Asigna la muestra
          _id: original._id ? original._id : uuidv4(), // Usa el _id existente o genera uno nuevo si no está presente
        };

        // Solo agrega labels si existe en el objeto original
        if (original.labels) {
          transformed.labels = JSON.parse(original.labels).map((item: any) => item.label); // Parsea la cadena JSON de etiquetas y extrae los valores
        }
        resolve(transformed); // Resuelve la promesa con el objeto transformado
      } catch (error) {
        reject('Error al transformar el objeto: ' + error); // Rechaza la promesa en caso de error
      }
    });
  }
}

// Interfaz para el objeto original
interface OriginalObject {
  colorActive: string;
  color: {
    code: string;
    name: string;
  };
  type: string;
  sample: string;
  labels?: string; // Puede estar ausente
  _id?: string; // Puede estar ausente
}

// Interfaz para el objeto transformado
interface TransformedObject {
  colorPrint: string;
  colorContainer: string;
  containerType: string;
  container: string;
  labels?: string[];
  _id: string;
}
