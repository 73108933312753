<!-- <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Autorización</h6>
     <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div> -->
<form [formGroup]="authForm" novalidate>
    <div class="modal-body pt-0 pb-2"> 
        <div class="row border-bottom">
            <div class="col-12 p-3 d-flex">
                <i class="fa fa-lock fa-2x my-auto text-primary"></i>
                <div class="d-flex flex-column ml-3 lh-1">
                    <h3 class="m-0">Autorización</h3>
                    <span class="text-muted">Ingresa credenciales.</span>
                </div>
            </div>
        </div>
        <div class="wrap mt-3" *ngIf="reason">
            <span class="text-info">{{reason}}</span>
        </div>
        <div class="row my-2">
            <div class="col-12">
                <h6>Usuario</h6>
                <div class="input-group">
                    <input type="text" class="form-control" formControlName="username" placeholder="Ingresa Usuario">
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-12">
                <h6>Contraseña</h6>
                <div class="input-group">
                    <input type="password" class="form-control" formControlName="password" placeholder="Ingresa Constraseña">
                </div>
            </div>
            <!-- <div class="col-4 pl-0">
                <h6>NIP</h6>
                <div class="input-group">
                    <input type="password" class="form-control" formControlName="nip" placeholder="****">
                </div>
            </div> -->
        </div>
    </div>
</form>
<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-danger" (click)="close()">Cancelar</button>
    <button type="submit" class="btn btn-success" [disabled]="!authForm.valid" (click)="authorize()">Autorizar</button>
</div>