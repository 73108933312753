import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ILogin } from '../interfaces/ilogin';
import { CryptoSharkService } from '../shared/services/crypto-shark.service';
import { HttpService } from '../shared/services/http.service';
import { UserService } from '../shared/services/user.service';
import { SocketService } from '../shared/services/socket.service';
import { BranchService } from '../shared/services/branch.service';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {

  @ViewChild('modalCredentials') private modalCredentials: TemplateRef<any>;

  public loginForm: FormGroup;
  public submitted: boolean = false;
  public loginData: ILogin = {};
  public returnUrl: string
  public expiredSesionAlert: boolean;
  public invalidCredentials: boolean;
  public loadingBranch = false;
  public selected;
  public branchData: any[] = [];
  public branchSelect: string = 'ALL';
  
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private cryptoService: CryptoSharkService,
    private userService: UserService,
    private http: HttpClient,
    private socket: SocketService,
    private branchService: BranchService,
    private modalService: NgbModal,
  ) { }

  async ngOnInit() {
    this.expiredSesionAlert = this.route.snapshot.queryParams['returnUrl'] ? true : false 
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/reception'
    
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      // nip: ['', Validators.required],
      branchId: [null , Validators.required]
    });

    //Selecciona la ultima sucursal a la que se logueo el usuario
    let branch = localStorage.getItem('branch');    
    if (branch) {
      let branchDecrypted = this.cryptoService.decrypt(branch)      
      this.loginForm.patchValue({
        branchId: JSON.parse(branchDecrypted)?._id
      });
      this.selected = JSON.parse(branchDecrypted);
    }
    
    await this.loadBranch();
  }
  
  get loginFormControl() {
    return this.loginForm.controls;
  }
  

  async loadBranch(){
    this.loadingBranch = true;
    this.httpService.post(`api/branch/getList`, {}).toPromise()
    .then((response: any) => { 
      this.branchData = response
      this.loadingBranch = false;
    }).catch((e) => {
      console.log(e);
      this.loadingBranch = false;
    });
  }

  Submit(){ 
    if(this.loginForm.invalid){ return;}
        this.httpService.post(`api/login`, {
          login: this.cryptoService.encryptAes( this.loginForm.controls['username'].value ),
          password: this.cryptoService.encryptSha( this.loginForm.controls['password'].value ),
          // nip: this.cryptoService.encryptSha( this.loginForm.controls['nip'].value ),
          branchId: this.loginForm.controls['branchId'].value
        }).toPromise()
        .then((response: any) => {         
          localStorage.setItem('branch', this.cryptoService.encryptAes(JSON.stringify(this.selected)))
          
          this.loginData = { 
            token: response.token,
            company: {
              logo: "logo/logoLabWhite.svg",
              name: "LabExpress"
            } 
          };
          this.loginData.isRegister = response?.isRegistered || false;
          this.userService.signInCurrentUser(this.loginData);
          
          this.socket.connect(response.token);

          if (response?.isRegistered) {
            this.router.navigateByUrl(this.returnUrl);
          }else{
            this.openModal(this.modalCredentials, "md");
          }
        }).catch((e) => {
          this.invalidCredentials = e.error.message ? true : false;
          if(e?.status == 401){
            
            Swal.fire({
              title: 'Caja abierta en otra sucursal',
              text: `Realiza el corte en la sucursal "${e?.error?.branch}" de la caja "${e?.error?.cashRegister}" para iniciar sesion`,
              icon: 'info',
              showDenyButton: false,
              confirmButtonText: 'Aceptar',
            });
          }
            console.log(e);
        })
  }

  private openModal(modal, size: string = 'xxl') {
    this.modalService.open(modal, { size: size, backdrop: 'static' });
  }

  public updateCredentials():void{
    this.loginData.isRegister = true;
    this.userService.signInCurrentUser(this.loginData);
    this.router.navigateByUrl(this.returnUrl);
  }
}

