import { UserService } from 'src/app/shared/services/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { CashRegisterService } from './../../shared/services/cash-register.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { CurrencyPipe } from '@angular/common';
import { ExchangeRateService } from '../../shared/services/exchangeRate.service';
import { ValidatorsService } from 'src/app/shared/services/validators.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { PdfService } from 'src/app/shared/services/pdf.service';

@Component({
  selector: 'app-modal-close-cash-register',
  templateUrl: './modal-close-cash-register.component.html',
  styleUrls: ['./modal-close-cash-register.component.scss']
})
export class ModalCloseCashRegisterComponent implements OnInit {

  public currencyMask = createNumberMask({
    prefix: '$',
    includeThousandsSeparator: true,
    allowDecimal: true,
  })

  @Input() item: any;  
  @Input() admin: boolean = false;
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>(); 

  public amount: any = {
    total: "",
    mxn: 0,
    usd: 0,
  };
  public remain: any = {
    total: "",
    mxn: 0,
    usd: 0,
  };

  public denomArray = [
    {currency: "MXN", denomination: null,  amount: null}
  ];

  // DENOMINACIONES

  public denomUSD: any = [
    {type: "COIN", value: .01, quantity: null},
    {type: "COIN", value: .05, quantity: null},
    {type: "COIN", value: .10, quantity: null},
    {type: "COIN", value: .25, quantity: null},
    {type: "COIN", value: 1, quantity: null},
    {type: "BILL", value: 1, quantity: null},
    {type: "BILL", value: 2, quantity: null},
    {type: "BILL", value: 5, quantity: null},
    {type: "BILL", value: 10, quantity: null},
    {type: "BILL", value: 20, quantity: null},
    {type: "BILL", value: 50, quantity: null},
    {type: "BILL", value: 100, quantity: null},
  ]
  public denomMXN: any = [
    {type: "COIN", value: .5, quantity: null},
    {type: "COIN", value: 1, quantity: null},
    {type: "COIN", value: 2, quantity: null},
    {type: "COIN", value: 5, quantity: null},
    {type: "COIN", value: 10, quantity: null},
    {type: "COIN", value: 20, quantity: null},
    {type: "BILL", value: 20, quantity: null},
    {type: "BILL", value: 50, quantity: null},
    {type: "BILL", value: 100, quantity: null},
    {type: "BILL", value: 200, quantity: null},
    {type: "BILL", value: 500, quantity: null},
    {type: "BILL", value: 1000, quantity: null},
  ]

  public denomUSDRemain: any = [
    {type: "COIN", value: .01, quantity: null},
    {type: "COIN", value: .05, quantity: null},
    {type: "COIN", value: .10, quantity: null},
    {type: "COIN", value: .25, quantity: null},
    {type: "COIN", value: 1, quantity: null},
    {type: "BILL", value: 1, quantity: null},
    {type: "BILL", value: 2, quantity: null},
    {type: "BILL", value: 5, quantity: null},
    {type: "BILL", value: 10, quantity: null},
    {type: "BILL", value: 20, quantity: null},
    {type: "BILL", value: 50, quantity: null},
    {type: "BILL", value: 100, quantity: null},
  ]
  public denomMXNRemain: any = [
    {type: "COIN", value: .5, quantity: null},
    {type: "COIN", value: 1, quantity: null},
    {type: "COIN", value: 2, quantity: null},
    {type: "COIN", value: 5, quantity: null},
    {type: "COIN", value: 10, quantity: null},
    {type: "COIN", value: 20, quantity: null},
    {type: "BILL", value: 20, quantity: null},
    {type: "BILL", value: 50, quantity: null},
    {type: "BILL", value: 100, quantity: null},
    {type: "BILL", value: 200, quantity: null},
    {type: "BILL", value: 500, quantity: null},
    {type: "BILL", value: 1000, quantity: null},
  ]

  constructor(
    private cashRegisterService: CashRegisterService, 
    private alertService: AlertService, 
    private userService: UserService,
    private currency: CurrencyPipe,
    public exchangeRate: ExchangeRateService,
    public validator: ValidatorsService,
    private httpServices: HttpService,
    private pdfService: PdfService
  ) { }

  ngOnInit(): void {
  }
  
  public unAssign(){
    let parsedAmount = this.amount.total.replace(/[$,]/g,'');
    let parsedAmountRemain = this.remain.total.replace(/[$,]/g,'');
    
    let denomination = {
      denomUSD: this.denomUSD,
      denomMXN: this.denomMXN,
      denomUSDRemain: this.denomUSDRemain,
      denomMXNRemain: this.denomMXNRemain
    }
    this.alertService.confirmToast(`Cierre de caja <b class="text-info">${this.item.title}</b>, Cierras con <i>${this.currency.transform(parsedAmount)}</i>`, async ()=>{
      
      let data = await this.cashRegisterService.unAssignCashRegister(this.item._id, {amount: parsedAmount, remain: parsedAmountRemain }, denomination);
      this.printTicket(data?.insertedId);
      if (!this.admin) {
        this.userService.setCashRegister(null);
        this.userService.setCashRegisterTitle(null);
      }else{
        if(this.userService.user.cashRegister == this.item._id){
          this.userService.setCashRegister(null);
          this.userService.setCashRegisterTitle(null);
        }
      }
      this.alertService.successToast('Caja cerrada con exito.');
      this.close();
    });
  }

  close(): void { !this.admin?this.cashRegisterService.modal.close(): this.onClose.emit() }

  disableBtn():boolean{
    return (this.amount.mxn <= 0 && this.amount.usd <= 0) || (this.remain.mxn <= 0 && this.remain.usd <= 0)
  }

  public addDenomination():void{ this.denomArray.push({currency: "MXN", denomination: null,  amount: null}) }

  public updateAmount(){
    let am = {
      total: "0",
      mxn: 0,
      usd: 0,
    };

    this.getCurrencySum(am, this.denomMXN, "MXN");
    this.getCurrencySum(am, this.denomUSD, "USD");

    am.total = (am.mxn + (am.usd * this.exchangeRate.amount)).toString();

    this.amount = am;
  }

  public updateRemain(){
    
    let re = {
      total: "0",
      mxn: 0,
      usd: 0,
    };

    this.getCurrencySum(re, this.denomMXNRemain, "MXN");
    this.getCurrencySum(re, this.denomUSDRemain, "USD");
      
      re.total = (re.mxn + (re.usd * this.exchangeRate.amount)).toString()

    this.remain = re;
  }

  getCurrencySum(total, arrayCurr, currency): void{
    arrayCurr.forEach(item =>{
      if(item.quantity){
        
        total.mxn += currency == "MXN" ? ((item.quantity * item.value) || 0) : 0;
        total.usd += currency == "USD" ? ((item.quantity * item.value) || 0) : 0;
      }
    })
  }

  getIconColor(currency: string, type: string, value: number = 0){return currency + '-' + (type?.[0]) + '-' + value?.toString().replace('0.', '-')}

  public async printTicket(id:string){
    try{
      this.httpServices.getBlob(`api/cashRegister/pdf/${id}`,`application/pdf`).subscribe( async data=>{
        await this.pdfService.print(data); 
      });
    }catch(err){
      console.log(err);
    }
  }

  public totalCard(){
    var total =0;
    this.item?.payments?.forEach(p => {
      if (p.type == 'TARJETA CREDITO' || p.type == 'TARJETA DEBITO')total+=p.amount; 
    });
    return total;
  }

  public totalTransfer(){
    var total =0;
    this.item?.payments?.forEach(p => {
      if (p.type == 'TRANSFERENCIA')total+=p.amount; 
    });
    return total;
  }

  public totalCheck(){
    var total =0;
    this.item?.payments?.forEach(p => {
      if (p.type == 'CHEQUE')total+=p.amount; 
    });
    return total;
  }
}
