import { Directive } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
  selector: '[ValidateForms]'
})
export class ValidateFormsDirective {

  constructor() { }

  campoEsValido( campo: any, formRules: FormGroup, isGroup = false, group = ""){
    return isGroup?
      formRules.get(group)?.['controls']?.[campo].errors && formRules.get(group)?.['controls']?.[campo].touched:
      formRules.controls[ campo ].errors && formRules.controls[ campo ].touched;
  }

  clearNullObjects(formGroup: Object){
    return new Promise<Object>((resolve, reject) => {
      for (const key in formGroup) {
        const field = formGroup[key];
        // console.log(key, ": ", field);
        
        if (field == null) {
          delete formGroup[key];
        }else if (typeof field === 'object' && !Array.isArray(field) && !(field instanceof Date) && !(field instanceof File)) {
          for (const childrenKey in field){
            const children = field[childrenKey];
            if (children == null) {
              delete formGroup[key][childrenKey];
            }
          }
          Object.entries(formGroup[key]).length == 0 ? delete formGroup[key]:'';
        }
      }
      resolve(formGroup);
    })
  }

}
