import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/shared/services/user.service';
import { CashRegisterService } from './../../shared/services/cash-register.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { CurrencyPipe } from '@angular/common';
import { ExchangeRateService } from 'src/app/shared/services/exchangeRate.service';
import { ValidatorsService } from 'src/app/shared/services/validators.service';

@Component({
  selector: 'app-modal-select-cash-register',
  templateUrl: './modal-select-cash-register.component.html',
  styleUrls: ['./modal-select-cash-register.component.scss']
})

export class ModalSelectCashRegisterComponent implements OnInit {

  public currencyMask = createNumberMask({
    prefix: '$',
    includeThousandsSeparator: true,
    allowDecimal: true,
  })

  @Output() cashRegisterSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() chashRegisters: any[];
  @Input() onlySelect: boolean = false;

  @ViewChild("modalAuth") modalAuth: NgbModal

  public amount: any = {
    total: "",
    mxn: 0,
    usd: 0,
  };
  
  public cashRegister: any;
  public reasonAuth: string = null;

  public denomArray = [
    {currency: "MXN", denomination: null,  amount: null}
  ];

  public denomUSD: any = [
    {type: "COIN", value: .01, quantity: null},
    {type: "COIN", value: .05, quantity: null},
    {type: "COIN", value: .10, quantity: null},
    {type: "COIN", value: .25, quantity: null},
    {type: "COIN", value: 1, quantity: null},
    {type: "BILL", value: 1, quantity: null},
    {type: "BILL", value: 2, quantity: null},
    {type: "BILL", value: 5, quantity: null},
    {type: "BILL", value: 10, quantity: null},
    {type: "BILL", value: 20, quantity: null},
    {type: "BILL", value: 50, quantity: null},
    {type: "BILL", value: 100, quantity: null},
  ]
  public denomMXN: any = [
    {type: "COIN", value: .5, quantity: null},
    {type: "COIN", value: 1, quantity: null},
    {type: "COIN", value: 2, quantity: null},
    {type: "COIN", value: 5, quantity: null},
    {type: "COIN", value: 10, quantity: null},
    {type: "COIN", value: 20, quantity: null},
    {type: "BILL", value: 20, quantity: null},
    {type: "BILL", value: 50, quantity: null},
    {type: "BILL", value: 100, quantity: null},
    {type: "BILL", value: 200, quantity: null},
    {type: "BILL", value: 500, quantity: null},
    {type: "BILL", value: 1000, quantity: null},
  ]
  
  constructor(
    private alertService: AlertService, 
    private cashRegisterService: CashRegisterService, 
    public userService: UserService,
    private currency: CurrencyPipe,
    public exchangeRate: ExchangeRateService,
    public validator: ValidatorsService,
    private modalService: NgbModal
  ) {
    if(this.userService.user.cashRegisterDefault){this.cashRegister = this.userService.user.cashRegisterDefault }
  }

  ngOnInit(): void {
    
  }

  disableBtn():boolean{
    return (this.amount.mxn <= 0 && this.amount.usd <= 0);
  }

  public cashSelect(cr){
    this.cashRegisterSelected.emit(cr);
  }

  public assign(force:string = null){
    let parsedAmount = this.amount.total.replace(/[$,]/g,'');
    let denomination = {
      denomUSD: this.denomUSD,
      denomMXN: this.denomMXN
    }
    if (force) {
      this.submit(parsedAmount, denomination, force);
    }else{
      this.alertService.confirmToast(`Seleccionar la caja <b class="text-info">${this.cashRegister.title}</b>, Apertura con <i>${this.currency.transform(parsedAmount)}</i>`, async ()=>{
        this.submit(parsedAmount, denomination, force);
      });
    }
  }

  private async submit(parsedAmount: string, denomination: any, force: string){
    try {
      await this.cashRegisterService.assignCashRegister(this.cashRegister._id, parsedAmount, denomination, force);
      this.userService.setCashRegister(this.cashRegister._id);
      this.userService.setCashRegisterTitle(this.cashRegister.title);
      this.alertService.successToast('Caja seleccionada con exito.');
      this.close();
    } catch (error) {
      if (error.status == 400) {
        if (error.error.auth) {
          this.reasonAuth = error.error.message;
          this.modalService.open(this.modalAuth, {size: 'md',backdrop: 'static'});
        }
        this.alertService.errorToast(error.error.message);
      }
    }
  }

  close(): void { this.cashRegisterService.modal.close() }
  
  public addDenomination():void{ this.denomArray.push({currency: "MXN", denomination: null,  amount: null}) }

  public updateAmount(){
    let am = {
      total: "0",
      mxn: 0,
      usd: 0,
    };

    this.getCurrencySum(am, this.denomMXN, "MXN");
    this.getCurrencySum(am, this.denomUSD, "USD");

    am.total = (am.mxn + (am.usd * this.exchangeRate.amount)).toString();

    this.amount = am;
  }

  getCurrencySum(total, arrayCurr, currency): void{
    arrayCurr.forEach(item =>{
      if(item.quantity){
        
        total.mxn += currency == "MXN" ? ((item.quantity * item.value) || 0) : 0;
        total.usd += currency == "USD" ? ((item.quantity * item.value) || 0) : 0;
      }
    })
  }

  getIconColor(currency: string, type: string, value: number = 0){return currency + '-' + (type?.[0]) + '-' + value?.toString().replace('0.', '-')}
}
