<div class="modal-body">
    <div class="row border-bottom">
        <div class="col-12 p-3 d-flex">
            <i class="fa fa-money fa-2x my-auto text-primary"></i>
            <div class="d-flex flex-column ml-3 lh-1">
                <ng-container *ngIf="!cashRegister || onlySelect">
                    <h3 class="m-0">Caja</h3>
                    <span class="text-muted">Selecciona Caja.</span>
                </ng-container>
                <ng-container *ngIf="cashRegister && !onlySelect">
                    <h3 class="m-0">Abrir Caja</h3>
                    <span class="text-muted">Ingresa monto para abrir caja.</span>
                </ng-container>
            </div>
        </div>
    </div>
    
    <ng-container *ngIf="onlySelect">
        <ng-container *ngFor="let c of chashRegisters; index as i">
            <div class="py-2 px-3 row _item justify-content-between" [class.border-top]="i" (click)="cashSelect(c)">
                <div class="d-flex flex-column">
                    <h5 class="m-0 text-primary">{{c.title}}</h5>
                    <span class="text-muted">{{c.note || "Disponible"}}</span>
                </div>
                <i class="fa fa-chevron-right my-auto op-5"></i>
            </div>
        </ng-container>
    </ng-container>
    
    <ng-container *ngIf="!cashRegister && !onlySelect">
        <ng-container *ngFor="let c of chashRegisters; index as i">
            <div class="py-2 px-3 row _item justify-content-between" [class._disabled]="c.user" [class.border-top]="i" (click)="cashRegister = c;">
                <div class="d-flex flex-column">
                    <h5 class="m-0 text-primary">{{c.title}}</h5>
                    <span class="text-muted">{{c.note || "Disponible"}}</span>
                </div>
                <i class="fa fa-chevron-right my-auto op-5"></i>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="cashRegister && !onlySelect">
        
        <div class="wrap py-3">
            <span class="text-primary mb-2">Saldo Inicial</span>
            <input type="text" class="input-big text-primary" readonly placeholder="$0.00" step="0.01"
                [(ngModel)]="amount.total" [textMask]="{mask: currencyMask}">
            <div class="w-100 text-center d-flex pt-2" *ngIf="amount.usd">
                <div class="_pill badge-primary-light"><span style="display: none;"></span><span>MXN: <b>{{
                            (amount.mxn) | currency}}</b></span></div>
                <div class="_pill badge-primary-light"
                    ngbTooltip="Tipo de cambio: {{exchangeRate.amount | currency}}"><span><i
                            class="fa fa-info-circle"></i></span><span>USD: <b>{{ (amount.usd) | currency}}</b>
                        <small class="ml-1">({{(exchangeRate.amount * amount.usd) | currency}}
                            MXN)</small></span></div>
            </div>
            <small class="text-muted mt-2">Aplica con todo el efectivo que tengas en tu caja</small>
        </div>
        <div class="wrap">
            <span class="text-primary mb-3">Denominaciones</span>
            <div class="card d-flex flex-row p-2 mb-3">
                <div class="col-md-6">
                    <div class="text-primary"><span class="badge badge-primary-light"><b>MXN</b></span></div>
                    <ng-container *ngFor="let type of denomMXN.reverse()">
                        <div class="row mt-1">
                            <div class="col-sm-12 px-2 py-1">
                                <div class="input-group">
                                    <div class="input-group-prepend w-65">
                                        <div class="input-group-text px-1 w-100">
                                            <i
                                                class="fa fa-{{type.type == 'BILL' ? 'money' : 'circle'}} c-{{getIconColor('mxn', type.type | lowercase, type.value)}} tx-16 mr-2"></i>
                                            <span class="lh--9">{{type.value | currency}}</span>
                                        </div>
                                    </div>
                                    <input type="text" (keypress)="validator.isInteger($event);" maxlength="3" class="form-control form-control-sm" placeholder="0" [(ngModel)]="type.quantity" (keyup)="updateAmount()">
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="col-md-6">
                    <div class="text-primary"><span class="badge badge-primary-light"><b>USD</b></span></div>
                    <ng-container *ngFor="let type of denomUSD.reverse()">
                        <div class="row mt-1">
                            <div class="col-sm-12 px-2 py-1">
                                <div class="input-group">
                                    <div class="input-group-prepend w-65">
                                        <div class="input-group-text px-1 w-100">
                                            <i
                                                class="fa fa-{{type.type == 'BILL' ? 'money' : 'circle'}} c-{{getIconColor('usd', type.type | lowercase, type.value)}} tx-16 mr-2"></i>
                                            <span class="lh--9">{{type.value | currency}}</span>
                                        </div>
                                    </div>
                                    <input type="text" (keypress)="validator.isInteger($event);" maxlength="3" class="form-control form-control-sm" placeholder="0" [(ngModel)]="type.quantity" (keyup)="updateAmount()">
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<div class="modal-footer justify-content-between" *ngIf="cashRegister">
    <button class="btn btn-danger" (click)="userService.user.cashRegisterDefault?this.close():cashRegister=null;">Cancelar</button>
    <button class="btn btn-success"  [disabled]="disableBtn()"  (click)="assign()">Abrir Caja</button>
</div>

<ng-template>
    <div class="card _pay px-3 mb-3">
        <ng-container  *ngFor="let item of denomArray; index as i">
            <div class="row pad">
                <div class="col-11">
                    <div class="row">
                        <div class="col-7 p-0">
                            <!-- <span class="badge badge-primary-light my-auto mr-2">MXN</span> -->
                            <div class="input-group">
                                <ng-select class="form-control muted" [clearable]="false" [(ngModel)]="item.currency" [items]="['MXN', 'USD']" (change)="item.denomination = null"></ng-select>
                                <ng-select class="form-control" [(ngModel)]="item.denomination" placeholder="Denominación" (change)="updateAmount()">
                                    <ng-option [value]="type" *ngFor="let type of (item.currency == 'MXN' ? denomMXN : denomUSD)"><i class="fa fa-{{type.type == 'BILL' ? 'money' : 'circle'}} c-{{getIconColor(item.currency | lowercase, type.type | lowercase, type.value)}} mr-1"></i> {{type.value | currency}}</ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-5 pl-1 pr-0">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <!-- <i class="fa fa-circle"></i> -->
                                        <span class="op-7 tx-12 lh--9">Cantidad</span>
                                    </div>
                                </div>
                                <input type="number" class="form-control" [(ngModel)]="item.amount" placeholder="00" (change)="updateAmount()">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-1 pr-0 pl-1  d-flex justify-content-center cursor-pointer" (click)="denomArray.splice(i,1); updateAmount()" *ngIf="i || denomArray.length - 1">
                    <i class="fa fa-trash text-danger my-auto"></i>
                </div>
            </div>
        </ng-container>
        <div class="row d-flex justify-content-between py-2 px-3">
            <!-- <span class="text-muted">{{denomArray.length}} forma(s) de pago</span> -->
            <span></span>
            <span class="text-primary cursor-pointer"><b (click)="addDenomination()">Agregar denominación <i class="fa fa-plus ml-2"></i></b></span>
        </div>
    </div>
</ng-template>

<ng-template #modalAuth let-modal>
    <app-modal-auth [reason]="reasonAuth" (onSuccess)="assign($event); modal.dismiss()" (onClose)="modal.dismiss()"></app-modal-auth>
</ng-template>