import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'

@Pipe({
  name: 'ageByBirthday'
})
export class ageByBirthdayPipe implements PipeTransform {
  public language: Object = {
    "ES": {
      DAY: "Dia(s)",
      MONTH: "Mes(es)",
      YEAR: "Año(s)" 
    },
    "EN": {
      DAY: "DAY",
      MONTH: "MONTH",
      YEAR: "YEAR"
    }
  };
  
  constructor(){}

  transform(date, locale = "ES"): string {  
    if (!date) return "-"
    let ageCalculated = moment().diff(date,"years");   
    if (ageCalculated == 0) {
      ageCalculated = moment().diff(date, "months");
      if (ageCalculated == 0) {
          ageCalculated = moment().diff(date, "days");
          
          return ageCalculated + ` ${this.language[locale]['DAY']}`;
          
      }else{
        return ageCalculated + ` ${this.language[locale]['MONTH']}`;
      }
    }else{ 
      return ageCalculated + ` ${this.language[locale]['YEAR']}`;
    }
  }
}
 