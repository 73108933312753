<mk-loader *ngIf="isLoading" class="loader-spinner"></mk-loader>
<!-- <div class="modal-header">
  <h6 class="modal-title" id="modal-basic-title">
     Cambio de credenciales
  </h6>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div> -->
<div class="modal-body"> 
    <div class="row border-bottom pb-3 mb-3">
        <div class="col-12">
          <h3 class="text-primary m-0"><i class="fa fa-lock op-5"></i> Cambio de credenciales</h3>
          <span class="text-muted">Registra una nueva <b>contraseña</b></span>
        </div>
    </div>
    <div class="row pb-2 mb-2">
      <div class="col-12">
        <h6 class="m-0 text-danger">La nueva contraseña debera de contener por lo menos 2 digitos y 4 letras</h6>
      </div>
  </div>
    <form [formGroup]="form" novalidate>
        <div class="row">
            <div class="col-6">
                <h6>Contraseña <span class="text-danger">*</span></h6>
                <input type="password" class="form-control" placeholder="**********" formControlName="newPass" maxlength="16">  
                <small *ngIf="validatorForm.campoEsValido('newPass',form)" class="text-danger">Campo requerido<br>Minimo 4 letras y 2 digitos</small>
            </div>
            <div class="col-6">
                <h6>Confirmar contraseña <span class="text-danger">*</span></h6>
                <input type="password" class="form-control" placeholder="**********" formControlName="confirmNewPass" maxlength="16">  
                <small *ngIf="validatorForm.campoEsValido('confirmNewPass',form)" class="text-danger">La contraseña es requerida o no coicide.</small>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-danger" (click)="close()">Cancelar</button>
    <button type="button" class="btn btn-success" (click)="submit()"><i class="fa fa-check mr-2"></i> Guardar</button>
</div>