import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/shared/services/alert.service';
import { BadgetService } from 'src/app/shared/services/badget.service';
import { CryptoSharkService } from 'src/app/shared/services/crypto-shark.service';
import { FiltersDatatableService } from 'src/app/shared/services/filters-datatable.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { CallService } from 'src/app/shared/services/http/call-service';
import { ProgramedService } from 'src/app/shared/services/http/programed.service';
import { UrgencyService } from 'src/app/shared/services/http/urgency.service';
import { ProgrammedServiceService } from 'src/app/shared/services/programmed-service.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { TableService } from 'src/app/shared/services/table.service';
import { UrgenciesService } from 'src/app/shared/services/urgencies.service';
import { UserService } from 'src/app/shared/services/user.service';
import Swal from 'sweetalert2';
import { IFilter } from './../../interfaces/iFilter';

@Component({
  selector: 'app-panel-urgencies',
  templateUrl: './panel-urgencies.component.html',
  styleUrls: ['./panel-urgencies.component.scss']
})
export class PanelUrgenciesComponent implements OnInit {

  public isLoading: boolean = false;
  public activeCalls: any; 
  public DT_Urgencies_Calls: MatTableDataSource<any>;
  public DT_Urgencies_Services: MatTableDataSource<any>;
  public urgencysCount = 0;
  public displayColumnsCalls: string[] = ['date', 'dateCreate','receibedBy','description', 'processType', 'attendedByUser', 'attendedByDate', 'attend', 'action'];
  public displayColumnsServices: string[] = ['folio', 'dateProcess', 'created', 'name', 'phone', 'address', 'attended', 'dateAttended', 'typePay', 'attend', 'action'];
  public defaultFilterPredicate;
  public searchField = null;
  public subscribePanel: Subscription;

  public filters: IFilter[] = [
    { name: "Sin Atender", type: "CREATED", active: true },
    { name: "Atendidos", type: "ATTENDED", active: true },
    // { name: "Finalizadas", type: "PROCESSED", active: false },
  ];


  public modalOptions: { _id: any, item, module } = { _id: null, item: null, module: "CALLS" }
  public callForm;

  public close = () => {this.modal.dismissAll()}

  public statusColors = {
    CREATED: "#ce0e5e",
    ATTENDED: "#7447c7",
    PROCESSED: "#25a775",
  }
  public pdf = null;

  @ViewChild('PaginatorServices') paginatorServices: MatPaginator;
  @ViewChild('PaginatorCalls') paginatorCalls: MatPaginator;
  @ViewChild('modalView') modalView: NgbModal
  @ViewChild('SortServices') sortServices: MatSort;
  @ViewChild('SortCalls') sortCalls: MatSort;

  
  constructor(
    public tableService: TableService,
    public urgencyService: UrgencyService,
    public programedServiceServices: ProgrammedServiceService,
    private cryptoService: CryptoSharkService,
    public userService:UserService,
    private socketService: SocketService,
    public badgetService: BadgetService,
    private filterService: FiltersDatatableService,
    public callService: CallService,
    public alertService:AlertService,
    public modal: NgbModal,
    private programedService: ProgramedService,
    private httpService: HttpService,
    private sanitazer: DomSanitizer,
    private urgenciesService: UrgenciesService
    ) { 
      this.DT_Urgencies_Calls = new MatTableDataSource;
      this.DT_Urgencies_Services = new MatTableDataSource;

    this.subscribePanel = this.urgenciesService.openPanel.subscribe(async () => {
      this.searchField = null;
      await this.getDataFiltered();
    });

    this.socketService.listenData(`changeUrgency`).subscribe(async (data) => {      
      await this.getUrgencys();
    });
    }

  ngOnDestroy(){
    this.subscribePanel.unsubscribe();
  }
  

  async ngOnInit() {
    this.searchField = null;
    await this.getUrgencys();
    this.defaultFilterPredicate = this.DT_Urgencies_Calls.filterPredicate;
  }

  private getUrgencys = async () => {
    try {
      this.isLoading = true;
      let data = await this.urgencyService.getUrgents();

      data.calls.forEach(item => {
        const deteComplete = moment(moment(item.date).format("YYYY-MM-DD")+"T"+item.hour, "YYYY-MM-DDTHH:mm").format();//revisar porque lo estan manejando asi ?
        item.dateCreate = item.createdBy.date + "*" + moment(item?.createdBy?.date).format("DD/MMM/YYYY - h:mm a");
        item.createdByUser = item.createdBy?.userName || '';
        item.date = deteComplete + "*" + moment(deteComplete).format("DD/MMM/YYYY - h:mm a");
        item.attendedByUser = item.attendedBy?.nameLogin || 'Sin Atender';
        item.attendedByExtraUser = item.attendedBy?.nameLoginExtra || 'Sin Atender';
        item.attendedByDate = (item.attendedBy?.date) ? (item.attendedBy?.date + "*" + moment(item.attendedBy.date).format("DD/MMM/YYYY - h:mm a")) : 'Sin Atender';
      });

      data.calls = data.calls.filter(element => {return element.status != "PROCESSED"})

      data.services.forEach(element => {
        element.name = this.cryptoService.decrypt(element?.name, this.userService?.login?.company?.dk),
        element.phone = JSON.parse(this.cryptoService.decrypt(element?.phone , this.userService?.login?.company?.dk))?.phone,
        element.dateProcess = element?.date + "*" + moment(element?.date).format("DD/MMM/YYYY - h:mm a"),
        element.created = element?.createdBy?.userName,
        element.address = this.cryptoService.decrypt(element?.location?.address, this.userService?.login?.company?.dk);
        element.attended = element?.attendedBy?.nameLogin ? element?.attendedBy?.nameLogin : "Sin Atender";
        element.dateAttended = element?.attendedBy?.date ? element?.attendedBy?.date + "*" + moment(element?.attendedBy?.date).format("DD/MMM/YYYY - h:mm a") : "Sin Atender"
      });

      data.services = data.services.filter(element => {return element.status != "PROCESSED"})

      //Asignacion de Llamadas
      this.DT_Urgencies_Calls.data = data?.calls || [];
      this.DT_Urgencies_Calls.paginator = this.paginatorCalls;
      this.DT_Urgencies_Calls.sort = this.sortCalls;
      //Asignacion de Servicios
      this.DT_Urgencies_Services.data = data?.services || [];
      this.DT_Urgencies_Services.paginator = this.paginatorServices;
      this.DT_Urgencies_Services.sort = this.sortServices;
      
      this.isLoading = false;

    } catch (error) {
      console.log(error);
      this.isLoading = false;
    }
  }

  public search(event){
    const filtersDefault = [
      { name: "Sin Atender", type: "CREATED", active: true },
      { name: "Atendidos", type: "ATTENDED", active: true },
      // { name: "Finalizadas", type: "PROCESSED", active: true },
    ];
      //const filters: string[] = this.filterService.filtersSelected(this.filters);
      this.DT_Urgencies_Calls.filterPredicate = this.defaultFilterPredicate;
      this.DT_Urgencies_Services.filterPredicate = this.defaultFilterPredicate;

      this.filters = filtersDefault;
      //this.DT_Services.filter = 'inicializado'
  
      const filterValue = (event.target as HTMLInputElement).value;
      this.DT_Urgencies_Calls.filter = filterValue.trim().toLowerCase();
      this.DT_Urgencies_Services.filter = filterValue.trim().toLowerCase();

  }


  public filterDataByStatus = () => {
    const filters: string[] = this.filterService.filtersSelected(this.filters)
    // generamos el filtro dentro de la dt_table en base a los filtros activos
    this.DT_Urgencies_Calls.filterPredicate = (data: any) => this.filterService.dataFiltered(data, filters)
    this.DT_Urgencies_Services.filterPredicate = (data: any) => this.filterService.dataFiltered(data, filters)

    // inicializamos
    this.DT_Urgencies_Calls.filter = 'inicializado'
    this.DT_Urgencies_Services.filter = 'inicializado'

  }

  public processCallSwal = (item) => {
    Swal.fire({
      title: '¿Desea procesar esta llamada?',
      text: `lugar: ${item.description}`,
      icon: 'info',
      showDenyButton: true,
      confirmButtonText: 'Si, Procesar!',
      denyButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) await this.processCall(item._id)
    });
  }

  private processCall = async (id) => {
    const data = { id }
    await this.callService.processCall(data).then((response) => {
      this.alertService.successToast(response.message);
      this.getDataFiltered()
    }).catch((error) => {
      this.alertService.infoToast(error.error.message)
    })
  }


  public getDataFiltered = async () => {
    await this.getUrgencys()
    this.filterDataByStatus()
  }

  public undoCallSwal = (item) => {
    Swal.fire({
      title: '¿Regresar el estatus a sin atender?',
      text: 'Se eliminara la fecha y usuario de quien atendio la llamada.',
      icon: 'info',
      showDenyButton: true,
      confirmButtonText: 'Cambiar',
      denyButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) await this.unatendedCall(item._id)
    });
  }

  private unatendedCall = async (id) => {
    const data = { id }
    await this.callService.unatendedCall(data).then((response) => {
      this.alertService.successToast(response.message);
      this.getDataFiltered()
    }).catch((error) => {
      this.alertService.infoToast(error.error.message);
    })
  }

  openModal(template, size, data) {
    !data || (this.modalOptions.item = data)    
    this.modal.open(template, { size: size })
  }

  public undoServiceSwal = (item) => {
    Swal.fire({
      title: '¿Regresar el estatus a sin atender?',
      text: 'Se eliminara la fecha y usuario de quien atendio la llamada.',
      icon: 'info',
      showDenyButton: true,
      confirmButtonText: 'Aceptar',
      denyButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) await this.unatendedService(item._id)
    });
  }

  private unatendedService = async (id) => {
    const data = { id }
    await this.programedService.unattendedprogramedService(data).then(async (response) => {
      this.alertService.successToast(response.message);
      await this.getUrgencys();
    }).catch((error) => {
      this.alertService.infoToast(error.error.message);
    })
  }

  public processServiceSwal = (item) => {
    Swal.fire({
      title: '¿Desea procesar este servicio?',
      text: `lugar: ${item?.description || ""}`,
      icon: 'info',
      showDenyButton: true,
      confirmButtonText: 'Si, Procesar!',
      denyButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed){
        item.id = item._id
        this.programedService.processprogramedService(item).then((resp) => {
          this.alertService.successToast("Servicio Finalizado")
        }).catch(error => { 
          console.log(error) 
        })
      }
    });
  }

  public async printQuotation(id){
    try {
      this.openModal(this.modalView, 'lg', null);

      this.isLoading = true;
      if (id) {
        let response = await this.httpService.getBlob(`api/quotations/print/${id}`,"application/json").toPromise();
        const url = URL.createObjectURL(response);
        this.pdf = this.sanitazer.bypassSecurityTrustResourceUrl(url);
        this.isLoading = false;

      }else{
        this.alertService.infoToast("El servicio no cuenta con una cotizacion");
        this.isLoading = false;
      }


    } catch (error) {
      console.log(error);
    }
  }
}
