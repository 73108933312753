import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'; 
import { DomSanitizer } from '@angular/platform-browser';
import { HttpService } from 'src/app/shared/services/http.service';

@Component({
  selector: 'modal-report',
  templateUrl: './modal-report.component.html',
  styleUrls: ['./modal-report.component.scss']
})
export class ModalReportComponent implements OnInit {
 
 
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Input() modalOptions: any = null; 
  public startDate;
  public endDate;

  public pdf = null;
  public isLoading: boolean = false;
  constructor(private httpService: HttpService,
              private sanitizer: DomSanitizer) { }

  async ngOnInit(){  
    this.typeOfPdf();  
  }


  typeOfPdf(){ 
    switch (this.modalOptions.tipoModal) {
      case 'mensual-billing-report':
        this.reportInvoiceByMonth();
        break;
      case 'medic-report':
        this.reportMedic();
        break;
      case 'pending-accounts-report':
        this.reportPendingAccount();
        break;
      case 'cashCut':
        this.reportCashCut();
        break;
      case 'cashCutByBranch':
          this.reportCashCutBranch();
          break;
      case 'cashCutById':
        this.reportCashCutId();
        break;
      case 'byAPI':
        this.viewByAPI();
        break;
      case 'priceList':
        this.reportPriceList();
        break;
      default:
        break;
    }
    // if(this.modalOptions.tipoModal === 'mensual-billing-report'){
    //   return this.reportInvoiceByMonth();
    // }
    // else if(this.modalOptions.tipoModal === 'medic-report'){
    //   return this.reportMedic();
    // }
    // else if(this.modalOptions.tipoModal === 'pending-accounts-report'){
    //   return this.reportPendingAccount();
    // }
    // else if(this.modalOptions.tipoModal === 'cashCut'){
    //   return this.reportCashCut();
    // }
    // else if(this.modalOptions.tipoModal === 'cashCutByBranch'){
    //   return this.reportCashCutBranch();
    // }
    // else if(this.modalOptions.tipoModal === 'cashCutById'){
    //   return this.reportCashCutId();
    // }
  }


  reportPendingAccount(){
    this.isLoading = true;
      this.httpService.getBlob(`api/reports/cuentasporcobrarpdf/${this.modalOptions.startDate}/${this.modalOptions.endDate}/${this.modalOptions.type}`,"application/json").toPromise()
      .then((resp : any )=> {
        let url = URL.createObjectURL(resp);   
        this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        console.log(url);
        this.isLoading = false;
      }).catch((err:any) => {
        this.isLoading = false;
        console.log(err.message);
      })
  }

  reportInvoiceByMonth(){ 
    this.isLoading = true;
      this.httpService.postBlob(`api/reports/mensualBilling/pdf`,{tickets: this.modalOptions.tickets, start: this.modalOptions.start, end: this.modalOptions.end},"application/json").subscribe(async (resp)=>{
        let url = URL.createObjectURL(resp);   
        this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this.isLoading = false;
      },error => {
        this.isLoading = false;
        console.log(error.message);
      })
  }

  // Servicio para generar el reporte de tipo pdf del medico.
   reportMedic(){ 
      this.isLoading = true;
      this.httpService.getBlob(`api/reports/medicospdf/${this.modalOptions.startDate}/${this.modalOptions.endDate}/${this.modalOptions.seller}`,"application/json").toPromise()
      .then((resp : any )=> {
        let url = URL.createObjectURL(resp);   
        this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this.isLoading = false;
      }).catch((err:any) => {
        this.isLoading = false;
        console.log(err.message);
      })
   
  }

  reportCashCut(){
    this.isLoading = true;
      this.httpService.getBlob(`api/reports/cashCutPDF/${this.modalOptions.startDate}/${this.modalOptions.endDate}`,"application/json").toPromise()
      .then((resp : any )=> {
        let url = URL.createObjectURL(resp);   
        this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this.isLoading = false;
      }).catch((err:any) => {
        this.isLoading = false;
        console.log(err.message);
      })
  }

  public reportCashCutBranch(): void{
    this.isLoading = true;
      this.httpService.getBlob(`api/reports/cashCutByBranchPDF/${this.modalOptions.branch}/${this.modalOptions.startDate}/${this.modalOptions.endDate}`,"application/json").toPromise()
      .then((resp : any )=> {
        let url = URL.createObjectURL(resp);   
        this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this.isLoading = false;
      }).catch((err:any) => {
        this.isLoading = false;
        console.log(err.message);
      })
  }

  public reportCashCutId(): void{
    this.isLoading = true;
      this.httpService.getBlob(`api/reports/cashCutIdPDF/${this.modalOptions.cashCut}`,"application/json").toPromise()
      .then((resp : any )=> {
        let url = URL.createObjectURL(resp);   
        this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this.isLoading = false;
      }).catch((err:any) => {
        this.isLoading = false;
        console.log(err.message);
      })
  }

  public reportPriceList(){
    this.isLoading = true;
      this.httpService.postBlob(`api/listprice/report`,{ selected: this.modalOptions.listSelected },"application/json").toPromise()
      .then((resp : any )=> {
        let url = URL.createObjectURL(resp);   
        this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this.isLoading = false;
      }).catch((err:any) => {
        this.isLoading = false;
        console.log(err.message);
      })
  }

  private viewByAPI(){
    this.isLoading = true;
      this.httpService.getBlob(this.modalOptions.API,"application/json").toPromise()
      .then((resp : any )=> {
        let url = URL.createObjectURL(resp);   
        this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this.isLoading = false;
      }).catch((err:any) => {
        this.isLoading = false;
        console.log(err.message);
      })
  }

  
  closeModal(){ this.onClose.emit() }

}
