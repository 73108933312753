<div class="modal-header">
    <h6 *ngIf="modalOptions.type == 'VIEW'" class="modal-title" id="modal-basic-title">Detalle de Médico</h6>
    <h6 *ngIf="modalOptions.type == 'ADD'" class="modal-title" id="modal-basic-title">Agregar Médico</h6>
    <h6 *ngIf="modalOptions.type == 'EDIT'" class="modal-title" id="modal-basic-title">Editar Médico</h6>

    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<mk-loader *ngIf="isLoading" class="loader-spinner" ></mk-loader>


<form #permissions="ngForm" novalidate>
    <div class="modal-body py-0">
        <form [formGroup]="FormMedic" novalidate>
            <fieldset [disabled]="blockEdition">
                <div class="row">
                    <div class="col-sm-12 col-md-7 py-3">
                        <div class="row">
                            <div class="col-12">
                                <h6>Nombre <span class="text-danger">*</span></h6>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Nombre" formControlName="name">
                                </div>
                                <small *ngIf="validateForm.campoEsValido('name', FormMedic)" class="text-danger">Campo requerido</small>
                            </div>
                            <div class="col-2 text-right" *ngIf="false">
                                <h6 class="mb-3">Estado</h6>
                                <label class="custom-switch my-auto cursor-pointer">
                                    <input type="checkbox" name="custom-switch-checkbox" formControlName="active" name="as" class="custom-switch-input">
                                    <span class="custom-switch-indicator"></span>
                                </label>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-6">
                                <h6>Calle y No.</h6>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Calle y No." formControlName="address" >
                                </div>
                                <!-- <small *ngIf="false" class="text-danger">Campo requerido</small> -->
                            </div>
                            <div class="col-6">
                                <h6>Colonia</h6>
                                <div class="input-group">
                                    <ng-select class="form-control" placeholder="Colonia" 
                                        [clearable]="true"  
                                        [items]="coloniesData"
                                        bindLabel="name" bindValue="_id"
                                        formControlName="colonie"
                                    ></ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">

                            <div class="col-12 mt-2">
                                <h6>Teléfono(s)</h6>
                                <!-- <ng-container *ngIf="modalOptions.type == 'ADD'"> -->
                                    <div class="input-group">
                                        <ng-select class="form-control px-1 text-left" [clearable]="false" [(ngModel)]="typePhone" [disabled]="blockEdition" (change)="changePhoneType($event)"
                                        [ngModelOptions]="{standalone: true}" placeholder="Tipo de telefono">
                                            <ng-option value="CEL">Celular</ng-option>
                                            <ng-option value="HOME">Casa</ng-option>
                                            <ng-option value="OFFICE">Oficina</ng-option>
                                        </ng-select>
            
                                        <input type="text" class="form-control" 
                                            (keypress)="validator.isInteger($event);"  
                                            [placeholder]="typePhone!='OFFICE'?'(___)___-____':'(___)___-____;__'" 
                                            [(ngModel)]="inpPhone"
                                            [ngModelOptions]="{standalone: true}"
                                            [textMask]="{mask: phoneMask, guide: false}" 
                                            [disabled]="blockEdition"
                                            >
                                            
                                        <div class="input-group-append">
                                            <button type="button" class="btn btn-dark" (click)="addPhones()" [disabled]="inpPhone?.length < 9"><i class="fa fa-plus mr-2"></i>Añadir</button>
                                        </div>
                                    </div>
                                <!-- </ng-container>  -->
                            </div>   
                            <div class="col-12 d-flex flex-wrap pt-2" *ngIf="phones?.length">
                                <ng-container *ngFor="let item of phones; index as i">
                                    <span class="_pill badge-{{item.primary ? 'primary-light' : 'medium-light'}} d-flex m-0 mb-1 mr-1" >
                                        <div class="d-flex cursor-pointer" (click)="primaryPhone(true, i)">
                                            <span *ngIf="item.primary" style="margin-left: -5px;"><i class="fa fa-star mr-1 text-warning"></i></span>
                                            <b>{{item.phone}} <i class="fa fa-{{item.type == 'HOME' ? 'home':(item.type == 'OFFICE'?'building':'mobile')}} ml-1"></i></b>
                                        </div>
                                        <i class="fa fa-close ml-2 my-auto cursor-pointer text-danger" (click)="phones.splice(i,1)"></i>
                                    </span>
                                </ng-container>
                            </div>
                        </div>
                        
                        <div class="row mt-2">
                            <div class="col-12">
                                <h6>Correo(s)</h6>
                                <!-- <ng-container *ngIf="modalOptions.type == 'ADD'"> -->
                                    <div class="input-group">
                                        <input #inpEmail type="text" class="form-control" placeholder="Correo" maxlength="40" [disabled]="blockEdition"> 
                                        <ng-select class="form-control" placeholder="Dominio" [disabled]="blockEdition"
                                            [clearable]="false"  
                                            [items]="domainsData"
                                            bindLabel="name" bindValue="name"
                                            [(ngModel)]="domain"
                                            [ngModelOptions]="{standalone: true}"
                                            [disabled]="blockEdition"
                                        >
                                        </ng-select>
                                        <div class="input-group-append"> 
                                            <button type="button" class="btn btn-dark"  (click)="modalOptions.type != 'VIEW' ? addEmails(inpEmail.value, domain):''" [disabled]="!inpEmail.value || !domain"><i class="fa fa-plus mr-2"></i>Añadir</button>
                                        </div>
                                    </div>
                                <!-- </ng-container>  -->
                            </div>
                            <div class="col-12 d-flex flex-wrap pt-2" *ngIf="emails?.length">
                                <ng-container *ngFor="let item of emails; index as i">
                                    <span class="_pill badge-{{item.primary ? 'primary-light' : 'medium-light'}} d-flex m-0 mb-1 mr-1" >
                                        <div class="d-flex cursor-pointer" (click)="primaryMail(true, i)">
                                            <span *ngIf="item.primary" style="margin-left: -5px;"><i class="fa fa-star mr-1 text-warning"></i></span>
                                            <b>{{item.email}}</b>
                                        </div>
                                        <i class="fa fa-close ml-2 my-auto cursor-pointer text-danger" (click)="emails.splice(i,1)"></i>
                                    </span>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-5 py-3 border-left">
                        <div class="row">
                            <div class="col-md-12">
                                <h6>Especialidad</h6>
                                <div class="input-group">
                                    <ng-select class="form-control" placeholder="Especialidad" 
                                        [clearable]="true"  
                                        [items]="specialtiesData"
                                        bindLabel="name" bindValue="_id"
                                        formControlName="specialty"
                                    ></ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-12">
                                <h6>Clinica</h6>
                                <div class="input-group">
                                    <ng-select class="form-control" placeholder="Clinica" 
                                        [clearable]="true"  
                                        [items]="clinicData"
                                        bindLabel="name" bindValue="_id"
                                        formControlName="clinic"
                                    ></ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-6">
                                <h6>Vendedor</h6>
                                <ng-select placeholder="Vendedor" class="form-control"
                                    [clearable]="true"  
                                    [items]="sellerData"
                                    bindLabel="name" bindValue="_id"
                                    formControlName="userSeller"
                                ></ng-select>
                            </div>
                            <div class="col-md-6">
                                <h6>Sucursal</h6>
                                <ng-select placeholder="Sucursal" class="form-control"
                                    [clearable]="true"  
                                    [items]="branchData"
                                    bindLabel="name" bindValue="_id"
                                    formControlName="branch"
                                ></ng-select>
                            </div>
                        </div>
                        <div class="row mt-2 border-top pt-2">
                            <div class="col-6 d-flex justify-content-between">
                                <h6 class="my-auto">Enviar por</h6>
                                <div class="btn-group">
                                    <button type="button" [disabled]="blockEdition" (click)="modalOptions.type != 'VIEW' ? changeSendMethod('email',!sendMedic.email): ''" class="btn btn-sm btn-icon btn-{{sendMedic?.email ? 'success' : 'light'}} mr-2"><i class="fa fa-envelope"></i></button>
                                    <button type="button" [disabled]="blockEdition" (click)="modalOptions.type != 'VIEW' ? changeSendMethod('web',!sendMedic.web): ''" class="btn btn-sm btn-icon btn-{{sendMedic?.web ? 'success' : 'light'}}"><i class="fa fa-laptop"></i></button>
                                </div>
                            </div>
                            <div class="col-sm-6 d-flex" style="padding-top: 5px;">
                                <label class="d-flex my-auto w-100 justify-content-between curcor-pointer ">
                                    <h6 class="my-auto">Reporte mensual</h6>
                                    <label class="custom-switch cursor-pointer my-auto">
                                        <input type="checkbox" name="custom-switch-checkbox" (change)="changeMensualReport($event.target.checked)" formControlName ="montlyReport" class="custom-switch-input"/>
                                        <span class="custom-switch-indicator"></span>
                                    </label>
                                </label>
                            </div>
                        </div>
                        <div class="row mt-3 pt-2 border-top">
                            <div class="col-12" *ngIf="!FormMedic.value.montlyReport">
                                <h6>Descuento Especial</h6>
                                <div class="input-group">
                                    <input type="text" class="form-control" maxlength="5" (keypress)="validator.isNumeric($event);" placeholder="Descuento especial" formControlName="discountSpecial">
                                    <div class="input-group-append">
                                        <div class="input-group-text border-right border-left-0"><i class="fa fa-percentage lh--9 op-6"></i></div>
                                    </div>
                                </div>
                                <small *ngIf="validateForm.campoEsValido('discountSpecial', FormMedic)" class="text-danger">Máximo 100 y Minímo 0</small>
                            </div>
                            <div class="col-12 mt-2" *ngIf="!FormMedic.value.montlyReport">
                                <h6>Descuento Rutina</h6>
                                <div class="input-group">
                                    <input type="text" class="form-control" maxlength="5"  (keypress)="validator.isNumeric($event);" placeholder="Descuento rutina" formControlName="discountRoutine">
                                    <div class="input-group-append">
                                        <div class="input-group-text border-right border-left-0"><i class="fa fa-percentage lh--9 op-6"></i></div>
                                    </div>
                                </div>
                                <small *ngIf="validateForm.campoEsValido('discountRoutine', FormMedic)" class="text-danger">Máximo 100 y Minímo 0</small>
                            </div>
                            <div class="col-12 mt-2" *ngIf="FormMedic.value.montlyReport">
                                <h6>Porcentaje Medico</h6>
                                <div class="input-group">
                                    <input type="text" class="form-control" maxlength="5"  (keypress)="validator.isNumeric($event);" placeholder="Porcentaje medico" formControlName="medicPorcent" >
                                    <div class="input-group-append">
                                        <div class="input-group-text border-right border-left-0"><i class="fa fa-percentage lh--9 op-6"></i></div>
                                    </div>
                                </div>
                                <small *ngIf="validateForm.campoEsValido('medicPorcent', FormMedic)" class="text-danger">Máximo 100 y Minímo 0</small>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </div>
</form>
<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-dark" (click)="close()">Cerrar</button>
    <button type="button" class="btn btn-success" (click)="submit()" *ngIf="modalOptions.type != 'VIEW' && !blockEdition">Guardar</button>
</div>