import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class UrgencyService {

  constructor(
    private http: HttpService
  ) { }

  public getUrgents = (): Promise<any> => this.http.get(`api/calls/listUrgent`).toPromise();

}
