import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  notifications: any[] =[
    {
        "origin": "WEB",
        "title": "FINALIZAR",
        "description": "Nueva solicitud de finalización.",
        "date": "2022-04-21T17:46:59.674Z",
        "page": "TICKET",
        "status": "AUTH_PEN_ADM",
        "views": []
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
