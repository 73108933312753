<mk-loader *ngIf="isLoading" class="loader-spinner" style="z-index: 120"></mk-loader>
<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title"><i class="fa fa-phone mr-2"></i> {{modalOptions.type === 'EDIT' ? "Editar" : modalOptions.type === 'VIEW' ? "Ver" : "Nueva"}} Llamada</h6>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
 
<div class="modal-body" [class.pt-0]="modalOptions.type == 'VIEW'">
    <form [formGroup] ="callForm">
        <div *ngIf="modalOptions.type == 'VIEW'" class="row mb-2 bg-info-2" style="margin: 0 -1rem;">
            <div class="col-sm-12 py-2 text-center">
                <span class="text-white"><i class="fa fa-info-circle mr-1"></i> Este registro se creo por medio del sistema y no se puede editar</span>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-9">

                <div class="d-flex justify-content-between mt-2">
                    <h6>Lugar  <span class="text-danger">*</span></h6>
                    <small class="text-info d-block" *ngIf="true">{{callForm?.value?.description?.length|| 0}} / 500</small>
                </div>
                
                <div class="input-group">
                    <textarea maxlength="500" rows="4" class="form-control" placeholder="Ingresa lugar de llamada"  formControlName="description"></textarea>
                    <!-- <input type="text" class="form-control" formControlName ="description" placeholder="Ingresa lugar de llamada"> -->
                </div>
                <small class="tx-danger" *ngIf="fieldIsValid.campoEsValido('description', callForm)">Lugar requerido. &nbsp; Max. 500 caracteres</small>
            </div>

            <div class="col-sm-3">
                <div class="text-center mt-4" *ngIf="modalOptions.type == 'VIEW' && modalOptions?.permissions['EDIT']">
                    <button class="btn btn-dark btn-block" [ngbTooltip]="'Click para ir a llamada origen'" placement="left" (click)="loadOriginCall()"><i class="fa fa-phone mr-1"></i> Origen</button>
                </div>

                <div class="d-flex flex-column text-center mt-2">
                    <h6>Urgencia</h6>
                    <label class="custom-switch m-auto" [class.cursor-pointer]="modalOptions.type != 'VIEW'">
                        <input type="checkbox" name="custom-switch-checkbox" formControlName ="urgent" class="custom-switch-input"/>
                        <!-- <mat-checkbox name="custom-switch-checkbox" formControlName="urgent"/> -->
                        <span class="custom-switch-indicator"></span>
                    </label>
                </div>

                <div class="d-flex flex-column text-center mt-4">
                    <h6>Bloquear</h6>
                    <label class="custom-switch m-auto" [class.cursor-pointer]="modalOptions.type != 'VIEW'">
                        <input type="checkbox" name="custom-switch-checkbox" formControlName ="block" class="custom-switch-input"/>
                        <span class="custom-switch-indicator"></span>
                    </label>
                </div>
                
            </div>


        </div>
        <div class="row mt-3">
            <div class="col-sm-{{callForm.value.block ? 8 : 12}}">
                <h6>Tipo de proceso  <span class="text-danger">*</span></h6>
                <div class="input-group">
                    <ng-select type="text" class="form-control" [items]="processTypeList" bindLabel="name" placeholder="selecciona tipo de proceso" bindValue="_id" formControlName="processTypeId" placeholder="Ingresa tipo de proceso"></ng-select>
                </div>
                <small class="tx-danger" *ngIf="fieldIsValid.campoEsValido('processTypeId', callForm)">Tipo de proceso requerido.</small>
            </div>
            <div class="col-sm-4" *ngIf="callForm.value.block">
                <h6>Fecha fin</h6>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <i class="fe fe-calendar lh--9 op-6"></i>
                        </div>
                    </div>
                    
                    <input class="form-control" placeholder="DD/MM/YYYY" readonly
                        style="border-top-right-radius: 11px !important; border-bottom-right-radius: 11px !important;"
                        ngxDaterangepickerMd 
                        opens="left"
                        [ngModelOptions]="{standalone: true}" 
                        [(ngModel)]="selectedDateEnd"
                        [locale]="locale" 
                        [autoApply]="true"
                        [minDate]="selectedDate.startDate"
                        [singleDatePicker]="true"
                        [showCustomRangeLabel]="true"
                        [disabled]="modalOptions.type == 'VIEW'"
                        />
                </div>
                <small class="tx-danger" *ngIf="fieldIsValid.campoEsValido('date', callForm)">Fecha requerida.</small>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-5">
                <h6>Fecha de proceso</h6>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <i class="fe fe-calendar lh--9 op-6"></i>
                        </div>
                    </div>

                    <!-- <input matInput class="form-control" formControlName="date"  placeholder="DD/MM/YYYY"> -->
                    <input class="form-control" placeholder="DD/MM/YYYY" readonly
                        style="border-top-right-radius: 11px !important; border-bottom-right-radius: 11px !important;"
                        ngxDaterangepickerMd 
                        opens="left"
                        [ngModelOptions]="{standalone: true}" 
                        [(ngModel)]="selectedDate"
                        [locale]="locale" 
                        [autoApply]="true"
                        [minDate]="today"
                        [singleDatePicker]="true"
                        [showCustomRangeLabel]="true"
                        [disabled]="modalOptions.type == 'VIEW'"
                        (change)="changeDateProcess()"
                        />

                </div>
                <small class="tx-danger" *ngIf="fieldIsValid.campoEsValido('date', callForm)">Fecha requerida.</small>
            </div>
            <div class="col-sm-4">
                <h6>Hora  <span class="text-danger">*</span></h6>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <i class="fa  fa-clock lh--9 op-6"></i>
                        </div>
                    </div>
                    <input type="time" formControlName="hour"  class="form-control" placeholder="HH:MM">
                    <!-- <ngx-material-timepicker #picker  ></ngx-material-timepicker> -->
                </div>
                <small class="tx-danger" *ngIf="fieldIsValid.campoEsValido('hour', callForm)">Hora requerida.</small>
            </div>
            <div class="col-sm-3 d-flex flex-column text-center">
                <h6>Programar</h6>
                <label class="custom-switch m-auto" [class.cursor-pointer]="modalOptions.type != 'VIEW'">
                    <input type="checkbox" name="custom-switch-checkbox" formControlName="program" #schedule class="custom-switch-input"/>
                    <!-- <input type="checkbox" name="custom-switch-checkbox" formControlName ="program" class="custom-switch-input" [checked]="false"/> -->
                    <!-- <mat-checkbox name="custom-switch-checkbox" formControlName="urgent"/> -->
                    <span class="custom-switch-indicator"></span>
                </label>
            </div>
        </div>
        <ng-container *ngIf="callForm.value.program"> <!-- SI ESTA PROGRAMADO -->
            <div class="row mt-3 border-top">
                <div class="col-sm-12 text-center"><h4 class="my-3 text-primary">Repetir días</h4></div>
            </div>
            <div class="row">
                <div class="col-sm-12 d-flex justify-content-around">
                    <ng-container *ngFor="let day of daysOfWeek; let i = index">
                        <div class="d-flex flex-column text-center">
                            <span class="text-muted">{{day}}</span>
                            <label class="ckbox mb-3" [class.cursor-pointer]="modalOptions.type != 'VIEW'"><input type="checkbox" [value]="i + 1" (change)="onCheckboxChange($event)" [disabled]="!schedule.checked" [checked]="existsDay(i+1)"><span></span></label>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div clas="row">
                <div class="col-sm-12"><small class="tx-danger" *ngIf="fieldIsValid.campoEsValido('days', callForm)">Se requiere que al menos se seleccione un día.</small></div>
            </div>
            <div class="row mt-2">
                <div class="col-sm-12 text-center"><span class="text-info"><i class="fa fa-info-circle"></i> Las llamadas programadas se generan automáticamente para cada dia que se seleccione.</span></div>
            </div>
        </ng-container>
    </form>
</div>

<div class="modal-footer justify-content-between">
       
    <div class="justify-content-between">
        <button class="btn btn-danger" (click)="close()">Cancelar</button>
        <button *ngIf="modalOptions.origin" [ngbTooltip]="'Click para volver a llamada'" placement="right"  class="btn btn-dark ml-3" (click)="goBack()"><i class="fa fa-chevron-left mr-2"></i> Volver</button>
    </div>
     
        <ng-container *ngIf="this.modalOptions.type != 'VIEW'">
            <button class="btn btn-success" (click)="submit()">Guardar</button>
        </ng-container>
        <ng-container *ngIf="false">
            <button class="btn btn-success" (click)="close()">Actualizar</button>
        </ng-container>

</div>