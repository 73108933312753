import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CallService } from 'src/app/shared/services/http/call-service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';

@Component({
  selector: 'app-atend-call',
  templateUrl: './atend-call.component.html',
  styleUrls: ['./atend-call.component.scss']
})

export class AtendCallComponent implements OnInit {
  public isLoading: boolean = false;
  public activeCalls: any;
  public displayColumns: string[] = ['date', 'receivedBy', 'place', 'type', 'attendedBy', 'dateAttended', 'attend', 'action'];
  public userForm;
  public users;
  @Output() onSave: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() modalOptions: any = null;

  public isCreated = (this.modalOptions?.item?.status == "CREATED") ? true : false
  public isAtended = (this.modalOptions?.item?.status == "ATTENDED") ? true : false

  public close = () => { this.onClose.emit() }

  constructor(
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    private callService: CallService,
    private alertService: AlertService,
    public validateForm: ValidateFormsDirective,

  ) { }

  async ngOnInit() {
    this.assignStatus();
    this.formUser();
    await this.getUsers();
  }

  private assignStatus = () => {
    this.isCreated = (this.modalOptions?.item?.status == "CREATED") ? true : false
    this.isAtended = (this.modalOptions?.item?.status == "ATTENDED") ? true : false
  }

  private getUsers = async () => {
    await this.httpService.get(`api/users`).toPromise().then((result) => { this.users = result }).catch((error) => { console.log(error) })
  }

  public atendCall = async () => {
    const obj = Object.keys(this.userForm.value).filter((k) => this.userForm.value[k] != null).reduce((a, k) => ({ ...a, [k]: this.userForm.value[k] }), {});

    if (!this.userForm.valid) {
      this.userForm.markAllAsTouched();
      this.alertService.infoToast("Campos incompletos, verifique porfavor");
      return;
    }

    await this.callService.attendCall(obj).then((resp) => {
      this.alertService.successToast(resp.message)
      this.close()
    }).catch(error => { 
      if(error.status != 403) this.alertService.infoToast(error?.error?.message);
      console.log(error) 
    })
  }

  public proccessCall = async () => {
    const obj = Object.keys(this.userForm.value).filter((k) => this.userForm.value[k] != null).reduce((a, k) => ({ ...a, [k]: this.userForm.value[k] }), {});

    if (!this.userForm.valid) {
      this.userForm.markAllAsTouched();
      this.alertService.infoToast("Campos incompletos, verifique porfavor");
      return;
    }

    await this.callService.processCall(obj).then((resp) => {
      this.alertService.successToast(resp.message)
      this.close()
    }).catch(error => { 
      if(error.status != 403) this.alertService.infoToast(error?.error?.message);
      console.log(error) 
    })
  }

  private formUser = () => {
    this.userForm = this.formBuilder.group({
      id: [this.modalOptions.item._id],
      userId: [null, Validators.compose([Validators.required])],
      userExtraId: [null]
    });
  }





}
