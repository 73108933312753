import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { CryptoSharkService } from 'src/app/shared/services/crypto-shark.service';
import { HttpService } from 'src/app/shared/services/http.service';

@Component({
  selector: 'app-modal-auth',
  templateUrl: './modal-auth.component.html',
  styleUrls: ['./modal-auth.component.scss']
})
export class ModalAuthComponent implements OnInit {

  @Output() onSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input('reason') reason: string = null;

  public authForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private cryptoService: CryptoSharkService,
    private alertServices: AlertService
  ) { }

  ngOnInit(): void {
    this.authForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      // nip: ['', Validators.required],
    });
  }

  close(){this.onClose.emit()}
  
  public async authorize(){
    this.httpService.post(`api/users/auth`, {
      username: this.authForm.controls['username'].value,
      password: this.cryptoService.encryptSha(this.authForm.controls['password'].value),
      // nip: this.cryptoService.encryptSha(this.authForm.controls['nip'].value),
    }).toPromise()
    .then((response: any) => { 
      this.onSuccess.emit(response?.authId)
    }).catch((e) => {
      console.log(e);
      if (e.status == 400) {
        this.alertServices.errorToast(e.error.message)
      }
    })
  }
}
