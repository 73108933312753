import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CallService } from 'src/app/shared/services/http/call-service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProgramedService } from 'src/app/shared/services/http/programed.service';

@Component({
  selector: 'app-modal-call-cancel',
  templateUrl: './modal-call-cancel.component.html',
  styleUrls: ['./modal-call-cancel.component.scss']
})

export class ModalCallCancelComponent implements OnInit {

  public isLoading: boolean = false;
  public cancelForm: FormGroup;

  @Output() onSave: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() modalOptions: any = null;

  public close = () => { this.onClose.emit() }

  constructor(
    private formBuilder: FormBuilder,
    private callService: CallService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private programedService: ProgramedService,

  ) { }

  ngOnInit(): void {
    this.formCancel()
    
  }

  private formCancel = () => {
    this.cancelForm = this.formBuilder.group({
      id: [this.modalOptions?.module == "CALLS" ? this.modalOptions?.item?._id : this.modalOptions?.module == "SERVICE" ? this.modalOptions?._id : "", Validators.compose([Validators.required])],
      motive: ['', Validators.compose([Validators.required])],
      authorizer: [null, Validators.compose([Validators.required])]
    })
  }

  public submit = async (authorizer: any) => {
    try {
      this.cancelForm.patchValue({
        authorizer: authorizer
      });

      if (!this.cancelForm.valid) {
        this.cancelForm.markAllAsTouched();
        
        this.alertService.infoToast("Campos incompletos, verifique porfavor");
        return;
      };

      if (this.modalOptions?.module == "CALLS") {
        //Apunta a Llamadas

        await this.callService.cancelCall(this.cancelForm?.value).then((resp) => {
          this.alertService.successToast(resp.message);
          this.onSave.emit();
        }).catch((error) => {
          this.alertService.infoToast(error.error.message);
        })
      }else if(this.modalOptions?.module == "SERVICE"){
        //Apunta a Servicios Programados
        await this.programedService.cancelprogramedService(this.cancelForm?.value).then((resp) => {
          this.alertService.successToast(resp.message);
          this.onSave.emit();

        }).catch((error) => {
          this.alertService.infoToast(error.error.message);
        })
      }
      
    
    }
    catch (error) {
      console.log(error)
    }
    finally {
      this.isLoading = false
    }
  }

  openModal(modal, size: string = 'sm',) {
    this.modalService.open(modal, { size: size, backdrop: 'static' });
  }

}
