import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ModalStudyComponent } from '../../pages/catalogs/modals/modal-study/modal-study.component';
import { LayoutService } from './layout.service';

@Injectable({
  providedIn: 'root'
})
export class StudiesService {

  public openPanel = new Subject<void>();

  constructor(private layoutCtrl: LayoutService) {
  }

  public async load(){
  }

  public async openModal($event,template){
    this.openPanel.next();
    this.layoutCtrl.emitSidebarNotifyChange($event, template);
  } 
}



