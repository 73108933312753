<mk-loader *ngIf="isLoading" class="loader-spinner" style="z-index: 120"></mk-loader>
<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Reporte</h6>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
 
<div class="modal-body p-0">
  <div class="row">
      <div class="col-12">
        <div class="pdf">
            <ng-container *ngIf="pdf">
              <embed [src]="pdf" class="w-100 h-100">
            </ng-container>
        </div>
      </div>
  </div>
</div>

<div class="modal-footer justify-content-end">
  <button class="btn btn-success" (click)="closeModal()">Aceptar</button>
</div>
