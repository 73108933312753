import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'modal-cancel',
  templateUrl: './modal-cancel.component.html',
  styleUrls: ['./modal-cancel.component.scss']
})
export class ModalCancelComponent implements OnInit {

  constructor() { }
  public modalViewCancelled: any = null;
  @Input() modalOptions: any = null;

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>(); 


  ngOnInit(): void {
    this.modalViewCancelled = this.modalOptions?.item 
  }

  public close(){
    this.onClose.emit();
  }

}
