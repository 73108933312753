<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Agregar Abreviaciones</h6>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body pb-0">
        <form [formGroup]="abbrevForm">
            <div class="row">
                <div class="col-md-3">
                    <h6>Código <span class="text-danger">*</span></h6>
                    <div class="input-group">
                        <input type="text" class="form-control" formControlName="code" placeholder="Código" maxlength="10">
                    </div>
                    <small *ngIf="validateForm.campoEsValido('code', abbrevForm)" class="text-danger ml-1">Ingresa código.</small>

                </div>
                <div class="col-md-9">
                    <h6>Descripción <span class="text-danger">*</span></h6>
                    <div class="input-group">
                        <input type="text" class="form-control" formControlName="description" maxlength="50" placeholder="Descripción">
                        <div class="input-group-append">
                            <!-- <button type="button" class="btn btn-success" (click)="addAbbreb()"><i class="fa fa-plus mr-1"></i>Agregar</button> -->
                            <ng-container *ngIf="!editGuestpencil">
                                <button class="btn btn-block btn-dark" [disabled]="!abbrevForm.valid" (click)="addAbbreb()">
                                    <i class="fa fa-plus mr-2"></i> Agregar
                                </button>

                            </ng-container>
                            <ng-container *ngIf="editGuestpencil">
                                <button class="btn btn-dark" [disabled]="!abbrevForm.valid" (click)="cancelEdit()">
                                <i class="fa fa-undo"></i>
                                </button>
                                <button class="btn btn-success" [disabled]="!abbrevForm?.valid" (click)="saveEdit()">
                                    <i class="fa fa-save mr-2"></i> Guardar
                                </button>
                            </ng-container>
                        </div>
                    </div>
                    <small *ngIf="validateForm.campoEsValido('description', abbrevForm)" class="text-danger ml-1">Ingresa descripción.</small>

                </div>
            </div>
        </form>
    <div class="row mt-3 border-top">
        <div class="col-sm-12 p-0">
            <div class="table-responsive">
                <table mat-table [dataSource]="DT_abreviations" matSort  #Sort="matSort" matSortActive="code" matSortDirection="asc" class="table table-bordered table-striped mb-0 material-table-container">
                    <ng-container matColumnDef="code">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th> 
                        <td mat-cell *matCellDef="let item">
                            <b>{{item?.code}}</b>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th> 
                        <td mat-cell *matCellDef="let item">
                            {{item?.description}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef width="80px"> <div class="text-center">Acción</div> </th> 
                        <td mat-cell *matCellDef="let item"> 
                            <div class="text-center">
                               <button  class="btn btn-info" (click)="editAbbreb(item)"><i class="zmdi zmdi-edit"></i></button>
                               <button class="btn btn-danger" [swal]="deleteSwal" (confirm)="deleteAbbreb(item)" title="Borrar"><i class="fa fa-trash"></i></button>
                            </div>
                         
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
        
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell text-center" colspan="7">{{isLoading?"Cargando ...":"Sin datos encontrados"}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-dark" (click)="close()">Cerrar</button>
    <!-- <button type="button" class="btn btn-success" (click)="close()">Guardar</button> -->
</div>

<!-- SWALS -->
<swal #deleteSwal class="d-none" title="¿Estas seguro de eliminar la abreviacion?" icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>