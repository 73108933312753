import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

/**
 * Generacion de formularios dinamicos
 * 1.- Utiliza la funcion ('genForm') que recive como parametro un array @param validatorArray con los diferentes campos y validadores;
  * Ejemplo:  [
                {
                  fields: [
                  {
                    formControlName: "r_init",
                    target:{
                      validators:{
                        required: true
                      }
                    }
                  }
                ]}
               ];

  * La funcion retornara un objeto de tipo FormGroup que puedes asignar a tu variable local;

* 2.- Llamar funcion ('asignControls') para reasignar Validador a algun control en formulario (en caso de que se quieran afectar, pero hay que considerar que la funcion 'genForm' ya establece los validadores iniciales). 
      Esta funcion recive como parametro @param DinamicForm  el formulario a afectar y un objeto con la info del campo a modificar @param check en el formulario.
      ** NOTA: Si se quieren afectar mas de 1 control con la funcion se debe realizar una iteracion por cada campo del formulario desde donde se mande a llamar.

  * Ejemplo de validadores:
 * *{
 *    formControlName: "r_init",
 *    target:{ 
 *         validators:{
 *          required: false
 *         }
 *     }
 * *}
*/


@Injectable({
  providedIn: 'root'
})
export class DynamicFormService {

  public validSentence = [];
  public DinamicFormElement: FormControl;

  constructor(
    private fb: FormBuilder
  ) { }

  public _form;
  public _dynamicform;


  public asignControls(check: any, DinamicForm: FormGroup ){
    return new Promise <any> (async (resolve, reject) =>{
    this.validSentence = [];        
  //Se establecen los controles para cada elemento por separado del formulario
    for (const item in check?.target?.validators) {

      if(item == "required" && check.target.validators[item]) DinamicForm.controls[check.formControlName].setValidators(Validators.required);
      // else if(item == "maxLength")      this.validSentence.push(Validators.maxLength(check.target.validators[item]));
      // else if(item == "pattern_email")  this.validSentence.push(Validators.pattern(/^[a-zA-Z0-9\._\-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/));
      // else if(item == "minLength")      this.validSentence.push(Validators.minLength(check.target.validators[item]));
      // else if(item == "max")            this.validSentence.push((Validators.max(check.target.validators[item])));
      // else if(item == "min")            this.validSentence.push((Validators.min(check.target.validators[item])));
      else if(item == "required" && !check.target.validators[item]) DinamicForm.get(check?.formControlName).clearValidators(); DinamicForm.get(check?.formControlName).updateValueAndValidity();
      
      // Si se desea agregar otro tipo de validador se debe agregar dentro del for.
    }
   //DinamicForm.controls[check.formControlName].setValidators(this.validSentence);
    resolve(DinamicForm);
  });
  }

  public genForm = async (validatorArray: any) => {
    return new Promise <any> (async (resolve) =>{
      try {
        let obj = {};

        // Genera Formulario principal
        for (const item of validatorArray) {
          let validators = [];
          
          for (const key1 of item.fields) {

            for (const key2 in key1.target?.validators) {

              const validator = key1.target.validators[key2];
              (key2 == "required" && validator ) ?  validators.push((Validators[key2])) : 
                (key2 == "pattern_email" && validator ) ? validators.push((Validators.pattern(/^[a-zA-Z0-9\._\-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/)))  :
                (key2 == "maxLength") ? validators.push((Validators.maxLength(validator))) :
                (key2 == "minLength") ? validators.push((Validators.minLength(validator))) :
                (key2 == "max") ? validators.push((Validators.max(validator))) :
                (key2 == "min") ? validators.push((Validators.min(validator))) :
                (key2 == "required" && !validator ) ? '' : validators.push(Validators[key2](validator));
                 // Si se desea agregar otro tipo de validador se debe agregar dentro del for.

          }

          if(key1.formControlName){
            if (key1.type == 'array') {
               obj[key1.formControlName] = [[null], Validators.compose(validators)]
            }else{
               obj[key1.formControlName] = [null, Validators.compose(validators)]
            }
          }

          validators = []
        }
      }
      this._form = obj;
      this._dynamicform =  this.fb.group(obj);

        resolve(this._dynamicform);

      } catch (error) {
        console.log(error);
        resolve(false)
      }
    });
}
}
