<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title"> {{modalOptionsSubE.type == 'ADD' ? 'Agregar Sub-Estudio' : 'Editar Sub-Estudio'}}</h6>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="subStudyForm">
        <div class="row">
            <div class="col-md-12">
                <h6>Sub-estudio  <span class="text-danger">*</span></h6>
                <div class="input-group">
                    <input type="text" class="form-control" formControlName="name" maxlength="60" placeholder="Sub-estudio" />
                </div>
                <small *ngIf="validateForm.campoEsValido('name', subStudyForm)" class="text-danger ml-1">Ingresa un nombre para el sub-estudio.</small>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-dark" (click)="close()">Cerrar</button>
    <button type="button" class="btn btn-success" (click)="modalOptionsSubE.type == 'ADD' ? addSubStudy() : editSubStudy()">{{modalOptionsSubE.type == 'ADD' ? 'Guardar' : 'Actualizar'}}</button>
</div>