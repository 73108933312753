import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-file-item',
  templateUrl: './file-item.component.html',
  styleUrls: ['./file-item.component.scss'],
  host: {
    "[attr.placement]": "'top'",
    "[attr.ngbTooltip]": "file.description",
    "[class]": "'card'",
    "(click)": "editFile(file)"
  }
})
export class FileItemComponent implements OnInit {

  @Input() file: any;
  @Input() forceName: string;
  @Input() blocked: boolean;
  @Input() hasPrivate: boolean = true;
  @Input() filesBill: boolean = false;
  @Input() filesmanager;               // configuracion tipo filemanager
  @Input() deletebtn: boolean = false; // boton de eliminar visible

  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSplice: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDownload: EventEmitter<any> = new EventEmitter<any>();

  public iconList: any = [
    { type: "pdf", icon: "fa fa-file-pdf" },
    { type: "jpg", icon: "fa fa-file-image" },
    { type: "png", icon: "fa fa-file-image" },
    { type: "jpeg", icon: "fa fa-file-image" },
    { type: "xlsx", icon: "fa fa-file-excel" },
    { type: "zip", icon: "fa fa-file-zipper" },
    { type: "dwg", icon: "fa fa-file" }];

  constructor() { }

  ngOnInit(): void {
  }

  public getFileExtension = (filename: string) => {
    
    if (filename) {
      let ext = filename.split(".").pop();
      let obj = this.iconList.filter(row => {
        if (row.type === ext) {
          return true;
        }
      });
      if (obj.length > 0) {
        let icon = obj[0].icon;
        return icon;
      } else {
        return "";

      }
    }
    else {
      return "";
    }
  }

  public deleteFile = () => { this.onDelete.emit() }

  public spliceFile = (event) => {    
    this.onSplice.emit(this.file?.file?.name ? this.file?.file?.name : this.file?.name)
  };

  public editFile(file){
    this.onEdit.emit(file);
  }

  public download(){ 
   this.onDownload.emit();
  }

}
