import { UserService } from 'src/app/shared/services/user.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { HttpService } from 'src/app/shared/services/http.service';
import { statusPipe } from 'src/app/shared/pipes/status.pipe';

@Component({
  selector: 'modal-movement-list',
  templateUrl: './modal-movement-list.component.html',
  styleUrls: ['./modal-movement-list.component.scss']
})
export class ModalMovementListComponent implements OnInit {

  @Input() cashRegister: string = null; 
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>(); 
  
  
  public isLoading: boolean = false;
  public DT_movement: MatTableDataSource<any>;
  public displayColumns: string[] = ['date', 'amount', 'type', 'user'];

  @ViewChild('Paginator') paginator: MatPaginator;
  @ViewChild('Sort') sort: MatSort;

  constructor(
    private httpservice: HttpService,
    private status: statusPipe
  ){
    this.DT_movement = new MatTableDataSource; 
  }

  ngOnInit(): void {
    this.getMovements();
  }

  private async getMovements(){
    try {
      this.isLoading = true;
      let data = await this.httpservice.get(`api/movement/${this.cashRegister}`).toPromise();
      for (const item of data) {
        item.type = this.status.transform(item.type)+'*'+item.type;
        item.date = item.date+'*'+moment(item.date).format("lll");
        item.user = item.userData?.name;
      }
      this.DT_movement.data = data;
      this.isLoading = false;
    } catch (error) {
      console.log(error);
      this.isLoading = false;
    }
  }

  // Cerrar modal
  close(): void { this.onClose.emit() }

}
