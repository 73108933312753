import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { AlertService } from 'src/app/shared/services/alert.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { ProgramedService } from 'src/app/shared/services/http/programed.service';

@Component({
  selector: 'attend-service',
  templateUrl: './attend-service.component.html',
  styleUrls: ['./attend-service.component.scss']
})
export class AttendServiceComponent implements OnInit {
  public isLoading: boolean = false;
  public activeCalls: any;
  public userForm: FormGroup;
  public users;
  @Output() onSave: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() modalOptions: any = null;

  public isCreated = (this.modalOptions?.item?.status == "CREATED") ? true : false
  public isAtended = (this.modalOptions?.item?.status == "ATTENDED") ? true : false
  public close = () => { this.onClose.emit() }

  constructor(
    private httpService: HttpService,
    public validateForm: ValidateFormsDirective,
    private alertService: AlertService,
    private fb: FormBuilder,
    public programedService:ProgramedService

  ) { }

  async ngOnInit() {
    this.userForm = this.fb.group({
      id: [this.modalOptions?.item?._id],
      userId: [null, Validators.compose([Validators.required])],
      userExtraId: [null]
    });
    
    this.assignStatus();
    await this.getUsers(); 
   }

  private assignStatus = () => {
    this.isCreated = (this.modalOptions?.item?.status == "CREATED") ? true : false
    this.isAtended = (this.modalOptions?.item?.status == "ATTENDED") ? true : false
  }

  private getUsers = async () => {
    await this.httpService.get(`api/users`).toPromise().then((result) => { this.users = result }).catch((error) => { console.log(error) })
    
  }

  public atendService = async () => {
    const obj = Object.keys(this.userForm.value).filter((k) => this.userForm.value[k] != null).reduce((a, k) => ({ ...a, [k]: this.userForm.value[k] }), {});

    if (!this.userForm.valid) {
      this.userForm.markAllAsTouched();
      this.alertService.infoToast("Campos incompletos, verifique porfavor");
      return;
    }

    await this.programedService.attendprogramedService(obj).then((resp) => {
      this.alertService.successToast(resp.message)
      this.onSave.emit();
    }).catch(error => { 
      if(error.status != 403) this.alertService.infoToast(error?.error?.message);
      console.log(error) 
    })
  }

  public proccessService = async () => {
    const obj = Object.keys(this.userForm.value).filter((k) => this.userForm.value[k] != null).reduce((a, k) => ({ ...a, [k]: this.userForm.value[k] }), {});

    if (!this.userForm.valid) {
      this.userForm.markAllAsTouched();
      this.alertService.infoToast("Campos incompletos, verifique porfavor");
      return;
    }

    await this.programedService.processprogramedService(obj).then((resp) => {
      this.alertService.successToast(resp.message)
      this.close()
    }).catch(error => { 
      if(error.status != 403) this.alertService.infoToast(error?.error?.message);
      console.log(error) 
    })
  }

}
