<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Archivo</h6>
     <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body p-0"> 
        <ng-container *ngIf="url && (Filename.split('.')[1] == 'pdf' || Filename.split('.')[1] == 'png' || Filename.split('.')[1] == 'jpeg' || Filename.split('.')[1] == 'jpg')">
            <div class="row">
                <div class="col-12">
                  <div class="pdf">
                    <ng-container *ngIf="url">
                      <embed [src]="url" class="w-100 h-100">
                    </ng-container>
                  </div>
                </div>
              </div>
        </ng-container>
        <ng-container *ngIf="!url || (Filename.split('.')[1] != 'pdf' && Filename.split('.')[1] != 'png' && Filename.split('.')[1] != 'jpeg' && Filename.split('.')[1] != 'jpg')">
            <div class="p-4">
                <app-file-item class="w-100 mx-0" [file]="{name: Filename, file: {name:Filename}}" [blocked]="true" [filesmanager]="true"></app-file-item>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer justify-content-between">
        <div>
            <ng-container *ngIf="!url">
                <button class="btn btn-info mr-3" (click)="download()"><i class="fa fa-download mr-1"></i>Descargar</button>
            </ng-container>
            <button type="button" class="btn btn-dark" (click)="close()">Cerrar</button>
        </div>
    </div>