import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'modal-download-file',
  templateUrl: './modal-download-file.component.html',
  styleUrls: ['./modal-download-file.component.scss']
})
export class ModalDownloadFileComponent implements OnInit {

  public Download: boolean = false
  public url: any;
  public Filename = "";
  public apiUrl = environment.apiUrl;
  public tmpFile = null;
  public removedImages: string[] = [];

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>(); 
  @Input() file = null;
  public contractFile = [{ index: 0, id: null, name: null, action: "", file: null }];

  constructor(    
    public sanitizer: DomSanitizer
    ) { }

  ngOnInit(): void {
    if (this.file) {
      this.file?.id ? this.Download = true : this.Download = false;
      this.Filename = (this.file?.nameOriginal) ? this.file?.nameOriginal : this.file.name;
      this.tmpFile = this.file;
      this.contractFile[0].name = this.file?.name;
      console.log("file 123",this.file);

    }else{
      this.tmpFile = null;            
      this.deleteFile();
    }

  }

  close(): void { this.onClose.emit() }

  public  download = async () => {
    if (this.Download) {
      window.open(`${this.apiUrl}${this.file?.path}`, "_blank");
    }else{
      
      let url = URL.createObjectURL(this.contractFile[0]?.file);
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
   
  }

  public deleteFile(){
    this.contractFile = [{ index: 0, id: null, name: null, action: "", file: null }];
    this.Filename = "";
    this.Download = false;
}

}
