<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Agregar Producto</h6>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-8">
            <h6>Producto</h6>
            <div class="input-group">
                <ng-select class="form-control" placeholder="Producto"></ng-select>
            </div>
        </div>
        <div class="col-md-4">
            <h6>Cantidad</h6>
            <div class="input-group">
                <input type="text" class="form-control" placeholder="Cantidad" />
            </div>
        </div>
    </div>
</div>
<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-dark" (click)="close()">Cerrar</button>
    <button type="button" class="btn btn-success" (click)="close()">Guardar</button>
</div>