<label class="d-flex form-control _tags" [class.list-open]="_openList">
    <ng-container *ngFor="let tag of _value; index as i">
      <span class="badge badge-primary-light d-flex"><span>{{tag?.label}}</span><i class="fa fa-close text-danger" (click)="removeTag(i)"></i></span>
    </ng-container>
    <input type="text" #tagInp [placeholder]="placeholder"
    (input)="tagInp.size = (tagInp.value.length + 1); filtercat($event?.target?.value);"
    (focus)="_openList = true; _hasFocus = true"
    (blur)="touched($event); _hasFocus = false; blurFocus();"
    (keydown.enter)="addTag(tagInp.value); tagInp.value = '';"
    (keydown.backspace)="backspace(tagInp.value)">
    
  </label>
  <div class="w-100 position-relative drop-list">
  <ng-containter [ngTemplateOutlet]="_openList ? dropList : false"></ng-containter>
  </div>
  
  <ng-template #dropList>
  <div class="drop-list-container" (mouseover)="_openList = true; _hasListHover = true" (mouseleave)="_hasListHover = false; blurFocus()">
  <ul>
    <ng-container *ngIf="tagCatalogFiltred.length else noTags">
      <ng-container *ngFor="let item of tagCatalogFiltred; index as i">
        <li (click)="addTag((item?.add) ? tagInp.value : item); tagInp.value = null" [class.disable]="tagExists(item?.text)">
          <div class="d-flex justify-content-between">
            <span>{{item?.text}}</span>
            <!-- <i class="fa fa-close my-auto"></i> -->
          </div>
        </li>
      </ng-container>
    </ng-container>
    <ng-template #noTags>
      <li>
        <div class="text-center">
          <span class="text-muted">Sin Etiquetas</span>
        </div>
      </li>
    </ng-template>
  </ul>
  </div>
  </ng-template>