import { BranchService } from './../../services/branch.service';
import { MovementsService } from './../../services/movements.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { Subscription } from 'rxjs';
import { LayoutService } from '../../services/layout.service';
import { NavService } from '../../services/nav.service';
import { UserService } from '../../services/user.service';
import { IUser } from 'src/app/interfaces/iuser'; 
import { TokenInterceptorService } from '../../services/token.interceptor.service';
import { ExchangeRateService } from '../../services/exchangeRate.service';
import { CashRegisterService } from '../../services/cash-register.service';
import { CallsService } from '../../services/calls.service';
import { ProgrammedServiceService } from '../../services/programmed-service.service';
import { UrgenciesService } from '../../services/urgencies.service';
import { BadgetService } from '../../services/badget.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  public user: IUser = {}

  public isCollapsed = true;
  public isSidebar = false;
  public config: any = {};
  layoutSubscription: Subscription;
  toggleClass = "fe fe-maximize";

  constructor(
    private layoutService: LayoutService,
    public navServices: NavService,
    public userService: UserService,
    //public interceptor: InterceptorMembershipService,
    public interceptor: TokenInterceptorService,
    public layoutCtrl: LayoutService,
    public exchangeService: ExchangeRateService,
    public cashRegisterService: CashRegisterService,
    public movementService: MovementsService,
    public brancService: BranchService,
    public callsService: CallsService,
    public programmedService: ProgrammedServiceService,
    public urgenciesService: UrgenciesService,
    public badgetService: BadgetService
  ) {
    this.layoutSubscription = layoutService.changeEmitted.subscribe(
      direction => {
        const dir = direction.direction;
      }
    )

  }

  ngOnInit(): void {
    this.user = this.userService.user    
  }

  toggleSidebarNotification() {
    this.layoutService.emitSidebarNotifyChange(true);
  }

  toggleSidebar() {
    if ((this.navServices.collapseSidebar = !this.navServices.collapseSidebar)) {
      document.querySelector('.main-body').classList.add('main-sidebar-hide');
    }
    else {
      document.querySelector('.main-body').classList.remove('main-sidebar-hide');
    }
  }

  toggleMobileSidebar() {
    if ((this.navServices.collapseSidebar = !this.navServices.collapseSidebar)) {
      document.querySelector('.main-body').classList.add('main-sidebar-show');
    }
    else {
      document.querySelector('.main-body').classList.remove('main-sidebar-show');
    }
  }

  ngAfterViewInit() {
    const sidebar = document.querySelector('.side-menu');
    let ps = new PerfectScrollbar(sidebar);
  }

  signOutHandler() {
    this.userService.signOutCurrentUser();
  }

}

