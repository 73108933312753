import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { HttpService } from 'src/app/shared/services/http.service';
import * as moment from 'moment-timezone';
import { ageByBirthdayPipe } from 'src/app/shared/pipes/ageByBirthday.pipe'; 
import { CryptoSharkService } from 'src/app/shared/services/crypto-shark.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { ValidatorsService } from 'src/app/shared/services/validators.service';
import { DecryptPipe } from 'src/app/shared/pipes/decrypt.pipe';
import { TitleCasePipe } from '@angular/common';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ModalPatientComponent),
  multi: true
};

@Component({
  selector: 'cat-modal-patient',
  templateUrl: './modal-patient.component.html',
  styleUrls: ['./modal-patient.component.scss']
})


export class ModalPatientComponent implements OnInit {

  @Input() patientsRecord;
  @Output() onSave: EventEmitter<any> = new EventEmitter<any>();  
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() modalOptions: any = null; 

  @ViewChild('inpEmail') private inpEmail: any;

  public isLoading: boolean = false;
  public domainsData: any[] = [];
  public domain: any;
  public inpPhone: any = null;
  public typePhone: string = null;
  public sendPatient = {email: true,print: true,web: true, mobile: true}
  public showBillingDetail: boolean = true;
  public phones: any[] = [];
  public emails: any[] = [];
  public FormPatient: FormGroup; 
  public validDate:boolean = false;
  public blockEdition: boolean = false;

  
  public userOfCfdiSelected:any = "000";
  public paymentMethodSelected:any = "000"; 
  public paymentSelected:any = "000"; 
  
  public mask =  [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public invalidDate: boolean = false; 
  public ageMeasureSelected: string = '';
  
  public paymentForm: Object[] = [
    {"value": "01", "text": "01 Efectivo"},
    {"value": "02", "text": "02 Cheque nominativo"},
    {"value": "03", "text": "03 Transferencia electronica de fondos"},
    {"value": "04", "text": "04 Tarjeta de credito"},
    {"value": "05", "text": "05 Monedero electrónico"},
    {"value": "06", "text": "06 Dinero electrónico"},
    {"value": "08", "text": "08 Vales de despensa"},
    {"value": "12", "text": "12 Dación en pago"},
    {"value": "13", "text": "13 Pago por subrogación"},
    {"value": "14", "text": "14 Pago por consignación"},
    {"value": "15", "text": "15 Condonación"},
    {"value": "17", "text": "17 Compensación"},
    {"value": "23", "text": "23 Novación"},
    {"value": "24", "text": "24 Confusión"},
    {"value": "25", "text": "25 Remisión de deuda"},
    {"value": "26", "text": "26 Prescripción o caducidad"},
    {"value": "27", "text": "27 A satisfacción del acreedor"},
    {"value": "28", "text": "28 Tarjeta de débito"},
    {"value": "29", "text": "29 Tarjeta de servicio"},
    {"value": "99", "text": "99 Por definir"}
  ];
  
  public paymentMethod: Object[] = [
    {"value": "PUE", "text": "PUE Pago en una sola exhibición"},
    {"value": "PIP", "text": "PIP Pago inicial y parcialidades"},
    {"value": "PPD", "text": "PPD Pago en parcialidades o diferido"}
  ];
  
  public useOfCfdi: Object[] = [
    {"value": "G01", "text": "G01 Adquisición de mercancias"},
    {"value": "G02", "text": "G02 Devoluciones, descuentos o bonificaciones"},
    {"value": "G03", "text": "G03 Gastos en general"},
    {"value": "I01", "text": "I01 Construcciones"},
    {"value": "I02", "text": "I02 Mobilario y equipo de oficina por inversiones"},
    {"value": "I03", "text": "I03 Equipo de transporte"},
    {"value": "I04", "text": "I04 Equipo de computo y accesorios"},
    {"value": "I05", "text": "I05 Dados, troqueles, moldes, matrices y herramental"},
    {"value": "I06", "text": "I06 Comunicaciones telefónicas"},
    {"value": "I07", "text": "I07 Comunicaciones satelitales"},
    {"value": "I08", "text": "I08 Otra maquinaria y equipo"},
    {"value": "D01", "text": "D01 Honorarios médicos, dentales y gastos hospitalarios"},
    {"value": "D02", "text": "D02 Gastos médicos por incapacidad o discapacidad"},
    {"value": "D03", "text": "D03 Gastos funerales"},
    {"value": "D04", "text": "D04 Donativos"},
    {"value": "D05", "text": "D05 Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)"},
    {"value": "D06", "text": "D06 Aportaciones voluntarias al SAR"},
    {"value": "D07", "text": "D07 Primas por seguros de gastos médicos"},
    {"value": "D08", "text": "D08 Gastos de transportación escolar obligatoria"},
    {"value": "D09", "text": "D09 Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones"},
    {"value": "D10", "text": "D10 Pagos por servicios educativos (colegiaturas)"},
    {"value": "P01", "text": "P01 Por definir"}
  ];

  public ageMeasure: Object[] = [
    {"value": "DAY", "text": "Dia(s)"},
    {"value": "MONTH", "text": "Mes(es)"},
    {"value": "YEAR", "text": "Año(s)"}
  ];

  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private alertService: AlertService, 
    private ageByBirthday: ageByBirthdayPipe,
    private cryptoService: CryptoSharkService,
    private userService: UserService,
    public validateForm: ValidateFormsDirective,
    public validator: ValidatorsService,
    private decryp: DecryptPipe,
    private titleCase: TitleCasePipe
  ) { }
 
  ngOnInit(): void {
    this.loadDomains();

    this.FormPatient = this.fb.group({
      name: [null, Validators.compose([Validators.required, Validators.maxLength(60)])],
      age:[null, Validators.compose([Validators.required, Validators.max(120)])],
      gender:[null, Validators.compose([Validators.required])],
      birthDate: [null],
      ageMeasure: [null, Validators.compose([Validators.required])],
      phone: [[]],
      email: [[]], 
      address: [null, Validators.compose([Validators.maxLength(100)])],
      membership:[null, Validators.compose([Validators.maxLength(20)])],
      insurance:[null, Validators.compose([Validators.maxLength(30)])],
      sendMethod: this.fb.group({
        email: [true, Validators.compose([Validators.required])],
        print: [true, Validators.compose([Validators.required])],
        web: [true, Validators.compose([Validators.required])],
        mobile: [true, Validators.compose([Validators.required])],
      }),
      billing: this.fb.group({
        businessName: [null, Validators.compose([Validators.maxLength(60)])],
        rfc: [null, Validators.compose([Validators.maxLength(13), Validators.minLength(12)])],
        paymentType: [null],
        paymentMethod: [null],
        cfdi: [null],
      }),
      comment: [null, Validators.compose([Validators.maxLength(250)])]
    });

    if(this.modalOptions.type != 'ADD'){ 
      this.detailPatient();
    }        
  }

  async loadDomains(){
    try {
      this.domainsData = await this.httpService.get(`api/domain/select`).toPromise();
    } catch (error) {
      console.log(error);
    }
  }

  public addEmails(mail: string, domain: any): void{
    mail = mail.replace(/\s/g,"");
    if (this.emails?.filter(item => {return item.email == mail+domain}).length == 0) {
      this.emails.push({"email": mail+domain, "primary": false});
      this.inpEmail.nativeElement.value = null;
      this.domain = null
    }else{
      this.alertService.infoToast("El correo ya se encuentra agregado.")
    }
  }

  public addPhones(){  
    if (this.phones?.filter(item => {return item.phone == this.inpPhone}).length == 0) {
      this.phones.push({"phone": this.inpPhone, type: this.typePhone, "primary": false}); 
      this.inpPhone = null;
      this.typePhone = null;
    }else{
      this.alertService.infoToast("El teléfono ya se encuentra agregado.")
    } 
  }

  public changePhoneType(event: any):void{
    this.inpPhone = null;
    if (event != "OFFICE") {
      this.phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }else{
      this.phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, ';', /\d/, /\d?/, /\d?/, /\d?/];
    }
  }

  public selectedDate(): void {
    let birthDay = moment(this.FormPatient.get('birthDate').value, 'DD/MM/YYYY');
    let date = moment(birthDay, 'DD/MM/YYYY');
    this.invalidDate = !date.isValid();
   
    if (date.isAfter()) {
      this.FormPatient.get('birthDate').setValue('');
      this.FormPatient.get("age").setValue('');
      this.FormPatient.get("ageMeasure").setValue('');
      this.alertService.infoToast("No se permite fechas futuras.");
    } else if (!this.invalidDate) {
      let measure = this.ageByBirthday.transform(birthDay, 'EN').split(' ');
      
      this.FormPatient.patchValue({
        age: measure[0],
        ageMeasure: measure[1]
      });

      this.validDate = true;

    } else {
      this.FormPatient.get("age").setValue('');
      this.FormPatient.get("ageMeasure").setValue('');
      this.FormPatient.get('birthDate').setValue('');
      this.validDate = false;
      this.alertService.infoToast("Fecha no valida.");
    }
  }

  public changeSendMethod(method: string, value: boolean):void{
    this.FormPatient.get("sendMethod").get(method).setValue(value);
    this.sendPatient[method] = value;
  }

  private detailPatient = async () => {
    this.isLoading = true;
    try {
      let data = await this.httpService.get(`api/patient/${this.modalOptions.id}`).toPromise();
      this.FormPatient.patchValue({
        name: this.titleCase.transform(this.decryp.transform(data?.name)),
        gender: data?.gender,
        address: data?.address,
        membership: data?.membership,
        insurance: data?.insurance,
        sendMethod: {
          email: data?.sendMethod?.email || false,
          print: data?.sendMethod?.print || false,
          web: data?.sendMethod?.web || false,
          mobile: data?.sendMethod?.mobile || false,
        },
        billing: {
          businessName: (data?.billing?.businessName) ? this.decryp.transform(data?.billing?.businessName) : null,
          rfc: (data?.billing?.rfc) ? this.decryp.transform(data?.billing?.rfc) : null,
          paymentType: data?.billing?.paymentType || null,
          paymentMethod: data?.billing?.paymentMethod || null,
          cfdi: data?.billing?.cfdi || null,
        },
        comment: data?.comment
      });

      for ( const p of data?.phone || []){p.phone = this.decryp.transform(p.phone)};
      for ( const m of data?.email || []){m.email = this.decryp.transform(m.email)}; 

      this.phones = data?.phone || [];
      this.emails = data?.email || [];

      this.sendPatient.email = data?.sendMethod?.email || false;
      this.sendPatient.print = data?.sendMethod?.print || false;
      this.sendPatient.web = data?.sendMethod?.web || false;
      this.sendPatient.mobile = data?.sendMethod?.mobile || false;

      if (data?.birthDate) {
        this.FormPatient.get('birthDate').setValue(moment(data?.birthDate).format('DD/MM/YYYY'));
        this.selectedDate();
      }else if(data?.age && data?.ageMeasure){
        this.FormPatient.get('age').setValue(data?.age);
        this.FormPatient.get('ageMeasure').setValue(data?.ageMeasure);
      }

      if (this.modalOptions?.type == 'VIEW' || (!this.modalOptions?.permissions['EDIT'] && this.modalOptions?.type == 'EDIT') ) {
        this.FormPatient.disable();
        this.blockEdition = true;
      }

      this.isLoading = false;
    } catch (error) {
      console.log(error.message);
      this.isLoading = false;
    }
  }

  public primaryPhone(event: any, index: number):void{
    this.phones.find(phone => {return phone.primary})?(this.phones.find(phone => {return phone.primary}).primary = false):null;
    this.phones[index].primary = event//.target.checked;
  }

  public primaryMail(event: any, index: number):void{
    // this.emails.find(mail => {return mail.primary})?(this.emails.find(mail => {return mail.primary}).primary = false):null;
    this.emails[index].primary = event//.target.checked;
  }

  async submit(){  
    try {
      this.isLoading = true;
      let phonesEncrypt = [];
      let onePhonePrimary = false;
      for ( const p of this.phones ){  
        if(p.primary) onePhonePrimary = true;
        phonesEncrypt.push({"phone": this.cryptoService.encryptAes(p.phone, this.userService.login.company.dk), "primary": p.primary, type: p.type}); 
      };  

      if (this.phones.length > 0 && !onePhonePrimary) {
        phonesEncrypt[0].primary = true;
      }
      
      this.FormPatient.value.phone = phonesEncrypt;
    
      let emailsEncrypt = [];
      let oneMailPrimary = false;
      for(const e of this.emails){
        if(e.primary) oneMailPrimary = true;
        emailsEncrypt.push({"email": this.cryptoService.encryptAes(e.email, this.userService.login.company.dk), "primary": e.primary});
      }

      if (this.emails.length > 0 && !oneMailPrimary) {
        emailsEncrypt[0].primary = true;
      }
      
      this.FormPatient.value.email = emailsEncrypt;
      
      if(!this.FormPatient.valid){ 
        this.FormPatient.markAllAsTouched();
        this.alertService.infoToast("Campos requeridos incompletos!");
        this.isLoading = false;
        return ;
      } 

      this.FormPatient.value.name = this.cryptoService.encryptAes(this.FormPatient.value.name, this.userService.login.company.dk);
      this.FormPatient.value.billing.businessName = (this.FormPatient.value.billing.businessName)? this.cryptoService.encryptAes(this.FormPatient.value.billing.businessName, this.userService.login.company.dk) : null;
      this.FormPatient.value.billing.rfc = (this.FormPatient.value.billing.rfc)? this.cryptoService.encryptAes(this.FormPatient.value.billing.rfc, this.userService.login.company.dk) : null;  
      if (this.FormPatient.value.birthDate) {
        this.FormPatient.value.birthDate = moment(this.FormPatient.value.birthDate, "DD/MM/YYYY").format("YYYY-MM-DD");
      }else{
        this.FormPatient.value.birthDate = null;
      }
      
      let obj = await this.validateForm.clearNullObjects(this.FormPatient.value);

      if (this.modalOptions.type == 'ADD') {
        this.httpService.post(`api/patient`, obj).subscribe(resp => {
          this.alertService.successToast(resp.message);
          this.onSave.emit({id: resp.insertedId});
          this.close();
          this.isLoading = false;
        },error =>{
          this.isLoading = false;
          console.log(error);
          if (error?.status != 403) {
            this.alertService.errorToast(error.error.message);
            this.close();
          }
        })
      }else if (this.modalOptions.type == 'EDIT') {
        this.httpService.put(`api/patient/${this.modalOptions.id}`, obj).subscribe(resp => {
          this.alertService.successToast(resp.message);
          this.onSave.emit({id: this.modalOptions.id});
          this.close();
          this.isLoading = false;
        },error =>{
          this.isLoading = false;
          console.log(error);
          if (error?.status != 403) {
            this.alertService.errorToast(error.error.message);
            this.close();
          }
        })
      }
      
    } catch (error) {
      console.log(error);
      this.isLoading = false;
    }
  }

  close(){this.onClose.emit()}

}
