import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'appointment-card',
  templateUrl: './appointment-card.component.html',
  styleUrls: ['./appointment-card.component.scss'],
  host: {
    class: 'card custom-card overflow-hidden'
  }
})
export class appointmentCardComponent implements OnInit {
  @Input() item: any;

  constructor() { }

  ngOnInit(): void {
  }

}
