<form [formGroup] ="userForm">
  <div class="modal-header">
      <h6 class="modal-title" id="modal-basic-title"><i class="fa fa-bullhorn mr-2"></i> Atender</h6>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
  </div>
      <div class="modal-body">
          <div class="row">
              <div class="col-sm-12">
                  <h6>Usuario que atiende <span class="text-danger">*</span></h6>
                  <div class="input-group">
                      <ng-select type="text" class="form-control" [items]="users" bindLabel="name" placeholder="selecciona usuario" bindValue="_id" formControlName="userId" placeholder="Ingresa el usuario"></ng-select>
                  </div>
                  <small *ngIf="validateForm.campoEsValido('userId', userForm)" class="text-danger">Campo requerido</small>
              </div>
          </div>
          <div class="row">
              <div class="col-sm-12 mt-2">
                  <h6>Usuario adicional</h6>
                  <div class="input-group">
                      <ng-select type="text" class="form-control" [items]="users" bindLabel="name" placeholder="selecciona usuario" bindValue="_id" formControlName="userExtraId" placeholder="Ingresa el usuario adicional"></ng-select>
                  </div>
              </div>
          </div>
  
      </div>
  
      <div class="modal-footer justify-content-between">
          <button class="btn btn-dark" (click)="close()">Cerrar</button>
  
          <ng-container *ngIf="isCreated">
              <button class="btn btn-success" (click)="atendCall()">Atender</button>
          </ng-container>
          <ng-container *ngIf="isAtended">
              <button class="btn btn-success" (click)="proccessCall()">Procesar</button>
          </ng-container>
      </div>
      </form>
  