import { AlertService } from 'src/app/shared/services/alert.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpService } from 'src/app/shared/services/http.service';
import { LabelsService } from 'src/app/shared/services/labels.service';


@Component({
  selector: 'modal-samples',
  templateUrl: './modal-samples.component.html',
  styleUrls: ['./modal-samples.component.scss']
})
export class ModalSamplesComponent implements OnInit {

  @Input() idOrder: string;
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();

  public isLoading: boolean = true;
  public samples: any;

  constructor( 
    public alertService: AlertService,
    private httpService: HttpService,  
    private labelsServices: LabelsService
  ) { }

  ngOnInit(): void {
    this.loadSamples();
  }

  private async loadSamples(){
    try {
      this.isLoading = true;
      this.samples = await this.labelsServices.getContainers(this.idOrder);
      
      if(!this.samples?.container?.length){
        await this.labelsServices.createContainer(this.idOrder);
        this.samples = await this.labelsServices.getContainers(this.idOrder)
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.log(error);
    }
  }

  public close(): void { this.onClose.emit(); }

  public async submit(){
    try {
      this.isLoading = true;
      let data:any = await this.labelsServices.updateLabels(this.samples?._id, this.samples?.container);
      this.alertService.successToast(data?.message);
      this.onSubmit.emit();
      this.close();
      this.isLoading = false;
    } catch (error) {
      console.log(error);
      this.isLoading = false;
    }
  }

}
