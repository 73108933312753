<div class="sidebar-title">
    <div class="d-flex justify-content-between">
        <div class="d-flex">
            <h1 class="m-0">Urgencias</h1>
            <!-- <div class="d-flex flex-column pl-3">
                <h4 class="color-tx m-0">2 llamadas activas</h4>
                <div class="d-flex mt-auto">
                    <span class="tx-16">1 nueva, 1 en proceso</span>
                </div>
            </div> -->
        </div>
        <!-- <div class=" my-auto">
            <button class="btn btn-dark"><i class="fa fa-plus mr-2"></i> Nueva Llamada</button>
        </div> -->
    </div>
</div>
<div class="zoom-container">
    <div class="row mx-0 my-2">        
        <div class="col-md-3 d-flex">
            <span class="my-auto" style="color: #bc293d;"><i class="fa fa-exclamation-triangle mr-1"></i> Hay <b>{{badgetService?.urgencysBadget || 0}}</b> Urgencias activas.</span>
        </div>
        <div class="col-md-9 d-flex justify-content-end">
            <div class="d-flex _filters">
                <ng-container *ngFor="let filter of filters; index as i">
                    <div class="card flex-row mr-3 text-overflow" [style.background-color]="statusColors[filter.type]" [class.active]="filter.active" (click)="filter.active = !filter.active; filterDataByStatus()">
                        <b class="my-auto"><i class="fa fa-circle tx-10 mr-1"></i> {{filter.name}}</b>
                    </div>
                </ng-container>
            </div>
            <!-- BUSCAR  -->
               <!-- BUSCAR  -->
               <div class="input-group search" style="width: 240px;">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <i class="fe fe-search lh--9 op-6"></i>
                    </div>
                </div>
                <input class="form-control" (keyup)="search($event)" [(ngModel)]="searchField" type="text" placeholder="Buscar...">
            </div>
            <!-- FECHA -->
            <!-- <div class="input-group date" style="width: 240px;">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <i class="fe fe-calendar lh--9 op-6"></i>
                    </div>
                </div>
                <input class="form-control" theme="dp-material">
            </div> -->
        </div>
    </div>

    <div class="row mx-0 mt-auto">
        <div class="col-md-4 d-flex mt-1 mb-2">
            <h4 class="my-auto mr-3 ">Llamadas</h4>
        </div>
    </div>
    <div class="row m-0 border-top">
        <div class="col-sm-12 p-0">
            <div class="table-responsive">
                <table mat-table [dataSource]="DT_Urgencies_Calls" matSort #SortCalls="matSort" matSortActive="name" matSortDirection="asc" class="table table-bordered table-striped mb-0">
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header width="180px">F. proceso</th>
                        <td mat-cell *matCellDef="let item" style="--color: {{statusColors[item.status]}}; border-left: 4px solid var(--color) !important;">{{ item.date?.split("*")[1] }}</td>
                    </ng-container>
                    <ng-container matColumnDef="dateCreate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header width="180px">F. aplicación</th>
                        <td mat-cell *matCellDef="let item">{{ item.dateCreate?.split("*")[1] }}</td>
                    </ng-container>
                    <ng-container matColumnDef="receibedBy">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Recibido por </th>
                        <td mat-cell *matCellDef="let item">{{item?.createdByUser | titlecase}}</td>
                    </ng-container>
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Lugar </th>
                        <td mat-cell *matCellDef="let item"><b>{{item?.description | titlecase}}</b></td>
                    </ng-container>
                    <ng-container matColumnDef="processType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
                        <td mat-cell *matCellDef="let item">{{item?.processType | titlecase}}</td>
                    </ng-container>
                    <ng-container matColumnDef="attendedByUser">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Atendido por </th>
                        <td mat-cell *matCellDef="let item">
                            <div class="text-center lh-1">
                                <ng-container><span [class.text-danger]="!item.attendedBy?.userName">{{item?.attendedByUser | titlecase}}</span></ng-container>
                                <ng-container *ngIf="item?.attendedBy?.userNameExtra"><br> <small class="text-info">{{item?.attendedByExtraUser | titlecase}}</small></ng-container>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="attendedByDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header width="180px"> F. Atendido </th>
                        <td mat-cell *matCellDef="let item">
                            <div class="text-center">
                                <ng-container><span [class.text-danger]="!item.attendedBy?.date">{{item?.attendedByDate?.split("*")[1] || 'Sin Atender'}}</span></ng-container>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="attend">
                        <th mat-header-cell *matHeaderCellDef width="120px"> <div class="text-center">Atender</div> </th>
                        <td mat-cell *matCellDef="let item">
                            <div class="text-center">
                                <button class="btn btn-primary w-100" *ngIf="item.status=='CREATED'"(click)="modalOptions.item = item; openModal(modaAceptarlAttend, 'sm')"><i class="fa fa-phone mr-1"></i> Atender</button>
                                <button class="btn btn-dark w-100" *ngIf="item.status=='ATTENDED'"(click)="processCallSwal(item)"><i class="fa fa-phone mr-1"></i> Finalizar</button>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef width="60px"> <div class="text-center">Acción</div> </th>
                        <td mat-cell *matCellDef="let item">
                            <div class="text-center">
                                <button class="btn btn-dark mr-2" title="Deshacer"  (click)="undoCallSwal(item)" *ngIf="item.status=='ATTENDED'"><i class="fa fa-undo"></i></button>
                                <button class="btn btn-danger" title="Cancelar" *ngIf="item.status!='PROCESSED'" (click)="modalOptions.item = item;openModal(cancel); modalOptions.module = 'CALLS'"><i class="fa fa-close"></i></button>
                            </div>
                        </td>
                    </ng-container>
    
                    <tr mat-header-row *matHeaderRowDef="displayColumnsCalls"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumnsCalls;"></tr>
        
                    <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell text-center" [attr.colspan]="displayColumnsCalls.length">{{isLoading?"Cargando ...":"Sin datos encontrados"}}</td>
                    </tr>
                  </table>
            </div>
    
            <div class="row border-top mx-0 mt-auto">
                <div class="col-md-4 d-flex">
                    <h4 class="my-auto mr-3">Servicios Programados</h4>
                </div>
                <div class="col-md-8 p-0">
                    <mat-paginator #PaginatorCalls="matPaginator" [pageSize]="8" showFirstLastButtons ></mat-paginator>
                </div>
            </div>
            <div class="table-responsive border-top">
                <div class="table-responsive">
                    <table mat-table [dataSource]="DT_Urgencies_Services" matSort #SortServices="matSort" matSortActive="name" matSortDirection="asc" class="table table-bordered table-striped mb-0">
                        <ng-container matColumnDef="folio">
                            <th mat-header-cell  mat-sort-header *matHeaderCellDef>Folio</th>
                            <td mat-cell *matCellDef="let item" style="--color: {{statusColors[item?.status]}}; border-left: 4px solid var(--color) !important;"><b>{{ item?.folio}}</b></td>
                        </ng-container>
                        <ng-container  matColumnDef="dateProcess">
                            <th mat-header-cell  mat-sort-header *matHeaderCellDef width="180px"> F. Proceso </th>
                            <td mat-cell *matCellDef="let item">{{item?.dateProcess.split('*')[1]}}</td>
                        </ng-container>
                        <ng-container matColumnDef="created">
                            <th mat-header-cell  mat-sort-header *matHeaderCellDef> Registrado por </th>
                            <td mat-cell *matCellDef="let item">{{item?.created | titlecase}}</td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <th mat-header-cell  mat-sort-header *matHeaderCellDef> Paciente </th>
                            <td mat-cell *matCellDef="let item">{{item?.name | titlecase}}</td>
                        </ng-container>
                        <ng-container matColumnDef="phone">
                            <th mat-header-cell  mat-sort-header *matHeaderCellDef> Teléfono </th>
                            <td mat-cell *matCellDef="let item">{{item?.phone | titlecase}}</td>
                        </ng-container>
                        <ng-container matColumnDef="address">
                            <th mat-header-cell  mat-sort-header *matHeaderCellDef>Domicilio </th>
                            <td mat-cell *matCellDef="let item">{{item?.address}}</td>
                        </ng-container>
                        <ng-container matColumnDef="attended">
                            <th mat-header-cell  mat-sort-header *matHeaderCellDef> Atendido por </th>
                            <td mat-cell *matCellDef="let item">
                                <div class="text-center">
                                    <ng-container *ngIf="item?.attendedBy?.userName">{{item?.attended | titlecase}}</ng-container>
                                    <ng-container *ngIf="!item?.attendedBy?.userName"><span class="text-danger">Sin Atender</span></ng-container>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="dateAttended">
                            <th mat-header-cell  mat-sort-header *matHeaderCellDef width="180px"> F. Atendido </th>
                            <td mat-cell *matCellDef="let item">
                                <div class="text-center">
                                    <ng-container *ngIf="item?.attendedBy?.date"> {{item.dateAttended.split('*')[1]}}</ng-container>
                                    <ng-container *ngIf="!item?.attendedBy?.date"><span class="text-danger">Sin Atender</span></ng-container>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="typePay">
                            <th mat-header-cell  mat-sort-header *matHeaderCellDef> Pago </th>
                            <td mat-cell *matCellDef="let item">{{item?.typePay | titlecase}}</td>
                        </ng-container>
                        <ng-container matColumnDef="attend">
                            <!-- <th mat-header-cell *matHeaderCellDef width="120px"> <div class="text-center">Atender/procesar</div> </th>
                            <td mat-cell *matCellDef="let item">
                                <div class="text-center">
                                    <ng-container *ngIf="item.status == 'UNSEEN'">
                                        <button class="btn btn-primary w-100" (click)="openModal(modalAttend, 'sm')"><i class="fa fa-phone mr-1"></i> Atender</button>
                                    </ng-container>
                                    <ng-container *ngIf="item.status == 'INPROCESS'">
                                        <button class="btn btn-dark w-100"><i class="fa fa-check mr-1"></i> Procesar</button>
                                    </ng-container>
                                </div>
                            </td> -->
        
                            <th mat-header-cell *matHeaderCellDef width="120px"> <div class="text-center">Atender</div> </th>
                            <td mat-cell *matCellDef="let item">
                                <div class="text-center">
                                    <button class="btn btn-primary w-100" *ngIf="item?.status=='CREATED'"(click)=" openModal(modalAttend, 'sm', item)"><i class="fa fa-phone mr-1"></i> Atender</button>
                                    <button class="btn btn-dark w-100" *ngIf="item?.status=='ATTENDED'"(click)="processServiceSwal(item)"><i class="fa fa-phone mr-1"></i> Finalizar</button>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef width="80px"> <div class="text-center">Acción</div> </th>
                            <td mat-cell *matCellDef="let item">
                                <div class="text-center">
                                    <button class="btn btn-dark mr-2" (click)="undoServiceSwal(item)" title="Deshacer"><i class="fa fa-undo"></i></button>
                                    <button class="btn btn-danger mr-2" (click)="openModal(cancel, 'md', item); modalOptions.module = 'SERVICE'; modalOptions._id = item?._id" title="Cancelar"><i class="fa fa-close"></i></button>
                                    <button class="btn btn-info" (click)="printQuotation(item?.qoutationId)" title="Imprimir"><i class="fa fa-print"></i></button>
                                </div>
                            </td>
                        </ng-container>                
                          <!-- Default Table lines -->
                          <tr mat-header-row *matHeaderRowDef="displayColumnsServices"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayColumnsServices;"></tr>
        
                        
                          <!-- Group header cells-->
                          <!-- <ng-container matColumnDef="groupHeader">
                            <td [attr.colspan]="displayColumns.length" mat-cell *matCellDef="let group" class="py-1">
                              <div class="text-center"><b>{{group?.groupName.split('*')[1] | titlecase}}</b></div>
                            </td>
                          </ng-container> -->
                          <!-- <ng-container matColumnDef="regs">
                            <td colspan="1" mat-cell *matCellDef="let group">
                              <div fxFill fxLayoutAlign="center center">
                                <mat-icon *ngIf="!group.reduced">expand_less</mat-icon>
                                <mat-icon *ngIf="group.reduced">expand_more</mat-icon>
                              </div>
                            </td>
                          </ng-container> -->
                        
                          <!-- Group line -->
                          <!-- <tr mat-row class="_weekDay" *matRowDef="let row; columns: ['groupHeader']; when: isGroup"></tr> -->
        
                          <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell text-center" colspan="11">{{isLoading?"Cargando ...":"Sin datos encontrados"}}</td>
                          </tr>
                        
                    </table>
        
                </div>
                <div class="row border-top mx-0 mt-auto">
                    <div class="col-sm-12 p-0">
                        <mat-paginator #PaginatorServices="matPaginator" [pageSize]="8" showFirstLastButtons ></mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #cancel let-cancel>
    <app-modal-call-cancel (onSave)="cancel.dismiss(); getDataFiltered()" (onClose)="cancel.dismiss();"  [modalOptions]="modalOptions"></app-modal-call-cancel>
</ng-template>

<ng-template #modaAceptarlAttend let-modaAceptarlAttend>
    <app-atend-call (onClose)="modaAceptarlAttend.dismiss(); getDataFiltered()" [modalOptions]="modalOptions"></app-atend-call>
</ng-template>

<ng-template #modalAttend let-modal>
    <attend-service [modalOptions]="modalOptions" (onSave)="close(); getDataFiltered();" (onClose)="modal.dismiss()"></attend-service>
</ng-template>

<ng-template #modalView let-modal>
    <mk-loader *ngIf="isLoading" class="loader-spinner" style="z-index: 120"></mk-loader>
    <div class="modal-header">
        <h6 class="modal-title" id="modal-basic-title">Vista Previa</h6>
        <button type="button" class="close" (click)="close()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    
    <div class="modal-body p-0">
    <div class="row">
        <div class="col-12">
            <div class="pdf">
                <ng-container *ngIf="pdf">
                    <embed [src]="pdf" class="w-100 h-100">
                </ng-container>
            </div>
        </div>
    </div>
    </div>
    
    <div class="modal-footer justify-content-between">
    <!-- <button class="btn btn-dark" (click)="modal.dismiss()">Volver a Cotización</button> -->
    <div></div>
    <div class="d-flex">
        <!-- <button class="btn btn-info mr-3"><i class="fa fa-send mr-2"></i> Enviar</button> -->
        <button class="btn btn-success" (click)="close()" ><i class="fa fa-check mr-2"></i> Terminar</button>
    </div>
    </div>
    
</ng-template>