<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">{{modalOptions?.title}} </h6>
    <button type="button" class="close"  aria-label="Close" (click)="modalViewCancelled = null; close();">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <div class="row">
        <div class="col-md-6 mb-2">
            <h6>Cancelado por</h6>
            <div class="input-group">
                <input type="text" class="form-control" [value]="modalViewCancelled?.cancelledBy?.userName" readonly>
            </div>
        </div>
        <div class="col-md-6 mb-2">
            <h6>Fecha de Cancelacion</h6>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <i class="fe fe-calendar lh--9 op-6"></i>
                    </div>
                </div>
                <input type="text" class="form-control" [value]="modalViewCancelled?.cancelledBy?.date | date: 'dd/MM/YYYY - HH:mm'" readonly>
            </div>
        </div>

        <div class="col-md-12">
            <h6>Motivo</h6>
            <textarea class="form-control" rows="5" [value]="modalViewCancelled?.motive || ''" readonly placeholder="Sin Motivo"></textarea>
        </div>
    </div>
</div>
<div class="modal-footer justify-content-end">
    <button class="btn btn-dark" (click)="modalViewCancelled = null; close();">Cerrar</button>
</div>