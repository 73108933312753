import { Injectable } from '@angular/core';
import { PanelServiceComponent } from 'src/app/components/panel-service/panel-service.component';
import { LayoutService } from './layout.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgrammedServiceService {
  public openPanel = new Subject<void>();

  constructor(private layoutCtrl: LayoutService) {
  }

  public async load(){
  }

  public async openModal($event){
    
    this.openPanel.next();
    this.layoutCtrl.emitSidebarNotifyChange($event, PanelServiceComponent);
  }
}
