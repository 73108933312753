<div class="sidebar-title">
    <div class="d-flex justify-content-between">
        <div class="d-flex">
            <h1 class="m-0">Llamadas</h1>
            <!-- <div class="d-flex flex-column pl-3">
                <h4 class="color-tx m-0">2 llamadas activas</h4>
                <div class="d-flex mt-auto">
                    <span class="tx-16">1 nueva, 1 en proceso</span>
                </div>
            </div> -->
        </div>
        <div class="header-btns">                    
            <button class="btn btn-primary" *ngIf="permisions['CREATE']" (click)="openModal(modal, 'md')"><i class="fa fa-plus mr-2"></i> Nueva Llamada</button>
        </div> 
        <!-- <div class=" my-auto">
            <button class="btn btn-dark"><i class="fa fa-plus mr-2"></i> Nueva Llamada</button>
        </div> -->
    </div>
</div>

<div class="zoom-container">
    <div class="row mx-0 my-2">
        <div class="col-md-3 d-flex">
            <span class="my-auto" style="color: #0b509b;"><i class="fa fa-bullhorn mr-2"></i> Hay <b>{{badgetService?.callsBadget || 0}}</b> Llamadas activas.</span>
        </div>
        <div class="col-md-9 d-flex justify-content-end">
            <div class="d-flex _filters">
                <ng-container *ngFor="let filter of filters; index as i">
                    <div class="card flex-row mr-3 text-overflow" [style.background-color]="statusColors[filter.type]" [class.active]="filter.active" (click)="filter.active = !filter.active; filterDataByStatus()">
                        <b class="my-auto"><i class="fa fa-circle tx-10 mr-1"></i> {{filter.name}}</b>
                    </div>
                </ng-container>
            </div>
            <!-- BUSCAR  -->
            <div class="input-group search" style="width: 240px;">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <i class="fe fe-search lh--9 op-6"></i>
                    </div>
                </div>
                <input class="form-control" (keyup)="search($event)" [(ngModel)]="searchField" type="text" placeholder="Buscar...">
            </div>
            <!-- FECHA -->
            <!-- <div class="input-group date" style="width: 240px;">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <i class="fe fe-calendar lh--9 op-6"></i>
                    </div>
                </div>
                <input class="form-control" theme="dp-material">
            </div> -->
        </div>
    </div>
    <div class="row m-0 border-top">
        <div class="col-sm-12 p-0">
            <div class="table-responsive">
                <table mat-table [dataSource]="DT_Calls" matSort #Sort="matSort" matSortActive="date" matSortDirection="desc" class="table table-bordered table-striped mb-0">
                    <ng-container matColumnDef="folio">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Folio </th>
                        <td mat-cell *matCellDef="let item" style="--color: {{statusColors[item.status]}}; border-left: 4px solid var(--color) !important;">{{item.folio || "Sin Folio"}}</td>
                    </ng-container>
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header width="180px">F. aplicación</th>
                        <td mat-cell *matCellDef="let item">{{ item.date?.split("*")[1] }}</td>
                    </ng-container>
                    <ng-container matColumnDef="dateCreate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header width="180px">Fecha</th>
                        <td mat-cell *matCellDef="let item">{{ item.dateCreate?.split("*")[1] }}</td>
                    </ng-container>
                    <ng-container matColumnDef="createdByUser">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Recibido por </th>
                        <td mat-cell *matCellDef="let item">{{item.createdByUser | titlecase}}</td>
                    </ng-container>
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Lugar </th>
                        <td mat-cell *matCellDef="let item"><b>{{item.description}}</b></td>
                    </ng-container>
                    <ng-container matColumnDef="processType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
                        <td mat-cell *matCellDef="let item">{{item?.processType | titlecase}}</td>
                    </ng-container>
                    <ng-container matColumnDef="attendedByUser">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Atendido por </th>
                        <td mat-cell *matCellDef="let item">
                            <div class="text-center lh-1">
                                <ng-container><span [class.text-danger]="!item.attendedBy?.userName">{{item.attendedByUser | titlecase}}</span></ng-container>
                                <ng-container *ngIf="item?.attendedBy?.userNameExtra"><br> <small class="text-info">{{item.attendedByExtraUser | titlecase}}</small></ng-container>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="attendedByDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header width="180px"> F. Atendido </th>
                        <td mat-cell *matCellDef="let item">
                            <div class="text-center">
                                <ng-container><span [class.text-danger]="!item.attendedBy?.date">{{item.attendedByDate?.split("*")[1] || 'Sin Atender'}}</span></ng-container>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="attend">
                        <th mat-header-cell *matHeaderCellDef width="120px"> <div class="text-center">Atender</div> </th>
                        <td mat-cell *matCellDef="let item">
                            <div class="text-center">
                                <span class="text-danger" title="Llamada bloqueada"><i class="fa fa-lock" *ngIf="item.block"></i></span>
                                <button class="btn btn-primary w-100" *ngIf="item.status=='CREATED' && !item.block"(click)="modalOptions.item = item; openModal(modaAceptarlAttend, 'sm')"><i class="fa fa-phone mr-1"></i> Atender</button>
                                <button class="btn btn-dark w-100" *ngIf="item.status=='ATTENDED' && !item.block"(click)="processCallSwal(item)"><i class="fa fa-phone mr-1"></i> Finalizar</button>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef width="60px"> <div class="text-center">Acción</div> </th>
                        <td mat-cell *matCellDef="let item">
                            <div class="text-center">
                                <span class="text-danger" title="Llamada bloqueada"><i class="fa fa-lock" *ngIf="item.block"></i></span>
                                <button class="btn btn-dark mr-2" title="Deshacer"  (click)="modalOptions.item = item; openModal(modalAuth, 'sm');" *ngIf="item.status=='ATTENDED' && !item.block"><i class="fa fa-undo"></i></button>
                                <button class="btn btn-danger" title="Cancelar" *ngIf="item.status!='PROCESSED' && !item.block && permisions['DELETE']" (click)="modalOptions.item = item;openModal(cancel)"><i class="fa fa-close"></i></button>
                            </div>
                        </td>
                    </ng-container>
    
                    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns;" [style.background-color]="row.urgent ? '#ff19194d' : (row.delay ? '#19acff4d' : '')"></tr>
        
                    <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell text-center" [attr.colspan]="displayColumns.length">{{isLoading?"Cargando ...":"Sin datos encontrados"}}</td>
                    </tr>
                  </table>
            </div>
        </div>
    </div>
    
    <div class="row border-top mx-0 mt-auto">
        <div class="col-sm-12 p-0">
            <mat-paginator #Paginator="matPaginator" [pageSize]="tableService.getRowCount(38)" showFirstLastButtons ></mat-paginator>
        </div>
    </div>
</div>

<!-- <div class="modal-footer justify-content-end">
  <button class="btn btn-success" (click)="close()">Aceptar</button>
</div> -->


<!-- TEMPLATES -->
<ng-template #cancel let-cancel>
    <app-modal-call-cancel (onSave)="cancel.dismiss(); getDataFiltered()" (onClose)="cancel.dismiss();"  [modalOptions]="modalOptions"></app-modal-call-cancel>
</ng-template>

<ng-template #modaAceptarlAttend let-modaAceptarlAttend>
    <app-atend-call (onClose)="modaAceptarlAttend.dismiss(); getDataFiltered()" [modalOptions]="modalOptions"></app-atend-call>
</ng-template>


<ng-template #modal let-modal>
    <modal-call (onClose)="modal.dismiss();" [modalOptions]="modalOptionsCall"></modal-call>
</ng-template>

<ng-template #modalAuth let-modalAuth>
    <app-modal-auth  [reason]="'Regresar el estatus a sin atender'" (onSuccess)="modalAuth.dismiss(); undoCallSwal(modalOptions.item)" (onClose)="modalAuth.dismiss();"></app-modal-auth>
</ng-template>



<!-- <swal #swalbackUnatended class="d-none" title="Regresar a sin atender ?" icon="question"
  [showCancelButton]="true" [focusCancel]="true"
  [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal> -->