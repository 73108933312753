<mk-loader *ngIf="isLoading" class="loader-spinner" ></mk-loader>
<div class="modal-header">
  <h6 class="modal-title" id="modal-basic-title">Vista de PDF</h6>
  <button type="button" class="close" aria-label="Close" (click)="close();">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-0">
  <!-- PDF -->
  <div class="row">
    <div class="col-12">
      <div class="pdf">
        <ng-container *ngIf="pdf">
          <embed [src]="pdf" type="application/pdf" class="w-100 h-100">
        </ng-container>
      </div>
    </div>
  </div>
  
</div>
<div class="modal-footer justify-content-end">
  <button type="button" class="btn btn-success" (click)="close();">Aceptar</button>
</div>