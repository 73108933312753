import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { LayoutService } from '../../services/layout.service';

@Component({
  selector: 'app-notification-sidebar',
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.scss'],
  host: {
  },
})
export class NotificationSidebarComponent implements OnInit {
  
  layoutSub: Subscription;
  isOpen: boolean = false;
  template: any;

  public isTemplate: boolean = false;

  @ViewChild('sidebar', {static: false}) sidebar: ElementRef;
  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    private layoutService: LayoutService
  ) {
    this.layoutSub = layoutService.SidebarNotifyChangeEmitted.subscribe(
      value => {
 
          this.onClose();
          setTimeout(() => {
            this.template = value.template;
            this.isOpen = true;
            this.isTemplate = typeof(value.template) == "object"
            
              this.renderer.addClass(this.sidebar.nativeElement, 'sidebar-open');
              document.querySelector('body').classList.add('overflow-hidden');
          }, 100);
      }
    );
  }

  ngOnInit(): void {
  }

  ngOnDestroy(){
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  onClose(){    
    this.renderer.removeClass(this.sidebar.nativeElement, 'sidebar-open');
    this.isOpen = false;
    this.template = null;
  }
}
