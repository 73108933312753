<div class="modal-body">
    <div class="row border-bottom">
        <div class="col-12 p-3 d-flex">
            <i class="fa fa-money fa-2x my-auto text-primary"></i>
            <div class="d-flex flex-column ml-3 lh-1">
                    <h3 class="m-0"> Movimiento</h3>
                    <span class="text-muted">Registra un ingreso o egreso.</span>
            </div>
        </div>
    </div>
    <div class="wrap py-3">
            <span class="text-info mb-1">Importe</span>
            <input type="text" class="_input" placeholder="$0.00" step="0.01" [(ngModel)]="movement.amount" [textMask]="{mask: currencyMask}">
            <span class="text-info my-2">Descripción</span>
            <textarea name="" id="" cols="30" rows="3" class="form-control"  placeholder="Descripción" [(ngModel)]="movement.description"></textarea>

            <div class="btn-group mt-3">
                <button class="btn btn-{{movement.type == 'INCOME' ? 'info':'light text-info'}}" (click)="movement.type = 'INCOME'">Ingreso</button>
                <button class="btn btn-{{movement.type == 'OUTFLOW' ? 'info':'light text-info'}}" (click)="movement.type = 'OUTFLOW'">Egreso</button>
            </div>
    </div>
    
</div>
<div class="modal-footer justify-content-between">
    <button class="btn btn-danger" (click)="close()"> Cancelar</button>
    <button class="btn btn-success"  [disabled]="disableBtn()"  (click)="save()"> Guardar </button>
</div>