<div class="row m-0 border-bottom pointer-events-none">
    <div class="col-12 text-right">
        <h4 class="my-3 lh-9">Notificaciones</h4>
    </div>
  </div>
  <div class="w-100 d-flex flex-column">
    <div class="_notif border-bottom p-2" *ngFor="let item of notifications">
        <div class="top d-flex flex-column mb-1">
        <b class="mb-1"><i class="fa fa-circle mr-2 text-primary"></i>{{item?.title || '...'}}</b>
        <span class="">{{item?.description || '...'}}</span>
        </div>
        <div class="info d-flex justify-content-between">
            <b class="text-primary">{{item.page | titlecase}}</b>
            <span class="text-muted">{{item.date | moment: "lll"}}</span>
        </div>
    </div>
  </div>