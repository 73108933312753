<mk-loader *ngIf="isLoading" class="loader-spinner" style="z-index: 120"></mk-loader>
<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title"><i class="fa fa-phone mr-2"></i> Cancelar {{(modalOptions?.module == "CALLS") ? "Llamada" : "Servicio Programado"}}</h6>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
 
<div class="modal-body">
    <form [formGroup] ="cancelForm">
        <div class="row">
        </div>
        <div class="row">
          <div class="col-sm-12">
              <h6>Motivo de Cancelación</h6>
              <div class="input-group">
                  <textarea class="form-control" placeholder="Ingresa el Motivo de Cancelación" formControlName="motive"></textarea>
              </div>
              <!-- <small class="tx-danger" *ngIf="fieldIsValid.campoEsValido('motive', cancelForm)">Motivo de Cancelación requerido.</small> -->
          </div>
      </div>

    </form>
</div>

<div class="modal-footer justify-content-between">
    <button class="btn btn-danger" (click)="close()">Cancelar</button>

    <ng-container >
        <button class="btn btn-success" (click)="openModal(modal)">Autorizar</button>
    </ng-container>
</div>

<ng-template #modal let-modal>
    <app-modal-auth  [reason]="cancelForm.value?.motive" (onSuccess)="submit($event); modal.dismiss()" (onClose)="modal.dismiss()"></app-modal-auth>
</ng-template>