<mk-loader *ngIf="isLoading" class="loader-spinner"></mk-loader>
<div class="modal-header">
    <h6 *ngIf="modalOptions.type == 'VIEW'" class="modal-title" id="modal-basic-title">Detalle de Referencia</h6>
    <h6 *ngIf="modalOptions.type == 'EDIT'" class="modal-title" id="modal-basic-title">Editar Referencia</h6>
    <h6 *ngIf="modalOptions.type == 'ADD'" class="modal-title" id="modal-basic-title">Agregar Referencia</h6>

     <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body py-0">
    <form [formGroup]="FormReference" novalidate>
        <div class="row">
            <div class="col-sm-12 col-lg-12 py-3 border-right">
                <div class="row mt-2">
                    <div class="col-12">
                        <h6>Nombre</h6>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Nombre" formControlName="name" maxlength="60">
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12">
                        <h6>Dirección</h6>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Dirección" formControlName="address" maxlength="100" >
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-6">
                        <h6>Razón Social</h6>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Nombre" formControlName="bussinesName" maxlength="60">
                        </div>
                    </div>
                    <div class="col-6">
                        <h6>RFC</h6>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="RFC" formControlName="rfc" maxlength="60">
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12 mt-2">
                        <h6>Teléfono(s)</h6>
                        <div class="input-group">
                            <ng-select class="form-control px-1 text-left" [clearable]="false" [disabled]="blockEdition" [(ngModel)]="typePhone" (change)="changePhoneType($event)"
                            [ngModelOptions]="{standalone: true}" placeholder="Tipo de telefono">
                                <ng-option value="CEL">Celular</ng-option>
                                <ng-option value="HOME">Casa</ng-option>
                                <ng-option value="OFFICE">Oficina</ng-option>
                            </ng-select>
                            <input type="text" class="form-control" 
                                [placeholder]="typePhone!='OFFICE'?'(___)___-____':'(___)___-____;__'" 
                                [(ngModel)]="inpPhone"
                                [ngModelOptions]="{standalone: true}"
                                [disabled]="blockEdition"
                                [textMask]="{mask: phoneMask, guide: false}" 
                            >
                            <div class="input-group-append">
                                <button type="button" class="btn btn-dark" (click)="addPhones()" [disabled]="inpPhone?.length < 9"><i class="fa fa-plus mr-2"></i>Añadir</button>
                            </div>
                        </div>
                    </div>   
                    <div class="col-12 d-flex flex-wrap pt-2" *ngIf="phones?.length">
                        <ng-container *ngFor="let item of phones; index as i">
                            <div class="contact-badges">
                                <span class="badge badge-primary d-flex mr-2" >
                                    <div class="d-flex cursor-pointer" (click)="modalOptions.type != 'VIEW' ? primaryPhone(true, i): ''">
                                        <i class="fa fa-star mr-1 text-{{item.primary ? 'warning' : 'white'}}"></i>
                                        <b>{{item.phone}} <i class="fa fa-{{item.type == 'HOME' ? 'home':(item.type == 'OFFICE'?'building':'mobile')}} ml-1"></i></b>
                                    </div>
                                    <i *ngIf="modalOptions.type != 'VIEW'"  class="fa fa-close ml-2 cursor-pointer" (click)="phones.splice(i,1)"></i>
                                </span>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-12 mt-2">
                        <h6>Correo(s)</h6>
                        <div class="input-group">
                            <input #inpEmail type="text" class="form-control" placeholder="Correo" maxlength="30" [disabled]="blockEdition"> 
                            <ng-select class="form-control" placeholder="Dominio"  [disabled]="blockEdition"
                                [clearable]="false"  
                                [items]="domainsData"
                                bindLabel="name" bindValue="name"
                                [(ngModel)]="domain"
                                [ngModelOptions]="{standalone: true}"
                            ></ng-select>
                            <div class="input-group-append"> 
                                <button type="button" class="btn btn-dark"  (click)="addEmails(inpEmail.value, domain)" [disabled]="!inpEmail.value || !domain"><i class="fa fa-plus mr-2"></i>Añadir</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex flex-wrap pt-2" *ngIf="emails?.length">
                        <ng-container *ngFor="let item of emails; index as i">
                            <div class="contact-badges">
                                <span class="badge badge-primary d-flex mr-2" >
                                    <div class="d-flex cursor-pointer" (click)="modalOptions.type != 'VIEW' ? primaryMail(!item.primary, i): ''">
                                        <i class="fa fa-star mr-1 text-{{item.primary ? 'warning' : 'white'}}"></i>
                                        <b>{{item.email}}</b>
                                    </div>
                                    <i *ngIf="modalOptions.type != 'VIEW'"  class="fa fa-close ml-2 cursor-pointer" (click)="emails.splice(i,1)"></i>
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </div>   
            </div>
        </div>
    </form>
</div>

<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-dark" (click)="close()">Cerrar</button>
    <button *ngIf="modalOptions.type != 'VIEW'" type="button" class="btn btn-success" (click)="submit()">Guardar</button>
</div>