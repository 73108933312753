import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DropdownService } from 'src/app/shared/services/dropdown.service';
import { ZoomService } from 'src/app/shared/services/zoom.service';

@Component({
  selector: 'mkt-dropdown',
  templateUrl: './mkt-dropwdown.component.html',
  styleUrls: ['./mkt-dropwdown.component.scss'],
  host: {
    'class': '',
    '[class.show]': 'show',
    '[class.center]': 'center',
    // '(mouseleave)': 'service.closeDialog()',
    '(click)': 'service.closeDialog($event)',
  }
})
export class MktDropwdownComponent implements OnInit {

  public show: boolean = false;
  public center: boolean = false;
  public position: IPosition = {x: 0, y: 0};
  public lockBot: boolean = false
  public template: any = null;

  public screenW = window.innerWidth;
  public screenH = window.innerHeight;

  public initHeight: any = 'auto';

  @ViewChild("dialog") dialog : ElementRef;

  constructor(private service: DropdownService, public zoom: ZoomService) {
    this.service.dataEvent
      .subscribe(res => {

        if(res.action != "height"){
          this.show = res.show;
          this.center = res.center;
          this.position = res.center ? {x: null, y: null} : {x: res.event.x - (res.offset - 0), y: res.event.y};
          this.template = res.template;
          this.initHeight = 'auto';
  
          if (res.show) document.querySelector('body').classList.add('overflow-hidden'); else {document.querySelector('body').classList.remove('overflow-hidden')};
        } else {
          this.initHeight = res.height != "auto" ? res.height+"px" : res.height;
          this.lockBot = (res.height + this.position.y + 10) > this.screenH;
        }
      });
  }

  ngOnInit(): void {}

  // getY():number{
  //   let posY = y;

  //   if ((screen.height - 300) < y) posY = ((screen.height - 300) / (screen.height / y)) + 10
  //                                                        ^ popup height
  // }
  
  
}

interface IPosition {x: number, y: number}