import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { AlertService } from 'src/app/shared/services/alert.service';
import { StudyService } from 'src/app/shared/services/http/study.service';

@Component({
  selector: 'cat-modal-containers',
  templateUrl: './modal-containers.component.html',
  styleUrls: ['./modal-containers.component.scss']
})
export class ModalContainersComponent implements OnInit {

  @Input() modalOptionsContainers = {id: null, type: 'ADD', item: null}

  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onAddcontainer: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onEditcontainer: EventEmitter<boolean> = new EventEmitter<boolean>();

  close = () => {this.onClose.emit()}
  public containerForm: FormGroup;
  public sampleTypeList = [];
  public colorList = [{code:'#df4a54', name: "Rojo"}, {code:'#ffb748', name:"Naranja Bajito"}, {code:'#2dc8f0', name: "Azul Bajito"}, {code:'#9665a6', name: "Morado"}, {code:'#008348', name: "Verde"}, {code:'#7f8c8d', name:"Gris"}, {code:'#f1c40f', name:"Amarillo"}, {code:'#e67e22', name: "Naranja Fuerte"}, {code:'#cfb786', name: "Beige"}, {code:'#1e3799', name: "Azul Fuerte"}, {code:'#ffffff', name: "Blanco"}];
  public containerList = [
    // {label:"Tubo APTIMA" ,value: "a56s56da675as56"}
  ]
  constructor(       
    private fb: FormBuilder,
    private alertService: AlertService,
    public validateForm: ValidateFormsDirective,
    public studyServices: StudyService
    ) {

   }

  ngOnInit(): void {
    this.containerForm = this.fb.group({
      colorActive: ['#df4a54'],
      color: [{code:'#df4a54', name: "Rojo"},  Validators.required],
      type:[null],
      sample:[null],
      labels:[[]],
      _id: [null]
    });

    this.getCatalogs();

    if (this.modalOptionsContainers.type == 'EDIT') {
      //let labelsString = JSON.stringify(this.modalOptionsContainers?.item?.labels);
      
      this.containerForm.patchValue({
        _id: this.modalOptionsContainers?.item?._id,
        color: this.modalOptionsContainers?.item?.color,
        type: this.modalOptionsContainers?.item?.type,
        sample: this.modalOptionsContainers?.item?.sample,
        labels: this.modalOptionsContainers?.item?.labels,
        colorActive: this.modalOptionsContainers?.item?.color?.code
      });
    }
  }

  ngOnChanges(change: SimpleChanges){
    console.log("change", change);
    
  }

  public addContainer = () => {
    if (!this.containerForm.valid) {
      this.alertService.infoToast("Campos incompletos");      
      this.containerForm.markAllAsTouched();        
      return;
    }
    
    if (this.containerForm.value.labels) {
      this.containerForm.value.labels = JSON.parse(this.containerForm.value.labels)
      
      let tmptags = [];
      for (const iterator of  this.containerForm.value.labels) {
        if (iterator?.id) {
          tmptags.push(iterator?.id)
        }else{
          tmptags.push(iterator?.label)
        }
      }
      
      this.containerForm.value.labels =  JSON.stringify(tmptags);
    }else{
      delete this.containerForm.value.labels
    }

    this.containerForm.patchValue({
      _id: Date.now()
    });

    this.onAddcontainer.emit(this.containerForm.value);
    this.onClose.emit();
  }

  public selectColor = (event) => {
    let colorFinded = this.colorList.find(element => {return element.code == this.containerForm.value.colorActive})
    this.containerForm.patchValue({
      color: colorFinded
    });    
  }

  public editContainer = () => {  
    if (!this.containerForm.valid) {
      this.alertService.infoToast("Campos incompletos");      
      this.containerForm.markAllAsTouched();        
      return;
    }    

    this.onEditcontainer.emit(this.containerForm.value);
    this.onClose.emit();
  }

  public getCatalogs = async () => {
    try {
      //Tipo de muestra
      await this.studyServices.getSampleTypeSelect().then(resp => {
          this.sampleTypeList = resp;
      });

     //Tipo de contenedor
      await this.studyServices.getContainerType().then(resp => {
           this.containerList = resp;           
      });
    } catch (error) {
      console.log(error);
    }
  }
}
