import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpService } from 'src/app/shared/services/http.service';

@Component({
  selector: 'modal-view',
  templateUrl: './modal-view.component.html',
  styleUrls: ['./modal-view.component.scss']
})
export class ModalViewComponent implements OnInit {
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Input() url: File | string = null;
  @Input() isAPI: any = true;

  public pdf = null;
  public isLoading: boolean = false;
  constructor(
    private sanitazer: DomSanitizer,
    private httpService: HttpService,
  ) { 
  }

  async ngOnInit() {
    try {
      if (this.isAPI) {
        this.httpService.postBlob(`api/customer/indicationPlant`, { name: this.url }, 'application/json').subscribe(async (response)=>{
          if (response) {
            let url = URL.createObjectURL(response);
            this.pdf = this.sanitazer.bypassSecurityTrustResourceUrl(url);
            this.isLoading = false;
          }
        },error => {
          console.log(error);
          this.pdf = null;
          this.isLoading = false;
        })
      }else{
        this.isLoading = true;
        if (typeof this.url == 'string'){
          this.httpService.getBlob(`api/customer/indicationPlant/${this.url}`, 'application/json').subscribe(async (response)=>{
            if (response) {
              let url = URL.createObjectURL(response);
              this.pdf = this.sanitazer.bypassSecurityTrustResourceUrl(url);
              this.isLoading = false;
            }
          },error => {
            console.log(error);
            this.pdf = null;
            this.isLoading = false;
          });
        }else if(this.url instanceof File){
          let url = URL.createObjectURL(this.url);
          this.pdf = this.sanitazer.bypassSecurityTrustResourceUrl(url);
          this.isLoading = false;
        }
      }
    } catch (error) {
      console.log(error);
      this.pdf = null;
      this.isLoading = false;
    }
    
  }

  close(): void { this.onClose.emit() }

}
