import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from 'src/app/shared/services/alert.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'detail-appointment',
  templateUrl: './detail-appointment.component.html',
  styleUrls: ['./detail-appointment.component.scss']
})

export class DetailAppointmentComponent implements OnInit {
  @Input() options: any;
  @Output() onDetailRefresh: EventEmitter<any> = new EventEmitter<any>();
  
  public detail: any;
  public isLoading: boolean = true;
  public imgUser: string = '';

  constructor(
    private httpService: HttpService,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges():void{
    this.loadDetails();
  }
  
  async loadDetails(){
    try {
      this.detail = await this.httpService.get(`api/appointment/detail/${this.options.idAppointment}`).toPromise();
      this.imgUser = (this.detail?.patient?.image)?`${environment.apiUrl}${this.detail?.patient?.image}`:'';
      this.isLoading = false;
    } catch (error) {
      console.log(error);
      this.isLoading = false;
      this.alertService.error("Ocurrió un error", "No se pudo cargar el detalle de la cita", "Aceptar");
    }
  }

  async cancelAppointment(){
    try {
      await this.httpService.delete(`api/appointment/cancel/${this.options.idAppointment}`).toPromise().then(resp => {
        this.refresh(); 
        this.alertService.successToast(resp.message);
      });
    } catch (error) {
      console.log(error);
      this.alertService.error("Ocurrió un error", "No se pudo cancelar la cita", "Aceptar");
    }
  }

  public refresh(): void { 
    this.loadDetails();
    this.onDetailRefresh.emit(); 
  }

}
