import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ZoomService {

  private zoom: number = .9;

  constructor() { this.applyZoom(); }

  public get getZoom(){return (this.zoom * 100) + "%";}
  
  public get getZoomValue(){return this.zoom;}

  public setZoom(val: number){
    this.zoom = val/100;
    this.applyZoom();
  };

  applyZoom(){
    const styleNode = document.createElement("style");
    styleNode.innerHTML=`
      :root{--vh: ${(window.innerHeight / this.zoom) / 100}px;--vw: ${(window.innerWidth / this.zoom) / 100}px; --zoomValue: ${this.zoom};} 
      .__zoom, .modal-content .modal-header, .modal-content .modal-body, .modal-content .modal-footer, .pac-container{zoom: calc(100% * var(--zoomValue))}
    `
    document.getElementsByTagName('head')[0].appendChild(styleNode);
  }
}
