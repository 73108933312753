<!-- Main Header-->
<div class="main-header side-header sticky">

    <div class="container-fluid">
        <div class="main-header-left">
            <div class="main-header-notification" (click)="true?exchangeService.loadAmount():null">
                <a href="javascript:void(0)" class="nav-link icon hover">
                    <i class="fe fe-dollar-sign header-icons"></i>
                    <span class="exchange">{{(exchangeService.amount | number:'1.2-2') || '...'}}</span>
                    <span class="badge bg-primary text-white nav-link-badge">Tipo de cambio</span>
                </a>
            </div>
        </div>
        <!-- <div class="main-header-left mobileIcon">
            <a class="main-header-menu-icon" href="javascript:void(0)" id="mainSidebarToggle" (click)="toggleMobileSidebar()">
                <img src="../../../../assets/img/K-fdbk.svg" alt="">
            </a>
        </div> -->
        <div class="main-header-center">
            <!-- <div class="responsive-logo d-block d-md-none">
                <a routerLink="/dashboard"><img src="../../../../assets/img/brand/logo.svg" class="mobile-logo" alt="logo"></a>
                <a routerLink="/dashboard"><img src="../../../../assets/img/brand/logo-light.svg" class="mobile-logo-dark" alt="logo"></a> 
            </div> -->

            <app-global-alert></app-global-alert>

            <!--Mensaje de alerta!-->
            <ngb-alert type="danger" [dismissible]="false" style="margin-top: 12px; text-align: center;"
                *ngIf="userService.mensaje">
                <strong>{{userService.mensaje}}</strong>
            </ngb-alert>
        </div>
        <div class="main-header-right">
            <!-- <div class="main-header-notification" (click)="layoutCtrl.emitSidebarNotifyChange($event, notifications)">
                <a href="javascript:void(0)" class="nav-link icon">
                    <i class="fe fe-bell header-icons"></i>
                    <span class="badge bg-danger text-white nav-link-badge">1</span>
                </a>
            </div> -->
            <div class="d-flex my-auto px-2">
                <!-- <div class="main-profile-menu">
                    <a class="card _headerBox d-flex flex-row my-auto urgency"  href="javascript:void(0)" (click)="this.urgenciesService.openModal($event)">
                        <div class="img"><i class="fa fa-exclamation-triangle"></i></div>
                        <div class="d-flex flex-column" *ngIf="badgetService?.urgencysBadget">
                            <span class="name text-white lh-6 bg-danger"><b>{{badgetService?.urgencysBadget || 0}}</b></span>
                        </div>
                    </a>
                </div> -->
                <div class="main-profile-menu">
                    <a class="card _headerBox d-flex flex-row my-auto calls"  href="javascript:void(0)" (click)="this.callsService.openModal($event)">
                        <div class="img"><i class="fa fa-bullhorn"></i></div>
                        <div class="d-flex flex-column" *ngIf="badgetService?.callsBadget">
                            <span class="name lh-6 text-white bg-danger"><b>{{badgetService?.callsBadget || 0}}</b></span>
                        </div>
                    </a>
                </div>
                <div class="main-profile-menu">
                    <a class="card _headerBox d-flex flex-row my-auto service"  href="javascript:void(0)" (click)="this.programmedService.openModal($event)">
                        <div class="img"><i class="fa fa-clock"></i></div>
                        <div class="d-flex flex-column" *ngIf="badgetService?.servicesBadget">
                            <span class="name lh-6 text-white bg-danger"><b>{{badgetService?.servicesBadget || 0}}</b></span>
                        </div>
                    </a>
                </div>
                

                <div ngbDropdown class="main-profile-menu">
                    <a class="card _headerBox d-flex flex-row my-auto" ngbDropdownToggle  href="javascript:void(0)" (click)="this.userService?.user?.cashRegisterTitle ? '' : this.brancService.openModal()">
                        <img src="../../../../assets/img/store-solid.svg" alt="">
                        <div class="d-flex flex-column">
                            <span class="name lh-6">{{this.brancService.branch?.name | titlecase}}</span>
                            <!-- <small class="text-danger">Cerrar Caja</small> -->
                        </div>
                    </a>
                </div>

                <div ngbDropdown class="main-profile-menu">
                    <a class="card _headerBox d-flex flex-row my-auto" ngbDropdownToggle  href="javascript:void(0)">
                        <img src="../../../../assets/img/cash-register.svg" alt="">
                        <div class="d-flex flex-column">
                            <span class="name lh-6" [class.op-5]="!this.userService.user.cashRegisterTitle">{{((this.userService.user.cashRegisterTitle||this.userService.user?.cashRegisterDefault?.title) | titlecase) || "Sin Caja"}}</span>
                            <!-- <small class="text-danger">Cerrar Caja</small> -->
                        </div>
                    </a>
                    <div ngbDropdownMenu *ngIf="userService.user.cashRegister">
                        <div class="header-navheading">

                            <h6 class="main-notification-title">{{this.userService.user.cashRegisterTitle | titlecase}}</h6>
                            <!-- <p class="main-notification-text"></p> -->
                        </div>
                        <button type="button" ngbDropdownItem class="" (click)="movementService.openModal(userService.user.cashRegister)">
                            <i class="fe fe-dollar-sign"></i> Movimiento
                        </button>
                        <button type="button" ngbDropdownItem class="" (click)="cashRegisterService.closeCashRegisterModal(this.userService.user.cashRegister)">
                            <span class="text-danger"><i class="fe fe-power"></i> Cerrar Caja</span>
                        </button>
                    </div>
                </div>
                <div class="input-group" *ngIf="false">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                        <i class="fa fa-building lh--9 op-6"></i>
                        </div>
                    </div>
                    <ng-select class="form-control" placeholder="Selecciona Sucursal"></ng-select>
                </div>
            </div>
            <div ngbDropdown class="main-profile-menu">
                <a ngbDropdownToggle class="d-flex" href="javascript:void(0)">
                    <span class="main-img-user">{{user.name | initials}}</span>
                </a>
                <div ngbDropdownMenu>
                    <div class="header-navheading">

                        <h6 class="main-notification-title"> {{user?.name}}</h6>
                        <p class="main-notification-text">{{user?.email}}</p>
                    </div>
                    <button type="button" ngbDropdownItem class="" (click)="signOutHandler()">
                        <i class="fe fe-power"></i> Sign Out
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Header-->

<div class="jumps-prevent" style="padding-top: 64px;"></div>

<ng-template #notifications>
    <app-notifications></app-notifications>
</ng-template>