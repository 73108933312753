import { AlertService } from './../../shared/services/alert.service';
import { ExchangeRateService } from './../../shared/services/exchangeRate.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'modal-exchangeRate',
  templateUrl: './modal-exchangeRate.component.html',
  styleUrls: ['./modal-exchangeRate.component.scss']
})
export class ModalExchangeRateComponent implements OnInit {

  public currencyMask = createNumberMask({
    prefix: '$',
    includeThousandsSeparator: true,
    allowDecimal: true,
    decimalLimit: 2,
    integerLimit: 2
  })

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>(); 
  @Output() onSave: EventEmitter<any> = new EventEmitter<any>(); 
  @Input() dateSelected: any; 
  
  public isLoading: boolean = false;
  public amount: string;

  constructor(
    private exchangeRateService: ExchangeRateService,
    private alertService: AlertService,
    private currency: CurrencyPipe
  ) { 
    this.amount = this.currency.transform(exchangeRateService.amount)
  }

  async ngOnInit(){  
  }


  async save(){
    // variable numerica para manejar el amount
    let parsedAmount = Number.parseFloat(this.amount.replace('$',''));
    if (parsedAmount != this.exchangeRateService.amount) {
      await this.exchangeRateService.updateAmount({amount: parsedAmount});
      this.exchangeRateService.amount = parsedAmount;
      this.alertService.successToast("El tipo de cambio se actualizo correctamente");
    }
    this.close();
  }
  // Cerrar modal
  close(): void { this.exchangeRateService.modal.close() }

  isNumberKey(evt)
  {
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57) || evt.target.value.length > 4 )
      return false;
      
      return true;
  }

}
