import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { DecryptPipe } from 'src/app/shared/pipes/decrypt.pipe';
import { ValidatorsService } from 'src/app/shared/services/validators.service';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';

@Component({
  selector: 'cat-modal-reference',
  templateUrl: './modal-reference.component.html',
  styleUrls: ['./modal-reference.component.scss']
})
export class ModalReferenceComponent implements OnInit {

  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() modalOptions: any = null;

  public FormReference: FormGroup;
  public isLoading: boolean = false;
  public phones: any[] = [];
  public emails: any[] = [];
  public typePhone: string = 'CEL';
  public inpPhone: any = null;
  public phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  public domainsData: any[] = [];
  public domain: any;
  public blockEdition: boolean = false;

  @ViewChild('inpEmail') private inpEmail: any;


  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private alertService: AlertService,
    private decryp: DecryptPipe,
    public validator: ValidatorsService,
    private validateForm: ValidateFormsDirective
  ) { }

  ngOnInit(): void {

    this.FormReference = this.fb.group({
      name: [null, Validators.compose([Validators.required, Validators.maxLength(60)])],
      phone: [[]],
      email: [[]], 
      address: [null, Validators.compose([Validators.maxLength(100)])],
      rfc: [null],
      bussinesName: [null]
    });
      this.loadDomains();

    if (this.modalOptions.type == 'EDIT' || this.modalOptions.type == 'VIEW') {
      this.detailReference();

    }

    if(this.modalOptions.type == 'VIEW' || (!this.modalOptions?.permissions?.EDIT && this.modalOptions.type == 'EDIT')){
      this.FormReference.disable();
      this.blockEdition = true;
    }
  }

  async detailReference(){
    this.isLoading = true;

    try {
      let data = await this.httpService.get(`api/references/${this.modalOptions.id}`).toPromise();      
      this.FormReference.patchValue({
        name: this.decryp.transform(data?.name),
        address: data?.address
      });

      for ( const p of data?.phones || []){p.phone = this.decryp.transform(p.phone)};
      for ( const m of data?.emails || []){m.email = this.decryp.transform(m.email)}; 

      this.phones = data?.phones || [];
      this.emails = data?.emails || [];

      this.isLoading = false;
    } catch (error) {
      console.log(error.message);
      this.isLoading = false;
    }
  }

  public primaryMail(event: any, index: number):void{
    this.emails.find(mail => {return mail.primary})?(this.emails.find(mail => {return mail.primary}).primary = false):null;
    this.emails[index].primary = event//.target.checked;
  }

  public primaryPhone(event: any, index: number): void {
    this.phones.find(phone => { return phone.primary }) ? (this.phones.find(phone => { return phone.primary }).primary = false) : null;
    this.phones[index].primary = event//.target.checked;
  }

  close(){this.onClose.emit()}

  public addPhones(){  
    if (this.phones?.filter(item => {return item.phone == this.inpPhone}).length == 0) {
      this.phones.push({"phone": this.inpPhone, type: this.typePhone, "primary": false}); 
      this.inpPhone = null;
      this.typePhone = null;
    }else{
      this.alertService.infoToast("El teléfono ya se encuentra agregado.")
    } 
  }

  public changePhoneType(event: any):void{
    this.inpPhone = null;
    if (event != "OFFICE") {
      this.phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }else{
      this.phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, ';', /\d/, /\d?/, /\d?/, /\d?/];
    }
  }

  public addEmails(mail: string, domain: any): void{
    mail = mail.replace(/\s/g,"");
    if (this.emails?.filter(item => {return item.email == mail+domain}).length == 0) {
      this.emails.push({"email": mail+domain, "primary": false});
      this.inpEmail.nativeElement.value = null;
      this.domain = null
    }else{
      this.alertService.infoToast("El correo ya se encuentra agregado.")
    }
  }

  async loadDomains(){
    try {
      this.domainsData = await this.httpService.get(`api/domain/select`).toPromise();
    } catch (error) {
      console.log(error);
    }
  }

  public async submit(){
    this.isLoading = true;

    let obj = await this.validateForm.clearNullObjects(this.FormReference.value);

    if (this.modalOptions.type == 'EDIT') {
      try {
        // let data = await this.httpService.get(`api/patient/${this.modalOptions.id}`).toPromise();
        // console.log(data);
      } catch (error) {
        console.log(error);
        
      }finally{
        this.isLoading = false;
      }
    }

    if (this.modalOptions.type == 'ADD') {
      try {
        // let data = await this.httpService.get(`api/patient/${this.modalOptions.id}`).toPromise();
        // console.log(data);
      } catch (error) {
        console.log(error);
        
      }finally{
        this.isLoading = false;
      }
    }
   
  }
}
