import { UserService } from 'src/app/shared/services/user.service';
import { BranchService } from './../../shared/services/branch.service';
import { CryptoSharkService } from 'src/app/shared/services/crypto-shark.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-branch',
  templateUrl: './modal-branch.component.html',
  styleUrls: ['./modal-branch.component.scss']
})
export class ModalBranchComponent implements OnInit {

  @Input() branchs: any[];

  public branch: any;

  constructor(private crypto: CryptoSharkService, private userService: UserService, private branchService: BranchService) {  }

  ngOnInit(): void {
  }

  public async select(){
    let c = await this.branchService.cashRegisterDefault(this.branch._id);
    var branch = this.crypto.encryptAes(JSON.stringify(this.branch));
    localStorage.setItem('branch', branch);
    this.userService.setCashRegisterDefault(c);
    this.close();
  }

  public close(){
    this.branchService.modal.close()
  }

}
