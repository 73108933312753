<div class="sidebar-title">
    <div class="d-flex justify-content-between">
        <div class="d-flex">
            <h1 class="m-0">{{modalOptions?.type == 'ADD' ? "Nuevo" : this.saveAsFlag ? "Duplicar" : "Editar"}} Estudio</h1>
        </div>
        <div class="my-auto">
            <!-- <button class="btn btn-info"><i class="mdi mdi-pencil mr-2"></i> Editar</button> -->
            <button *ngIf="(editFlag || saveAsFlag) && modalOptions.type != 'ADD'" class="btn btn-danger mr-3" (click)="cancelEdition()">Cancelar</button>
            <button *ngIf="modalOptions?.type == 'EDIT' && !saveAsFlag && !editFlag" class="btn btn-info mr-3" (click)="saveAs()">Guardar Como</button>
            <button *ngIf="modalOptions?.type == 'ADD' || editFlag || saveAsFlag" type="button" class="btn btn-success" (click)="Submit()"><i class="fa fa-check mr-2"></i> {{modalOptions?.type == 'ADD' || saveAsFlag ? "Guardar" : editFlag ? "Actualizar" : "Editar"}}</button>
            <button *ngIf="modalOptions?.type == 'EDIT' && !editFlag && !saveAsFlag" type="button" class="btn btn-success" (click)="editingReg()"><i class="fa fa-edit mr-2"></i> Editar</button>
        </div>
    </div>
</div>

<div class="scroll">
    
    <mk-loader *ngIf="isLoading" class="loader-spinner" ></mk-loader>

    <!-- INFORMACIÓN  -->
    <ng-container [ngTemplateOutlet]="studyInfo"></ng-container>



    <!-- CONTENEDORES  -->

    <div class="row mx-0 py-2 border-top bg-muted">
        <div class="col-md-12 d-flex justify-content-between">
            <h4 class=" my-2"><i class="fa fa-flask mr-1" style="color: var(--info);"></i> Contenedores <span class="text-muted font-weight-normal tx-18">({{containers?.length}})</span></h4>
            <div class="d-flex my-auto">
                <button *ngIf="editFlag" type="button" class="btn btn-dark my-auto" (click)="modalOptionsContainers.type = 'ADD'; modalOptionsContainers.item = null; openModalContainer(modalContainers);"><i class="fa fa-plus mr-2"></i> Agregar Contenedor</button>
                <button class="btn btn-light btn-circle text-muted ml-3" (click)="collapseGroup.containers = !collapseGroup.containers"><i class="fa fa-chevron-{{collapseGroup.containers ? 'down' : 'up'}}"></i></button>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!collapseGroup.containers">
        <div class="row mx-0 pb-2 bg-muted">
            <ng-container *ngFor="let item of containers; index as i">
                <div class="col-md-6"><ng-container *ngTemplateOutlet="container; context: {item: item}"></ng-container></div>
            </ng-container>
        </div>
    </ng-container>

    <!-- PRODUCTOS  -->
    <!-- <div class="row mx-0 py-2 border-top">
        <div class="col-md-12 d-flex justify-content-between">
            <h4 class=" my-2"><i class="fa fa-box mr-1" style="color: var(--purple);"></i> Productos <span class="text-muted font-weight-normal tx-18">({{products.length}})</span></h4>
            <div class="d-flex my-auto">
                <button class="btn btn-dark my-auto" (click)="openModal(modalProducts)"><i class="fa fa-plus mr-2"></i> Agregar Producto</button>
                <button class="btn btn-light btn-circle text-muted ml-3" (click)="collapseGroup.products = !collapseGroup.products"><i class="fa fa-chevron-{{collapseGroup.products ? 'down' : 'up'}}"></i></button>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!collapseGroup.products">
        <div class="row mx-0 pb-2">
            <ng-container *ngFor="let item of products; index as i">
                <div class="col-md-4"><ng-container *ngTemplateOutlet="product; context: {item: item}"></ng-container></div>
            </ng-container>
        </div>
    </ng-container> -->

    <!-- DETERMINACIONES  -->
    <div class="row mx-0 py-2 border-top bg-muted">
        <div class="col-md-12 d-flex justify-content-between">
            <h4 class="my-2"><i class="fa fa-cog mr-1" style="color: var(--orange);"></i> Determinaciones <span class="text-muted font-weight-normal tx-18">({{StudyForm.value?.determinations?.length || 0}})</span></h4>
            <div class="d-flex my-auto">
                <button  *ngIf="editFlag" class="btn btn-dark my-auto" (click)="modalOptionsDeter.type = 'ADD'; openModalDeter(modalDeterminations, 'xxl')"><i class="fa fa-plus mr-2"></i> Agregar Determinación</button>
                <button class="btn btn-light btn-circle text-muted ml-3" (click)="collapseGroup.determinations = !collapseGroup.determinations"><i class="fa fa-chevron-{{collapseGroup.determinations ? 'down' : 'up'}}"></i></button>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!collapseGroup.determinations">
        <div class="row mx-0 pb-2 bg-muted">
            <ng-container *ngFor="let item of StudyForm.value?.determinations; index as i">
                <div class="col-md-12"><ng-container *ngTemplateOutlet="determination; context: {item: item, index: i}"></ng-container></div>
            </ng-container>
        </div>
    </ng-container>

    <!-- SUB-ESTUDIOS  -->
    <div class="row mx-0 py-2 border-top">
        <div class="col-md-12 d-flex justify-content-between">
            <h4 class="my-2"><i class="fa fa-file-text mr-1" style="color: var(--green);"></i> Sub-Estudios <span class="text-muted font-weight-normal tx-18">({{substudies.length}})</span></h4>
            <div class="d-flex my-auto">
                <button  *ngIf="editFlag" class="btn btn-dark my-auto" (click)="openModalSubE(modalSubstudies)"><i class="fa fa-plus mr-2"></i> Agregar Sub-Estudio</button>
                <button class="btn btn-light btn-circle text-muted ml-3" (click)="collapseGroup.substudies = !collapseGroup.substudies"><i class="fa fa-chevron-{{collapseGroup.substudies ? 'down' : 'up'}}"></i></button>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!collapseGroup.substudies">
        <div class="row mx-0 pb-2">
            <ng-container *ngFor="let item of substudies; index as i">
                <div class="col-md-4"><ng-container *ngTemplateOutlet="substudy; context: {item: item}"></ng-container></div>
            </ng-container>
        </div>
    </ng-container>
</div>

<!-- TEMPLATES  -->

<ng-template #studyInfo >
    <form [formGroup]="StudyForm">
        <div class="row mx-0 mt-2">
            <div class="col-md-4">
                <div class="row">
                    <div class="col-md-12 mb-2">
                        <h6>Nombre de estudio  <span class="text-danger">*</span></h6>
                        <div class="input-group">
                            <input type="text" [readOnly]="!editFlag && !saveAsFlag" class="form-control" maxlength="100" placeholder="Nombre de estudio" formControlName="longName">
                        </div>
                        <small *ngIf="validateForm.campoEsValido('longName', StudyForm)" class="text-danger ml-1">Ingrese un nombre valido.</small>

                    </div>
                    <div class="col-md-12 mb-2">
                        <h6>Sinónimo  <span class="text-danger">*</span></h6>
                        <div class="input-group">
                            <input type="text" [readOnly]="!editFlag" class="form-control"  maxlength="60" placeholder="Sinónimo" formControlName="synonymous">
                        </div>
                        <small *ngIf="validateForm.campoEsValido('synonymous', StudyForm)" class="text-danger ml-1">Ingrese un sinonimo.</small>

                    </div>
                    <div class="col-md-12 mb-2">
                        <h6>Nombre corto  <span class="text-danger">*</span></h6>
                        <div class="input-group">
                            <input [readOnly]="!editFlag && !saveAsFlag" type="text" class="form-control"  maxlength="60" placeholder="Nombre corto" formControlName="shortName">
                        </div>
                        <small *ngIf="validateForm.campoEsValido('shortName', StudyForm)" class="text-danger ml-1">Ingrese un nombre corto.</small>

                    </div>
                    <div class="col-md-12 mb-2">
                        <h6>Título de reporte  <span class="text-danger">*</span></h6>
                        <div class="input-group">
                            <input [readOnly]="!editFlag" type="text" class="form-control"  maxlength="60" placeholder="Título de reporte" formControlName="titleReport">
                        </div>
                        <small *ngIf="validateForm.campoEsValido('titleReport', StudyForm)" class="text-danger ml-1">Ingrese un titulo de reporte.</small>

                    </div>
                    <div class="col-md-6 mb-2">
                        <h6>Días proceso paciente  <span class="text-danger">*</span></h6>
                        <div class="input-group">
                            <input [readOnly]="!editFlag" type="text" class="form-control" maxlength="3" placeholder="Días proceso paciente" formControlName="processDaysPatient" (keypress)="validatorsService.isInteger($event);">
                            <div class="input-group-append">
                                <div class="input-group-text">Días</div>
                            </div>
                        </div>
                        <small *ngIf="validateForm.campoEsValido('processDaysPatient', StudyForm)" class="text-danger ml-1">Ingrese días de proceso del paciente.</small>

                    </div>
                    <div class="col-md-6 mb-2" >
                        <h6>Días proceso cliente  <span class="text-danger">*</span></h6>
                        <div class="input-group">
                            <input [readOnly]="!editFlag" type="text" class="form-control"  maxlength="3" formControlName="processDaysCustomer" placeholder="Días proceso cliente" (keypress)="validatorsService.isInteger($event);">
                            <div class="input-group-append">
                                <div class="input-group-text">Días</div>
                            </div>
                        </div>
                        <small *ngIf="validateForm.campoEsValido('processDaysCustomer', StudyForm)" class="text-danger ml-1">Ingrese días de proceso del cliente.</small>

                    </div>
                    <div class="col-md-12 mb-2">
                        <h6>Tipo días de proceso</h6>
                        <div class="input-group mb-2">
                            <ng-select  class="form-control"                                 
                            bindLabel="label" [readonly]="!editFlag"  bindValue="value" placeholder="Tipo días de proceso" [items]="procesTypeList" [clearable]="false" value formControlName="typeProcessDays"></ng-select>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-4">
                <div class="row">
                    <div class="col-md-12">
                        <h6>Departamento</h6>
                        <div class="input-group mb-2">
                            <ng-select class="form-control" [readonly]="!editFlag" placeholder="Departamento" [items]="departmentsList" bindValue="_id"  bindLabel="name" formControlName="departmentId"></ng-select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <h6>Orden de impresión</h6>
                        <div class="input-group mb-2">
                            <input type="text" [readOnly]="!editFlag" class="form-control" placeholder="Orden de impresión" formControlName="printOrder">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <h6>Metodología</h6>
                        <div class="input-group mb-2">
                            <ng-select class="form-control" [readonly]="!editFlag" [items]="methodsList" bindValue="_id"  bindLabel="name" placeholder="Metodología" formControlName="methodId"></ng-select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <h6>Unidad de conversión</h6>
                        <div class="input-group mb-2">
                            <ng-select class="form-control" [readonly]="!editFlag" [items]="unidConversionList" bindValue="_id"  bindLabel="parameters" placeholder="Unidad de conversión" formControlName="unitConversionId"></ng-select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <h6>Tipo de muestra</h6>
                        <div class="input-group mb-2">
                            <ng-select class="form-control" [readonly]="!editFlag" [items]="sampleTypeList" bindValue="_id"  bindLabel="name"  placeholder="Tipo de muestra" formControlName="sampleTypeId"></ng-select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <h6>Estudio Similar</h6>
                        <div class="input-group mb-2">
                            <ng-select class="form-control" [readonly]="!editFlag" placeholder="Estudio Similar" [items]="studyList" bindValue="_id"  bindLabel="longName"  formControlName="studySimilar"></ng-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="row">
                    <ng-container>
                        <div class="col-md-6">
                            <h6>Estatus</h6>
                            <div class="input-group mb-2">
                                <label  class="form-control d-flex flex-row justify-content-between cursor-pointer">
                                    <span class="my-auto text-muted" style="user-select: none;">{{StudyForm?.controls?.statusActive?.value ? "Activo" :  "Inactivo"}}</span>
                                    <label class="custom-switch cursor-pointer my-auto">
                                        <input type="checkbox" [attr.disabled]="!editFlag ? true : null" name="custom-switch-checkbox" class="custom-switch-input"  formControlName="statusActive"/>
                                        <span class="custom-switch-indicator"></span>
                                    </label>  
                                </label>
                            </div>

                        </div>
                        <div class="col-md-6">
                            <h6>Lista de precios</h6>
                            <div class="input-group mb-2">
                                <label class="form-control d-flex flex-row justify-content-between cursor-pointer">
                                    <span class="my-auto text-muted" style="user-select: none;">{{StudyForm?.controls?.showListPrice?.value ? "Incluir" :  "No incluir"}}</span>
                                    <label class="custom-switch cursor-pointer my-auto">
                                        <input type="checkbox" [attr.disabled]="!editFlag ? true : null" name="custom-switch-checkbox" class="custom-switch-input"  formControlName="showListPrice"/>
                                        <span class="custom-switch-indicator"></span>
                                    </label>      
                                </label>             
                            </div>
                        </div>
                    </ng-container>
                    <div class="col-md-12">
                        <h6>Cuestionario</h6>
                        <div class="input-group mb-2">
                            <ng-select class="form-control" [readonly]="!editFlag" placeholder="Cuestionario" formControlName="questionary" [items]="questionaryList"  bindValue="_id"  bindLabel="name"></ng-select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="d-flex justify-content-between">
                            <h6>Abreviaciones</h6>
                            <h6 *ngIf="editFlag" class="text-info cursor-pointer font-weight-normal" (click)="openModal(modalAbbrevations, 'md', abreviationList)"><i class="fa fa-plus-circle tx-12"></i> Agregar/editar</h6>
                        </div>
                        <div class="card d-block p-1 mb-2" [style.min-height.px]="true ? 66 : 133">
                            <ng-container  *ngFor="let item of abreviationList; index as i">
                                <span class="badge badge-primary-light" [class.ml-1]="i" [ngbTooltip]="item?.description">{{item?.code}}</span>
                            </ng-container>
                            <ng-container *ngIf="!abreviationList?.length">
                                <span class="text-muted m-1">Sin Abreviaciones</span>
                            </ng-container>
                        </div>
                    </div>
                
                </div>
                <div class="row mt-2">
                    <div class="col-md-12 d-flex justify-content-between mb-2">
                        <h6 class="my-auto d-flex"><div class="text-center op-4 mr-1" style="width: 18px;"><i class="fa fa-building"></i></div> Subrogado</h6>
                        <label class="custom-switch cursor-pointer my-auto">
                            <input type="checkbox" [attr.disabled]="!editFlag ? true : null" name="custom-switch-checkbox" class="custom-switch-input"  formControlName="isSurrogate"/>
                            <span class="custom-switch-indicator"></span>
                        </label>
                    </div>
                    <div class="col-md-12 d-flex justify-content-between mb-2">
                        <h6 class="my-auto d-flex"><div class="text-center op-4 mr-1" style="width: 18px;"><i class="fa fa-warning"></i></div> Urgencia</h6>
                        <label class="custom-switch cursor-pointer my-auto">
                            <input type="checkbox" [attr.disabled]="!editFlag ? true : null" name="custom-switch-checkbox" class="custom-switch-input"  formControlName="isUrgency"/>
                            <span class="custom-switch-indicator"></span>
                        </label>
                    </div>
                    <div class="col-md-12 d-flex justify-content-between mb-2">
                        <h6 class="my-auto d-flex"><div class="text-center op-4 mr-1" style="width: 18px;"><i class="fa fa-star"></i></div> Especial</h6>
                        <label class="custom-switch cursor-pointer my-auto">
                            <input type="checkbox" [attr.disabled]="!editFlag ? true : null" name="custom-switch-checkbox" class="custom-switch-input" formControlName="isSpecial"/>
                            <span class="custom-switch-indicator"></span>
                        </label>
                    </div>
                    <div class="col-md-12 d-flex justify-content-between mb-2">
                        <h6 class="my-auto d-flex"><div class="text-center op-4 mr-1" style="width: 18px;"><i class="fa fa-clock"></i></div> Rutina</h6>
                        <label class="custom-switch cursor-pointer my-auto">
                            <input type="checkbox" [attr.disabled]="!editFlag ? true : null" name="custom-switch-checkbox" class="custom-switch-input" formControlName="isRutina"/>
                            <span class="custom-switch-indicator"></span>
                        </label>
                    </div>
                    <div class="col-md-12 d-flex justify-content-between mb-2">
                        <h6 class="my-auto d-flex"><div class="text-center op-4 mr-1" style="width: 18px;"><i class="fa fa-file-text"></i></div> Requiere archivo adicional</h6>
                        <label class="custom-switch cursor-pointer my-auto">
                            <input type="checkbox" [attr.disabled]="!editFlag ? true : null" name="custom-switch-checkbox" class="custom-switch-input" formControlName="requireFileAditional"/>
                            <span class="custom-switch-indicator"></span>
                        </label>
                    </div>
                    <div class="col-md-12 d-flex justify-content-between mb-2">
                        <h6 class="my-auto d-flex"><div class="text-center op-4 mr-1" style="width: 18px;"><i class="fa fa-usd"></i></div>Acreditado</h6>
                        <label class="custom-switch cursor-pointer my-auto">
                            <input type="checkbox" [attr.disabled]="!editFlag ? true : null" name="custom-switch-checkbox" class="custom-switch-input" formControlName="isCertified"/>
                            <span class="custom-switch-indicator"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>

    <!-- DIAS DE DISPONIBILIDAD  -->
        <ng-container formGroupName="availableDays">
            <div  class="row mx-0 mt-2 py-2 border-top bg-muted">
                <div class="col-md-12 d-flex justify-content-between">
                    <h4 class="text-primary my-2"><i class="fa fa-calendar mr-1"></i> Dias de disponibilidad del estudio</h4>
                    <div class="d-flex my-auto">
                        <ng-container  *ngFor="let day of week">
                            <label class="d-flex cursor-pointer my-0 ml-3">
                                <b>{{day?.label}}</b>
                                <label class="ckbox ml-2 cursor-pointer"><input type="checkbox" [attr.disabled]="!editFlag && !saveAsFlag ? true : null" [formControlName]="day?.target"><span></span></label>
                            </label>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>


    <!-- INDICACIONES  -->

    <div class="row mx-0 py-2 border-top">
        <div class="col-md-6">
            <div class="d-flex justify-content-between mb-2">
                <h6 class="my-auto">Indicaciones Cliente</h6>
                <div class="input-group w-50">
                    <ng-select [readonly]="!editFlag" class="form-control" formControlName="indicationsClientId" placeholder="Indicaciones Cliente" [items]="indicationsList"  bindLabel="name"  bindValue="_id" (change)="setIndications($event, 'customer')"></ng-select>
                </div>
            </div>
            <div class="input-group mb-2">
                <textarea name="" id="" [ngModelOptions]="{standalone: true}" cols="30" readonly  rows="3" class="form-control text-muted" [(ngModel)]="indicationsCustomer" placeholder="Indicaciones Cliente"></textarea>
            </div>
        </div>
        <div class="col-md-6">
            <div class="d-flex justify-content-between mb-2">
                <h6 class="my-auto">Indicaciones Paciente</h6>
                <div class="input-group w-50">
                    <ng-select [readonly]="!editFlag" class="form-control" formControlName="indicationsPatientId" placeholder="Indicaciones Paciente" [items]="indicationsPatientList"  bindLabel="name"  bindValue="_id" (change)="setIndications($event, 'patient')"></ng-select>
                </div>
            </div>
            <div class="input-group mb-2">
                <textarea name="" [ngModelOptions]="{standalone: true}" id="" cols="30" disabled rows="3" class="form-control text-muted" [(ngModel)]="indicationsPatient" placeholder="Indicaciones Paciente"></textarea>
            </div>
        </div>
    </div>

    </form>
</ng-template>


<!-- Templates -->

<ng-template #container let-item="item">
    <div class="card" style="--colorContainer: {{item?.color?.code}}; border-left: 6px solid var(--colorContainer);">
        <div class="d-flex justify-content-between" style="padding: 12px;">
            <div class="d-flex flex-column">
                <div class="d-flex lh-1"><b>{{'Examen: ' + item?.type?.name}}</b> <span class="text-muted mx-2">&bull;</span> <span><em>{{item?.sample?.name}}</em></span></div>
                <div class="mt-1">
                    <ng-container *ngFor="let tag of item?.labels">
                        <span class="badge badge-primary-light mt-1 mr-1">{{tag?.label}}</span>
                    </ng-container>
                </div>
            </div>
            <div class="d-flex">
                <button *ngIf="editFlag" class="btn btn-info btn-circle mr-2" (click)="modalOptionsContainers.type = 'EDIT'; modalOptionsContainers.item = item; openModalContainer(modalContainers, 'md',item)"><i class="mdi mdi-pencil"></i></button>
                <button *ngIf="editFlag" class="btn btn-danger btn-circle" [swal]="deleteSwal" (confirm)="deleteContainer(item)"><i class="fa fa-trash"></i></button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #product let-item="item">
    <div class="card" style="border-left: 6px solid var(--purple);">
        <div class="d-flex justify-content-between" style="padding: 12px;">
            <div class="d-flex my-auto"><b>x{{item.quantity}} {{item.name}}</b></div>
            <div class="d-flex">
                <button class="btn btn-info btn-circle mr-2" (click)="openModal(modalProducts)"><i class="mdi mdi-pencil"></i></button>
                <button class="btn btn-danger btn-circle"><i class="fa fa-trash"></i></button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #determination let-item="item" let-index="index">
    <div class="card mb-2" style="border-left: 6px solid var(--orange);">
        <div class="d-flex justify-content-between" style="padding: 12px;">
            <div class="d-flex">
                <b class="my-auto tx-18" style="color: var(--orange);">{{item?.printOrder}}</b> 
                <div class="d-flex" style="padding-left: 12px;">
                    <button *ngIf="editFlag" class="btn btn-dark btn-circle" [disabled]="!index" (click)="movePosition(item, -1)"><i class="fa fa-caret-up"></i></button>
                    <button *ngIf="editFlag" class="btn btn-dark btn-circle ml-2" (click)="movePosition(item, 1)" [disabled]="StudyForm.value?.determinations?.length <= (index + 1)"><i class="fa fa-caret-down"></i></button>
                </div>
                <div class="d-flex lh-1 my-auto" style="padding: 0 12px;"><b>{{item?.determination}} ( {{item?.unit}} )</b> <span class="text-muted mx-2">&bull;</span> <span><em>{{item?.legendF}} <ng-container *ngIf="item?.SubEstudy">, <b>Sub-estudio: {{subStudieName(item?.SubEstudy)}}</b></ng-container></em></span></div>
            </div>
            <div class="d-flex">
                <button *ngIf="editFlag" class="btn btn-info btn-circle mr-2" (click)="modalOptionsDeter._id = item?._id; modalOptionsDeter.type = 'EDIT'; openModalDeter(modalDeterminations, 'xxl', item)"><i class="mdi mdi-pencil"></i></button>
                <button *ngIf="editFlag" class="btn btn-danger btn-circle mr-2" [swal]="deleteDetSwal" (confirm)="deleteDeterm(item._id)"><i class="fa fa-trash"></i></button>
                <button class="btn btn-dark btn-circle" (click)="item.expanded = !item.expanded"><i class="fa fa-chevron-{{item.expanded ? 'up' : 'down'}}"></i></button>
            </div>
        </div>
        <ng-container *ngIf="item.expanded">
            <div class="row m-0 border-top">
                <div class="col-sm-12 p-0">
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped m-0 text-center">
                            <thead>
                                <tr>
                                    <th colspan="3">Rango</th>
                                    <th colspan="3">Paciente</th>
                                    <th colspan="6">Valor</th>
                                </tr>
                            </thead>
                            
                            <ng-container *ngFor="let det of item.values">
                                <!-- Valor Numerico -->
                            <ng-container *ngIf="det?.type == 'NUMERIC'">
                                <thead>
                                    <tr>
                                        <ng-container *ngTemplateOutlet="determinationHeader"></ng-container>
                                        <!-- Valor -->
                                        <th>Tipo</th>
                                        <th>Inicio</th>
                                        <th>Fin</th>
                                        <th>Crit. min</th>
                                        <th>Crit. max</th>
                                        <th>Nota</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <ng-container *ngTemplateOutlet="determinationData; context: {data: det}"></ng-container>
                                        <td width="100px">{{det?.type | status}}</td>
                                        <td width="80px">{{det?.n_init}}</td>
                                        <td width="80px">{{det?.n_end}}</td>
                                        <td width="80px">{{det?.crit_init}}</td>
                                        <td width="80px">{{det?.crit_end}}</td>
                                        <td>{{det?.note}}</td>
                                    </tr>
                                </tbody>
                            </ng-container>
                            
                            <!-- Valor Numerico -->
                            <ng-container *ngIf="det?.type == 'DESCRIPTIVE'">
                                <thead>
                                    <tr>
                                        <ng-container *ngTemplateOutlet="determinationHeader"></ng-container>
                                        <!-- Valor -->
                                        <th>Tipo</th>
                                        <th colspan="4">Valor Referencia</th>
                                        <th>Nota</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <ng-container *ngTemplateOutlet="determinationData; context: {data: det}"></ng-container>
                                        <td width="100px">{{det?.type | status}}</td>
                                        <td colspan="4">{{det?.description}}</td>
                                        <td>{{det?.note}}</td>
                                    </tr>
                                </tbody>
                            </ng-container>

                            <!-- Valor Numerico -->
                            <ng-container *ngIf="det?.type == 'INTERPRETATIVE'">
                                <thead>
                                    <tr>
                                        <ng-container *ngTemplateOutlet="determinationHeader"></ng-container>
                                        <!-- Valor -->
                                        <th>Tipo</th>
                                        <th colspan="6">Valor Referencia</th>
                                        <!-- <th>Nota</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <ng-container *ngTemplateOutlet="determinationData; context: {data: det}"></ng-container>
                                        <td width="100px">{{det?.type | status}}</td>
                                        <td colspan="4">{{det?.description}}</td>
                                        <!-- <td>{{det?.note}}</td> -->
                                    </tr>
                                </tbody>
                            </ng-container>
                            </ng-container>

                        </table>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>
<ng-template #determinationHeader>
    <!-- ? Rango -->
    <th width="120px">Inicio y fin</th>
    <!-- <th width="80px">Fin</th>
    <th width="80px">Medida</th> -->
    <!-- ? Paciente -->
    <th width="80px">Genero</th>
    <th width="80px">Fumador</th>
    <th width="80px">Embarazo</th>
</ng-template>
<ng-template #determinationData let-data="data">
    <td>{{data?.r_init}} - {{data?.r_end }} {{data?.measure | status}}</td>
    <!-- {{data.range.metric}} -->
    <!-- <td>{{data.range.end}}</td>
    <td>{{data.range.metric}}</td> -->
    <td><i class="fa fa-male" *ngIf="data?.gender == 'MALE' || data?.gender == 'ANY'"></i> <i class="fa fa-female" *ngIf="data?.gender == 'FEMALE' || data?.gender == 'ANY'"></i></td>
    <td><i class="fa fa-check" *ngIf="data?.smoker else blank"></i></td>
    <td><i class="fa fa-check" *ngIf="data?.pregnant else blank"></i></td>

    <ng-template #blank><b>...</b></ng-template>
</ng-template>
<ng-template #substudy let-item="item">
    <div class="card" style="border-left: 6px solid var(--green);">
        <div class="d-flex justify-content-between" style="padding: 12px;">
            <div class="d-flex my-auto"><b>{{item?.name}}</b></div>
            <div class="d-flex">
                <button *ngIf="editFlag" class="btn btn-info btn-circle mr-2" (click)="modalOptionsSubE.type = 'EDIT' ;openModalSubE(modalSubstudies, 'md', item)"><i class="mdi mdi-pencil"></i></button>
                <button *ngIf="editFlag" class="btn btn-danger btn-circle" [swal]="deleteSubESwal" (confirm)="deletesubStudy(item)"><i class="fa fa-trash"></i></button>
            </div>
        </div>
    </div>
</ng-template>

<!-- MODALS  -->

<ng-template #modalAbbrevations let-modal>
    <cat-modal-abbreviations (onAddabbreb)="abreviationList = $event" [modalOptions]="modalOptions" (onClose)="modal.dismiss()"></cat-modal-abbreviations>
</ng-template>

<ng-template #modalContainers let-modal>
    <cat-modal-containers [modalOptionsContainers]="modalOptionsContainers" (onEditcontainer)="EditContainer($event)" (onAddcontainer)="AddContainer($event)" (onClose)="modal.dismiss()"></cat-modal-containers>
</ng-template>

<ng-template #modalProducts let-modal>
    <cat-modal-products (onClose)="modal.dismiss()"></cat-modal-products>
</ng-template>

<ng-template #modalDeterminations let-modal>
    <cat-modal-determination [subStudies]="substudies" [determinationForm]="determinationForm" [addValueForm]="addValueForm" [modalOptions]="modalOptions" [modalOptionsDeter]="modalOptionsDeter" (onEdit)="editDeterm($event); modal.dismiss()" (onSave)="assignDeterm($event); modal.dismiss()" (onClose)="modal.dismiss()"></cat-modal-determination>
</ng-template>

<ng-template #modalSubstudies let-modal>
    <cat-modal-substudy (onAddsubStudy)="AddsubStudy($event)" [subStudies]="substudies" (onEditsubStudy)="EditsubStudy($event)" [modalOptionsSubE]="modalOptionsSubE" (onClose)="modal.dismiss()"></cat-modal-substudy>
</ng-template>

<!-- SWALS -->
<swal #deleteSwal class="d-none" title="¿Estas seguro de eliminar el contenedor?" icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>

<!-- SWALS -->
<swal #deleteSubESwal class="d-none" title="¿Estas seguro de eliminar el sub-estudio?" icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>

<!-- SWALS -->
<swal #deleteDetSwal class="d-none" title="¿Estas seguro de eliminar la determinación?" icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>