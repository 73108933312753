<div class="modal-body">
    <div class="row border-bottom">
        <div class="col-12 p-3 d-flex">
            <i class="fa fa-money fa-2x my-auto text-primary"></i>
            <div class="d-flex flex-column ml-3 lh-1">
                <h3 class="m-0">Cerrar Caja</h3>
                <span class="text-muted">Registra corte y cierre de caja.</span>
            </div>
        </div>
    </div>

    <div class="row">
        <aw-wizard #wizard class="mt-2" [awNavigationMode] navigateBackward="allow" navigateForward="allow">
            <aw-wizard-step [stepTitle]="'Saldo final'">
                <div class="modal-body p-0 border-top">
                    <div class="wrap py-3">
                        <span class="text-primary mb-2">Saldo Final Efectivo</span>
                        <input type="text" class="input-big text-primary" readonly placeholder="$0.00" step="0.01"
                            [(ngModel)]="amount.total" [textMask]="{mask: currencyMask}">
                        <div class="w-100 text-center d-flex pt-2" *ngIf="amount.usd">
                            <div class="_pill badge-primary-light"><span style="display: none;"></span><span>MXN: <b>{{
                                        (amount.mxn) | currency}}</b></span></div>
                            <div class="_pill badge-primary-light"
                                ngbTooltip="Tipo de cambio: {{exchangeRate.amount | currency}}"><span><i
                                        class="fa fa-info-circle"></i></span><span>USD: <b>{{ (amount.usd) | currency}}</b>
                                    <small class="ml-1">({{(exchangeRate.amount * amount.usd) | currency}}
                                        MXN)</small></span></div>
                        </div>
                        <small class="text-muted mt-2">Aplica con todo el efectivo que tengas en tu caja</small>
                    </div>
                    <div class="wrap">
                        <span class="text-primary mb-3">Denominaciones</span>
                        <div class="card d-flex flex-row p-2 mb-3">
                            <div class="col-md-6">
                                <div class="text-primary"><span class="badge badge-primary-light"><b>MXN</b></span></div>
                                <ng-container *ngFor="let type of denomMXN.reverse()">
                                    <div class="row mt-1">
                                        <div class="col-sm-12 px-2 py-1">
                                            <div class="input-group">
                                                <div class="input-group-prepend w-65">
                                                    <div class="input-group-text px-1 w-100">
                                                        <i
                                                            class="fa fa-{{type.type == 'BILL' ? 'money' : 'circle'}} c-{{getIconColor('mxn', type.type | lowercase, type.value)}} tx-16 mr-2"></i>
                                                        <span class="lh--9">{{type.value | currency}}</span>
                                                    </div>
                                                </div>
                                                <input type="text" (keypress)="validator.isInteger($event);" maxlength="3" class="form-control form-control-sm" placeholder="0" [(ngModel)]="type.quantity" (keyup)="updateAmount()">
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="col-md-6">
                                <div class="text-primary"><span class="badge badge-primary-light"><b>USD</b></span></div>
                                <ng-container *ngFor="let type of denomUSD.reverse()">
                                    <div class="row mt-1">
                                        <div class="col-sm-12 px-2 py-1">
                                            <div class="input-group">
                                                <div class="input-group-prepend w-65">
                                                    <div class="input-group-text px-1 w-100">
                                                        <i class="fa fa-{{type.type == 'BILL' ? 'money' : 'circle'}} c-{{getIconColor('usd', type.type | lowercase, type.value)}} tx-16 mr-2"></i>
                                                        <span class="lh--9">{{type.value | currency}}</span>
                                                    </div>
                                                </div>
                                                <input type="text" (keypress)="validator.isInteger($event);" maxlength="3" class="form-control form-control-sm" placeholder="0" [(ngModel)]="type.quantity" (keyup)="updateAmount()">
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        <div class="row text-center">
                            <div class="col-12 op-7 font-weight-semibold">Otros metodos de pago</div>
                        </div>
                        <div class="row py-3">
                            <div class="col-12">
                                <div class="card text-dark _pay summary">
                                    <div class="row py-1">
                                        <div class="col-7 text-left">
                                            <b class="my-auto">Cobros con tarjeta</b>
                                        </div>
                                        <div class="col-5 text-right text-primary">
                                            <div class="b my-auto ml-auto">{{totalCard()| currency}}</div>
                                        </div>
                                    </div>
                                    <div class="row py-1">
                                        <div class="col-7 text-left">
                                            <b class="my-auto">Cobros con transferencia</b>
                                        </div>
                                        <div class="col-5 text-right text-primary">
                                            <div class="b my-auto ml-auto">{{totalTransfer() | currency}}</div>
                                        </div>
                                    </div>
                                    <div class="row py-1">
                                        <div class="col-7 text-left">
                                            <b class="my-auto">Cobros con cheque</b>
                                        </div>
                                        <div class="col-5 text-right text-primary">
                                            <div class="b my-auto ml-auto">{{ totalCheck() | currency}}</div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
    
                <div class="modal-footer justify-content-between">
                    <button class="btn btn-danger" (click)="close()"> Cancelar</button>
                    <button class="btn btn-dark" awNextStep> Restante en caja <i class="fa fa-chevron-right ml-1"></i></button>
                </div>
    
            </aw-wizard-step>
    
            <aw-wizard-step [stepTitle]="'Restante en caja'">
                <div class="modal-body p-0 border-top">
    
                    <div class="wrap py-3">
                        <span class="text-info mb-2">Restante en caja</span>
                        <input type="text" class="input-big text-info" readonly placeholder="$0.00" step="0.01"
                            [(ngModel)]="remain.total" [textMask]="{mask: currencyMask}">
                        <div class="w-100 text-center d-flex pt-2" *ngIf="remain.usd">
                            <div class="_pill badge-info-light"><span style="display: none;"></span><span>MXN: <b>{{
                                        (remain.mxn) | currency}}</b></span></div>
                            <div class="_pill badge-info-light" 
                                ngbTooltip="Tipo de cambio: {{exchangeRate.amount | currency}}"><span><i
                                        class="fa fa-info-circle"></i></span><span>USD: <b>{{ (remain.usd) | currency}}</b>
                                    <small class="ml-1">({{(exchangeRate.amount * remain.usd) | currency}}
                                        MXN)</small></span></div>
                        </div>
                        <small class="text-muted mt-2">Aplica con todo el efectivo que tengas en tu caja</small>
                    </div>
                    <div class="wrap">
                        <span class="text-info mb-3">Denominaciones</span>
                        <div class="card d-flex flex-row p-2 mb-3">
                            <div class="col-md-6">
                                <div class="text-info"><span class="badge badge-info-light"><b>MXN</b></span></div>
                                <ng-container *ngFor="let type of denomMXNRemain.reverse()">
                                    <div class="row mt-1">
                                        <div class="col-sm-12 px-2 py-1">
                                            <div class="input-group">
                                                <div class="input-group-prepend w-65">
                                                    <div class="input-group-text px-1 w-100">
                                                        <i
                                                            class="fa fa-{{type.type == 'BILL' ? 'money' : 'circle'}} c-{{getIconColor('mxn', type.type | lowercase, type.value)}} tx-16 mr-2"></i>
                                                        <span class="lh--9">{{type.value | currency}}</span>
                                                    </div>
                                                </div>
                                                <input type="text" (keypress)="validator.isInteger($event);" maxlength="3" class="form-control form-control-sm" placeholder="0" [(ngModel)]="type.quantity" (keyup)="updateRemain()">
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="col-md-6">
                                <div class="text-info"><span class="badge badge-info-light"><b>USD</b></span></div>
                                <ng-container *ngFor="let type of denomUSDRemain.reverse()">
                                    <div class="row mt-1">
                                        <div class="col-sm-12 px-2 py-1">
                                            <div class="input-group">
                                                <div class="input-group-prepend w-65">
                                                    <div class="input-group-text px-1 w-100">
                                                        <i
                                                            class="fa fa-{{type.type == 'BILL' ? 'money' : 'circle'}} c-{{getIconColor('usd', type.type | lowercase, type.value)}} tx-16 mr-2"></i>
                                                        <span class="lh--9">{{type.value | currency}}</span>
                                                    </div>
                                                </div>
                                                <input type="text" (keypress)="validator.isInteger($event);" maxlength="3" class="form-control form-control-sm" placeholder="0" [(ngModel)]="type.quantity" (keyup)="updateRemain()">
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="modal-footer justify-content-between">
                    <button class="btn btn-danger" (click)="close()"> Cancelar</button>
                    <div>
                        <button class="btn btn-dark mr-2" awPreviousStep><i class="fa fa-chevron-left mr-1"></i> Saldo final </button>
                        <button class="btn btn-success" [disabled]="disableBtn()" (click)="unAssign()"> Cerrar Caja </button>
                    </div>
                </div>
    
            </aw-wizard-step>
    
        </aw-wizard>
    </div>
</div>
<!-- DENOMINACIONES ANTIGUAS  -->
<ng-template>

    <div class="card _pay px-3 mb-3" *ngIf="false">
        <ng-container *ngFor="let item of denomArray; index as i">
            <div class="row pad">
                <div class="col-11">
                    <div class="row">
                        <div class="col-7 p-0">
                            <!-- <span class="badge badge-primary-light my-auto mr-2">MXN</span> -->
                            <div class="input-group">
                                <ng-select class="form-control muted" [clearable]="false" [(ngModel)]="item.currency"
                                    [items]="['MXN', 'USD']" (change)="item.denomination = null"></ng-select>
                                <ng-select class="form-control" [(ngModel)]="item.denomination"
                                    placeholder="Denominación" (change)="updateAmount()">
                                    <ng-option [value]="type"
                                        *ngFor="let type of (item.currency == 'MXN' ? denomMXN : denomUSD)"><i
                                            class="fa fa-{{type.type == 'BILL' ? 'money' : 'circle'}} c-{{getIconColor(item.currency | lowercase, type.type | lowercase, type.value)}} mr-1"></i>
                                        {{type.value | currency}}</ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-5 pl-1 pr-0">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <!-- <i class="fa fa-circle"></i> -->
                                        <span class="op-7 tx-12 lh--9">Cantidad</span>
                                    </div>
                                </div>
                                <input type="number" class="form-control" [(ngModel)]="item.amount" placeholder="00"
                                    (change)="updateAmount()">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-1 pr-0 pl-1  d-flex justify-content-center cursor-pointer"
                    (click)="denomArray.splice(i,1); updateAmount()" *ngIf="i || denomArray.length - 1">
                    <i class="fa fa-trash text-danger my-auto"></i>
                </div>
            </div>
        </ng-container>
        <div class="row d-flex justify-content-between py-2 px-3">
            <!-- <span class="text-muted">{{denomArray.length}} forma(s) de pago</span> -->
            <span></span>
            <span class="text-primary cursor-pointer"><b (click)="addDenomination()">Agregar denominación <i
                        class="fa fa-plus ml-2"></i></b></span>
        </div>
    </div>
</ng-template>