import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { regExpEscape } from '@ng-bootstrap/ng-bootstrap/util/util';
import { Logs } from 'selenium-webdriver';
import { AlertService } from 'src/app/shared/services/alert.service';
import { BuiltFormDataService } from 'src/app/shared/services/built-form-data.service';
import { DynamicFormService } from 'src/app/shared/services/dynamic-form.service';
import { StudyService } from 'src/app/shared/services/http/study.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ValidatorsService } from 'src/app/shared/services/validators.service';
import { threadId } from 'worker_threads';
import { ValidateFormsDirective } from '../../../../directives/validate-forms.directive';
import { StudiesService } from '../../../../shared/services/studies.service';

@Component({
  selector: 'cat-modal-study',
  templateUrl: './modal-study.component.html',
  styleUrls: ['./modal-study.component.scss']
})
export class ModalStudyComponent implements OnInit {

  public loading: boolean = false;
  @Input() modalOptions: any = {_id: null, type: "ADD", available: true, userEditing_userName: null};
  @Input() NRegisters = 0;

  @Output() onSave: EventEmitter<boolean> = new EventEmitter<boolean>();
  public modalOptionsContainers: any = {_id: null, type: "ADD"};
  public modalOptionsSubE: any = {_id: null, type: "ADD"};
  public modalOptionsDeter: any = {_id: null, type: "ADD", length: 0};

  public determinationForm: FormGroup;
  public determinationTableForm: FormGroup;
  public addValueForm: FormGroup;

  collapseGroup: any = {
    containers: true,
    products: true,
    determinations: true,
    substudies: true,
  }
  public editFlag:boolean = false;
  public saveAsFlag:boolean = false;

  public StudyForm: FormGroup;
  public isLoading: boolean = false;
  public departmentsList = [];
  public methodsList = [];
  public unidConversionList = [];
  public sampleTypeList = [];
  public questionaryList = [];
  public studyList = [];
  public indicationsList = [];
  public indicationsPatientList = [];
  public containerTypeList = [];
  public containers = [
    // {
    //   color: "#e44",
    //   type: "Tubo Rojo subrogado",
    //   sampleType: "Suero",
    //   tags: ["Etiqueta 1", "Etiqueta 2"],
    // },
  ];
  public originDetermin:any = [];
  public originContain:any = [];
  public originSubstudy:any = [];

  public products = [
    {
      name: "Acido Muriatico",
      quantity: 1,
    },
    {
      name: "Tubo de ensayo",
      quantity: 2,
    },
  ];
  public determinations = [];
  public substudies = [];
  public week = [
    {label:"Lun", target:"Monday"},
    {label:"Mar", target:"Tuesday"},
    {label:"Mie", target:"Wednesday"},
    {label:"Jue", target:"Thursday"},
    {label:"Vie", target:"Friday"},
    {label:"Sab", target:"Saturday"},
    {label:"Dom", target:"Sunday"}
  ];
  public abreviationList = [
  ];
  public indicationsPatient = "";
  public indicationsCustomer = "";
  public procesTypeList = [
    {value: 'LV' , label:"Lunes a Viernes"},
    {value: 'LS' , label:"Lunes a Sabado"},
    {value: 'TD' , label:"Todos los dias"},
  ];
 

  // MODALES 
  public modalDeterminationsData = {
    name: "ACIDO VANILMADELICO EN ORINA DE 24 HORAS",
    unit: " mg/24hrs",
    order: 2,
    legend: "Leyenda",
    substudy: "",
    determinations: [
      {
        range:{
          start: 1,
          end: 7,
          metric: "YEARS",
        },
        patient: {
          sex: "any",
          smoker: false,
          pregnancy: false,
        },
        value: {
          type: "NUMERIC",
          start: 1.5,
          end: 7.4,
          criticStart: 7.5,
          criticEnd: 10,
          note: ""
        },
      },
      {
        range:{
          start: 1,
          end: 7,
          metric: "MOTHS",
        },
        patient: {
          sex: "any",
          smoker: false,
          pregnancy: true,
        },
        value: {
          type: "DESCRIPTIVE",
          start: 1.5,
          end: 7.4,
          criticStart: 7.5,
          criticEnd: 10,
          note: ""
        },
      },
      {
        range:{
          start: 1,
          end: 7,
          metric: "años",
        },
        patient: {
          sex: "any",
          smoker: true,
          pregnancy: false,
        },
        value: {
          type: "INTERPRETATIVE",
          start: 1.5,
          end: 7.4,
          criticStart: 7.5,
          criticEnd: 10,
          note: ""
        },
      }
    ],
    expanded: false,
  };

  constructor(
      private modal: NgbModal,
      private fb: FormBuilder,
      public studyServices: StudyService,
      public validatorsService: ValidatorsService,
      public validateForm: ValidateFormsDirective,
      private studiesService: StudiesService,
      private alertService: AlertService,
      private userService: UserService
    ) { 

    this.studiesService.openPanel.subscribe(async () => {
      this.abreviationList = []
      this.containers = [];
      this.ngOnInit();
    });
    }

  async ngOnInit(){
    this.StudyForm = this.fb.group({
      id: [null],
      longName: [null, Validators.compose([Validators.required])],
      synonymous: [null,  Validators.compose([Validators.required])],
      shortName: [null,  Validators.compose([Validators.required])], 
      titleReport: [null,  Validators.compose([Validators.required])], 
      processDaysPatient: [null,  Validators.compose([Validators.required])],
      processDaysCustomer: [null,  Validators.compose([Validators.required])],
      typeProcessDays: ['TD'],
      departmentId: [null],
      printOrder: [this.NRegisters + 1],
      methodId: [null],
      unitConversionId:[null],
      sampleTypeId: [null],
      questionary:[null],
      isSurrogate: [false],
      isUrgency: [false],
      isSpecial: [false],
      isRutina: [false],
      isSimilar: [false],
      inUse:[false],
      requireFileAditional: [false],
      isCertified: [false],
      abbreviation: [[]],
      statusActive:[true],
      especialPrices: ['N/A'],
      availableDays: this.fb.group({
        Monday:   [true],
        Tuesday:  [true],
        Wednesday:[true],
        Thursday: [true],
        Friday:   [true],
        Saturday: [true],
        Sunday:   [true]
      }),
      containers: [[]],
      indicationsClientId: [null],
      indicationsPatientId: [null],
      studySimilar: [null],
      showListPrice: [false],
      determinations: [[]],
      subStudies: [[]]
    });

    // Hereda las variablas para ser usadas en evento window.beforeunload
    var _this = this;

    window.addEventListener("beforeunload", function (e) {
       if (e) {
        if (_this.editFlag &&  _this.modalOptions?.type == 'EDIT') {
          _this.editFlag = false;
          _this.saveAsFlag = false;
          _this.modalOptions.available = true;
          _this.modalOptions.userEditing_userName = null;
          _this.editingReg();
        }
       }
    });

    this.indicationsCustomer = "";
    this.indicationsPatient = "";

   
    if (this.modalOptions.type == "EDIT") { 
     await this.getStudyDetail();
    }else{
      this.editFlag = true;
    }
  }

  async ngAfterContentInit(){
    await this.getCatalogues();
  }

  async ngOnDestroy() {
    this.modalOptions.available = true;
    this.editFlag && this.modalOptions?.type == 'EDIT' ? await this.editingReg() : '';
  }


  public openModal(template: any, size = "md", data = null){
    !data || (this.modalOptions.item = data);
    this.modal.open(template, {size: size});
  }

  public openModalContainer(template: any, size = "md", data = null){
    !data || (this.modalOptionsContainers.item = data);
    this.modal.open(template, {size: size});
  }

  public openModalSubE(template: any, size = "md", data = null){
    !data || (this.modalOptionsSubE.item = data);
    this.modal.open(template, {size: size});
  }

  public openModalDeter(template: any, size = "md", data = null){
    !data || (this.modalOptionsDeter.item = data);    
    this.modalOptionsDeter.length = this.StudyForm.value?.determinations?.length;
    this.modal.open(template, {size: size});
  }

  public getCatalogues = async () => {
    return new Promise(async (resolve) => {

    try {
     //////////////// .W Conaulta para catalogos ////////////////
      //Departamentos
        await this.studyServices.getDepartmentsSelect().then(resp => {
          this.departmentsList = resp;
        });
      //Metodologia
        await this.studyServices.getMethodsSelect().then(resp => {
          this.methodsList = resp;
        });
      //Unidad de Conversion
        await this.studyServices.getUnitConversionSelect().then(resp => {
          this.unidConversionList = resp;
        });
      //Tipo de muestra
        await this.studyServices.getSampleTypeSelect().then(resp => {
          this.sampleTypeList = resp;
        });
      //Questionario
        await this.studyServices.getQuestionarySelect().then(resp => {
          this.questionaryList = resp;          
        });
      //Estudios
        await this.studyServices.getStudySelect().then(resp => {
          this.studyList = resp;
        });
      //Indicaciones
        await this.studyServices.getIndicationsAll().then(resp => {
          this.indicationsList = resp;
        });
      //Indicaciones Cliente
        await this.studyServices.getIndicationsClients().then(resp => {
          this.indicationsPatientList = resp;
        });
      //Tipo de Contenedor
        await this.studyServices.getContainerType().then(resp => {
          this.containerTypeList = resp;
        });

    } catch (error) {
      console.log(error);
    }
      resolve(true);
    });
  }

  public Submit = async ()  =>  {   

    if (!this.StudyForm.valid) {
      this.alertService.infoToast("Campos incompletos");      
      this.StudyForm.markAllAsTouched();        
      return;
    }

    this.StudyForm.patchValue({
      abbreviation:  this.abreviationList
    });

    if(this.modalOptions.type == "ADD" || this.saveAsFlag){
      //Reinicia estatus de collapse
        this.collapseGroup = {
          containers: true,
          products: true,
          determinations: true,
          substudies: true,
      }

      //Elimina _id temporales al agregar.
      await this.StudyForm?.value?.containers?.forEach(async element => {
        delete element?._id;
        element.type = element?.type?._id;
        element.sample = element?.sample?._id
      });  

      //Elimina _id temporales al guardar determinaciones.
      await this.StudyForm?.value?.determinations?.forEach(async element => {
        delete element?._id;
      });  

      //Elimina _id temporales al guardar sub-studios.
      await this.StudyForm?.value?.subStudies?.forEach(async element => {
        delete element?._id;
      });  

      let obj = Object.keys(this.StudyForm.value).filter((k) => this.StudyForm.value[k] != null).reduce((a, k) => ({ ...a, [k]: this.StudyForm.value[k] }), {});

     // -B API para guardar formulario
      await this.studyServices.postStudy(obj).then(resp => {
        this.isLoading = false;
        this.alertService.successToast(resp);
        this.editFlag = false;
        this.onSave.emit();

      });
    }else if(this.modalOptions.type == "EDIT" && !this.saveAsFlag){
      let originDeterminParsed = JSON.parse(this.originDetermin);
      let originContainParsed = JSON.parse(this.originContain);
      let originSubstudyParsed = JSON.parse(this.originSubstudy);

      // Elimina id de determinations nuevos
      for (let index = 0; index < this.StudyForm?.value?.determinations?.length; index++) {
        if (!originDeterminParsed.some(item =>{return item._id == this.StudyForm?.value?.determinations[index]._id})) {
          delete this.StudyForm?.value?.determinations[index]?._id;
        }
      }

      // Elimina id de containers nuevos
      for (let index = 0; index < this.StudyForm?.value?.containers?.length; index++) {
        if (!originContainParsed.some(item =>{return item._id == this.StudyForm?.value?.containers[index]._id})) {
          delete this.StudyForm?.value?.containers[index]?._id;
        }
      }

      // Elimina id de determinations nuevos
      for (let index = 0; index < this.StudyForm?.value?.subStudies?.length; index++) {
        if (!originSubstudyParsed.some(item =>{return item._id == this.StudyForm?.value?.subStudies[index]._id})) {
          delete this.StudyForm?.value?.subStudies[index]?._id;
        }
      }

      this.StudyForm.patchValue({
        id: this.modalOptions._id
      });

      let obj = Object.keys(this.StudyForm.value).filter((k) => this.StudyForm.value[k] != null).reduce((a, k) => ({ ...a, [k]: this.StudyForm.value[k] }), {});
    
      // -B API para actualizar formulario
      await this.studyServices.putStudy(obj).then(resp => {
        this.isLoading = false;
        this.editFlag = false;
        this.alertService.successToast("Estudio actualizado exitosamente");
        this.onSave.emit();
      });
    }


  }

  public setIndications = (event, type) => {
    return new Promise(async (resolve)=>{
      let formatedArray = [];
        event?.indications.forEach(element => {
          formatedArray.push('** ' + element)
        });
      if (type == "customer") {
        this.indicationsCustomer = formatedArray?.reverse().join('\r\n');
        resolve(true)
      }else if (type == "patient"){
        this.indicationsPatient = formatedArray?.reverse().join('\r\n');
        resolve(true)
      }
    });
  }

  public AddContainer = (container) => { 
  container.labels = JSON.parse(container?.labels) || [];
  
   this.containers.push(container);
    this.StudyForm.patchValue({
      containers: this.containers
    });    
  }

  public deleteContainer = (event) => {
      try {
        
        this.containers = this.containers.filter(item => {return item?._id != event?._id});
        this.StudyForm.patchValue({
          containers: this.containers
        })        
        this.alertService.successToast("Contenedor eliminado exitosamente.")    
      } catch (error) {
        console.log(error);
      }    
  }

  public EditContainer = (container) =>{    
   let Index = this.containers.findIndex(item => {return item?._id == container?._id});
   if (Index >= 0) {
    this.containers[Index] = container;
    this.containers[Index].labels = JSON.parse(container.labels);
    this.alertService.successToast("Contenedor Actualizado exitosamente")
   }else{
    this.alertService.infoToast("Contenedor no encontrado");
   }   
  }

  public AddsubStudy = (subStudy) =>{
    this.substudies.push(subStudy)
    this.StudyForm.patchValue({
      subStudies: this.substudies
    });
  }

  public EditsubStudy = (subStudy) =>{
    let Index = this.substudies.findIndex(item => {return item?._id == subStudy?._id});
    if (Index >= 0) {
     this.substudies[Index] = subStudy;
     this.alertService.successToast("Sub-estudio Actualizado exitosamente")
    }else{
     this.alertService.infoToast("Sub-estudio no encontrado");
    }   
  }
  public deletesubStudy = (subStudy) =>{
    this.substudies = this.substudies.filter(item => {return item._id != subStudy?._id });
    this.alertService.successToast("Sub-estudio eliminado exitosamente.")    

  }

  //Agrega Determinacion
  public assignDeterm = (determination) => {    
    this.StudyForm.value.determinations.push(determination);

     let A =  this.StudyForm?.value?.determinations.findIndex(item => {return item._id == determination._id});
     let B =  this.StudyForm?.value?.determinations.findIndex(item => {return item.printOrder == determination?.printOrder})
      
      for (let index = 0; index < this.StudyForm?.value?.determinations.length; index++) {
        let element = this.StudyForm?.value?.determinations[index];

        if (A > B) {
          if (A >= index && B <= index) {// Si esta en el rango
            if (A != index) {
              element.printOrder++;
            }
          }
        }else{
          if (A <= index && B >= index) {// Si esta en el rango
            if (A != index) {
              element.printOrder--;
            }
          }
        }
      }
    
        // O Sort de array 
        this.StudyForm.value.determinations = this.StudyForm.value.determinations.sort((a, b) => a.printOrder - b.printOrder);
  }
  //Edita Determinacion
  public editDeterm = (determination) => {  

     let A =  this.StudyForm?.value?.determinations.findIndex(item => {return item._id == determination._id});
     let B =  this.StudyForm?.value?.determinations.findIndex(item => {return item.printOrder == determination?.printOrder})

      for (let index = 0; index < this.StudyForm?.value?.determinations.length; index++) {
        let element = this.StudyForm?.value?.determinations[index];

        if (A > B) {
          if (A >= index && B <= index) {// Si esta en el rango
            if (A == index) {
              this.StudyForm.value.determinations[index] = determination;
            }else{
              element.printOrder++;
            }
          }
        }else{
          if (A <= index && B >= index) {// Si esta en el rango
            if (A == index) {
              this.StudyForm.value.determinations[index] = determination;
            }else{
              element.printOrder--;
            }
          }
        }
      }
   
      // -R Sort de array de datos
      this.StudyForm.value.determinations = this.StudyForm.value.determinations.sort((a, b) => a.printOrder - b.printOrder);
      this.alertService.infoToast("Determinacion Actualizada");
  }

  public deleteDeterm = (id) =>{   
     
    this.StudyForm.value.determinations = this.StudyForm?.value?.determinations.filter(item => {return item._id != id});
    // Reasigna numeros de impresion
    for (let index = 0; index < this.StudyForm.value?.determinations?.length; index++) {
      this.StudyForm.value.determinations[index].printOrder = index;
    }

    this.alertService.infoToast("Determinación eliminada");

  }

  public buildFormData = (formData, data, parentKey?) => {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {

        this.buildFormData(formData, data[key], parentKey ? `${parentKey}` : key);
      });
    } else {
      const value = data == null ? '' : data;

      formData.append(parentKey, value);
    }
  }

  public subStudieName = (idSubstudie) => {
    return this.substudies.find(item => {return item._id == idSubstudie})?.name
  }

  public movePosition = (item, position) => {
    if (position == 1) {// BAJANDO
      //Reasignacion de posicion al dato debajo del target
      this.StudyForm.value.determinations[item?.printOrder + 1].printOrder = item?.printOrder;
    }else{// SUBE
      //Reasignacion de posicion al dato de arriba del target
      this.StudyForm.value.determinations[item?.printOrder - 1].printOrder = item?.printOrder;
    }
      // W Asigna la posicion nueva
      this.StudyForm.value.determinations[item?.printOrder].printOrder = item?.printOrder + (position);   

    // O Sort de array
    this.StudyForm.value.determinations = this.StudyForm.value.determinations.sort((a, b) => a.printOrder - b.printOrder);
  }

  public async editingReg(): Promise<boolean>{ 
    return new Promise(async (resolve) => {

      if(this.modalOptions?.available == false){
        this.alertService.infoToast("El registro esta siendo editado por " + this.modalOptions?.userEditing_userName);

         resolve(true);
      }else{
        await this.studyServices.availableStudy({id: this.modalOptions?._id}).then(async (resp: any) => {
          this.editFlag = !resp?.available;
          this.modalOptions.available = resp?.available;
          this.isLoading = false;
          resolve(true);

        }).catch((err: any) => {
          console.log(err);
          this.isLoading = false;
          resolve(true);
      });
      }
    });

  }

  public cancelEdition = async () => {
    this.editFlag = false;
    this.modalOptions.available = true;
    this.modalOptions.userEditing_userName = null;
    if(!this.saveAsFlag){await this.editingReg();}
    await this.getStudyDetail();
    this.saveAsFlag = false;
  }

  public cancelSaveAs = async () =>{
    this.editFlag = false;
  }

  public getStudyDetail = async () => {
    this.isLoading = true;     
    await this.studyServices.getStudy(this.modalOptions._id).then(async (resp: any) => {
      this.StudyForm.patchValue({
        shortName: resp?.shortName,
        longName: resp?.longName,
        synonymous: resp?.synonymous,
        titleReport: resp?.titleReport,
        typeProcessDays: resp?.typeProcessDays,
        questionary: resp?.questionary,
        indicationsPatient: resp?.indicationsPatient,
        isUrgency: resp?.isUrgency,
        availableDays: resp?.availableDays,
        processDaysPatient: resp?.processDaysPatient,
        containers: resp?.containers,
        statusActive: resp?.statusActive,
        departmentId: resp?.departmentId,
        printOrder: resp?.printOrder,
        methodId: resp?.methodId,
        unitConversionId: resp?.unitConversionId,
        sampleTypeId: resp?.sampleTypeId,
        isSurrogate: resp?.isSurrogate,
        isSpecial: resp?.isSpecial,
        isRutina: resp?.isRutina,
        isSimilar: resp?.isSimilar,
        inUse: resp?.inUse,
        requireFileAditional: resp?.requireFileAditional,
        isCertified: resp?.isCertified,
        abbreviation: resp?.abbreviation,
        especialPrices: resp?.especialPrices,
        indicationsClientId: resp?.indicationsClientId,
        indicationsPatientId: resp?.indicationsPatientId,
        studySimilar: resp.studySimilar?._id,
        showListPrice: resp?.showListPrice,
        processDaysCustomer: resp?.processDaysCustomer,
        determinations: resp?.determinations
      });
      
      // Asigna a listas los registros ya guardados
      this.originDetermin = JSON.stringify(resp?.determinations || []);
      this.originContain =  JSON.stringify(resp?.containers || [] );
      this.originSubstudy =  JSON.stringify(resp?.subStudies || []);
      
      // ===========================================
      let indiClient = await this.indicationsList.find(async element => {return element._id = resp?.indicationsClientId});
      let indiPatient = await this.indicationsPatientList.find(async element => {return element._id = resp?.indicationsPatientId});

      await this.setIndications(indiClient, 'customer');
      await this.setIndications(indiPatient, 'patient');

      //? Asignacion a lista de array obtenido en consulta.
      this.abreviationList = resp.abbreviation;
      
      //? Se genera un id temporal para cada contenedor///
      resp?.containers.forEach(async element => {
        //element.type  =  this.containerTypeList.find( item1 => {return item1._id == element?.type});
        //element.sample =  this.sampleTypeList.find( item2 => {return item2._id == element?.sample});
        // if (!element._id) {
        //   element._id = Date.now();
        // }
        this.containers.push(element);
      });
       // Asigna estatus de disponibilidad para editar registro y nombre de quien lo esta editando.
       this.modalOptions.available = resp?.available;
       this.modalOptions.userEditing_userName = resp?.userEditing_userName;

      //? Se genera un id temporal para cada subEstudio///
      resp?.subStudies?.forEach(element => {
        if (!element?._id) {
          element._id = Date.now();
        }
        this.substudies.push(element);
      });
    
      this.isLoading = false;
      
    }).catch((err: any) => {
      this.isLoading = false;
      console.log(err.message);
    });
  }

  public saveAs = () => {
    this.StudyForm.patchValue({
      longName: null,
      shortName: null,
      printOrder: this.NRegisters + 1
    });
    this.saveAsFlag = true;

  }
 
}
