import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalPatientComponent } from './modal-patient/modal-patient.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';  
import { ageByBirthdayPipe } from 'src/app/shared/pipes/ageByBirthday.pipe';
import { TextMaskModule } from 'angular2-text-mask';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { ModalMedicComponent } from '../modals/modal-medic/modal-medic.component';
import { ModalClientComponent } from '../modals/modal-client/modal-client.component';
import { ModalReferenceComponent } from './modal-reference/modal-reference.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ModalStudyComponent } from './modal-study/modal-study.component';
import { ModalAbbreviationsComponent } from '../components/studies/components/modal-abbreviations/modal-abbreviations.component';
import { ModalContainersComponent } from '../components/studies/components/modal-containers/modal-containers.component';
import { ModalProductsComponent } from '../components/studies/components/modal-products/modal-products.component';
import { ModalDeterminationComponent } from '../components/studies/components/modal-determination/modal-determination.component';
import { ModalSubstudyComponent } from '../components/studies/components/modal-substudy/modal-substudy.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { ModalDownloadFileComponent } from './modal-download-file/modal-download-file.component';
import { ModalFormulaComponent } from '../components/studies/components/modal-formula/modal-formula.component';
import { MentionModule } from 'angular-mentions';
@NgModule({
  declarations: [
    ModalPatientComponent,
    ModalMedicComponent, 
    ModalClientComponent, 
    ModalReferenceComponent,
    ModalStudyComponent,
    ModalAbbreviationsComponent,
    ModalContainersComponent,
    ModalProductsComponent,
    ModalDeterminationComponent,
    ModalSubstudyComponent,
    ModalDownloadFileComponent,
    ModalFormulaComponent
  ],
  imports: [ 
    CommonModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule, 
    NgxDaterangepickerMd.forRoot(),
    MatDatepickerModule,
    MatNativeDateModule,
    PipesModule,
    TextMaskModule,
    ComponentsModule,
    SweetAlert2Module.forRoot(),
    MatTableModule,
    MatPaginatorModule, 
    MatSortModule,
    MentionModule
  ],
  exports:[
    ModalPatientComponent,
    ModalMedicComponent, 
    ModalClientComponent,
    ModalReferenceComponent,
    ModalStudyComponent,
    ModalFormulaComponent
  ], 
  providers: [ 
    ageByBirthdayPipe,
    ValidateFormsDirective 
  ]
})
export class CatalogModalsModule { }


