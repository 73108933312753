<mk-loader *ngIf="isLoading" class="loader-spinner"></mk-loader>
<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">{{ modalOptions.type == 'EDIT'  ? 'Editar' : (modalOptions.type == 'ADD' ? 'Agregar' : 'Ver') }} Paciente</h6>
     <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body py-0"> 
    <form [formGroup]="FormPatient" novalidate>
        <div class="row">
            <div class="col-sm-12 col-lg-6 py-3 border-right">
                <div class="row">
                    <div class="col-12">
                        <h6>Nombre <span class="text-danger" *ngIf="modalOptions.type != 'EDIT'">*</span></h6>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Nombre(s) y  Apellido(s)" formControlName="name" maxlength="60">
                        </div>
                        <small *ngIf="validateForm.campoEsValido('name', FormPatient)" class="text-danger">Campo requerido<br>Máximo 60 caracteres</small> 
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-6">
                        <h6>Fecha de Nacimiento <span class="text-danger" *ngIf="modalOptions.type != 'EDIT'">*</span></h6> 
                        <input type="text" class="form-control" 
                            [textMask]="{mask: mask}" 
                            formControlName="birthDate"
                            (change)="selectedDate()"  
                            placeholder="DD/MM/YYYY">  
                        <small *ngIf="invalidDate" class="text-danger">Fecha invalida</small>
                    </div>
                    <div class="col-6">
                        <h6>Edad <span class="text-danger" *ngIf="modalOptions.type != 'EDIT'">*</span></h6>
                        <div class="input-group">
                            {{ageMeasureSelected}}
                            <input type="text" class="form-control"  [readonly]="validDate" placeholder="Edad" maxlength="3" formControlName="age"> 
                            <ng-select class="form-control px-1 text-left" [clearable]="false"  [readonly]="validDate"
                                style="width: 50px;"
                                [items]="ageMeasure"
                                bindLabel="text"
                                bindValue="value" formControlName="ageMeasure">  
                            </ng-select> 
                        </div>
                        <small *ngIf="validateForm.campoEsValido('age', FormPatient) || validateForm.campoEsValido('ageMeasure', FormPatient)" class="text-danger">Campo requerido<br>Máx 120</small>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12">
                        <h6>Género <span class="text-danger" *ngIf="modalOptions.type != 'EDIT'">*</span></h6> 
                        <div class="input-group">
                            <ng-select name="" id="" class="form-control px-1 text-left" [clearable]="false" formControlName="gender" placeholder="Selecciona Género">
                                <ng-option value="MALE">Masculino</ng-option>
                                <ng-option value="FEMALE">Femenino</ng-option>
                            </ng-select>
                        </div>
                        <small *ngIf="validateForm.campoEsValido('gender', FormPatient)" class="text-danger">Campo requerido</small>    
                    </div>  
                    <div class="col-12 mt-2">
                        <h6>Teléfono(s)</h6>
                        <div class="input-group">
                            <ng-select class="form-control px-1 text-left" [disabled]="blockEdition" [clearable]="false" [(ngModel)]="typePhone" (change)="changePhoneType($event)"
                                [ngModelOptions]="{standalone: true}" placeholder="Tipo de telefono">
                                <ng-option value="CEL">Celular</ng-option>
                                <ng-option value="HOME">Casa</ng-option>
                                <ng-option value="OFFICE">Oficina</ng-option>
                            </ng-select>
                            <input type="text" class="form-control" 
                                (keypress)="validator.isInteger($event);"  
                                [placeholder]="typePhone!='OFFICE'?'(___)___-____':'(___)___-____;__'" 
                                [(ngModel)]="inpPhone"
                                [ngModelOptions]="{standalone: true}"
                                [textMask]="{mask: phoneMask, guide: false}" 
                                [disabled]="blockEdition"
                            >
                            <div class="input-group-append">
                                <button type="button" class="btn btn-dark" (click)="addPhones()" [disabled]="inpPhone?.length < 9"><i class="fa fa-plus mr-2"></i>Añadir</button>
                            </div>
                        </div>
                    </div>   
                    <div class="col-12 d-flex flex-wrap pt-2" *ngIf="phones?.length">
                        <ng-container *ngFor="let item of phones; index as i">
                            <span class="_pill badge-{{item.primary ? 'primary-light' : 'medium-light'}} d-flex m-0 mb-1 mr-1" >
                                <div class="d-flex cursor-pointer" (click)="primaryPhone(true, i)">
                                    <span *ngIf="item.primary" style="margin-left: -5px;"><i class="fa fa-star mr-1 text-warning"></i></span>
                                    <b>{{item.phone}} <i class="fa fa-{{item.type == 'HOME' ? 'home':(item.type == 'OFFICE'?'building':'mobile')}} ml-1"></i></b>
                                </div>
                                <i class="fa fa-close ml-2 my-auto cursor-pointer text-danger" (click)="phones.splice(i,1)"></i>
                            </span>
                        </ng-container>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12">
                        <h6>Correo(s)</h6>
                        <div class="input-group">
                            <input #inpEmail type="text" [disabled]="blockEdition" class="form-control" placeholder="Correo" maxlength="40"> 
                            <ng-select class="form-control" placeholder="Dominio" 
                                [clearable]="false"  
                                [items]="domainsData"
                                bindLabel="name" bindValue="name"
                                [(ngModel)]="domain"
                                [ngModelOptions]="{standalone: true}"
                                [disabled]="blockEdition"
                            ></ng-select>
                            <div class="input-group-append"> 
                                <button type="button" class="btn btn-dark"  (click)="addEmails(inpEmail.value, domain)" [disabled]="!inpEmail.value || !domain"><i class="fa fa-plus mr-2"></i>Añadir</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex flex-wrap pt-2" *ngIf="emails?.length">
                        <ng-container *ngFor="let item of emails; index as i">
                            <span class="_pill badge-{{item.primary ? 'primary-light' : 'medium-light'}} d-flex m-0 mb-1 mr-1" >
                                <div class="d-flex cursor-pointer" (click)="primaryMail(true, i)">
                                    <span *ngIf="item.primary" style="margin-left: -5px;"><i class="fa fa-star mr-1 text-warning"></i></span>
                                    <b>{{item.email}}</b>
                                </div>
                                <i class="fa fa-close ml-2 my-auto cursor-pointer text-danger" (click)="emails.splice(i,1)"></i>
                            </span>
                        </ng-container>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12">
                        <h6>Dirección</h6>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Dirección" formControlName="address" maxlength="100">
                        </div>
                        <small *ngIf="validateForm.campoEsValido('address', FormPatient)" class="text-danger">Máximo 100 caracteres</small>    
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-lg-6 py-3">
                <div class="row">
                    <div class="col-12">
                        <h6>Membresía <small class="text-info font-weight-normal">(La membresia se modifica desde el modulo de membresia.)</small></h6>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Número de Membresía" formControlName="membership" maxlength="20" disabled>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-6">
                        <h6>Aseguranza</h6>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Aseguranza" formControlName="insurance" maxlength="30">
                        </div>
                        <small *ngIf="validateForm.campoEsValido('insurance', FormPatient)insurance" class="text-danger">Máximo 30 caracteres</small>    
                    </div>
                    <div class="col-6">
                        <h6 >Enviar por</h6>
                        <div class="btn-group mt-1">
                            <button type="button" [disabled]="blockEdition" (click)="changeSendMethod('email',!sendPatient.email)" class="btn btn-sm btn-icon btn-{{sendPatient?.email ? 'success' : 'light'}}"><i class="fa fa-envelope"></i></button>
                            <button type="button" [disabled]="blockEdition" (click)="changeSendMethod('print',!sendPatient.print)" class="btn btn-sm btn-icon btn-{{sendPatient?.print ? 'success' : 'light'}}"><i class="fa fa-print"></i></button>
                            <button type="button" [disabled]="blockEdition" (click)="changeSendMethod('web',!sendPatient.web)" class="btn btn-sm btn-icon btn-{{sendPatient?.web ? 'success' : 'light'}}"><i class="fa fa-laptop"></i></button>
                            <button type="button" [disabled]="blockEdition" (click)="changeSendMethod('mobile',!sendPatient.mobile)" class="btn btn-sm btn-icon btn-{{sendPatient?.mobile ? 'success' : 'light'}}"><i class="fa fa-mobile"></i></button>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12">
                        <div class="d-flex justify-content-between mt-2">
                            <h6>Comentario</h6>
                            <small class="text-info d-block">{{FormPatient?.value?.comment?.length|| 0}} / 250</small>
                        </div>
                        <textarea maxlength="250" rows="3" class="form-control" placeholder="Ingresa comentario sobre el paciente"  formControlName="comment"></textarea>
                        <small class="tx-danger" *ngIf="validateForm.campoEsValido('comment', FormPatient)">Max. 250 caracteres</small>    
                    </div>
                </div>
                <ng-container *ngIf="showBillingDetail" formGroupName="billing">
                    <div class="row mt-2 border-top pt-2">
                        <div class="col-6">
                            <h6 class="text-primary">Razón Social</h6>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Razón Social" formControlName="businessName" maxlength="60">
                            </div>
                            <small *ngIf="validateForm.campoEsValido('businessName', FormPatient, true, 'billing')" class="text-danger">Máximo 60 caracteres</small>    
                        </div>
                        <div class="col-6">
                            <h6 class="text-primary">RFC</h6>
                            <div class="input-group">
                                <input type="text" class="form-control text-uppercase" placeholder="RFC" formControlName="rfc" maxlength="13">
                            </div> 
                            <small *ngIf="validateForm.campoEsValido('rfc', FormPatient, true, 'billing')" class="text-danger">Máximo 13 caracteres<br>Mínimo 12 caracteres</small>    
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12">
                            <h6 class="text-primary">Forma de pago</h6>
                            <div class="input-group"> 
                                <ng-select class="form-control" 
                                    [items]="paymentForm" 
                                    [multiple]="false" 
                                    placeholder="Forma de pago" 
                                    bindLabel="text" 
                                    appearance="true" 
                                    bindValue="value" 
                                    formControlName="paymentType">
                                </ng-select>
                            </div> 
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12">
                            <h6 class="text-primary">Método de pago</h6>
                            <div class="input-group">
                                <ng-select class="form-control" 
                                    [items]="paymentMethod" 
                                    [multiple]="false" 
                                    placeholder="Método de pago" 
                                    bindLabel="text" 
                                    appearance="true" 
                                    bindValue="value"
                                    formControlName="paymentMethod">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12">
                            <h6 class="text-primary">Uso de CFDI</h6>
                            <div class="input-group">
                                <ng-select class="form-control" 
                                    [items]="useOfCfdi" 
                                    [multiple]="false"
                                    placeholder="Uso de CFDI" 
                                    bindLabel="text" 
                                    appearance="true" 
                                    bindValue="value"
                                    formControlName="cfdi">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-danger" (click)="close()">Cancelar</button> 
    <button type="submit" class="btn btn-success" (click)="submit()" *ngIf="!blockEdition" [disabled]="!FormPatient.valid">Guardar</button>
</div>